import Health from "./Health";
import LmfHealth from "./LmfHealth";
import SmartPhone from "./SmartPhone";
import SoLyonHealth from "./SoLyonHealth";

export default {
  Health,
  SmartPhone,
  SoLyonHealth,
  LmfHealth,
};

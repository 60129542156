import { find, get, isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdAdd, MdCheckCircle, MdDeleteOutline } from "react-icons/md";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import format from "string-template";
import { CONTENT_TYPES, DATE_FORMAT, DOCUMENTS_TYPES } from "utils/constants";

const Initial = ({
  onSign,
  options,
  setSelectedSubscription,
  selectedSubscription,
  manualLoading,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const SIGN_PROJECT = t("LIST.SIGN_PROJECT", { returnObjects: true });
  const [signedDocuments, setSignedDocuments] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [attachments, setAttachments] = useState({});
  const [errors, setErrors] = useState("");

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const onFileRemove = async ({ uid: id, key }) => {
    setAttachments((prev) => ({
      ...prev,
      [key]: [...prev[key]]?.filter((file) => file.uid !== id),
    }));
  };
  useEffect(() => {
    const selectedOption = options.find(
      ({ value }) => value === selectedSubscription
    );

    if (
      selectedOption?.issuanceDate &&
      selectedOption?.deltaStart &&
      selectedDate &&
      selectedDate
        .add(selectedOption?.deltaStart, "days")
        .startOf("day")
        .isAfter(moment(selectedOption?.issuanceDate).startOf("day"))
    ) {
      return setErrors(
        format(SIGN_PROJECT.SIGN_DATE_ERROR, {
          date: moment(selectedOption?.issuanceDate)
            .subtract(selectedOption?.deltaStart, "days")
            .format(DATE_FORMAT),
        })
      );
    }
    setErrors("");
  }, [selectedDate, selectedSubscription]);

  return (
    <div className="sign-project__container">
      <Select
        label={SIGN_PROJECT.CHOOSE_FORMULA}
        placeholder={SIGN_PROJECT.CHOOSE_PLACEHOLDER}
        type="subtext"
        options={options}
        onChange={(option) => {
          setSignedDocuments(
            get(find(options, { value: option }), "generatedDocuments", [])
          );
          setSelectedSubscription(option);
        }}
      />

      <Input
        type="date"
        format="DD/MM/YYYY"
        label={SIGN_PROJECT.SIGN_DATE}
        placeholder={SIGN_PROJECT.SIGN_DATE}
        onChange={handleDateChange}
        disabledDate={(current) => current > moment()}
        touched
        errors={errors}
      />
      <div className="divider" />
      {Object.keys(signedDocuments).length > 0 && (
        <>
          <div className="sign-project__attachments--title">
            <h4>{SIGN_PROJECT.ATTACHMENTS}</h4>
            <span>{SIGN_PROJECT.ATTACHMENTS_SIZE}</span>
          </div>
          {Object.keys(signedDocuments).map((document) => (
            <div key={`document-${document}`}>
              <div className="sign-project__attachments">
                <p>{SIGN_PROJECT[document]}</p>
                {!isEmpty(attachments[DOCUMENTS_TYPES[document]]) && (
                  <div className="sign-project__attachments--list">
                    {attachments[DOCUMENTS_TYPES[document]].map((file) => (
                      <div
                        className="sign-project__attachments--item"
                        key={file?.uid}
                      >
                        <div className="sign-project__attachments--item--left">
                          <MdCheckCircle color={COLORS.C_SUCCESS} size={16} />
                          <p>{file?.name}</p>
                          <span>{`(${(file?.size / 1024).toFixed(
                            1
                          )} KB)`}</span>
                        </div>
                        <div
                          className="sign-project__attachments--item--delete"
                          onClick={() =>
                            onFileRemove({
                              uid: file?.uid,
                              key: DOCUMENTS_TYPES[document],
                            })
                          }
                        >
                          <MdDeleteOutline size={16} />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <Input
                  type="file"
                  variant="uploader"
                  listType="text"
                  onUpload={(fileList) => {
                    setAttachments((prev) => ({
                      ...prev,
                      [DOCUMENTS_TYPES[document]]: fileList,
                    }));
                  }}
                  validTypes={[
                    CONTENT_TYPES.PNG,
                    CONTENT_TYPES.JPEG,
                    CONTENT_TYPES.JPG,
                    CONTENT_TYPES.PDF,
                  ]}
                  fileList={attachments[DOCUMENTS_TYPES[document]]}
                  showUploadList={false}
                >
                  <Button type="primary--link">
                    <MdAdd size={12} />
                    {SIGN_PROJECT.ADD_FILES}
                  </Button>
                </Input>
              </div>
              <div className="divider" />
            </div>
          ))}
        </>
      )}

      <div className="m-top-24" />
      <Button
        type="primary"
        size="full--width"
        onClick={() =>
          onSign({
            subscriptionId: selectedSubscription,
            signedDate: selectedDate,
            attachments,
          })
        }
        disabled={
          !selectedDate ||
          !selectedSubscription ||
          !isEmpty(errors) ||
          manualLoading
        }
      >
        {SIGN_PROJECT.SUBMIT}
      </Button>
    </div>
  );
};

export default Initial;

import { get } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import Template from "../Template";
import View from "./View";

const SocialSecurityNumber = ({
  tracer,
  project,
  onNext,
  currentStatus,
  onBack,
}) => {
  const subscriptionId =
    get(project, "fields.currentSubscription") ||
    localStorage.getItem("subscriptionId");

  const { data, loading } = useQuery(graphql.queries.SUBSCRIPTION, {
    variables: { where: { id: subscriptionId } },
  });

  const onSubmit = ({ contact: { socialSecurityNumber } }) => {
    onNext({
      payload: {
        contact: {
          ...get(
            omitDeep(get(data, "subscription"), "__typename"),
            "project.contact",
            {}
          ),
          socialSecurityNumber,
        },
      },
    });
  };

  const initialValues = {
    contact: {
      ...get(data, "subscription.project.contact"),
      socialSecurityNumber: get(data, "subscription.project.contact.socialSecurityNumber"),
    },
  };

  return (
    <Template
      progress={currentStatus.progress}
      onBack={onBack}
      loading={loading}
      HeaderComponent={{
        subscription: get(data, "subscription"),
        tracer,
        project,
        contact: get(project, "contact"),
      }}
      View={
        <View
          initialValues={initialValues}
          locked={get(project, "locked", false)}
          onNext={onSubmit}
        />
      }
      contact={get(project, "contact")}
    />
  );
};
export default SocialSecurityNumber;

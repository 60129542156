import { get } from "lodash";
import { MdClose } from "react-icons/md";
import { CURRENCY_SYMBOL } from "utils/constants";
import Button from "../../../../Button";
import Cost from "../../../../Cost";

function View({ onSubmit, onClose, generalCost, upsells, totalAmount }) {
  return (
    <div className="solyon-help__modal">
      <div className="grid--1">
        <div className="solyon-recap__title">Votre panier</div>
        <div className="solyon-product-card">
          <img
            className="solyon-product-card__icon"
            alt={get(generalCost, "insurancePlan.name")}
            src={get(generalCost, "insurancePlan.icon")}
          />
          <Cost
            cost={totalAmount}
            currency={get(generalCost, "currency")}
            showYearly
          />
        </div>

        <div className="solyon-recap__prices">
          <div className="solyon-recap__title">Inclut dans le prix</div>
          <div className="solyon-recap__prices--row">
            <div>{get(generalCost, "insurancePlan.name")}</div>
            <span>{`${generalCost?.cost || ""} ${
              CURRENCY_SYMBOL[generalCost?.currency] || ""
            }`}</span>
          </div>
          {upsells.map((module) => (
            <div className="solyon-recap__prices--row">
              <div>{get(module, "insurancePlan.name")}</div>
              <span>{`${module?.cost} ${
                CURRENCY_SYMBOL[module?.currency]
              }`}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="d-flex">
        <Button type={"primary"} onClick={onSubmit}>
          Modifier
        </Button>
        <div className="flex--grow-1">
          <Button block type={"gray-light"} onClick={onClose}>
            <MdClose /> Fermer
          </Button>
        </div>
      </div>
    </div>
  );
}

export default View;

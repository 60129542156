import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { bindInputProps } from "utils/helpers/input";
import Button from "../Components/Button";
import DocumentDownload from "../Components/DocumentDownload";
import MemberInfo from "../Components/MemberInfo";
import PricingItem from "../Components/PricingItem";
import validation from "./validation";

const View = ({
  initialValues,
  generalCost,
  attachments,
  project,
  subscription,
  onSubmit,
  download,
  onEdit,
}) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("B2C_LMF.CONTRACT", { returnObjects: true });

  const { handleSubmit, values, setFieldValue, ...formProps } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
    ),
  });

  return (
    <>
      <h1 className="header-title">{TRANSLATION.TITLE}</h1>
      <span
        className="header-description"
        dangerouslySetInnerHTML={{ __html: TRANSLATION.DESCRIPTION }}
      ></span>
      <div className="__LMF--content large LMF-content">
        <div className="__LMF--content__content">
          <div className="__LMF--content__content--column">
            <PricingItem
              title={get(generalCost, "insurancePlan.name")}
              price={get(generalCost, "cost")}
              currency={get(generalCost, "currency")}
              per={"par mois"}
              breakdown={get(
                project,
                `fields.upsells.${get(subscription, "insurancePlan.id")}`
              )}
            />
            <DocumentDownload
              title={"Vos documents pré-contractuels"}
              items={attachments}
              download={(id) => download(id)}
            />
          </div>
          <div className="divider"></div>
          <div className="__LMF--content__content--column">
            <MemberInfo data={subscription} onEdit={(v) => onEdit(v)} />
          </div>
        </div>
        <div className="__LMF--content__contract">
          <span className="__LMF--content__contract--title">
            {TRANSLATION.OPT_INS_TITLE}
          </span>

          <div className="__LMF--content__contract--body">
            <span>{TRANSLATION.OPT_INS.DOCUMENTS.TITLE}</span>
            <div>
              <Input
                type="checkbox"
                label={TRANSLATION.OPT_INS.DOCUMENTS.DOCUMENT_INFOS}
                checked={get(values, "fields.acceptProductDocuments", false)}
                {...bindInputProps({
                  name: "fields.acceptProductDocuments",
                  values,
                  setFieldValue,
                  ...formProps,
                })}
              />
              <Input
                type="checkbox"
                label={TRANSLATION.OPT_INS.DOCUMENTS.STATUSES_AGREEMENT}
                checked={get(values, "fields.acceptProductStatuses", false)}
                {...bindInputProps({
                  name: "fields.acceptProductStatuses",
                  values,
                  setFieldValue,
                  ...formProps,
                })}
              />
              <Input
                type="checkbox"
                label={TRANSLATION.OPT_INS.DOCUMENTS.FEES}
                checked={get(values, "fields.acceptFees", false)}
                {...bindInputProps({
                  name: "fields.acceptFees",
                  values,
                  setFieldValue,
                  ...formProps,
                })}
              />
              <Input
                type="checkbox"
                label={TRANSLATION.OPT_INS.DOCUMENTS.NOTICE}
                checked={get(values, "fields.acceptNotice", false)}
                {...bindInputProps({
                  name: "fields.acceptNotice",
                  values,
                  setFieldValue,
                  ...formProps,
                })}
              />
            </div>
            <span>{TRANSLATION.OPT_INS.ACCEPT}</span>
            <span>{TRANSLATION.OPT_INS.INFOS.TITLE}</span>
            <div>
              <Input
                type="checkbox"
                label={TRANSLATION.OPT_INS.INFOS.AGREEMENT}
                checked={get(values, "fields.acceptTeletransmission", false)}
                {...bindInputProps({
                  name: "fields.acceptTeletransmission",
                  values,
                  setFieldValue,
                  ...formProps,
                })}
              />
              <Input
                type="checkbox"
                label={TRANSLATION.OPT_INS.INFOS.INFORMED}
                checked={get(values, "fields.acceptAgreement", false)}
                {...bindInputProps({
                  name: "fields.acceptAgreement",
                  values,
                  setFieldValue,
                  ...formProps,
                })}
              />
            </div>
            <span>{TRANSLATION.OPT_INS.HINT}</span>
          </div>
          <Button
            disabled={!isEmpty(formProps.errors)}
            onClick={handleSubmit}
            type={isEmpty(formProps.errors) ? "primary" : "white"}
            size="medium"
            className="w-full"
          >
            <span>{TRANSLATION.NEXT}</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default View;

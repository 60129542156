import classNames from "classnames";
import React from "react";
import { MdAdd, MdInfo, MdInfoOutline, MdRemove } from "react-icons/md";
import Button from "../Components/Button";
import Cost from "../Components/Cost";
import { useMediaQuery } from "react-responsive";

function UpsellsCard({
  icon,
  name,
  cost,
  currency,
  onAdd,
  notSelected,
  max,
  maxReached,
  onDetailsClick,
}) {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <>
      {isMobile ? (
        <div className="solyon-upsells__item --mobile">
          <div className="solyon-upsells-card">
            <div
              className={classNames("solyon-upsells-card__container", {
                "solyon-upsells-card__not-selected": notSelected,
              })}
            >
              {/* {maxReached && notSelected ? (
                <Button type="primary" >
                  {notSelected ? <MdAdd size={14} /> : <MdRemove size={14} />}
                </Button>
              ) : ( */}
                <Button type="primary" onClick={onAdd}>
                  {notSelected ? <MdAdd size={14} /> : <MdRemove size={14} />}
                </Button>
              {/* )} */}
              <img alt={name} src={icon} className="--logo" />
              <Cost cost={cost} currency={currency} className="--cost" />
              <div onClick={onDetailsClick} className="--link-mobile">
                <MdInfoOutline size={24} color="#80bc00" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="solyon-upsells__item" >
          <div className="solyon-upsells-card">
            <div
              className={classNames("solyon-upsells-card__container", {
                "solyon-upsells-card__not-selected": notSelected,
              })}
            >
              <img alt={name} src={icon} />
              <Cost cost={cost} currency={currency} />
              {maxReached && notSelected ? (
                <div className="solyon-upsells-card__max">{`${max} modules au maximum`}</div>
              ) : (
                <Button type="primary" onClick={onAdd}>
                  {notSelected ? <MdAdd size={14} /> : <MdRemove size={14} />}
                </Button>
              )}
            </div>
          </div>
          <div
            onClick={onDetailsClick}
            className="solyon-upsells__item--link"
          >
            Découvrir les forfaits
          </div>
        </div>
      )}
    </>
  );
}

export default React.memo(UpsellsCard);

import * as yup from "yup";

const validation = (errors) => {
  const requiredString = yup
    .string()
    .typeError(errors.REQUIRED)
    .required(errors.REQUIRED);
  return yup.object().shape({
    name: requiredString,
    models: yup.array().of(
      yup.object().shape({
        id: requiredString,
        name: requiredString,
      })
    ),
  });
};

export default validation;

import { Modal } from "antd";
import { get } from "lodash";
import View from "./View";

const Profile = ({ open, project, updateStatus, onClose }) => {
  return (
    <Modal centered open={open} onCancel={onClose}  footer={null}>
      <View
        contact={{ ...get(project, "contact", {}) }}
        onClose={onClose}
        locked={project?.locked}
        updateProjectStatus={updateStatus}
      />
    </Modal>
  );
};

export default Profile;

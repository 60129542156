import { get, isEmpty } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "shared/hooks/useApi";
import { DOWNLOAD_SINGLE_DOCUMENT } from "utils/api/graphql/mutations/download-document";
import { ATTACHMENTS } from "utils/api/graphql/queries/attachments";
import { GENERAL_COST } from "utils/api/graphql/queries/general-cost";
import { SUBSCRIPTION } from "utils/api/graphql/queries/subscription";
import {
  DOCUMENTS_TYPES,
  INSURANCE_PLAN_ATTACHMENTS_TYPES,
} from "utils/constants";
import { downloadDocument } from "utils/helpers/files";
import Loader from "../Components/Loader";
import Navbar from "../Components/Navbar/Navbar";
import View from "./View";

const DOCUMENTS_TYPE = [
  // DOCUMENTS_TYPES.CIN,
  // DOCUMENTS_TYPES.PAYMENT_ACCOUNT,
  // DOCUMENTS_TYPES.SOCIAL_SECURITY_CERTIFICATE,
  // DOCUMENTS_TYPES.TAX_NOTICE,
  // DOCUMENTS_TYPES.PAY_SLIP,
  DOCUMENTS_TYPES.DUTY_OF_ADVICE,
  DOCUMENTS_TYPES.MEMBERSHIP_FORM,
  DOCUMENTS_TYPES.SEPA_DIRECT_DEBIT_MANDATE,
  DOCUMENTS_TYPES.TERMINATION_MANDATE,
  DOCUMENTS_TYPES.TERMINATION_LETTER,
  INSURANCE_PLAN_ATTACHMENTS_TYPES.IPID,
  INSURANCE_PLAN_ATTACHMENTS_TYPES.INFO_NOTICE,
  INSURANCE_PLAN_ATTACHMENTS_TYPES.BROCHURE,
];

const SubscriptionDone = ({
  project,
  tracer,
  onNext,
  onBack,
  updateUrl,
  currentStatus,
}) => {
  const subscriptionId =
    localStorage.getItem("subscriptionId") ||
    get(project, "fields.currentSubscription");
  const [loading, setLoading] = useState(true);

  const [downloadSingleDocument] = useMutation(DOWNLOAD_SINGLE_DOCUMENT);
  const [getAttachments, { data: attachmentsList }] = useLazyQuery(ATTACHMENTS);
  const [getCost, { data: costData, loading: generalCostLoading }] =
    useLazyQuery(GENERAL_COST);
  const { data } = useQuery(SUBSCRIPTION, {
    variables: { where: { id: subscriptionId } },
    onCompleted: ({ subscription }) => {
      getCost({
        variables: {
          where: {
            project: { id: project.id },
            insurancePlan: { id: get(subscription, "insurancePlan.id") },
          },
        },
      });
      getAttachments({
        fetchPolicy: "no-cache",
        variables: {
          where: {
            OR: [
              {
                AND: [
                  {
                    project: null,
                  },
                  {
                    insurancePlans: {
                      id: get(subscription, "insurancePlan.id", ""),
                    },
                  },
                ],
              },
              {
                subscription: {
                  id: get(subscription, "id", ""),
                },
              },
              {
                AND: [
                  {
                    project: {
                      id: get(subscription, "project.id"),
                    },
                  },
                  {
                    insurancePlans: {
                      id: get(subscription, "insurancePlan.id", ""),
                    },
                  },
                ],
              },
            ],
          },
          isIn: {
            type: DOCUMENTS_TYPE,
          },
        },
        onCompleted: () => {
          setLoading(false);
        },
      });
    },
  });

  const downloadFile = async (id) => {
    await downloadSingleDocument({
      variables: { where: { id } },
      onCompleted: (_) => {
        const { document } = omitDeep(_.downloadSingleDocument, "__typename");
        downloadDocument(document);
      },
    });
  };

  if (loading || generalCostLoading || isEmpty(costData)) return <Loader />;

  return (
    <>
      <Navbar
        project={project}
        progress={currentStatus?.progress}
        showHelp={false}
      />
      <View
        subscription={omitDeep(get(data, "subscription", {}), "__typename")}
        generalCost={omitDeep(get(costData, "generalCost", {}), "__typename")}
        onBack={onBack}
        attachments={get(attachmentsList, "attachments.data", [])}
        downloadFile={downloadFile}
      />
    </>
  );
};

export default SubscriptionDone;

import { Alert, notification } from "antd";
import cuid from "cuid";
import { get, isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdReportGmailerrorred, MdSend } from "react-icons/md";
import Button from "shared/components/Button";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { CONTACT_POINT_SYSTEM, REGEX, TEMPLATE_TYPES } from "utils/constants";
import RadianceEmailTemplate from "./Templates/Radiance/EmailTemplate";
import SolyonEmailTemplate from "./Templates/Solyon/EmailTemplate";

const View = ({ project, onSubmit, data: costs, link }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.SUBSCRIPTION);
  const SEND_LINK = t("SEND_LINK", { returnObjects: true });
  const [telecoms, setTelecoms] = useState(
    get(project, "contact.telecoms", [])
      .filter(
        (telecom) =>
          telecom.value && telecom.system === CONTACT_POINT_SYSTEM.EMAIL
      )
      .map((telecom) => ({
        label: telecom.value,
        value: telecom.id,
      }))
  );
  const onAddEmail = (value) => {
    if (REGEX.EMAIL.test(value)) {
      setTelecoms([...telecoms, { label: value, value: cuid() }]);
      value = "";
    } else {
      notification.error({
        message: SEND_LINK.INVALID_EMAIL,
      });
    }
  };

  const onDeleteEmail = (email) => {
    setTelecoms(telecoms.filter(({ value }) => value !== email));
  };
  const adminAddress = [
    get(project, "author.address", ""),
    get(project, "author.zipCode", ""),
    get(project, "author.city", ""),
  ].join(" ");

  const commonProps = {
    translation: SEND_LINK,
    firstName: get(project, "contact.user.firstname", ""),
    lastName: get(project, "contact.user.lastname", ""),
    adminPhoneNumber: get(project, "author.phone", ""),
    adminEmail: get(project, "author.email", ""),
    adminFirstName: get(project, "author.firstname", ""),
    adminLastName: get(project, "author.lastname", ""),
    adminAddress,
    costs,
    link,
    upsells: get(project, `fields.upsells`, []),
  };
  const translationKey = get(project, "fields.processTemplate")?.includes(
    "SOLYON"
  )
    ? "SOLYON"
    : "RADIANCE";
  const templateTranslation = SEND_LINK[translationKey];

  const EMAIL_TEMPLATE_MAPPING = useMemo(() => {
    return {
      [TEMPLATE_TYPES.DEFAULT]: (
        <RadianceEmailTemplate
          {...commonProps}
          translation={templateTranslation}
        />
      ),
      [TEMPLATE_TYPES.RADIANCE]: (
        <RadianceEmailTemplate
          {...commonProps}
          translation={templateTranslation}
        />
      ),
      [TEMPLATE_TYPES.RADIANCE_COSA]: (
        <RadianceEmailTemplate
          {...commonProps}
          translation={templateTranslation}
        />
      ),
      [TEMPLATE_TYPES.B2B_RADIANCE_COSA]: (
        <RadianceEmailTemplate
          {...commonProps}
          translation={templateTranslation}
        />
      ),
      [TEMPLATE_TYPES.B2B_SOLYON]: (
        <SolyonEmailTemplate
          {...commonProps}
          translation={templateTranslation}
        />
      ),
      [TEMPLATE_TYPES.B2C_SOLYON]: (
        <SolyonEmailTemplate
          {...commonProps}
          translation={templateTranslation}
        />
      ),
    };
  }, [templateTranslation, project]);

  return (
    <>
      <Header key={"header"} title={SEND_LINK.TITLE} actions={[]} />
      <div className="quote-template">
        <Alert
          type="warning"
          showIcon
          icon={<MdReportGmailerrorred size={16} />}
          message={SEND_LINK.WARNING}
        />
        <div className="quote-template__mail">
          <div className="quote-template__mail-header">
            <Select
              label={SEND_LINK.TO}
              mode="tags"
              maxTagCount="responsive"
              value={telecoms}
              options={telecoms}
              onSelect={onAddEmail}
              onDeselect={onDeleteEmail}
            />
            <Input
              label={SEND_LINK.SUBJECT.LABEL}
              value={SEND_LINK.SUBJECT.TEXT}
              disabled
            />
          </div>
          <div className="quote-template__mail-label">{SEND_LINK.MESSAGE}</div>
          {EMAIL_TEMPLATE_MAPPING[get(project, "fields.processTemplate")] ||
            EMAIL_TEMPLATE_MAPPING.DEFAULT}
        </div>

        <div className="d-flex justify--end">
          <Button
            onClick={() => onSubmit(telecoms)}
            type="secondary"
            disabled={isEmpty(telecoms)}
          >
            {SEND_LINK.SEND}
            <MdSend size={16} />
          </Button>
        </div>
      </div>
    </>
  );
};

export default View;

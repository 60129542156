import { MdOutlineFileDownload } from "react-icons/md";
import { COLORS } from "shared/style/colors";

const Item = ({ name, size, onDownload }) => {
  return (
    <div className="documentDownloadLmf__item">
      <div className="documentDownloadLmf__item--details">
        <span className="--name">{name}</span>
        <span className="--size">PDF | {size}</span>
      </div>
      <span className="documentDownloadLmf__item--action" onClick={onDownload}>
        <MdOutlineFileDownload size={20} color={COLORS.C_WHITE} />
      </span>
    </div>
  );
};
export default Item;

import Loading from "shared/components/Spin";
import { TEMPLATES } from "utils/constants";
import AdherentAddress from "./AdherentAddress";
import AdherentDetails from "./AdherentDetails";
import AdherentInfo from "./AdherentInfo";
import AdherentTelecoms from "./AdherentTelecoms";
import BankCard from "./BankCard";
import ChildrenJustification from "./ChildrenJustification";
import CollectionNeeds from "./CollectionNeeds";
import Contract from "./Contract";
import Default from "./Default";
import EffectiveDate from "./EffectiveDate";
import Justification from "./Justification";
import Payment from "./Payment";
import Products from "./Products";
import Profile from "./Profile";
import Reinforcement from "./Reinforcement";
import RelatedPersons from "./RelatedPersons";
import SocialSecurityNumber from "./SocialSecurityNumber";
import SpouseJustification from "./SpouseJustification";
import SubscriptionDone from "./SubscriptionDone";
import ThankYou from "./ThankYou";

const Templates = {
  Loading: <Loading />,
  [TEMPLATES.B2C.THEMES.B2C_LMF.PROFILE]: Profile,
  [TEMPLATES.B2C.THEMES.B2C_LMF.CONTACT]: AdherentInfo,
  [TEMPLATES.B2C.THEMES.B2C_LMF.COLLECTION_NEEDS]: CollectionNeeds,
  [TEMPLATES.B2C.THEMES.B2C_LMF.PRODUCTS]: Products,
  [TEMPLATES.B2C.THEMES.B2C_LMF.ADHERENT_TELECOMS]: AdherentTelecoms,
  [TEMPLATES.B2C.THEMES.B2C_LMF.THANK_YOU]: ThankYou,
  [TEMPLATES.B2C.THEMES.B2C_LMF.ADHERENT_DETAILS]: AdherentDetails,
  [TEMPLATES.B2C.THEMES.B2C_LMF.MODULES]: Reinforcement,
  [TEMPLATES.B2C.THEMES.B2C_LMF.EFFECTIVE_DATE]: EffectiveDate,
  [TEMPLATES.B2C.THEMES.B2C_LMF.ADDRESS_ADHERENT]: AdherentAddress,
  [TEMPLATES.B2C.THEMES.B2C_LMF.SOCIAL_SECURITY_NUMBER]: SocialSecurityNumber,
  [TEMPLATES.B2C.THEMES.B2C_LMF.PAYMENT]: Payment,
  [TEMPLATES.B2C.THEMES.B2C_LMF.JUSTIFICATIONS]: Justification,
  [TEMPLATES.B2C.THEMES.B2C_LMF.SPOUSE_JUSTIFICATIONS]: SpouseJustification,
  [TEMPLATES.B2C.THEMES.B2C_LMF.CHILDREN_JUSTIFICATIONS]: ChildrenJustification,
  [TEMPLATES.B2C.THEMES.B2C_LMF.BENEFICIARIES]: RelatedPersons,
  [TEMPLATES.B2C.THEMES.B2C_LMF.CONTRACT]: Contract,
  [TEMPLATES.B2C.THEMES.B2C_LMF.BANK_CARD]: BankCard,
  [TEMPLATES.B2C.THEMES.B2C_LMF.SUBSCRIPTION_DONE]: SubscriptionDone,
  default: Default,
};
export default Templates;

import View from "./View";

const Detail = ({ updateQualifier, setOpenDetail, openDetail }) => {
  const onClose = () => setOpenDetail({});

  const onSubmit = (data) => {
    const { name, description, tag } = data;
    return updateQualifier({
      variables: {
        where: { id: openDetail?.id },
        data: {
          name,
          description,
          tag,
        },
      },
    });
  };

  return <View onSubmit={onSubmit} onClose={onClose} open={openDetail} />;
};

export default Detail;

import { gql } from "@apollo/client";

export const GENERAL_COSTS = gql`
  query generalCosts(
    $where: GeneralCostWhereInput
    $gt: GeneralCostWhereInput
    $lt: GeneralCostWhereInput
    $take: Int
    $skip: Int
    $isIn: JSONObject
    $orderBy: [JSONObject]
  ) {
    generalCosts(
      where: $where
      take: $take
      skip: $skip
      isIn: $isIn
      gt: $gt
      lt: $lt
      orderBy: $orderBy
    ) {
      data {
        id
        cost
        currency
        recommended
        commission {
          id
        }
        insurancePlan {
          id
          icon
          name
          description
          fields
          distributions {
            id
            broker {
              id
            }
            commissions {
              id
              value
              type
              withholding
            }
          }
          params {
            rules {
              id
              name
              value
            }
          }
          supportingInformation {
            category
            value
            weight
          }
          insurancePlanCoverages {
            id
            needs {
              id
              level
            }
            coverageCategory {
              id
              value
              label
              icon
              order
            }
            coverageMappings {
              id
              coverage {
                id
                type
                subtype
                additionalInfo
                requirement
                order
              }
              supportingInformation {
                id
                category
                value
                weight
              }
              limits {
                id
                code
                value
              }
            }
          }
        }
        project {
          id
        }
      }
      count
    }
  }
`;

export const GENERAL_COST = gql`
  query generalCost($where: GeneralCostWhereInput) {
    generalCost(where: $where) {
      cost
      currency
      recommended
      commission {
        id
      }
      insurancePlan {
        id
        name
        description
        icon
        insurancePlanCoverages {
          id
          needs {
            id
            level
          }
          coverageCategory {
            id
            value
            label
            icon
            order
          }
          coverageMappings {
            id
            coverage {
              id
              type
              subtype
              additionalInfo
              requirement
              order
            }
            supportingInformation {
              id
              category
              value
              weight
            }
            limits {
              id
              code
              value
            }
          }
        }
      }
      project {
        id
      }
    }
  }
`;

const generalCosts = { GENERAL_COSTS, GENERAL_COST };

export default generalCosts;

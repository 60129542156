import { ANSET_SUBSCRIPTION_FORM_KEYS } from "utils/constants";
import SupportingDocuments from "./SupportingDocuments";

const generateProofForm = ({ paymentType }) => ({
  // [ANSET_SUBSCRIPTION_FORM_KEYS.PROOF.ID]: Id,
  // [ANSET_SUBSCRIPTION_FORM_KEYS.PROOF.PROOF_OF_RESIDENCE]: ProofOfResidence,
  // [ANSET_SUBSCRIPTION_FORM_KEYS.PROOF.PURCHASE_INVOICE]: PurchaseInvoice,
  // [ANSET_SUBSCRIPTION_FORM_KEYS.PROOF.BANK_ACCOUNT]:
  //   paymentType === PAYMENT_TYPES.DEBIT && BankAccount,
  [ANSET_SUBSCRIPTION_FORM_KEYS.PROOF.SUPPORTING_DOCUMENTS]:
    SupportingDocuments,
});

export default generateProofForm;

import { get, isEmpty } from "lodash";
import {
  MdAdd,
  MdAttachFile,
  MdOutlineDelete,
  MdOutlineFileDownload,
} from "react-icons/md";
import { bytesToMB } from "utils/helpers/files";
import Input from "../../Components/Input";

const DocumentUpload = ({
  items,
  label,
  onDownload,
  onUpload,
  onFileRemove,
}) => {
  return !isEmpty(items) ? (
    <div className="solyon-document">
      <MdAttachFile className="solyon-document__icon" size={24} />
      {items.map((item) => [
        <div className="solyon-document__content">
          <div className="solyon-document__content--name">
            {item?.name || ""}
          </div>
          <div className="solyon-document__content--size">{`${
            get(item, "name", "").split(".")[1]
          } ${item?.size ? `| ${bytesToMB(item?.size)} Mo` : ""}`}</div>
        </div>,
        <div className="solyon-document__actions">
          <div
            className="solyon-document__action"
            onClick={() => onDownload(item)}
          >
            <MdOutlineFileDownload size={20} />
          </div>
          <div
            className="solyon-document__action solyon-document__action--danger"
            onClick={() => onFileRemove(item)}
          >
            <MdOutlineDelete size={20} />
          </div>
        </div>,
      ])}
    </div>
  ) : (
    <div className="solyon-document-uploader">
      <MdAttachFile className="solyon-document-uploader__icon" size={24} />
      <div className="solyon-document-uploader__content">
        <div className="solyon-document-uploader__content--name">{label}</div>
      </div>
      <Input
        type="file"
        showUploadList={false}
        fileList={items}
        onUpload={onUpload}
      >
        <div className="solyon-document-uploader__action">
          <MdAdd /> <span>Charger le document</span>
        </div>
      </Input>
    </div>
  );
};
export default DocumentUpload;

import omitDeep from "omit-deep-lodash";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { BRANDS } from "utils/api/graphql/queries/brands";
import { SCREENS } from "utils/constants";
import View from "./View";

const Detail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.BRANDS.LIST.path);

  const [updateBrand] = useMutation(graphql.mutations.UPDATE_BRAND, {
    refetchQueries: [
      {
        query: BRANDS,
        awaitRefetchQueries: true,
      },
    ],
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.ADMIN.BRANDS.LIST.path);
    },
    onError: () => {},
  });
  const { data, loading: dataLoading } = useQuery(graphql.queries.BRAND, {
    variables: { where: { id } },
  });

  const onSubmit = ({ models, iconAttachment, ...rest }) => {
    return updateBrand({
      variables: {
        where: {
          id,
        },
        data: {
          ...rest,
          logo: iconAttachment,
          models: models.map((model) => {
            return {
              id: model?.id,
              name: model?.name,
              image: model?.iconAttachment,
            };
          }),
        },
      },
    });
  };

  if (dataLoading) return <Loading />;

  return (
    <View
      onSubmit={onSubmit}
      data={omitDeep(data.brand, "__typename")}
      cancel={cancel}
    />
  );
};

export default Detail;

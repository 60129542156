import { get } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useState } from "react";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import Template from "../Template";
import View from "./View";

const BankCard = ({ currentStatus, tracer, project, onBack, onNext }) => {
  const [loading, setLoading] = useState(true);
  const subscriptionId =
    get(project, "fields.currentSubscription") ||
    localStorage.getItem("subscriptionId");

  const [updateSubscription] = useMutation(
    graphql.mutations.UPDATE_SUBSCRIPTION,
    {
      refetchQueries: [
        {
          query: graphql.queries.SUBSCRIPTION,
          awaitRefetchQueries: true,
          variables: { where: { id: subscriptionId } },
        },
      ],
    }
  );

  const { data } = useQuery(graphql.queries.SUBSCRIPTION, {
    fetchPolicy: "no-cache",
    variables: { where: { id: subscriptionId } },
  });

  const { data: generalCost } = useQuery(graphql.queries.GENERAL_COST, {
    variables: {
      where: {
        project: { id: get(project, "id") },
        insurancePlan: {
          id: get(data, "subscription.insurancePlan.id"),
        },
      },
    },
    onCompleted: () => setLoading(false),
  });

  const initialValues = {
    additionalInfo: {
      bankCard: {
        ...get(
          omitDeep(data, "__typename"),
          "subscription.additionalInfo.bankCard"
        ),
      },
    },
  };

  const onSubmit = ({ additionalInfo: { bankCard } }) => {
    const subscriptionData = omitDeep(get(data, "subscription"), "__typename");
    setLoading(true);
    updateSubscription({
      variables: {
        where: {
          id: subscriptionId,
        },
        data: {
          additionalInfo: {
            ...get(subscriptionData, "additionalInfo", {}),
            bankCard: {
              ...get(subscriptionData, "additionalInfo.bankCard", {}),
              ...bankCard,
            },
          },
        },
      },
      onCompleted: () => {
        setLoading(false);
        onNext({
          payload: {},
        });
      },
    });
  };

  return (
    <Template
      progress={currentStatus.progress}
      onBack={onBack}
      loading={loading}
      HeaderComponent={{
        subscription: get(data, "subscription"),
        tracer,
        project,
        contact: get(project, "contact"),
      }}
      View={
        <View
          initialValues={initialValues}
          locked={get(project, "locked", false)}
          onSubmit={onSubmit}
          generalCost={get(generalCost, "generalCost")}
          modules={get(
            project,
            `fields.upsells.${get(data, "subscription.insurancePlan.id")}`
          )}
        />
      }
      contact={get(project, "contact")}
    />
  );
};
export default BankCard;

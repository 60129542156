import { Col, Row } from "antd";
import dayjs from "dayjs";
import { get, isEmpty, set } from "lodash";
import { useTranslation } from "react-i18next";
import {
  MdOutlineBadge,
  MdOutlineCake,
  MdOutlineFamilyRestroom,
  MdOutlineSubtitles,
  MdPeopleOutline,
} from "react-icons/md";
import { useParams } from "react-router-dom";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { useMutation } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import {
  DATE_FORMAT,
  FAMILY_SITUATION,
  GENDER,
  SUBSCRIPTION_FORM_KEYS,
} from "utils/constants";

const Adherent = ({
  formProps,
  values,
  setFieldValue,
  setPercent,
  isFieldRequired,
  setDisabled,
  initialValues,
  onFormSubmit,
  errors,
}) => {
  const { id } = useParams();
  const [updateSubscription] = useMutation(
    graphql.mutations.UPDATE_SUBSCRIPTION,
    {
      refetchQueries: [
        {
          query: graphql.queries.SUBSCRIPTION,
          awaitRefetchQueries: true,
          variables: { where: { id } },
        },
      ],
    }
  );

  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });

  return (
    <div
      className="subscription-form--wrapper"
      id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.ADHERENT}-section`}
    >
      <Form
        type="vertical"
        title={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.ADHERENT.TITLE}
        subtitle={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.ADHERENT.SUBTITLE}
      >
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <Input
              type="radio"
              variant="group"
              optional={SUBSCRIPTION.FORM.OPTIONAL}
              values={[
                {
                  value: GENDER.MALE,
                  label: SUBSCRIPTION.FORM.GENDER.OPTIONS.MR,
                },
                {
                  value: GENDER.FEMALE,
                  label: SUBSCRIPTION.FORM.GENDER.OPTIONS.MRS,
                },
              ]}
              label={
                <div className="subscription-form--group-label">
                  <MdPeopleOutline icon="man" size={16} />
                  <span className="group-label--text">
                    {SUBSCRIPTION.FORM.GENDER.LABEL}
                  </span>
                </div>
              }
              value={get(initialValues, "project.contact.user.gender")}
              errors={errors["project.contact.user.gender"]}
              touched={!isEmpty(errors["project.contact.user.gender"])}
              onChange={(value) => {
                set(initialValues, "project.contact.user.gender", value);
                setPercent(initialValues);
              }}
              required={isFieldRequired("project.contact.user.gender")}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              placeholder={SUBSCRIPTION.FORM.LAST_NAME.PLACEHOLDER}
              label={SUBSCRIPTION.FORM.LAST_NAME.LABEL}
              icon={<MdOutlineBadge size={16} />}
              defaultValue={get(initialValues, "project.contact.user.lastname")}
              errors={errors["project.contact.user.lastname"]}
              touched={!isEmpty(errors["project.contact.user.lastname"])}
              disabled
              required={isFieldRequired("project.contact.user.lastname")}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              placeholder={SUBSCRIPTION.FORM.BIRTH_NAME.PLACEHOLDER}
              label={SUBSCRIPTION.FORM.BIRTH_NAME.LABEL}
              icon={<MdOutlineBadge size={16} />}
              defaultValue={get(
                initialValues,
                "project.contact.user.birthName"
              )}
              errors={errors["project.contact.user.birthName"]}
              touched={!isEmpty(errors["project.contact.user.birthName"])}
              required={isFieldRequired("project.contact.user.birthName")}
              onChange={({ target: { value } }) => {
                set(initialValues, "project.contact.user.birthName", value);
              }}
              onBlur={() => {
                setPercent(initialValues);
              }}
              optional={!isFieldRequired("project.contact.user.birthName")}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              label={SUBSCRIPTION.FORM.FIRST_NAME.LABEL}
              icon={<MdOutlineBadge size={16} />}
              placeholder={SUBSCRIPTION.FORM.LAST_NAME.PLACEHOLDER}
              defaultValue={get(
                initialValues,
                "project.contact.user.firstname"
              )}
              errors={errors["project.contact.user.firstname"]}
              touched={!isEmpty(errors["project.contact.user.firstname"])}
              onChange={({ target: { value } }) => {
                set(initialValues, "project.contact.user.firstname", value);
              }}
              disabled
              required={isFieldRequired("project.contact.user.firstname")}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              placeholder={SUBSCRIPTION.FORM.BIRTH_DATE.PLACEHOLDER}
              type="date"
              variant="simple"
              label={SUBSCRIPTION.FORM.BIRTH_DATE.LABEL}
              defaultValue={dayjs(
                get(initialValues, "project.contact.user.birthDate")
              )}
              icon={<MdOutlineCake size={16} />}
              onChange={(_, date) => {
                set(initialValues, "project.contact.user.birthDate", date);
              }}
              onBlur={() => setPercent(initialValues)}
              format={DATE_FORMAT}
              disabled
              required={isFieldRequired("project.contact.user.birthDate")}
              optional={!isFieldRequired("project.contact.user.birthDate")}
            />
          </Col>
          {/* <Col lg={24} xs={24}>
            <Select
              icon={<MdOutlineHealthAndSafety size={16} />}
              options={Object.entries(SOCIAL_REGIMES).map(([key, value]) => ({
                value,
                label: SUBSCRIPTION.FORM.MANDATORY_SCHEME.OPTIONS[key],
              }))}
              label={SUBSCRIPTION.FORM.MANDATORY_SCHEME.LABEL}
              placeholder={SUBSCRIPTION.FORM.MANDATORY_SCHEME.PLACEHOLDER}
              defaultValue={get(initialValues, "project.contact.socialRegime")}
              errors={errors["project.contact.socialRegime"]}
              touched={!isEmpty(errors["project.contact.socialRegime"])}
              onChange={(value) => {
                set(initialValues, "project.contact.socialRegime", value);
              }}
              required={isFieldRequired("project.contact.socialRegime")}
            />
          </Col> */}
          <Col lg={24} xs={24}>
            <Input
              label={SUBSCRIPTION.FORM.SOCIAL_SECURITY_NUMBER.LABEL}
              placeholder={SUBSCRIPTION.FORM.SOCIAL_SECURITY_NUMBER.PLACEHOLDER}
              icon={<MdOutlineSubtitles size={16} />}
              defaultValue={get(
                initialValues,
                "project.contact.socialSecurityNumber"
              )}
              errors={errors["project.contact.socialSecurityNumber"]}
              touched={!isEmpty(errors["project.contact.socialSecurityNumber"])}
              onChange={({ target: { value } }) => {
                set(
                  initialValues,
                  "project.contact.socialSecurityNumber",
                  value
                );
              }}
              onBlur={() => {
                setPercent(initialValues);
              }}
              required={isFieldRequired("project.contact.socialSecurityNumber")}
              optional={
                !isFieldRequired("project.contact.socialSecurityNumber")
              }
            />
          </Col>
          <Col lg={24} xs={24}>
            <Select
              label={SUBSCRIPTION.FORM.FAMILY_SITUATION.LABEL}
              optional={SUBSCRIPTION.FORM.OPTIONAL}
              icon={<MdOutlineFamilyRestroom size={16} />}
              placeholder={SUBSCRIPTION.FORM.FAMILY_SITUATION.PLACEHOLDER}
              type="simple"
              options={Object.entries(FAMILY_SITUATION).map(([key, value]) => ({
                value,
                label: SUBSCRIPTION.FORM.FAMILY_SITUATION.OPTIONS[key],
              }))}
              defaultValue={get(
                initialValues,
                "project.contact.fields.familySituation"
              )}
              errors={errors["project.contact.fields.familySituation"]}
              touched={
                !isEmpty(errors["project.contact.fields.familySituation"])
              }
              onChange={(value) => {
                set(
                  initialValues,
                  "project.contact.fields.familySituation",
                  value
                );
                setPercent(initialValues);
              }}
              required={isFieldRequired(
                "project.contact.fields.familySituation"
              )}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Adherent;

import { Col, Row } from "antd";
import GeneralCosts from "entities/GeneralCosts";
import { get, isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdOutlineExpandCircleDown,
  MdReportGmailerrorred,
} from "react-icons/md";
import Alert from "shared/components/Alert";
import Button from "shared/components/Button";
import Header from "shared/components/Header";
import Loading from "shared/components/Spin";
import { useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { COVERAGE_CATEGORIES } from "utils/api/graphql/queries/coverage-categories";
import { ACTIONS } from "utils/constants";
import Filter from "./Widgets/Filters";
import InsurancePlans from "./Widgets/InsurancePlans";

const View = ({
  initialFields,
  onNext,
  take,
  onBack,
  onAdd,
  onRemove,
  selectedProducts,
  filter,
  onChangeCommission,
  onFilter,
  onLoadMore,
  loading,
  initialNeeds,
  actions,
  expired,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const [needs, setNeeds] = useState(initialNeeds);
  const [fields, setFields] = useState(initialFields);
  const selectedInsurances = useMemo(
    () => selectedProducts.map((product) => get(product, "insurancePlan.id")),
    [selectedProducts.length]
  );

  const { data, loading: filterLoading } = useQuery(COVERAGE_CATEGORIES, {
    variables: { gt: { weight: 0 } },
  });

  return (
    <>
      <Header
        key={"header"}
        title={DETAIL.TEMPLATES.CONTACT.TITLE}
        actions={[]}
      />
      <div className="template-product-list--container">
        <div className="template-product-list--wrapper">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={10}>
              <div className="grid--1">
                {filterLoading ? (
                  <Loading />
                ) : (
                  <Filter
                    data={get(data, "coverageCategories.data", [])}
                    fields={fields}
                    setFields={setFields}
                    onFilter={onFilter}
                    needs={needs}
                    setNeeds={setNeeds}
                  />
                )}
                <InsurancePlans
                  data={selectedProducts}
                  actions={{ onRemove }}
                  display="selected"
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={14}>
              {loading ? (
                <Loading />
              ) : (
                <GeneralCosts
                  fetchPolicy={"cache-and-network"}
                  onNext={onNext}
                  filter={filter}
                  take={take}
                  View={({ data, count, fetchMore }) => (
                    <>
                      <InsurancePlans
                        data={data}
                        selectedProducts={selectedInsurances}
                        actions={{
                          onAdd,
                          onRemove,
                          onChangeCommission,
                        }}
                        display="all"
                        showCommission={get(actions, ACTIONS.UPDATE_COMMISSION)}
                      />
                      {count > data?.length && (
                        <div className="m-top-20">
                          <Button
                            onClick={() => {
                              onLoadMore(data?.length);
                              fetchMore({
                                variables: {
                                  skip: data?.length,
                                  take,
                                },
                                updateQuery: (prev, { fetchMoreResult }) => {
                                  return fetchMoreResult
                                    ? {
                                        generalCosts: {
                                          ...fetchMoreResult.generalCosts,
                                          data: [
                                            ...prev?.generalCosts?.data,
                                            ...fetchMoreResult.generalCosts
                                              .data,
                                          ],
                                        },
                                      }
                                    : prev;
                                },
                              });
                            }}
                            type="gray--outlined"
                            size={"full--width"}
                          >
                            {
                              DETAIL.TEMPLATES.FILTERS.BUTTON.TEXT
                                .DISPLAY_MORE_PRODUCT
                            }
                            <MdOutlineExpandCircleDown size={16} />
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                  NoData={() => (
                    <Alert
                      type="warning"
                      showIcon
                      message={DETAIL.TEMPLATES.PRODUCTS.NO_PRODUCTS}
                      icon={<MdReportGmailerrorred size={16} />}
                    />
                  )}
                />
              )}

              <div className="d-flex justify--between m-top-20">
                <Button onClick={onBack} type="secondary--link">
                  <MdArrowBackIos size={16} />
                  {DETAIL.TEMPLATES.FILTERS.BUTTON.TEXT.PREVIOUS}
                </Button>

                <Button
                  onClick={() =>
                    onNext({
                      payload: {
                        fields: {
                          ...fields,
                          selectedToCompare: selectedInsurances,
                        },
                      },
                    })
                  }
                  disabled={isEmpty(selectedInsurances) || expired}
                  type="primary"
                >
                  {DETAIL.TEMPLATES.FILTERS.BUTTON.TEXT.NEXT}
                  <MdArrowForwardIos size={16} />
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default View;

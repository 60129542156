import cuid from "cuid";
import { FieldArray } from "formik";
import { get } from "lodash";
import { MdAdd, MdDelete } from "react-icons/md";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { COLORS } from "shared/style/colors";
import { INSURANCE_PLANS } from "utils/api/graphql/queries/insurance-plans";
import { PROCESSES } from "utils/api/graphql/queries/processes";
import { bindArrayInputProps } from "utils/helpers/input";

const Upsells = ({ translation, values, setFieldValue, formProps }) => {
  return (
    <Card title={translation.TITLE} divider={false}>
      <div className="grid--1">
        <FieldArray
          name={"offers"}
          render={(arrayHelpers) => [
            get(values, "offers", []).map((_, index) => (
              <div className="grid--1" key={_.id}>
                <div className="grid--3">
                  <Input
                    placeholder={translation.NAME}
                    label={translation.NAME}
                    {...bindArrayInputProps({
                      parent: "offers",
                      index,
                      name: "name",
                      values,
                      ...formProps,
                    })}
                  />
                  <Input
                    placeholder={translation.DESCRIPTION}
                    label={translation.DESCRIPTION}
                    {...bindArrayInputProps({
                      parent: "offers",
                      index,
                      name: "description",
                      values,
                      ...formProps,
                    })}
                  />
                  <Select
                    type="async"
                    mode="multiple"
                    allowClear
                    node={{
                      query: PROCESSES,
                      accessors: {
                        root: "processes.data",
                        label: "name",
                      },
                    }}
                    label={translation.PROCESS}
                    placeholder={translation.PROCESS}
                    {...bindArrayInputProps({
                      parent: "offers",
                      index,
                      name: "processes",
                      values,
                      ...formProps,
                    })}
                    onChange={(values) => {
                      setFieldValue(`offers.${index}.processes`, values);
                    }}
                  />
                </div>
                <div>
                  <FieldArray
                    name={`offers.${index}.offerInsurancePlans`}
                    render={(arrHelpers) => (
                      <div className="grid--1">
                        {[
                          get(
                            values,
                            `offers.${index}.offerInsurancePlans`,
                            []
                          ).map((_, i) => (
                            <div className="grid--3" key={_.id}>
                              <Select
                                type="async"
                                node={{
                                  query: INSURANCE_PLANS,
                                  accessors: {
                                    root: "insurancePlans.data",
                                    label: "name",
                                  },
                                }}
                                placeholder={translation.SELECT_FORMULA}
                                label={translation.FORMULA}
                                {...bindArrayInputProps({
                                  parent: `offers.${index}.offerInsurancePlans`,
                                  index: i,
                                  name: "insurancePlan.id",
                                  values,
                                  ...formProps,
                                })}
                                filter={({ value }) => {
                                  return (
                                    value !== values.id &&
                                    !get(
                                      values,
                                      `offerInsurancePlans`,
                                      []
                                    ).some(({ insurancePlan }, idx) => {
                                      return (
                                        i !== idx &&
                                        insurancePlan?.id &&
                                        insurancePlan.id === value
                                      );
                                    })
                                  );
                                }}
                              />
                              <Input
                                type="number"
                                label={translation.WEIGHT}
                                placeholder={translation.WEIGHT}
                                {...bindArrayInputProps({
                                  parent: `offers.${index}.offerInsurancePlans`,
                                  index: i,
                                  name: "weight",
                                  values,
                                  ...formProps,
                                })}
                              />
                              <MdDelete
                                size={24}
                                color={COLORS.C_DANGER}
                                onClick={() => arrHelpers.remove(i)}
                              />
                            </div>
                          )),
                          <Button
                            type="primary--outlined"
                            key="add-row"
                            onClick={() => {
                              arrHelpers.push({
                                id: cuid(),
                                insurancePlan: { id: null },
                                main: false,
                              });
                            }}
                          >
                            <MdAdd /> {translation.ADD_FORMULA}
                          </Button>,
                        ]}
                      </div>
                    )}
                  />
                </div>
                <Button
                  type="danger--outlined"
                  key="remove-row"
                  onClick={() => arrayHelpers.remove(index)}
                >
                  <MdDelete /> {translation.DELETE_OFFER}
                </Button>

                <div className="divider m-bottom-16" />
              </div>
            )),
            <Button
              type="primary"
              key="add-row"
              onClick={() => {
                arrayHelpers.push({
                  id: cuid(),
                  process: { id: null },
                });
              }}
            >
              <MdAdd /> {translation.ADD_OFFER}
            </Button>,
          ]}
        />
      </div>
    </Card>
  );
};

export default Upsells;

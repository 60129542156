import gql from "graphql-tag";

export const CREATE_PROJECT = gql`
  mutation AddProject($data: ProjectDataInput) {
    addProject(data: $data) {
      id
      statuses {
        id
        createdDate
        status {
          id
          template
          name
          tag
          progress
          order
          color
          background
          moveToNext
          possibleStatuses {
            order
            nextStatus
            conditions {
              operation
              rightOperand
              leftOperand
            }
          }
          actions {
            id
            type
            args
            conditions {
              id
              operation
              rightOperand
              leftOperand
            }
          }
          process {
            id
            theme
            processStatuses {
              id
              template
              name
              tag
              progress
              order
              color
              background
              moveToNext
              createdDate
              possibleStatuses {
                order
                nextStatus
                conditions {
                  operation
                  rightOperand
                  leftOperand
                }
              }
              actions {
                id
                type
                args
                conditions {
                  id
                  operation
                  rightOperand
                  leftOperand
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($where: ProjectWhereInput, $data: ProjectDataInput) {
    updateProject(where: $where, data: $data) {
      id
      name
      ria
      terminationRequest {
        id
        contractNumber
        reason
        address
        brokerageMandate {
          id
          firstName
          lastName
          currentCompany
        }
      }
      statuses {
        id
        createdDate
        status {
          id
          template
          name
          tag
          progress
          order
          color
          background
          moveToNext
          possibleStatuses {
            order
            nextStatus
            conditions {
              operation
              rightOperand
              leftOperand
            }
          }
          actions {
            id
            type
            args
            conditions {
              id
              operation
              rightOperand
              leftOperand
            }
          }
          process {
            id
          }
        }
      }
      fields
      contact {
        id
        profession
        socialRegime
        socioProfessionalCategory
        socialSecurityNumber
        exerciseFrame
        acceptContact
        acceptDigital
        optMadelin
        telecoms {
          id
          system
          value
        }
        user {
          id
          firstname
          lastname
          gender
          phone
          birthDate
        }
        relatedPersons {
          id
          relationship
          identity {
            id
            socialRegime
            socioProfessionalCategory
            user {
              id
              gender
              firstname
              lastname
              birthDate
            }
          }
        }
        telecoms {
          id
          system
          value
        }
        fields
      }
      contract {
        id
        issuanceDate
      }
      locked
      expired
      subscriptions {
        id
        status
        insurancePlan {
          id
        }
      }
      author {
        id
        firstname
        lastname
        email
        phone
        address
        zipCode
        city
      }
    }
  }
`;

export const UPDATE_PROJECTS = gql`
  mutation UpdateProjects($where: ProjectWhereInput, $data: ProjectDataInput) {
    updateProjects(where: $where, data: $data) {
      id
      name
      ria
      statuses {
        id
        createdDate
        status {
          id
          template
          name
          tag
          progress
          order
          color
          background
          moveToNext
          possibleStatuses {
            order
            nextStatus
            conditions {
              operation
              rightOperand
              leftOperand
            }
          }
          actions {
            id
            type
            args
            conditions {
              id
              operation
              rightOperand
              leftOperand
            }
          }
          process {
            id
          }
        }
      }
      fields
      contact {
        id
        socialRegime
        socioProfessionalCategory
        exerciseFrame
        acceptContact
        acceptDigital
        telecoms {
          id
          system
          value
        }
        user {
          id
          firstname
          lastname
          gender
          phone
          birthDate
        }
        relatedPersons {
          id
          relationship
          identity {
            id
            socialRegime
            socioProfessionalCategory
            user {
              id
              firstname
              lastname
              birthDate
            }
          }
        }
        telecoms {
          id
          system
          value
        }
        fields
      }
      contract {
        id
        issuanceDate
      }
      locked
      expired
      subscriptions {
        id
        status
        insurancePlan {
          id
        }
      }
      author {
        id
        firstname
        lastname
        email
        phone
        address
        zipCode
        city
      }
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation DeleteProject($where: ProjectWhereInput) {
    deleteProject(where: $where) {
      id
    }
  }
`;

export const DUPLICATE_PROJECT = gql`
  mutation duplicateProject(
    $where: ProjectWhereInput
    $data: ProjectDataInput
  ) {
    duplicateProject(where: $where, data: $data) {
      id
    }
  }
`;

const projects = {
  CREATE_PROJECT,
  UPDATE_PROJECT,
  UPDATE_PROJECTS,
  DELETE_PROJECT,
  DUPLICATE_PROJECT,
};

export default projects;

import { filter, get, isEmpty } from "lodash";
import { useQuery } from "shared/hooks/useApi";
import { SUBSCRIPTION } from "utils/api/graphql/queries/subscription";
import { RELATIONSHIP } from "utils/constants";
import Navbar from "../Components/Navbar/Navbar";
import View from "./View";

const RelatedPersons = ({
  project,
  onNext,
  onBack,
  currentStatus,
  tracer,
  updateUrl,
}) => {
  const subscriptionId =
    localStorage.getItem("subscriptionId") ||
    get(project, "fields.currentSubscription");
  const { data } = useQuery(SUBSCRIPTION, {
    variables: { where: { id: subscriptionId } },
  });
  const onSubmit = ({ spouse, children }) => {
    const relatedPersons = [
      ...(!isEmpty(spouse) ? [spouse] : []),
      ...children.map((child) => child),
    ];
    const source = localStorage.getItem("source");
    updateUrl({
      subscriptionId,
      url: get(
        data,
        "subscription.insurancePlan.config.generatedUrl",
        `${process.env.REACT_APP_BASE_URL}/#/public/process/init/${source}`
      ),
    });
    onNext({
      payload: {
        contact: {
          ...get(project, "contact"),
          relatedPersons,
        },
      },
    });
  };
  return (
    <>
      <Navbar
        project={project}
        progress={currentStatus?.progress}
        tracer={tracer}
        showCart
      />
      <View
        children={filter(get(project, "contact.relatedPersons", []), {
          relationship: RELATIONSHIP.CHILD,
        })}
        spouse={get(project, "contact.relatedPersons", []).find(
          ({ relationship }) => relationship === RELATIONSHIP.SPOUSE
        )}
        onNext={onSubmit}
        onBack={onBack}
      />
    </>
  );
};

export default RelatedPersons;

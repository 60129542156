import cuid from "cuid";
import { get } from "lodash";
import { PROJECT_SOURCE, PROJECT_TYPES, SALE_MODES } from "utils/constants";
import Template from "../Template";
import View from "./View";

const Profile = ({ project, currentStatus, onNext }) => {
  const next = ({ contact }) => {
    onNext({
      payload: {
        contact: {
          id: cuid(),
          user: { id: cuid() },
          ...contact,
        },
        contract: {
          id: get(project, "contract.id", cuid()),
          saleMode: SALE_MODES.DISTANT,
        },
        projectType: PROJECT_TYPES.HOT_LEAD,
        fields: {
          ...get(project, "fields", {}),
          budget: { max: 1000, min: 0 },
          source: PROJECT_SOURCE.CLIENT,
        },
      },
    });
  };

  return (
    <Template
      progress={currentStatus.progress}
      View={<View onNext={next} contact={get(project, "contact")} />}
    />
  );
};

export default Profile;

import gql from "graphql-tag";

export const CREATE_STORE = gql`
  mutation AddStore($data: StoreDataInput) {
    addStore(data: $data) {
      id
    }
  }
`;

export const UPDATE_STORE = gql`
  mutation UpdateStore($data: StoreDataInput, $where: StoreWhereInput) {
    updateStore(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_STORE = gql`
  mutation DeleteStore($where: StoreWhereInput) {
    deleteStore(where: $where) {
      id
    }
  }
`;

const brands = { CREATE_STORE, UPDATE_STORE, DELETE_STORE };

export default brands;

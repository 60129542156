// import cuid from "cuid";
// import { PROJECT_SOURCE, PROJECT_TYPES, SALE_MODES } from "utils/constants";
import { get } from "lodash";
import Template from "../Template";
import View from "./View";

const CollectionNeeds = ({ project, onBack, currentStatus, onNext }) => {
  const next = ({ budget, formula }) => {
    onNext({
      payload: {
        fields: {
          ...get(project, "fields", {}),
          budget: { max: budget, min: budget },
          selectedFormula: formula,
        },
      },
    });
  };

  return (
    <Template
      onBack={onBack}
      progress={currentStatus.progress}
      View={
        <View
          onNext={next}
          initialValues={{
            budget: get(project, "fields.budget.min"),
            formula: get(project, "fields.selectedFormula"),
          }}
        />
      }
    />
  );
};

export default CollectionNeeds;

import { Col, Row, notification } from "antd";
import cuid from "cuid";
import { defaults, get, map, set } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineLink } from "react-icons/ai";
import { FaRegAddressCard } from "react-icons/fa";
import {
  MdArrowForwardIos,
  MdGavel,
  MdOutlineLocationOn,
  MdOutlinePayment,
  MdOutlinePhone,
  MdOutlineShareLocation,
} from "react-icons/md";
import { useParams } from "react-router-dom";
import Alert from "shared/components/Alert";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import { useMutation, useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import format from "string-template";
import graphql from "utils/api/graphql";
import { POLYNESIE_CITIES } from "utils/api/graphql/queries/referentials";
import {
  CONTACT_POINT_SYSTEM,
  SUBSCRIBER_TYPES,
  SUBSCRIPTION_STATUSES,
} from "utils/constants";
import { STEPPER_KEYS } from "../..";
import Progress from "../Progress";
import { generateFormMap } from "./Forms";
import validation from "./validation";

const ICONS = {
  CONTRACT: <MdGavel size={16} />,
  TERMINAL: <MdOutlineShareLocation size={16} />,
  SUBSCRIBER: <MdOutlinePhone size={16} />,
  ADHERENT: <FaRegAddressCard size={16} />,
  CONTACT: <MdOutlineLocationOn size={16} />,
  PAYMENT: <MdOutlinePayment size={16} />,
};

const AdditionalInformation = ({
  subscription,
  progress,
  disabled,
  initialValues,
  setDisabled,
  setPercent,
  subscriptionForm,
  calculateProgressMean,
  setActive,
  isFieldRequired,
  setIsSubmitting,
  isSubmitting,
}) => {
  const [polynesiecities, setPolynesiecities] = useState([]);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("ANSET_SUBSCRIPTION", { returnObjects: true });
  const { id } = useParams();
  useQuery(POLYNESIE_CITIES, {
    variables: { where: {} },
    onCompleted: ({ polynesieCities: { response } }) => {
      setPolynesiecities(response);
    },
  });

  const formList = useMemo(
    () => Object.entries(generateFormMap()).filter(([_, Form]) => Form),
    []
  );

  const [updateSubscription] = useMutation(
    graphql.mutations.UPDATE_SUBSCRIPTION,
    {
      refetchQueries: [
        {
          query: graphql.queries.SUBSCRIPTION,
          awaitRefetchQueries: true,
          variables: { where: { id } },
        },
      ],
    }
  );

  const slideTo = (elementRef) => {
    document
      .getElementById(`${elementRef}-section`)
      ?.scrollIntoView({ behavior: "smooth" });
  };

  const onFormSubmit = async (values = {}) => {
    try {
      setIsSubmitting(true);
      setErrors({});
      await validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, {
          returnObjects: true,
        }),
        {
          paymentType: get(initialValues, "payment.type"),
          subscriberType: get(
            initialValues,
            "additionalInfo.terminalUser.subscriberType"
          ),
        }
      ).validate(values, { abortEarly: false });
      const {
        isPayerReceiver,
        telecoms: { contact, payer },
        spouse,
        children,
        attachments,
        locked,
        status,
        envelopeId,
        signedDate,
        signature,
        birthCountry,
        ...data
      } = values;

      if (locked) return;

      const contactTelecoms = map(
        map(contact, (item, key) =>
          defaults(item, { id: cuid(), system: key })
        ),
        (item) =>
          item.system === CONTACT_POINT_SYSTEM.PHONE
            ? { ...item, value: "+689" + item.value.toString() }
            : item
      );
      const payerTelecoms = map(payer, (item, key) =>
        defaults(item, { id: cuid(), system: key })
      );
      set(data, "project.contact.telecoms", contactTelecoms);
      set(
        data,
        "payment.payer.owner.telecoms",
        get(data, "payment.payer.owner.isAdherent")
          ? contactTelecoms
          : payerTelecoms
      );

      if (
        get(data, "additionalInfo.terminalUser.subscriberType") ===
        SUBSCRIBER_TYPES.ADHERENT
      ) {
        set(
          data,
          "additionalInfo.terminalUser.firstName",
          get(data, "project.contact.user.firstname", "")
        );
        set(
          data,
          "additionalInfo.terminalUser.lastName",
          get(data, "project.contact.user.lastname", "")
        );
      }

      setActive(STEPPER_KEYS.PROOF);

      updateSubscription({
        variables: {
          where: {
            id,
          },
          data:
            get(subscription, "status") === SUBSCRIPTION_STATUSES.DRAFT
              ? { ...data, status: SUBSCRIPTION_STATUSES.IN_PROGRESS }
              : data,
        },
        onCompleted: () => {
          calculateProgressMean(
            STEPPER_KEYS.ADDITIONAL_INFORMATION,
            values,
            disabled
          );
        },
      });
    } catch (error) {
      setIsSubmitting(false);
      const errorObject = error.inner.reduce((acc, { path, message }) => {
        acc[path] = message;
        return acc;
      }, {});
      setErrors(errorObject);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return notification.error({
        message: SUBSCRIPTION.ADDITIONAL_INFORMATION.ERROR,
        duration: 5,
        placement: "bottomRight",
        style: {
          backgroundColor: COLORS.C_DANGER_BACKGROUND,
        },
      });
    }
  };

  const { isValid, handleSubmit, setFieldValue, values, ...formProps } =
    subscriptionForm;

  return (
    <div
      key="additional-information"
      className="subscription-additional-information--wrapper"
    >
      <Row gutter={[16, 16]} key={"row"}>
        <Col lg={24} xs={24}>
          <Row align="middle" gutter={[16, 16]}>
            <Col lg={18} xs={24}>
              <Alert
                type="warning"
                showIcon
                message={SUBSCRIPTION.ADDITIONAL_INFORMATION.WARNING}
              />
            </Col>
            {subscription.locked && (
              <Col lg={18} xs={24}>
                <Alert
                  type="info"
                  showIcon
                  message={SUBSCRIPTION.ADDITIONAL_INFORMATION.NO_EDIT}
                />
              </Col>
            )}
            <Col align="end" lg={6} xs={24}>
              <span className="indicator-step">
                {format(SUBSCRIPTION.INDICATOR, {
                  count: "1",
                  total: "3",
                })}
              </span>
            </Col>
          </Row>
        </Col>
        <Col lg={24} xs={24}>
          <Row gutter={[16, 16]}>
            <Col lg={9} xs={24}>
              <div className="card-skeleton--container highlighted">
                <div className="card-skeleton--wrapper">
                  <h3 className="title--md">
                    {SUBSCRIPTION.ADDITIONAL_INFORMATION.TITLE}
                  </h3>
                  <div className="card-skeleton--wrapper__progress-list">
                    {formList.map(([form], index) => (
                      <Progress
                        key={"progress-" + index}
                        percent={progress["ADDITIONAL_INFORMATION"][form]}
                        translation={SUBSCRIPTION}
                        form={form}
                        slideTo={slideTo}
                        icon={ICONS[form]}
                        title={SUBSCRIPTION.ADDITIONAL_INFORMATION.STEPS[form]}
                      />
                    ))}
                  </div>
                </div>
                <Button type={"primary"} size="full--width" disabled>
                  {SUBSCRIPTION.GENERATE_LINK}
                  <AiOutlineLink size={24} />
                </Button>
              </div>
            </Col>
            <Col lg={15} xs={24}>
              <Card
                title={SUBSCRIPTION.ADDITIONAL_INFORMATION.TITLE}
                subtitle={SUBSCRIPTION.ADDITIONAL_INFORMATION.SUBTITLE}
              >
                {formList.map(([key, Form], index) => (
                  <Form
                    id={"section-" + key}
                    key={"form-" + index}
                    values={values}
                    polynesiecities={polynesiecities}
                    progress={progress}
                    disabled={disabled}
                    setDisabled={setDisabled}
                    formProps={formProps}
                    initialValues={initialValues}
                    setFieldValue={setFieldValue}
                    onFormSubmit={onFormSubmit}
                    errors={errors}
                    setErrors={setErrors}
                    setPercent={(values) =>
                      setPercent(
                        STEPPER_KEYS.ADDITIONAL_INFORMATION,
                        key,
                        values
                      )
                    }
                    isFieldRequired={(field) => isFieldRequired(key, field)}
                  />
                ))}
              </Card>
            </Col>
            <Col
              lg={24}
              xs={24}
              className="subscription-additional-information--wrapper__action"
            >
              <Row align="end">
                <Button
                  type="primary"
                  onClick={() => onFormSubmit(initialValues)}
                >
                  {SUBSCRIPTION.ADDITIONAL_INFORMATION.ACTION.SAVE}
                  <MdArrowForwardIos size={12} />
                </Button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AdditionalInformation;

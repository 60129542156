import { REGEX_EXPS } from "utils/constants";
import * as yup from "yup";

const validation = (messages, { hasPartner = false }) => {
  const string = yup
    .string()
    .required(messages.REQUIRED)
    .typeError(messages.NOT_STRING)
    .nullable();
  const nameValidation = yup
    .string()
    .typeError(messages.NOT_STRING)
    .nullable()
    .notRequired()
    .test(
      "is-valid-name",
      messages.INVALID_NAME,
      (value) => !value || REGEX_EXPS.name.test(value)
    );

  const spouseValidationSchema = yup.object().shape({
    identity: yup.object().shape({
      user: yup.object().shape({
        lastname: nameValidation,
        firstname: nameValidation,
        gender: string,
      }),
    }),
  });
  const childrenValidationSchema = yup.object().shape({
    identity: yup.object().shape({
      user: yup.object().shape({
        lastname: nameValidation,
        firstname: nameValidation,
        gender: string,
      }),
    }),
  });
  return yup.object().shape({
    spouse: hasPartner && spouseValidationSchema,
    children: yup.array().of(childrenValidationSchema),
  });
};

export default validation;

import gql from "graphql-tag";

export const SIGN_DOCUMENT = gql`
  mutation SignDocuments($data: SubscriptionDataInput) {
    signDocuments(data: $data) {
      response
    }
  }
`;

export const MANUAL_SIGNATURE = gql`
  mutation ManualSignature($data: ManualSignatureDataInput) {
    manualSignature(data: $data) {
      response
    }
  }
`;
const esigns = {
  SIGN_DOCUMENT,
  MANUAL_SIGNATURE,
};

export default esigns;

import { Modal } from "antd";
import { defaults, get, keyBy } from "lodash";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import View from "./View";

const Cart = ({
  open,
  onSubmit,
  onClose,
  generalCost,
  project,
  totalAmount,
  upsells,
}) => {
  const telecoms = defaults(keyBy(get(project, "contact.telecoms"), "system"), {
    [CONTACT_POINT_SYSTEM.PHONE]: { value: null },
    [CONTACT_POINT_SYSTEM.EMAIL]: { value: null },
  });

  return (
    <Modal open={open} onCancel={onClose} width={452} footer={null}>
      <View
        totalAmount={totalAmount}
        contact={{ ...get(project, "contact", {}), telecoms }}
        onSubmit={onSubmit}
        onClose={onClose}
        locked={project?.locked}
        generalCost={generalCost}
        upsells={upsells}
      />
    </Modal>
  );
};

export default Cart;

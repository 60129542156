import { get } from "lodash";
import { useQuery } from "shared/hooks/useApi";
import { COVERAGE_CATEGORIES } from "utils/api/graphql/queries/coverage-categories";
import View from "./View";

const CollectionNeeds = ({ project, onNext, onBack }) => {
  const next = (fields) =>
    onNext({
      payload: {
        fields,
      },
    });
  const { data } = useQuery(COVERAGE_CATEGORIES, {
    variables: { gt: { weight: 0 } },
  });

  return (
    <View
      data={get(data, "coverageCategories.data") || []}
      next={next}
      project={project}
      onBack={onBack}
    />
  );
};

export default CollectionNeeds;

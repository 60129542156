import { get } from "lodash";
import moment from "moment";
import { CONTACT_POINT_SYSTEM, GENDER, REGEX_EXPS } from "utils/constants";
import * as yup from "yup";

const validation = (messages, { hasPartner }) => {
  const validateSocialSecurityNumber = function (value) {
    if (!value) return true;
    const prefix = `${
      (get(this.parent, "user.gender") === GENDER.MALE && "[17]") ||
      (get(this.parent, "user.gender") === GENDER.MALE && "[28]") ||
      "[1278]"
    }${moment(get(this.parent, "user.birthDate")).format("YY")}${moment(
      moment(get(this.parent, "user.birthDate"))
    ).format("MM")}`;
    const regexPattern = new RegExp(`^${prefix}(?:[0-9]{8}|[0-9]{10})$`);
    return new RegExp(regexPattern).test(value);
  };
  const string = yup
    .string()
    .required(messages.REQUIRED)
    .typeError(messages.NOT_STRING)
    .nullable();

  const nameValidation = yup
    .string()
    .typeError(messages.NOT_STRING)
    .nullable()
    .notRequired()
    .test(
      "is-valid-name",
      messages.INVALID_NAME,
      (value) => !value || REGEX_EXPS.name.test(value)
    );

  const spouseValidationSchema = yup.object().shape({
    identity: yup.object().shape({
      user: yup.object().shape({
        lastname: nameValidation,
        firstname: nameValidation,
      }),
      socialSecurityNumber: yup
        .string()
        .typeError(messages.REQUIRED)
        .nullable()
        .required(messages.REQUIRED)
        .test(
          "securityNumberFormat",
          messages.INVALID_VALUE,
          validateSocialSecurityNumber
        ),
    }),
  });
  const childrenValidationSchema = yup.object().shape({
    identity: yup.object().shape({
      user: yup.object().shape({
        lastname: nameValidation,
        firstname: nameValidation,
      }),
      // socialSecurityNumber: yup.mixed().when("user", {
      //   is: ({ birthDate }) => {
      //     console.log();
      //     return (
      //       moment()
      //         .startOf("year")
      //         .diff(moment(birthDate).startOf("year"), "years") >= 16
      //     );
      //   },
      //   then: yup
      //     .string()
      //     .typeError(messages.REQUIRED)
      //     .nullable()
      //     .required(messages.REQUIRED)
      //     .test(
      //       "securityNumberFormat",
      //       messages.INVALID_VALUE,
      //       validateSocialSecurityNumber
      //     ),
      // }),
    }),
  });
  return yup.object().shape({
    project: yup.object().shape({
      contract: yup.object().shape({
        issuanceDate: yup.date().typeError(messages.REQUIRED),
      }),
      contact: yup.object().shape({
        nationality: string,
        socialSecurityNumber: yup
          .string()
          .typeError(messages.REQUIRED)
          .nullable()
          .required(messages.REQUIRED)
          .test(
            "securityNumberFormat",
            messages.INVALID_VALUE,
            validateSocialSecurityNumber
          ),
        socioProfessionalCategory: string,
      }),
    }),
    telecoms: yup.object().shape({
      [CONTACT_POINT_SYSTEM.ADDRESS]: yup.object().shape({
        value: string,
      }),
      [CONTACT_POINT_SYSTEM.COMPLEMENT]: yup.object().shape({
        value: yup.string().typeError(messages.NOT_STRING).nullable(),
      }),
      [CONTACT_POINT_SYSTEM.BIRTH_COUNTRY]: yup.object().shape({
        value: string,
      }),
      [CONTACT_POINT_SYSTEM.DEPARTMENT_OF_BIRTH]: yup.object().shape({
        value: string,
      }),
      [CONTACT_POINT_SYSTEM.CITY]: yup.object().shape({
        value: string,
      }),
    }),
    spouse: hasPartner && spouseValidationSchema,
    children: yup.array().of(childrenValidationSchema),
  });
};

export default validation;

import classNames from "classnames";
import { isEmpty } from "lodash";
import Cost from "shared/components/Cost";
import Input from "shared/components/Input";
import { CURRENCY_SYMBOL } from "utils/constants";

const insurancePlan = ({
  logo,
  name,
  description,
  cost,
  currency = "DEFAULT",
  translation,
  checked,
  onCheck,
  justification,
  onJustify,
  checkDisabled,
  disabled,
  upsells = [],
}) => {
  const totalCost = upsells
    ?.reduce((acc, curr) => acc + curr.cost, cost)
    ?.toFixed(2);

  return (
    <div className="insurance-card-container">
      <div
        className={classNames("insurance-card", {
          "insurance-card--disabled": disabled,
        })}
      >
        <div className="insurance-card__checkbox">
          <Input
            type="checkbox"
            onChange={(e) => onCheck(e.target.checked)}
            checked={checked}
            disabled={(!checked && checkDisabled) || disabled}
          />
        </div>
        <div className="insurance-card__image">
          <img src={logo} alt="logo" />
        </div>
        <div className="insurance-card__item">
          <div className="insurance-card__item--name">{name}</div>
          <span className="insurance-card__item--description">
            {description}
          </span>
        </div>
        <div className="insurance-card__item">
          <div>
            <Cost
              cost={totalCost || "-"}
              currency={currency}
              period={`/ ${translation.PER_MONTH}`}
            />
          </div>
          <span className="insurance-card__item--total">
            {`${(+totalCost * 12).toFixed(0)} ${CURRENCY_SYMBOL[currency]} / ${
              translation.PER_YEAR
            } `}
            <span>{translation.ALL_COST}</span>
          </span>
        </div>
        {/* <div className="insurance-card__item">
        <Input
          type="textarea"
          placeholder={translation.ARGUMENT}
          rows={2}
          value={justification}
          onChange={({ target: { value } }) => onJustify(value)}
          disabled={!checked}
        />
      </div> */}
      </div>
      <div
        className={classNames("insurance-card", {
          "insurance-card--disabled": disabled,
          hidden: isEmpty(upsells),
        })}
      >
        <div className="insurance-card__checkbox"></div>
        <div className="insurance-card__image"></div>
        <div className="insurance-card__item">
          <div className="insurance-card__item--included">
            {translation.INCLUDED}
          </div>
          <div className="insurance-card__item--upsells">{name}</div>
          {upsells.map(({ insurancePlan }) => (
            <div
              className="insurance-card__item--upsells"
              key={insurancePlan.id}
            >
              {insurancePlan?.name}
            </div>
          ))}
        </div>
        <div className="insurance-card__item justify--end">
          <Cost cost={cost || "-"} currency={currency} size="small" />
          {upsells.map(({ id, cost, currency }) => (
            <Cost
              key={id}
              cost={cost || "-"}
              currency={currency}
              size="small"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default insurancePlan;

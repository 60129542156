import { Col, Modal, Row } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { find, get, head, isEmpty } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import {
  CONTACT_POINT_SYSTEM,
  DATE_FORMAT,
  INSURANCE_PLAN_PARAMS_RULES,
} from "utils/constants";
import { bindDateInputProps, bindInputProps } from "utils/helpers/input";
import Button from "../Components/Button";
import Lamp from "../Components/svg/Lamp.svg";
import InputChecked from "../Components/svg/check_small.svg";
import InputError from "../Components/svg/error.svg";
import UpdateCostModal from "./Widgets/UpdateCostModal";
import validation from "./validation";

const View = ({
  initialValues,
  subscription,
  onNext,
  locked,
  recalculateCost,
}) => {
  const [openHelp, setOpenHelp] = useState(false);
  const [occupation, setOccupation] = useState(false);
  const [resiliation, setResiliation] = useState(false);
  const [validStartDate, setValidStartDate] = useState();
  const [validEndDate, setValidEndDate] = useState();
  const [deltaRia, setDeltaRia] = useState(30);
  const [deltaStart, setDeltaStart] = useState(1);
  const [showChangeCostModal, setShowChangeCostModal] = useState({});
  const [oldIssuanceDate, setOldIssuanceDate] = useState(
    get(initialValues, "project.contract.issuanceDate")
  );
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("B2C_LMF.EFFECTIVE_DATE", { returnObjects: true });
  const [pickerValue, setPickerValue] = useState(
    !get(subscription, "project.contract.issuanceDate") && dayjs()
  );
  useEffect(() => {
    const rules = get(
      head(get(subscription, "insurancePlan.params", [])),
      "rules",
      []
    );
    const deltaRia = get(
      find(rules, {
        name: INSURANCE_PLAN_PARAMS_RULES.DELTA_START_RIA,
      }),
      "value",
      30
    );
    const deltaStart = get(
      find(rules, {
        name: INSURANCE_PLAN_PARAMS_RULES.DELTA_START,
      }),
      "value",
      1
    );
    const deltaEndRia = get(
      find(rules, {
        name: INSURANCE_PLAN_PARAMS_RULES.DELTA_END_RIA,
      }),
      "value",
      0
    );
    const deltaEnd = get(
      find(rules, {
        name: INSURANCE_PLAN_PARAMS_RULES.DELTA_END,
      }),
      "value",
      0
    );
    setDeltaRia(deltaRia);
    setDeltaStart(deltaStart);
    setValidStartDate(
      get(subscription, "project.ria")
        ? dayjs(
            moment
              .max(
                moment().add(deltaRia, "days"),
                moment().startOf("y").add(5, "h")
              )
              .toDate()
          )
        : dayjs(
            moment
              .max(
                moment().add(deltaStart, "days"),
                moment().startOf("y").add(5, "h")
              )
              .toDate()
          )
    );
    setValidEndDate(
      get(subscription, "project.ria")
        ? deltaEndRia === 0
          ? dayjs().endOf("year")
          : dayjs().add(deltaEndRia, "days")
        : deltaEnd === 0
        ? dayjs().endOf("year")
        : dayjs().add(deltaEnd, "days")
    );
  }, []);

  const checkExpired = useCallback(({ issuanceDate }) => {
    const minIssuanceDate = dayjs().startOf("day").add(deltaRia, "days");
    const expired = dayjs(issuanceDate).isBefore(minIssuanceDate);
    return expired;
  }, []);

  const { handleSubmit, values, setFieldValue, ...formProps } = useFormik({
    initialValues,
    onSubmit: onNext,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true }),
      { hasRia: resiliation, occupation }
    ),
  });

  const onChangeCost = () => {
    setShowChangeCostModal({});
  };

  const onCancelChangeCost = () => {
    if (showChangeCostModal?.isRia) {
      setFieldValue("resiliation", false);
      setFieldValue("project.ria", false);
      setFieldValue("project.fields.selfOccupation", undefined);
      setFieldValue(
        "project.contract.issuanceDate",
        get(initialValues, "project.contract.issuanceDate")
      );
      setValidStartDate(dayjs().add(deltaStart, "days"));
      setPickerValue(dayjs().add(deltaStart, "days"));
    }
    recalculateCost({
      oldIssuanceDate: values?.project?.contract?.issuanceDate,
      issuanceDate: oldIssuanceDate,
      setFieldValue,
      setShowChangeCostModal,
      isCancel: true,
    });
  };

  const RIA_STATUS = {
    [true]: () => {
      setFieldValue("project.fields.selfOccupation", undefined);
      setOccupation(false);
      if (subscription?.locked) return;
      if (
        checkExpired({
          issuanceDate: values?.project?.contract?.issuanceDate,
        })
      ) {
        setValidStartDate(dayjs().add(deltaRia, "days"));
        recalculateCost({
          oldIssuanceDate: oldIssuanceDate,
          issuanceDate: dayjs().add(deltaRia, "days"),
          setFieldValue,
          setShowChangeCostModal,
          isRia: true,
        });
        setFieldValue(
          "project.contract.issuanceDate",
          dayjs().add(deltaRia, "days")
        );
        setPickerValue(dayjs().add(deltaRia, "days"));
      }
    },
    [false]: () => {
      if (subscription?.locked) return;
      setFieldValue("project.ria", false);
      setFieldValue("project.fields.selfOccupation", undefined);
      setFieldValue(
        "project.contract.issuanceDate",
        get(initialValues, "project.contract.issuanceDate")
      );
      setValidStartDate(dayjs().add(deltaStart, "days"));
      setPickerValue(dayjs().add(deltaStart, "days"));
    },
  };

  return (
    <>
      <Modal
        open={openHelp}
        onCancel={() => setOpenHelp(false)}
        width={500}
        centered
        footer={null}
        className="LMF-resiliation-modal"
      >
        <h1 className="LMF-resiliation-modal__title">
          {TRANSLATION.RESILIATION_MODAL.TITLE}
        </h1>
        <p className="LMF-resiliation-modal__description">
          {TRANSLATION.RESILIATION_MODAL.DESCRIPTION}
        </p>
        <Button
          className="w-full"
          onClick={() => setOpenHelp(false)}
          type="white"
          size="middle"
        >
          {TRANSLATION.RESILIATION_MODAL.CLOSE}
        </Button>
      </Modal>
      <UpdateCostModal
        showChangeCostModal={false && showChangeCostModal}
        insurancePlanName={get(subscription, "insurancePlan.name")}
        setShowChangeCostModal={setShowChangeCostModal}
        onChangeCost={onChangeCost}
        onCancelChangeCost={onCancelChangeCost}
      />
      <h1 className="header-title">{TRANSLATION.TITLE}</h1>
      <span
        className="header-description"
        dangerouslySetInnerHTML={{ __html: TRANSLATION.DESCRIPTION }}
      ></span>
      <div className="__LMF--content LMF-content">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              label={TRANSLATION.FORM.EFFECTIVE_DATE.LABEL}
              placeholder={TRANSLATION.FORM.EFFECTIVE_DATE.PLACEHOLDER}
              onFocus={() =>
                setOldIssuanceDate(values?.project?.contract?.issuanceDate)
              }
              className={classNames({
                valid: get(values, "project.contract.issuanceDate"),
              })}
              type="date"
              variant="simple"
              format={DATE_FORMAT}
              defaultPickerValue={pickerValue}
              disabledDate={(current) => {
                return (
                  current &&
                  (current < validStartDate || current > validEndDate)
                );
              }}
              {...bindDateInputProps({
                name: "project.contract.issuanceDate",
                values,
                setFieldValue,
                ...formProps,
              })}
              onChange={(date) => {
                setFieldValue("project.contract.issuanceDate", date);
                if (
                  date.isValid() &&
                  date >= validStartDate &&
                  date <= validEndDate
                ) {
                  recalculateCost({
                    oldIssuanceDate: oldIssuanceDate,
                    issuanceDate: date,
                    setFieldValue,
                    setShowChangeCostModal,
                  });
                }
              }}
              suffix={
                get(formProps.errors, `project.contract.issuanceDate`) ? (
                  <img src={InputError} />
                ) : (
                  <img src={InputChecked} />
                )
              }
              onBlur={({ target: { value: issuanceDate } }) => {
                const date = dayjs(issuanceDate, DATE_FORMAT).add(2, "h");
                if (
                  date.isValid() &&
                  date >= validStartDate &&
                  date <= validEndDate
                ) {
                  setFieldValue("project.contract.issuanceDate", date);
                } else
                  formProps.setFieldTouched("project.contract.issuanceDate");
              }}
              disabled={locked}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              label={TRANSLATION.FORM.BIRTH_CITY.LABEL}
              placeholder={TRANSLATION.FORM.BIRTH_CITY.PLACEHOLDER}
              className={classNames({
                valid: get(
                  values,
                  `telecoms.${CONTACT_POINT_SYSTEM.BIRTH_CITY}.value`
                ),
              })}
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.BIRTH_CITY}.value`,
                values,
                setFieldValue,
                ...formProps,
              })}
              onChange={({ target: { value } }) => {
                setFieldValue(
                  `telecoms.${CONTACT_POINT_SYSTEM.BIRTH_CITY}.value`,
                  value
                );
              }}
              suffix={
                get(
                  formProps.errors,
                  `telecoms.${CONTACT_POINT_SYSTEM.BIRTH_CITY}.value`
                ) ? (
                  <img src={InputError} />
                ) : (
                  <img src={InputChecked} />
                )
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              label={TRANSLATION.FORM.DEPARTMENT_OF_BIRTH.LABEL}
              placeholder={TRANSLATION.FORM.DEPARTMENT_OF_BIRTH.PLACEHOLDER}
              className={classNames({
                valid: get(
                  values,
                  `telecoms.${CONTACT_POINT_SYSTEM.DEPARTMENT_OF_BIRTH}.value`
                ),
              })}
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.DEPARTMENT_OF_BIRTH}.value`,
                values,
                setFieldValue,
                ...formProps,
              })}
              onChange={({ target: { value } }) => {
                setFieldValue(
                  `telecoms.${CONTACT_POINT_SYSTEM.DEPARTMENT_OF_BIRTH}.value`,
                  value
                );
              }}
              suffix={
                get(
                  formProps.errors,
                  `telecoms.${CONTACT_POINT_SYSTEM.DEPARTMENT_OF_BIRTH}.value`
                ) ? (
                  <img src={InputError} />
                ) : (
                  <img src={InputChecked} />
                )
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              label={TRANSLATION.FORM.BIRTH_COUNTRY.LABEL}
              placeholder={TRANSLATION.FORM.BIRTH_COUNTRY.PLACEHOLDER}
              className={classNames({
                valid: get(
                  values,
                  `telecoms.${CONTACT_POINT_SYSTEM.BIRTH_COUNTRY}.value`
                ),
              })}
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.BIRTH_COUNTRY}.value`,
                values,
                setFieldValue,
                ...formProps,
              })}
              onChange={({ target: { value } }) => {
                setFieldValue(
                  `telecoms.${CONTACT_POINT_SYSTEM.BIRTH_COUNTRY}.value`,
                  value
                );
              }}
              suffix={
                get(
                  formProps.errors,
                  `telecoms.${CONTACT_POINT_SYSTEM.BIRTH_COUNTRY}.value`
                ) ? (
                  <img src={InputError} />
                ) : (
                  <img src={InputChecked} />
                )
              }
            />
          </Col>
        </Row>

        <div className="__LMF--content__body">
          <div className="payment__section--refund-container">
            <div className="payment__section--refund-container--action">
              <div className="payment__section--refund-container--action__left">
                <span>{TRANSLATION.FORM.RIA.LABEL}</span>
              </div>
              <div className="payment__section--refund-container--right">
                <div className="d-flex">
                  <Button
                    size="middle"
                    type={classNames({
                      primary: get(values, "resiliation") === true,
                      white: get(values, "resiliation", false) === false,
                    })}
                    onClick={() => {
                      if (locked) return;
                      setResiliation(true);
                      setFieldValue("resiliation", true);
                      RIA_STATUS[true]();
                    }}
                  >
                    {TRANSLATION.YES}
                  </Button>
                  <Button
                    size="middle"
                    type={classNames({
                      primary: get(values, "resiliation") === false,
                      white: get(values, "resiliation", true) === true,
                    })}
                    onClick={() => {
                      if (locked) return;
                      setFieldValue("resiliation", false);
                      setResiliation(false);
                      RIA_STATUS[false]();
                    }}
                  >
                    {TRANSLATION.NO}
                  </Button>
                </div>
              </div>
            </div>
            {get(values, "resiliation") && (
              <div className="__LMF--content__body--container">
                <div className="__LMF--content__body--container__title">
                  {TRANSLATION.FORM.RIA.ENABLED.TITLE}
                </div>
                <div className="__LMF--content__body--container__body rounded">
                  <Input
                    type="checkbox"
                    label={TRANSLATION.FORM.RIA.ENABLED.SELF_OCCUPATION}
                    checked={get(values, "project.fields.selfOccupation")}
                    {...bindInputProps({
                      name: "project.fields.selfOccupation",
                      values,
                      ...formProps,
                    })}
                    onChange={() => {
                      setFieldValue("project.fields.selfOccupation", true);
                      setFieldValue("project.ria", false);
                      setFieldValue("project.terminationRequest", {
                        contractNumber: null,
                        brokerageMandate: { currentCompany: null },
                      });
                      setOccupation(true);
                    }}
                    disabled={locked}
                  />
                  <Input
                    type="checkbox"
                    label={TRANSLATION.FORM.RIA.ENABLED.AUTO_OCCUPATION}
                    checked={
                      !get(values, "project.fields.selfOccupation", true)
                    }
                    {...bindInputProps({
                      name: "project.fields.selfOccupation",
                      values,
                      ...formProps,
                    })}
                    onChange={() => {
                      setFieldValue("project.fields.selfOccupation", false);
                      setFieldValue("project.ria", true);
                      setOccupation(false);
                    }}
                    disabled={locked}
                  />
                </div>
                {get(values, "resiliation") && get(values, "project.ria") && (
                  <div className="__LMF--content__body--container__body">
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Input
                          label={TRANSLATION.FORM.CURRENT_EFFECTIVE_DATE.LABEL}
                          placeholder={
                            TRANSLATION.FORM.CURRENT_EFFECTIVE_DATE.PLACEHOLDER
                          }
                          type="date"
                          variant="simple"
                          format={DATE_FORMAT}
                          defaultPickerValue={dayjs().add(-1, "day")}
                          disabledDate={(current) => {
                            return current && current > dayjs();
                          }}
                          {...bindDateInputProps({
                            name: `project.terminationRequest.dueDate`,
                            values,
                            setFieldValue,
                            ...formProps,
                          })}
                          disabled={locked}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <Input
                          label={TRANSLATION.FORM.CURRENT_CONTRACT.LABEL}
                          placeholder={
                            TRANSLATION.FORM.CURRENT_CONTRACT.PLACEHOLDER
                          }
                          {...bindInputProps({
                            name: `project.terminationRequest.contractNumber`,
                            values,
                            ...formProps,
                          })}
                          disabled={get(subscription, "locked")}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <Input
                          label={TRANSLATION.FORM.CURRENT_COMPANY.LABEL}
                          placeholder={
                            TRANSLATION.FORM.CURRENT_COMPANY.PLACEHOLDER
                          }
                          {...bindInputProps({
                            name: `project.terminationRequest.brokerageMandate.currentCompany`,
                            values,
                            ...formProps,
                          })}
                          disabled={locked}
                        />
                      </Col>
                    </Row>
                    <div className="__LMF--content__body--container__body--caption">
                      <img src={Lamp} />
                      <span>{TRANSLATION.FORM.CAPTION}</span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Button
        disabled={!isEmpty(formProps.errors)}
        onClick={handleSubmit}
        type="white"
        size="middle"
      >
        <span>{TRANSLATION.NEXT}</span>
      </Button>
    </>
  );
};

export default View;

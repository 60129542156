import cuid from "cuid";
import { defaultsDeep, filter, get, isEmpty } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useEffect, useState } from "react";
import { useLazyQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { RELATIONSHIP } from "utils/constants";
import Template from "../Template";
import View from "./View";

const RelatedPersons = ({ currentStatus, tracer, project, onBack, onNext }) => {
  const [initialValues, setInitialValues] = useState({});
  const [loading, setLoading] = useState(true);
  const subscriptionId =
    get(project, "fields.currentSubscription") ||
    localStorage.getItem("subscriptionId");
  const [getSubscription, { data }] = useLazyQuery(
    graphql.queries.SUBSCRIPTION
  );

  useEffect(() => {
    if (subscriptionId)
      getSubscription({
        variables: { where: { id: subscriptionId } },
        onCompleted: ({ subscription }) => {
          const subscriptionData = omitDeep(subscription, "__typename");
          const children = filter(
            get(subscriptionData, "project.contact.relatedPersons", []),
            {
              relationship: RELATIONSHIP.CHILD,
            }
          );
          const spouse = get(
            subscriptionData,
            "project.contact.relatedPersons",
            []
          ).find(({ relationship }) => relationship === RELATIONSHIP.SPOUSE);
          const initialFormValues = {
            children,
            spouse: isEmpty(spouse)
              ? undefined
              : defaultsDeep(spouse, {
                  identity: { fields: { teletransmission: true } },
                }),
          };
          setInitialValues(initialFormValues);
          setLoading(false);
        },
      });
  }, []);

  const onSubmit = ({ spouse, children }) => {
    const relatedPersons = [
      ...(!isEmpty(spouse)
        ? [
            defaultsDeep(spouse, {
              id: cuid(),
              relationship: RELATIONSHIP.SPOUSE,
              identity: { id: cuid(), user: { id: cuid() } },
            }),
          ]
        : []),
      ...children.map((child) =>
        defaultsDeep(child, {
          id: cuid(),
          relationship: RELATIONSHIP.CHILD,
          identity: { id: cuid(), user: { id: cuid() } },
        })
      ),
    ];
    onNext({
      payload: {
        contact: {
          ...get(project, "contact"),
          relatedPersons,
        },
      },
    });
  };
  return (
    <Template
      progress={currentStatus.progress}
      onBack={onBack}
      loading={loading}
      HeaderComponent={{
        subscription: get(data, "subscription"),
        tracer,
        project,
        contact: get(project, "contact"),
      }}
      View={
        <View
          initialValues={initialValues}
          locked={get(project, "locked", false)}
          onSubmit={onSubmit}
        />
      }
      contact={get(project, "contact")}
    />
  );
};
export default RelatedPersons;

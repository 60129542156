import format from "string-template";
import { REGEX_EXPS } from "utils/constants";
import * as yup from "yup";

const validation = (messages) => {
  const string = yup
    .string()
    .typeError(messages.NOT_STRING)
    .required(messages.REQUIRED)
    .nullable();
  const nameValidation = yup
    .string()
    .typeError(messages.NOT_STRING)
    .nullable()
    .notRequired()
    .test(
      "is-valid-name",
      messages.INVALID_NAME,
      (value) => !value || REGEX_EXPS.name.test(value)
    );
  return yup.object().shape({
    payment: yup.object().shape({
      type: string,
      frequency: string,
      debitDay: string,
      payer: yup.object().shape({
        BIC: string,
        IBAN: yup
          .string()
          .required(messages.REQUIRED)
          .typeError(messages.NOT_STRING)
          .max(34, format(messages.MAX_LENGTH, { length: 34 }))
          .matches(
            /^(fr|cz|nl)/i,
            format(messages.INVALID_PREFIX, { prefixes: '"FR", "CZ" or "NL"' })
          )
          .nullable(),
        owner: yup.object().shape({
          isAdherent: yup.boolean().typeError(messages.NOT_BOOLEAN),
          firstName: nameValidation,
          lastName: nameValidation,
        }),
      }),
    }),
  });
};

export default validation;

import { get } from "lodash";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import { GENERATE_SUBSCRIPTION_DOCUMENTS } from "utils/api/graphql/mutations/attachment";
import { SUBSCRIPTION } from "utils/api/graphql/queries/subscription";
import Navbar from "../Components/Navbar/Navbar";
import View from "./View";

const Rgpd = ({ project, onNext, currentStatus, tracer, updateUrl }) => {
  const subscriptionId =
    localStorage.getItem("subscriptionId") ||
    get(project, "fields.currentSubscription");

  const { data } = useLazyQuery(SUBSCRIPTION, {
    variables: { where: { id: subscriptionId } },
  });
  const [generateSubscriptionDocuments, { loading: generateLoading }] =
    useMutation(GENERATE_SUBSCRIPTION_DOCUMENTS);

  const initialValues = {
    fields: {
      acceptProductDocuments: get(
        project,
        "fields.acceptProductDocuments",
        false
      ),
      acceptProductStatuses: get(
        project,
        "fields.acceptProductStatuses",
        false
      ),
      acceptFees: get(project, "fields.acceptFees", false),
      acceptNotice: get(project, "fields.acceptNotice", false),
      acceptAgreement: get(project, "fields.acceptAgreement", false),
      acceptTeletransmission: get(
        project,
        "fields.acceptTeletransmission",
        false
      ),
    },
  };

  const onSubmit = async ({ fields }) => {
    await generateSubscriptionDocuments({
      variables: {
        data: { id: subscriptionId },
      },
    });
    const source = localStorage.getItem("source");
    updateUrl({
      subscriptionId,
      url: get(
        data,
        "subscription.insurancePlan.config.generatedUrl",
        `${process.env.REACT_APP_BASE_URL}/#/public/process/init/${source}`
      ),
    });
    onNext({
      payload: {
        fields: { ...get(project, "fields"), ...fields },
      },
    });
  };

  return (
    <>
      <Navbar
        project={project}
        progress={currentStatus?.progress}
        tracer={tracer}
      />
      <View
        initialValues={initialValues}
        onSubmit={onSubmit}
        loading={generateLoading}
      />
    </>
  );
};

export default Rgpd;

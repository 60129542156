import { get } from "lodash";
import { CURRENCY_SYMBOL } from "utils/constants";
import { costToFrench } from "utils/helpers/values";

const Card = ({
  icon,
  name,
  cost,
  currency,
  period,
  perYear,
  allIncluded,
  link,
  upsells = [],
}) => {
  return (
    <div className="quote-template-solyon__product-card">
      <div className="quote-template-solyon__product-card--body">
        <img src={icon} alt={name} />

        <div className="quote-template-solyon__product-card--infos">
          <span className="quote-template-solyon__product-card--name">
            {name}
          </span>
          <div className="quote-template-solyon__product-card--price">
            <span className="price">{costToFrench(cost)}</span>
            <div className="currency">
              <span>{CURRENCY_SYMBOL[currency]}</span>
              <small> {period}</small>
            </div>
          </div>
          <span className="quote-template-solyon__product-card--small">
            {`${(+cost * 12).toLocaleString("fr-FR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} ${CURRENCY_SYMBOL[currency]} ${perYear} `}
            <small>{allIncluded}</small>
          </span>
          <div>
            {upsells.map(({ id, insurancePlan }) => (
              <p
                key={id}
                className="quote-template-solyon__product-card--sec-name"
              >
                {get(insurancePlan, "name", "")}
              </p>
            ))}
          </div>
        </div>
        <div className="quote-template-solyon__product-card--link">{link}</div>
      </div>
    </div>
  );
};

export default Card;

import { SUBSCRIPTION_FORM_KEYS } from "utils/constants";
import BankAccount from "./BankAccount";
import HealthInsuranceRightsCertificate from "./HealthInsuranceRightsCertificate";
import Id from "./Id";

const generateProofForm = ({}) => ({
  [SUBSCRIPTION_FORM_KEYS.PROOF.ID]: Id,
  [SUBSCRIPTION_FORM_KEYS.PROOF.BANK_ACCOUNT]: BankAccount,
  [SUBSCRIPTION_FORM_KEYS.PROOF.HEALTH_INSURANCE_RIGHTS_CERTIFICATE]:
    HealthInsuranceRightsCertificate,
});

export default generateProofForm;

import { Progress } from "antd";
import logo from "../../Assets/images/logo_solyon.svg";
import Actions from "./Actions";

const Navbar = ({ progress = 0, project, showCart, showHelp, tracer }) => {
  return (
    <div className="solyon-navbar">
      <div className="solyon-navbar__row">
        <img src={logo} alt="" />
        <div className="solyon-navbar__actions">
          <Actions
            project={project}
            showCart={showCart}
            showHelp={showHelp}
            tracer={tracer}
          />
        </div>
      </div>
      <Progress percent={parseFloat(progress)} showInfo={false} />
    </div>
  );
};

Navbar.propTypes = {};

export default Navbar;

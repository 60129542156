import { gql } from "@apollo/client";

export const STORES = gql`
  query Stores(
    $where: StoreWhereInput
    $like: StoreWhereInput
    $take: Int
    $skip: Int
    $orderBy: [StoreWhereInput]
  ) {
    stores(
      where: $where
      like: $like
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      data {
        id
        name
        email
        phone
        address
        zipCode
        city
      }
      count
    }
  }
`;

export const STORE = gql`
  query Store($where: StoreWhereInput) {
    store(where: $where) {
      id
      name
      email
      phone
      address
      zipCode
      city
    }
  }
`;

const stores = { STORES, STORE };

export default stores;

import { REGEX_EXPS } from "utils/constants";
import * as yup from "yup";

const validation = (messages) => {
  const nameValidation = yup
    .string()
    .typeError(messages.NOT_STRING)
    .nullable()
    .notRequired()
    .test(
      "is-valid-name",
      messages.INVALID_NAME,
      (value) => !value || REGEX_EXPS.name.test(value)
    );
  return yup.object().shape({
    additionalInfo: yup.object().shape({
      sponsor: yup.object().shape({
        firstName: nameValidation,
        lastName: nameValidation,
      }),
    }),
  });
};

export default validation;

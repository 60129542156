import cuid from "cuid";
import { find, get } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useLazyQuery, useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { DOWNLOAD_SINGLE_DOCUMENT } from "utils/api/graphql/mutations/download-document";
import {
  ACTION_TYPES,
  INSURANCE_PLAN_ATTACHMENTS_TYPES,
  PROCESS_TYPES,
  QUOTE_ATTACHMENTS_TYPES,
  SCREENS,
  SUBSCRIPTION_ATTACHMENTS_TYPES,
  TEMPLATES,
  TEMPLATE_TYPES
} from "utils/constants";
import { downloadDocument } from "utils/helpers/files";
import { filterByConditions } from "../../../..";
import Template from "../Template";
import View from "./View";

const Contract = ({ currentStatus, tracer, project, onBack, onNext }) => {
  const [updateProject] = useMutation(graphql.mutations.UPDATE_PROJECT);
  const [loading, setLoading] = useState(true);
  const subscriptionId =
    get(project, "fields.currentSubscription") ||
    localStorage.getItem("subscriptionId");

  const navigate = useNavigate();
  const [getAttachments, { data: attachmentsList }] = useLazyQuery(
    graphql.queries.ATTACHMENTS
  );
  const [downloadSingleDocument] = useMutation(DOWNLOAD_SINGLE_DOCUMENT);
  const { data } = useQuery(graphql.queries.SUBSCRIPTION, {
    fetchPolicy: "no-cache",
    variables: { where: { id: subscriptionId } },
    onCompleted: ({ subscription: subscriptionData }) => {
      getAttachments({
        fetchPolicy: "no-cache",
        variables: {
          isIn: {
            type: [
              ...Object.values(INSURANCE_PLAN_ATTACHMENTS_TYPES),
              ...Object.values(SUBSCRIPTION_ATTACHMENTS_TYPES),
              ...Object.values(QUOTE_ATTACHMENTS_TYPES),
            ],
          },
          where: {
            OR: [
              {
                AND: [
                  {
                    project: {
                      id: project.id,
                    },
                  },
                  {
                    insurancePlans: {
                      id: get(subscriptionData, "insurancePlan.id", ""),
                    },
                  },
                ],
              },
              {
                subscription: {
                  id: subscriptionId,
                },
              },
              {
                AND: [
                  {
                    project: null,
                  },
                  {
                    insurancePlans: {
                      id: get(subscriptionData, "insurancePlan.id", ""),
                    },
                  },
                ],
              },
            ],
          },
        },
        onCompleted: () => {
          setLoading(false);
        },
      });
    },
  });

  const download = async (id) => {
    setLoading(true);
    await downloadSingleDocument({
      variables: { where: { id } },
      onCompleted: (_) => {
        const { document } = omitDeep(_.downloadSingleDocument, "__typename");
        downloadDocument(document);
        setLoading(false);
      },
    });
  };

  const { data: generalCost } = useQuery(graphql.queries.GENERAL_COST, {
    fetchPolicy: "no-cache",
    variables: {
      where: {
        project: { id: get(project, "id") },
        insurancePlan: {
          id: get(data, "subscription.insurancePlan.id"),
        },
      },
    },
    onCompleted: () => setLoading(false),
  });

  const findStatus = (data, template) => {
    return find(data, ({ template: _ }) => _ === template);
  };

  const updateProjectStatus = ({ template }) => {
    const status = findStatus(
      get(tracer, "flow.process.processStatuses", []),
      template
    );
    const actionsToExecute = filterByConditions(
      get(status, "actions", []),
      project
    );
    const syncActions = actionsToExecute
      .filter(({ type }) => type === ACTION_TYPES.SYNC_PROCESSES)
      .map(({ args: { status } }) => ({
        id: cuid(),
        status: { id: status },
      }));
    updateProject({
      variables: {
        where: { id: project.id },
        data: {
          statuses: [{ id: cuid(), status: { id: status.id } }, ...syncActions],
        },
      },
      onCompleted: ({ updateProject }) => {
        navigate(
          `${SCREENS.PRIVATE.GUEST.PROJECTS.DETAIL.path}/${updateProject?.id}`,
          {
            state: {
              process: get(omitDeep(tracer, "__typename"), "flow.process"),
              tracer: omitDeep(tracer, "__typename"),
            },
          }
        );
      },
    });
  };

  const initialValues = {
    fields: {
      acceptTeletransmission: get(
        project,
        "fields.acceptTeletransmission",
        false
      ),
      acceptAgreement: get(project, "fields.acceptAgreement", false),
    },
  };

  const onEdit = (v) => {
    updateProjectStatus({
      template: TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.B2C_LMF][v],
    });
  };

  const onSubmit = ({ fields }) => {
    onNext({
      payload: {
        fields: { ...get(project, "fields"), ...fields },
      },
    });
  };

  return (
    <Template
      progress={currentStatus.progress}
      onBack={onBack}
      loading={loading}
      HeaderComponent={{
        subscription: get(data, "subscription"),
        tracer,
        project,
        contact: get(project, "contact"),
      }}
      View={
        <View
          download={download}
          initialValues={initialValues}
          attachments={get(attachmentsList, "attachments.data", [])}
          locked={get(project, "locked", false)}
          onSubmit={onSubmit}
          project={project}
          subscription={get(data, "subscription")}
          generalCost={get(generalCost, "generalCost")}
          onEdit={onEdit}
        />
      }
      contact={get(project, "contact")}
    />
  );
};
export default Contract;

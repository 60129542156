import cuid from "cuid";
import { defaults, get, keyBy, map } from "lodash";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import Template from "../Template";
import View from "./View";

const AdherentInfo = ({ project, currentStatus, onNext, onBack }) => {
  const telecoms = defaults(keyBy(get(project, "contact.telecoms"), "system"), {
    [CONTACT_POINT_SYSTEM.ZIP_CODE]: { value: null },
  });

  const next = ({ contact, contract }) => {
    const mappedTelecoms = map(get(contact, "telecoms"), (item, key) =>
      defaults(item, { id: cuid(), system: key })
    );
    onNext({
      payload: {
        contact: {
          ...contact,
          telecoms: mappedTelecoms,
        },
        contract,
      },
    });
  };

  const initialValues = {
    contact: { ...get(project, "contact", {}), telecoms },
    contract: get(project, "contract"),
  };

  return (
    <Template
      progress={currentStatus.progress}
      onBack={onBack}
      View={
        <View
          onNext={next}
          initialValues={initialValues}
          fields={get(project, "fields", {})}
          locked={project.locked}
        />
      }
    />
  );
};

export default AdherentInfo;

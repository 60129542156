import PropTypes from "prop-types";
import React from "react";

function Card({ children }) {
  return (
    <div className="solyon-card">
      <div className="solyon-card__container">{children}</div>
    </div>
  );
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(Card);

import { CONTACT_POINT_SYSTEM, REGEX_EXPS } from "utils/constants";
import * as yup from "yup";

const validation = (errors) => {
  const requiredString = yup
    .string()
    .typeError(errors.REQUIRED)
    .required(errors.REQUIRED);

  return yup.object().shape({
    telecoms: yup.object().shape({
      [CONTACT_POINT_SYSTEM.CITY]: yup.object().shape({
        value: requiredString,
      }),
      [CONTACT_POINT_SYSTEM.ADDRESS]: yup.object().shape({
        value: requiredString,
      }),
      [CONTACT_POINT_SYSTEM.ZIP_CODE]: yup.object().shape({
        value: yup
          .string()
          .typeError(errors.REQUIRED)
          .test("isValid", errors.INVALID_VALUE, async function (value) {
            const isValidRegex = new RegExp(REGEX_EXPS.zipCode).test(value);
            return isValidRegex;
          }),
      }),
    }),
  });
};

export default validation;

import { get } from "lodash";
import { useState } from "react";
import Button from "../Components/Button";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Needs from "./Needs";

function View({ fields, onNext, onBack, categories }) {
  const [selectedValues, setSelectedValues] = useState(
    categories.reduce((acc, category) => {
      acc[category.value] = get(fields, `needs.${category.value}`, 1);
      return acc;
    }, {})
  );
  return (
    <div className="solyon">
      <div className="solyon__container">
        <div className="solyon__wrapper">
          <Header
            title="Quels sont vos besoins ?"
            highlight="besoins"
            subtitle="Personnalisation de votre couverture"
            onBack={onBack}
          />
          <div className="solyon__body">
            <Needs
              categories={categories}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
            />
            <div className="solyon__body--actions">
              <Button
                type={"primary"}
                width={366}
                alignment={"center"}
                // disabled={isUndefined(selectedValue)}
                onClick={() =>
                  onNext({
                    payload: {
                      fields: {
                        ...fields,
                        budget: { min: 0, max: 1000 },
                        needs: selectedValues,
                      },
                    },
                  })
                }
              >
                Voir mon offre personnalisée
              </Button>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default View;

import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import { GENDER } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import Button from "../Components/Button";
import Card from "../Components/Card";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Input from "../Components/Input";
import validation from "./validation";

const NUMBERS_MAPPING = {
  1: "1er",
  2: "2ème",
  3: "3ème",
  4: "4ème",
  5: "5ème",
  6: "6ème",
  7: "7ème",
  8: "8ème",
  9: "9ème",
  10: "10ème",
  11: "11ème",
  12: "12ème",
};

function View({ children, spouse, onNext, onBack }) {
  const { t } = useTranslation();
  const { handleSubmit, values, setFieldValue, ...formProps } = useFormik({
    initialValues: { children, spouse },
    onSubmit: onNext,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true }),
      { hasPartner: !isEmpty(spouse) }
    ),
  });

  return (
    <div className="solyon">
      <div className="solyon__container">
        <div className="solyon__wrapper">
          <Header
            title="Indiquez les informations nécessaires pour couvrir votre foyer"
            highlight="votre foyer"
            subtitle="Souscription : Informations du foyer"
            onBack={onBack}
          />
          <div className="solyon__body">
            <div className="grid--1 max-500">
              {!isEmpty(spouse) && (
                <Card>
                  <div className="solyon-card__label">
                    {`Nom et prénom de votre conjoint`}
                  </div>
                  <div className="grid--1">
                    <Input
                      row
                      type="radio"
                      values={[
                        {
                          value: GENDER.MALE,
                          label: "Homme",
                        },
                        {
                          value: GENDER.FEMALE,
                          label: "Femme",
                        },
                      ]}
                      {...bindInputProps({
                        name: "spouse.identity.user.gender",
                        values,
                        ...formProps,
                      })}
                      onChange={(value) =>
                        setFieldValue("spouse.identity.user.gender", value)
                      }
                    />
                    <div className="grid--2">
                      <Input
                        placeholder={"ex : Doe"}
                        label={"Nom"}
                        {...bindInputProps({
                          name: "spouse.identity.user.lastname",
                          values,
                          ...formProps,
                        })}
                      />
                      <Input
                        placeholder={"ex : John"}
                        label={"Prénom"}
                        {...bindInputProps({
                          name: "spouse.identity.user.firstname",
                          values,
                          ...formProps,
                        })}
                      />
                    </div>
                  </div>
                </Card>
              )}
              {children.map((_, i) => (
                <Card key={`card_${i}`}>
                  <div className="solyon-card__label">
                    {`Nom et prénom de votre ${NUMBERS_MAPPING[i + 1]} enfant`}
                  </div>
                  <div className="grid--1">
                    <Input
                      row
                      type="radio"
                      values={[
                        {
                          value: GENDER.MALE,
                          label: "Masculin",
                        },
                        {
                          value: GENDER.FEMALE,
                          label: "Féminin",
                        },
                      ]}
                      {...bindInputProps({
                        name: `children.${i}.identity.user.gender`,
                        values,
                        ...formProps,
                      })}
                      onChange={(value) =>
                        setFieldValue(
                          `children.${i}.identity.user.gender`,
                          value
                        )
                      }
                    />
                    <div className="grid--2">
                      <Input
                        placeholder={"ex : Doe"}
                        label={"Nom"}
                        {...bindInputProps({
                          name: `children.${i}.identity.user.lastname`,
                          values,
                          ...formProps,
                        })}
                      />
                      <Input
                        placeholder={"ex : John"}
                        label={"Prénom"}
                        {...bindInputProps({
                          name: `children.${i}.identity.user.firstname`,
                          values,
                          ...formProps,
                        })}
                      />
                    </div>
                  </div>
                </Card>
              ))}
            </div>
            <div className="solyon__body--actions">
              <Button
                type={"primary"}
                alignment={"center"}
                disabled={!isEmpty(formProps.errors)}
                onClick={handleSubmit}
              >
                Continuer
              </Button>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default View;

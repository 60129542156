import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { FRANCE_ADDRESS_ZIP_CODE_CITY } from "utils/api/graphql/queries/referentials";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import Button from "../Components/Button";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Input from "../Components/Input";
import Select from "../Components/Select";
import validation from "./validation";

function View({ telecoms, onNext, onBack }) {
  const { t } = useTranslation();

  const { handleSubmit, values, setFieldValue, ...formProps } = useFormik({
    initialValues: { telecoms },
    onSubmit: onNext,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
    ),
  });
  const [getCities, { data: cities, loading }] = useLazyQuery(
    FRANCE_ADDRESS_ZIP_CODE_CITY,
    {
      variables: {
        where: {
          zipCode: get(
            values,
            `telecoms.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
          ),
        },
      },
    }
  );

  useEffect(() => {
    const zipCode = get(
      values,
      `telecoms.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
    );
    if (!zipCode || zipCode?.length < 5) return;
    getCities({ where: zipCode });
  }, [get(values, `telecoms.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`)]);

  return (
    <div className="solyon">
      <div className="solyon__container">
        <div className="solyon__wrapper">
          <Header
            title="Passons à l’adhésion, nous avons besoin de quelques informations complémentaires"
            highlight="informations complémentaires"
            subtitle="Souscription : Informations complémentaires"
            onBack={onBack}
          />
          <div className="solyon__body">
            <div className="grid--1 max-500">
              <Input
                placeholder={"ex : 17, Rue de la victoire"}
                label={"Adresse postale"}
                {...bindInputProps({
                  name: `telecoms.${CONTACT_POINT_SYSTEM.ADDRESS}.value`,
                  values,
                  ...formProps,
                })}
              />
              <div className="grid--2">
                <Input
                  placeholder={"ex : 69001"}
                  label={"Code postal"}
                  {...bindInputProps({
                    name: `telecoms.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`,
                    values,
                    ...formProps,
                  })}
                />
                <Select
                  loading={loading}
                  options={get(cities, "franceAddressZipCodeCity.response", [])}
                  placeholder={"Sélectionnez ..."}
                  label={"Ville"}
                  {...bindInputProps({
                    name: `telecoms.${CONTACT_POINT_SYSTEM.CITY}.value`,
                    values,
                    ...formProps,
                  })}
                />
              </div>
            </div>
            <div className="solyon__body--actions">
              <Button
                type={"primary"}
                alignment={"center"}
                disabled={
                  !get(
                    values,
                    `telecoms.${CONTACT_POINT_SYSTEM.ADDRESS}.value`
                  ) || !isEmpty(formProps.errors)
                }
                onClick={handleSubmit}
              >
                Continuer
              </Button>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default View;

import { useFormik } from "formik";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import { bindInputProps } from "utils/helpers/input";
import Button from "../Components/Button";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Input from "../Components/Input";
import validation from "./validation";

function View({ onBack, loading, initialValues, onSubmit }) {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("B2C_SOLYON.RGPD", { returnObjects: true });

  const { handleSubmit, values, setFieldValue, errors, ...formProps } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema: validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      ),
    });

  const consentOptions = [
    {
      name: "fields.acceptProductDocuments",
      label: TRANSLATION.OPT_INS.DOCUMENT_INFOS,
    },
    {
      name: "fields.acceptProductStatuses",
      label: TRANSLATION.OPT_INS.STATUSES_AGREEMENT,
    },
    { name: "fields.acceptFees", label: TRANSLATION.OPT_INS.FEES },
    { name: "fields.acceptNotice", label: TRANSLATION.OPT_INS.NOTICE },
    {
      name: "fields.acceptTeletransmission",
      label: TRANSLATION.OPT_INS.TELETRANSMISSION,
    },
    { name: "fields.acceptAgreement", label: TRANSLATION.OPT_INS.AGREEMENT },
  ];

  return (
    <div className="solyon">
      <div className="solyon__container">
        <div className="solyon__wrapper">
          <Header
            title={TRANSLATION.TITLE.CONTENT}
            highlight={TRANSLATION.TITLE.HIGHLIGHT}
            subtitle={TRANSLATION.TITLE.SUBTITLE}
            onBack={onBack}
          />
          <div className="solyon__body">
            <div className="rgpd__checkboxes">
              {consentOptions.map(({ name, label }) => (
                <Input
                  key={name}
                  type="checkbox"
                  label={label}
                  {...bindInputProps({
                    name,
                    values,
                    setFieldValue,
                    ...formProps,
                  })}
                />
              ))}
            </div>
            <div className="solyon__body--actions">
              <Button
                type="primary"
                alignment="center"
                disabled={
                  !formProps.isValid ||
                  !get(values, "fields.acceptProductDocuments")
                }
                onClick={handleSubmit}
                loading={loading}
              >
                {TRANSLATION.ACTION.SIGN}
              </Button>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default View;

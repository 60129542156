import { useMutation } from "@apollo/client";
import classNames from "classnames";
import { get } from "lodash";
import { useState } from "react";
import { MdOutlineContactSupport } from "react-icons/md";
import { UPDATE_PROJECT } from "utils/api/graphql/mutations/projects";
import HelpModal from "./Modal";
import HelpContactModal from "./ModalContact";
import { useMediaQuery } from "react-responsive";

function Help({ project }) {
  const [open, setOpen] = useState(false);
  const [updateProject] = useMutation(UPDATE_PROJECT);
  const onSubmit = (data) => {
    updateProject({
      variables: {
        where: { id: project?.id },
        data,
      },
    });
  };

  const onClose = () => setOpen(false);

  const onOpen = () => setOpen(true);

  const MODAL = {
    [true]: (
      <HelpContactModal
        open={open}
        onSubmit={onSubmit}
        onClose={onClose}
        project={project}
      />
    ),
    [false]: (
      <HelpModal
        open={open}
        onSubmit={onSubmit}
        onClose={onClose}
        project={project}
      />
    ),
  };

  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <>
      <div className="solyon-help" onClick={onOpen}>
        <MdOutlineContactSupport size={isMobile ? 24 : 16} />
        <span
          className={classNames({
            hidden: !!get(project, "contact.user.firstname"),
          })}
        >
          Besoin d’aide ?
        </span>
      </div>
      {MODAL[!!get(project, "contact.user.firstname")]}
    </>
  );
}

export default Help;

import { get } from "lodash";
import { useMemo } from "react";
import { JOB_LIST, SKILL_TYPES } from "utils/constants";
import Navbar from "../Components/Navbar/Navbar";
import ViewActive from "./ViewActive";
import ViewFPH from "./ViewFPH";
import ViewRetired from "./ViewRetired";

const VIEWS = {
  DEFAULT: ViewActive,
  [JOB_LIST.RETIRED]: ViewRetired,
  [JOB_LIST.HOSPITAL_CIVIL_SERVICE]: ViewFPH,
};

const Income = ({ project, onNext, onBack, currentStatus, tracer }) => {
  const View = useMemo(
    () => VIEWS[project?.contact?.profession] || VIEWS.DEFAULT,
    [project?.contact?.profession]
  );

  const onSubmit = (contact) => {
    const establishment = get(contact, "fields.establishment") || "";
    const skills = Object.keys(get(project, "fields.skills", {})).includes(
      SKILL_TYPES.ESTABLISHMENT
    )
      ? {
          ...get(project, "fields.skills", {}),
          [SKILL_TYPES.ESTABLISHMENT]: establishment,
        }
      : get(project, "fields.skills", {});
    onNext({
      payload: {
        contact,
        fields: { ...get(project, "fields", {}), skills },
      },
    });
  };

  return (
    <>
      <Navbar
        project={project}
        progress={currentStatus?.progress}
        tracer={tracer}
      />
      <View
        contact={get(project, "contact", {})}
        onSubmit={onSubmit}
        onBack={onBack}
      />
    </>
  );
};

export default Income;

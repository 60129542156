import PropTypes from "prop-types";
import React from "react";
import { CURRENCY_SYMBOL } from "utils/constants";

function Cost({ cost, currency, showYearly,className }) {
  const [integer = "0", decimal = "00"] = cost?.toString()?.split(".") || ["-"];
  return (
    <div className={`solyon-cost ${className}`}>
      <div className="solyon-cost--main">
        <div className="solyon-cost--main--integer">{integer}</div>
        <div>
          <div className="solyon-cost--main--decimal">
            {`,${decimal} ${CURRENCY_SYMBOL[currency] || ""}`}
          </div>
          <div className="solyon-cost--main--period">{"/mois"}</div>
        </div>
      </div>
      {showYearly && (
        <div className="solyon-cost--secondary">
          {`par mois, soit ${(cost * 12)?.toFixed(2)}  par an`}
        </div>
      )}
    </div>
  );
}

Cost.propTypes = {
  cost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  currency: PropTypes.string.isRequired,
};

export default React.memo(Cost);

import { useState } from "react";
import { useTranslation } from "react-i18next";
import Loading from "shared/components/Spin";
import { NAME_SPACES } from "shared/locales/constants";
import Button from "./Components/Button";
import Cart from "./Components/Cart";
import ContactUs from "./Components/ContactUs";
import Header from "./Components/Header";
import Back from "./Components/svg/back.svg";

const Template = ({
  loading,
  contact,
  progress,
  onBack,
  View,
  HeaderComponent,
}) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("B2C_LMF.TEMPLATE", { returnObjects: true });
  const [help, setHelp] = useState(true);

  return (
    <div className="container __LMF">
      <Header progressValue={progress} View={HeaderComponent && Cart(HeaderComponent)} />
      {onBack && (
        <Button className="back" type="default" onClick={onBack}>
          <img src={Back} />
        </Button>
      )}
      {loading ? <Loading /> : View}
      <ContactUs
        contact={contact}
        display={true}
        phone={"+212666952697"}
        text={TRANSLATION.HELP}
        help={help}
        setHelp={setHelp}
      />
    </div>
  );
};

export default Template;

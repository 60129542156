import { Col, Row } from "antd";
import dayjs from "dayjs";
import { get, isEmpty, set } from "lodash";
import { useTranslation } from "react-i18next";
import {
  MdOutlineCake,
  MdOutlineFreeCancellation,
  MdPinDrop,
} from "react-icons/md";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { ANSET_SUBSCRIPTION_FORM_KEYS, DATE_FORMAT } from "utils/constants";

const Terminal = ({ setPercent, isFieldRequired, initialValues, errors }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const BLOCK = t("ANSET_SUBSCRIPTION.ADDITIONAL_INFORMATION", {
    returnObjects: true,
  });
  return (
    <div
      className="subscription-form--wrapper"
      id={`${ANSET_SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.TERMINAL}-section`}
    >
      <Form
        type="vertical"
        title={BLOCK.TERMINAL.TITLE}
        subtitle={BLOCK.TERMINAL.SUBTITLE}
      >
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <Input
              placeholder={BLOCK.TERMINAL.FIELDS.IMEI.PLACEHOLDER}
              label={BLOCK.TERMINAL.FIELDS.IMEI.LABEL}
              icon={<MdOutlineFreeCancellation size={16} />}
              defaultValue={get(initialValues, "project.fields.imei")}
              errors={errors["project.fields.imei"]}
              touched={!isEmpty(errors["project.fields.imei"])}
              onChange={({ target: { value } }) => {
                set(initialValues, "project.fields.imei", value);
              }}
              onBlur={() => setPercent(initialValues)}
              required={isFieldRequired("project.fields.imei")}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              placeholder={BLOCK.TERMINAL.FIELDS.SERIAL_NUMBER.PLACEHOLDER}
              label={BLOCK.TERMINAL.FIELDS.SERIAL_NUMBER.LABEL}
              icon={<MdOutlineFreeCancellation size={16} />}
              defaultValue={get(initialValues, "project.fields.serialNumber")}
              errors={errors["project.fields.serialNumber"]}
              touched={!isEmpty(errors["project.fields.serialNumber"])}
              onChange={({ target: { value } }) => {
                set(initialValues, "project.fields.serialNumber", value);
              }}
              onBlur={() => setPercent(initialValues)}
              required={isFieldRequired("project.fields.serialNumber")}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              label={BLOCK.TERMINAL.FIELDS.PURCHASE_DATE.LABEL}
              placeholder={BLOCK.TERMINAL.FIELDS.PURCHASE_DATE.PLACEHOLDER}
              icon={<MdOutlineCake size={16} />}
              type="date"
              variant="simple"
              errors={errors["project.fields.purchaseDate"]}
              disabledDate={(current) =>
                current && (current > dayjs() || current < dayjs().add(-3, "y"))
              }
              defaultValue={dayjs()}
              onChange={(date) => {
                if (date && date.isValid()) {
                  set(initialValues, "project.fields.purchaseDate", date);
                  setPercent(initialValues);
                } else {
                  set(initialValues, "project.fields.purchaseDate", null);
                  setPercent(initialValues);
                }
              }}
              value={
                get(initialValues, "project.fields.purchaseDate")
                  ? dayjs(get(initialValues, "project.fields.purchaseDate"))
                  : null
              }
              touched={!isEmpty(errors["project.fields.purchaseDate"])}
              onBlur={({ target: { value } }) => {
                const date = dayjs(value, DATE_FORMAT);
                if (
                  date.isValid() &&
                  date < dayjs() &&
                  date > dayjs().add(-3, "y")
                )
                  set(
                    initialValues,
                    "project.fields.purchaseDate",
                    dayjs(value, DATE_FORMAT)
                  );
                setPercent(initialValues);
              }}
              format={DATE_FORMAT}
              required={isFieldRequired("project.fields.purchaseDate")}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              placeholder={BLOCK.TERMINAL.FIELDS.PURCHASE_PLACE.PLACEHOLDER}
              label={BLOCK.TERMINAL.FIELDS.PURCHASE_PLACE.LABEL}
              icon={<MdPinDrop size={16} />}
              defaultValue={get(initialValues, "project.fields.purchasePlace")}
              errors={errors["project.fields.purchasePlace"]}
              touched={!isEmpty(errors["project.fields.purchasePlace"])}
              onChange={({ target: { value } }) => {
                set(initialValues, "project.fields.purchasePlace", value);
              }}
              onBlur={() => setPercent(initialValues)}
              required={isFieldRequired("project.fields.purchasePlace")}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Terminal;

import { Modal } from "antd";
import classNames from "classnames";
import cuid from "cuid";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaMinus, FaPlus } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { ATTACHMENT_TYPES, DATE_FORMAT, RELATIONSHIP } from "utils/constants";
import {
  hundredYearsAgoStart,
  isMajor,
  twentySevenYearsAgoStart,
} from "utils/helpers/date";
import { downloadDocument } from "utils/helpers/files";
import { bindDateInputProps } from "utils/helpers/input";
import Button from "../Components/Button";
import ProductCard from "../Components/PricingCard";
import checkMarkSmall from "../Components/svg/check_small-secondary.svg";
import relatedPersonIcon from "../Components/svg/related-person-icon.svg";
import validation from "./validation";

const phoneNumber = "+212666952697";

const View = ({
  onSendQuote,
  products,
  contact,
  refetchCosts,
  downloadSingleDocument,
  locked,
  duplicateProject,
  getInTouch,
}) => {
  const [benefits, setBenefits] = useState([]);
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });
  const TRANSLATION_2 = t("B2C_LMF.PRODUCTS", { returnObjects: true });
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [open, setOpen] = useState(false);
  const onSubmit = async ({ spouse, children }) => {
    await refetchCosts({
      contact: {
        id: contact.id,
        relatedPersons: [spouse, ...children],
      },
    });
    setAddBeneficiaries((prev) => ({ ...prev, visible: false }));
  };

  const { handleSubmit, values, ...formProps } = useFormik({
    initialValues: {
      spouse: get(contact, "relatedPersons", []).find(
        ({ relationship }) => relationship === RELATIONSHIP.SPOUSE
      ),
      children: get(contact, "relatedPersons", []).filter(
        ({ relationship }) => relationship === RELATIONSHIP.CHILD
      ),
    },
    onSubmit,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
    ),
  });
  const [childrenNumber, setChildrenNumber] = useState(
    get(values, "children", []).length
  );

  const [addBeneficiaries, setAddBeneficiaries] = useState({
    visible: false,
    spouse: !isEmpty(get(values, "spouse")),
    children: !isEmpty(get(values, "children")),
    status: !(
      isEmpty(get(values, "spouse")) && isEmpty(get(values, "children"))
    ),
  });
  const [disabled, setDisabled] = useState({});

  useEffect(() => {
    const children = get(values, "children", []);
    const oldChildrenLength = children.length;

    if (oldChildrenLength < childrenNumber) {
      formProps.setFieldValue("children", [
        ...children,
        {
          id: cuid(),
          relationship: RELATIONSHIP.CHILD,
          identity: {
            id: cuid(),
            user: {
              id: cuid(),
              birthDate: null,
            },
          },
        },
      ]);
    } else if (oldChildrenLength > childrenNumber) {
      formProps.setFieldValue("children", children.slice(0, childrenNumber));
    }
  }, [childrenNumber]);

  useEffect(() => {
    if (prevRef.current && nextRef.current) {
      prevRef.current.classList.add("custom-prev");
      nextRef.current.classList.add("custom-next");
    }
  }, []);

  const onOkClick = async () => {
    await duplicateProject();
    setOpenEditModal(false);
  };

  const onHelpClick = (benefits) => {
    setBenefits(benefits);
    setOpen(true);
  };

  const handleYesClick = () => {
    if (locked) return setOpenEditModal(true);

    setAddBeneficiaries((prev) => ({
      ...prev,
      visible: true,
      status: true,
    }));
  };

  const handleNoClick = () => {
    if (!addBeneficiaries?.status) return;
    if (locked) return setOpenEditModal(true);

    formProps.setFieldValue("spouse", undefined);
    formProps.setFieldValue("children", []);
    setChildrenNumber(0);
    setAddBeneficiaries({
      spouse: false,
      children: false,
      visible: false,
      status: false,
    });
    handleSubmit();
  };

  return (
    <>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        width={400}
        footer={null}
        className="LMF-formula-modal"
      >
        {benefits.length && (
          <>
            <span className="LMF-formula-modal__title">
              {TRANSLATION_2.HELP_MODAL.FORMULA}
            </span>
            <div className="LMF-formula-modal__content">
              <ul>
                {benefits.map(({ coverage, limit, complement }, index) => (
                  <li key={`benefit-${index}`}>
                    <img src={checkMarkSmall} />
                    <span>
                      {coverage} <span>{limit}</span> <sup>{complement}</sup>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <Button
              onClick={() => setOpen(false)}
              className="w-full"
              type="white"
              size="middle"
            >
              <span>{TRANSLATION_2.HELP_MODAL.CLOSE}</span>
            </Button>
          </>
        )}
      </Modal>
      <Modal
        type="info"
        visible={openEditModal}
        message={TRANSLATION.LOCKED_EDIT_MODAL.MESSAGE}
        okText={TRANSLATION.LOCKED_EDIT_MODAL.OK}
        cancelText={TRANSLATION.LOCKED_EDIT_MODAL.CANCEL}
        onOk={onOkClick}
        onCancel={() => setOpenEditModal(false)}
      />
      <h1 className="header-title">{TRANSLATION_2.TITLE}</h1>
      <span
        className="header-description"
        dangerouslySetInnerHTML={{ __html: TRANSLATION_2.DESCRIPTION }}
      ></span>
      <div className="b2c__area--content__related_persons">
        <div className="beneficiary_container LMF-content">
          <div className="beneficiary_container--main">
            <span className="question">
              <img src={relatedPersonIcon} />
              {TRANSLATION.PRODUCTS.ADD_BENEFICIARY}
            </span>
            <div className="d-flex">
              <Button
                type={classNames({
                  primary: addBeneficiaries?.status,
                  white: !addBeneficiaries?.status,
                })}
                size="medium"
                onClick={handleYesClick}
              >
                {TRANSLATION.YES}
              </Button>

              <Button
                type={classNames({
                  primary: !addBeneficiaries?.status,
                  white: addBeneficiaries?.status,
                })}
                size="medium"
                onClick={handleNoClick}
              >
                {TRANSLATION.NO}
              </Button>
            </div>
          </div>
          <div
            className={classNames("beneficiary_container--expand", {
              hidden: !addBeneficiaries.visible,
            })}
          >
            <div className="beneficiary_container--expand--row">
              <Input
                type="checkbox"
                label={TRANSLATION.PRODUCTS.SPOUSE}
                checked={addBeneficiaries.spouse}
                onChange={({ target: { checked } }) => {
                  setAddBeneficiaries((prev) => ({
                    ...prev,
                    spouse: checked,
                  }));
                  if (checked)
                    formProps.setFieldValue("spouse", {
                      id: cuid(),
                      relationship: RELATIONSHIP.SPOUSE,
                      identity: {
                        id: cuid(),
                        user: {
                          id: cuid(),
                          birthDate: null,
                        },
                      },
                    });
                  else formProps.setFieldValue("spouse", undefined);
                }}
              />
              <Input
                type="date"
                placeholder={TRANSLATION.BIRTH_DATE.LABEL}
                format={DATE_FORMAT}
                disabled={!addBeneficiaries.spouse}
                showToday={false}
                {...bindDateInputProps({
                  name: "spouse.identity.user.birthDate",
                  values,
                  ...formProps,
                })}
                defaultPickerValue={
                  !get(values, `spouse.identity.user.birthDate`) &&
                  dayjs().subtract(24, "years")
                }
                disabledDate={(current) =>
                  current > isMajor || current < hundredYearsAgoStart
                }
              />
            </div>
            <div className="divider" />
            <div className="beneficiary_container--expand--row">
              <div className="beneficiary_container--expand--row__children">
                <Input
                  type="checkbox"
                  label={TRANSLATION.PRODUCTS.CHILDREN}
                  checked={addBeneficiaries.children}
                  onChange={({ target: { checked } }) => {
                    if (!checked) {
                      setChildrenNumber(0);
                      formProps.setFieldValue("children", []);
                    } else if (childrenNumber === 0) setChildrenNumber(1);
                    setAddBeneficiaries((prev) => ({
                      ...prev,
                      children: checked,
                    }));
                  }}
                />
                <p>Gratuité à partir du 3ème enfant </p>
              </div>
              <div className="children-count">
                <Button
                  className="button-small-rounded"
                  onClick={() => setChildrenNumber((prev) => +prev - 1)}
                  disabled={childrenNumber < 2 || !addBeneficiaries.children}
                >
                  <FaMinus size={14} />
                </Button>
                <div className="flex--grow-1 counter">
                  <Input
                    type="number"
                    min={0}
                    max={12}
                    placeholder={"0"}
                    name={"gender"}
                    value={childrenNumber}
                    onChange={({ target: { value } }) => {
                      setChildrenNumber(+value);
                    }}
                    disabled={!addBeneficiaries.children}
                  />
                </div>
                <Button
                  className="button-small-rounded"
                  onClick={() => setChildrenNumber((prev) => +prev + 1)}
                  disabled={childrenNumber > 11 || !addBeneficiaries.children}
                >
                  <FaPlus size={14} />
                </Button>
              </div>
            </div>
            <div className="children-birthday">
              {Array(childrenNumber)
                .fill()
                .map((_, i) => (
                  <Input
                    direction="row"
                    key={`child-${i}`}
                    label={`${TRANSLATION.NUMBERS_MAPPING[i + 1] || i + 1} ${
                      TRANSLATION.CHILDREN_BIRTHDATES.CHILD
                    }  (-28 ans)`}
                    placeholder={TRANSLATION.CHILDREN_BIRTHDATES.BIRTH_DATE}
                    type="date"
                    variant="simple"
                    format={DATE_FORMAT}
                    disabledDate={(current) => {
                      return (
                        current > dayjs() || current < twentySevenYearsAgoStart
                      );
                    }}
                    defaultPickerValue={
                      !get(values, `children.${i}.identity.user.birthDate`) &&
                      dayjs().subtract(1, "years")
                    }
                    showToday={false}
                    {...bindDateInputProps({
                      name: `children.${i}.identity.user.birthDate`,
                      values,
                      ...formProps,
                    })}
                  />
                ))}
            </div>
            <Button
              type="white"
              size="middle"
              className="w-full"
              onClick={() => handleSubmit()}
              disabled={
                !isEmpty(formProps.errors) ||
                (childrenNumber === 0 && !addBeneficiaries.spouse)
              }
            >
              {TRANSLATION.PRODUCTS.SUBMIT}
            </Button>
          </div>
        </div>
        {(childrenNumber > 0 || addBeneficiaries.spouse) && (
          <div className="beneficiary_chips">
            {addBeneficiaries.spouse &&
              get(values, "spouse.identity.user.birthDate") && (
                <div className="beneficiary_chips--button">
                  <span>{TRANSLATION.PRODUCTS.SPOUSE}</span>
                  <RxCross2
                    size={14}
                    onClick={() => {
                      formProps.setFieldValue("spouse", undefined);
                      setAddBeneficiaries((prev) => ({
                        ...prev,
                        spouse: false,
                      }));
                      handleSubmit();
                    }}
                  />
                </div>
              )}
            {get(values, "children", [])
              .filter((child) => get(child, "identity.user.birthDate"))
              .map((_, i) => (
                <div key={`child_${i}`} className="beneficiary_chips--button">
                  <span>{`${TRANSLATION.CHILDREN.CHILD.TITLE} ${i + 1}`}</span>
                  <RxCross2
                    onClick={() => {
                      values.children.splice(i, 1);
                      setChildrenNumber((prev) => +prev - 1);
                      if (childrenNumber === 1)
                        setAddBeneficiaries((prev) => ({
                          ...prev,
                          children: false,
                        }));
                      formProps.setFieldValue(
                        "children",
                        get(values, "children", [])
                      );
                      handleSubmit();
                    }}
                    size={14}
                  />
                </div>
              ))}
          </div>
        )}
      </div>
      <div className="lmf__swiper">
        <Swiper
          modules={[Navigation]}
          slidesPerView={3}
          spaceBetween={0}
          autoplay={{
            delay: 8000,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          breakpoints={{
            100: {
              slidesPerView: 1,
              initialSlide: 1,
            },
            320: {
              slidesPerView: 1,
              centeredSlides: true,
              initialSlide: 1,
            },
            520: {
              slidesPerView: 1,
              centeredSlides: true,
              initialSlide: 1,
            },
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 2,
            },
            840: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 3,
            },
            1099: {
              slidesPerView: 3,
            },
            1199: {
              slidesPerView: 3,
            },
          }}
        >
          {products.map(({ id, cost, currency, insurancePlan }, i) => (
            <SwiperSlide key={insurancePlan.id}>
              <ProductCard
                perMonth={true}
                price={{
                  cost,
                  currency,
                  period: TRANSLATION.PRODUCTS.PER_MONTH,
                }}
                planName={get(insurancePlan, "name")}
                onHelpClick={() =>
                  onHelpClick(get(insurancePlan, `fields.flagshipGuarantees`))
                }
                action={{
                  onClick: () => {
                    setDisabled({ [id]: true });
                    onSendQuote(insurancePlan.id);
                  },
                  label: TRANSLATION_2.PRODUCTS.CHOOSE_OFFER,
                  loading: disabled[id],
                  disabled: !isEmpty(disabled) || addBeneficiaries.visible,
                }}
                info={{
                  title: "Les garanties phares",
                  items: get(insurancePlan, `fields.flagshipGuarantees`),
                }}
                link={{
                  onClick: () => {
                    downloadSingleDocument({
                      variables: {
                        where: {
                          insurancePlans: { id: insurancePlan?.id },
                          type: ATTACHMENT_TYPES.BROCHURE,
                        },
                      },
                      onCompleted: (_) => {
                        const { document } = omitDeep(
                          _.downloadSingleDocument,
                          "__typename"
                        );
                        downloadDocument(document);
                      },
                    });
                  },
                  label: TRANSLATION.PRODUCTS.COVERAGE_DETAIL,
                }}
                isRecommended={i === 1}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="lmf_swiper__arrows">
          <div className="swiper-button-prev"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
      <div className="custom_offer">
        <div className="custom_offer--info">
          <span className="custom_offer--info__title">
            {TRANSLATION_2.GET_IN_TOUCH.TITLE}
          </span>
          <span className="custom_offer--info__description">
            {TRANSLATION_2.GET_IN_TOUCH.DESCRIPTION}
          </span>
        </div>
        <Button type="white" size="medium" onClick={getInTouch}>
          {TRANSLATION_2.GET_IN_TOUCH.ACTION}
        </Button>
      </div>
    </>
  );
};

export default View;

import { get } from "lodash";
import { useQuery } from "shared/hooks/useApi";
import { SUBSCRIPTION } from "utils/api/graphql/queries/subscription";
import Navbar from "../Components/Navbar/Navbar";
import View from "./View";

const SocialSecurityNumber = ({
  project,
  onNext,
  onBack,
  tracer,
  currentStatus,
  updateUrl,
}) => {
  const subscriptionId =
    localStorage.getItem("subscriptionId") ||
    get(project, "fields.currentSubscription");
  const { data } = useQuery(SUBSCRIPTION, {
    variables: { where: { id: subscriptionId } },
  });
  const onSubmit = ({ socialSecurityNumber }) => {
    const source = localStorage.getItem("source");
    updateUrl({
      subscriptionId,
      url: get(
        data,
        "subscription.insurancePlan.config.generatedUrl",
        `${process.env.REACT_APP_BASE_URL}/#/public/process/init/${source}`
      ),
    });
    onNext({
      payload: {
        contact: {
          id: get(project, "contact.id"),
          socialSecurityNumber,
        },
      },
    });
  };
  return (
    <>
      <Navbar
        project={project}
        progress={currentStatus?.progress}
        tracer={tracer}
        showCart
      />
      <View
        contact={get(project, "contact", {})}
        onNext={onSubmit}
        onBack={onBack}
      />
    </>
  );
};

export default SocialSecurityNumber;

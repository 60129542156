import Loading from "shared/components/Spin";
import { TEMPLATES } from "utils/constants";
import CollectionNeeds from "./CollectionNeeds";
import Comparison from "./Comparison";
import Contact from "./Contact";
import Contract from "./Contract";
import Default from "./Default";
import Fast from "./Fast";
import Quote from "./Quote";
import SelectProducts from "./SelectProducts";
import Upsells from "./Upsells";

const Templates = {
  Loading: Loading,
  [TEMPLATES.B2B.THEMES.B2B_LMF.CONTACT]: Contact,
  [TEMPLATES.B2B.THEMES.B2B_LMF.CONTRACT]: Contract,
  [TEMPLATES.B2B.THEMES.B2B_LMF.QUOTE]: Quote,
  [TEMPLATES.B2B.THEMES.B2B_LMF.FAST]: Fast,
  [TEMPLATES.B2B.THEMES.B2B_LMF.COLLECTION_NEEDS]: CollectionNeeds,
  [TEMPLATES.B2B.THEMES.B2B_LMF.SELECT_PRODUCTS]: SelectProducts,
  [TEMPLATES.B2B.THEMES.B2B_LMF.COMPARISON]: Comparison,
  [TEMPLATES.B2B.THEMES.B2B_LMF.UPSELLS]: Upsells,
  default: Default,
};
export default Templates;

import { gql } from "@apollo/client";

export const TRACERS = gql`
  query Tracers(
    $where: TracerWhereInput
    $take: Int
    $skip: Int
    $like: TracerWhereInput
  ) {
    tracers(where: $where, take: $take, skip: $skip, like: $like) {
      data {
        id
        name
        destination
        type
        source {
          id
          name
        }
        flow {
          id
          process {
            id
            theme
            type
            processStatuses {
              id
              template
              name
              tag
              progress
              order
              color
              background
              moveToNext
              createdDate
              possibleStatuses {
                order
                nextStatus
                conditions {
                  operation
                  rightOperand
                  leftOperand
                }
              }
              actions {
                id
                type
                args
                conditions {
                  id
                  operation
                  rightOperand
                  leftOperand
                }
              }
            }
          }
        }
      }
      count
    }
  }
`;

export const TRACER = gql`
  query Tracer($where: TracerWhereInput) {
    tracer(where: $where) {
      id
      name
      destination
      type
      source {
        id
        name
      }
      flow {
        id
        process {
          id
          theme
          processStatuses {
            id
            template
            name
            tag
            progress
            order
            color
            background
            moveToNext
            createdDate
            possibleStatuses {
              order
              nextStatus
              conditions {
                operation
                rightOperand
                leftOperand
              }
            }
            actions {
              id
              type
              args
              conditions {
                id
                operation
                rightOperand
                leftOperand
              }
            }
          }
        }
      }
    }
  }
`;

const tracers = { TRACERS, TRACER };

export default tracers;

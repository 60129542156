import classNames from "classnames";
import { get } from "lodash";
import format from "string-template";
import Card from "./Card";

const EmailTemplate = ({
  civility,
  lastName,
  firstName,
  currentDate,
  endOfValidityDate,
  adminPhoneNumber,
  adminFirstName,
  adminLastName,
  adminEmail,
  adminAddress,
  translation,
  costs,
  addLinks,
  upsells,
}) => {
  return (
    <div className="quote-template-solyon__main-container">
      <div className="quote-template-solyon__header">
        <img
          src="https://www.solyon-mutuelle.fr/wp-content/uploads/2022/06/Logo_quadri.svg"
          alt="SO'LYON Logo"
        />
      </div>
      <div className="quote-template-solyon__container">
        <div className="quote-template-solyon__content">
          <p>
            {translation.HELLO}{" "}
            <strong>
              {translation[civility] || civility} {firstName} {lastName}
            </strong>
            ,
          </p>
          <p>{translation.P1}</p>
          <p>
            {translation.P2} {currentDate}.
          </p>
          <p>{translation.P3}</p>
          <p>
            {translation.P4}{" "}
            <span className="quote-template-solyon__highlight">
              {endOfValidityDate}
            </span>
            .
          </p>
        </div>
        <div className="quote-template-solyon__content">
          <div className="quote-template-solyon__submit--link">
            <div className="quote-template-solyon__submit">
              {translation.BUTTON}
            </div>
          </div>
        </div>
        <div className={classNames({ hidden: !addLinks })}>
          <div className="quote-template-solyon__content">
            <p className="subtitle">{translation.P5}</p>
          </div>
          {costs?.map(({ id, insurancePlan, cost, currency }) => {
            const insuranceUpsells = get(upsells, insurancePlan.id, []);
            const totalCost = insuranceUpsells.reduce(
              (acc, { cost }) => (acc += cost),
              cost
            );
            return (
              <Card
                key={id}
                icon={insurancePlan?.icon}
                name={insurancePlan?.name}
                cost={totalCost}
                currency={currency}
                period={translation.PER_MONTH}
                perYear={translation.PER_YEAR}
                allIncluded={translation.ALL_INCLUDED}
                link={translation.LINK}
                upsells={insuranceUpsells}
              />
            );
          })}
        </div>
        <div className="quote-template-solyon__content">
          <p>{format(translation.P6, { adminPhoneNumber })}</p>
          <p>{translation.P7}</p>
          <p>{translation.P8}</p>
          <p>{translation.YOUR_ADVISOR}</p>
        </div>
        <div className="quote-template-solyon__info">
          <p className="quote-template-solyon__info--name">
            {adminFirstName} {adminLastName}
          </p>
          <p>{translation.SOLYON}</p>
          <p>{adminEmail}</p>
          <p>{adminPhoneNumber}</p>
          <p>{adminAddress}</p>
        </div>
      </div>
      <div className="quote-template-solyon__footer">
        <p className="quote-template-solyon__footer--title">
          {translation.SOLYON}
        </p>
        <p>28 rue Narcisse Bertholey 69600 OULLINS</p>
      </div>
    </div>
  );
};

export default EmailTemplate;

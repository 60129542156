import { Radio } from "antd";
import PropTypes from "prop-types";
import { useState } from "react";
import checkMarkChecked from "../svg/check-mark-checked.svg";
import checkMarkUnchecked from "../svg/check-mark-unchecked.svg";

const Simple = ({
  errors,
  touched,
  label,
  icon,
  onChange,
  values,
  value,
  required,
  subLabel,
  style,
  ...rest
}) => {
  const [checkedValue, setCheckedValue] = useState(value);
  const onSelect = ({ target: { value } }) => {
    setCheckedValue(value);
    onChange(value);
  };

  return (
    <div className="lmg-simple-radio">
      <Radio.Group
        onChange={onSelect}
        value={checkedValue}
        defaultValue={value}
        optionType="button"
        {...rest}
      >
        {values.map(
          ({ label, icon, value: _value, popover, subLabel, row }) => (
            <Radio
              value={_value}
              className={`custom--item  ${
                _value === checkedValue && "checked"
              }`}
              key={`radio-${_value}`}
            >
              <div className="checkbox-container">
                <img
                  src={
                    _value === checkedValue
                      ? checkMarkChecked
                      : checkMarkUnchecked
                  }
                />
                <p className="radio-title">{label}</p>
              </div>
            </Radio>
          )
        )}
      </Radio.Group>
      {touched && errors && <p className={"input--errors"}>{errors}</p>}
    </div>
  );
};

Simple.propTypes = {
  loading: PropTypes.bool,
  hidePlaceholder: PropTypes.bool,
  placeholder: PropTypes.node,
  onChange: PropTypes.func,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      value: PropTypes.any,
    })
  ),
  value: PropTypes.any,
};

Simple.defaultProps = {
  onChange: () => {},
  values: [],
};
export default Simple;

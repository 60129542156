import { get } from "lodash";
import format from "string-template";
import Card from "./Card";

const SolyonEmailTemplate = ({
  lastName,
  firstName,
  translation,
  costs,
  adminPhoneNumber,
  adminEmail,
  adminFirstName,
  adminLastName,
  adminAddress,
  upsells,
}) => {
  return (
    <div className="quote-template-solyon__main-container">
      <div className="quote-template-solyon__header">
        <img
          src="https://www.solyon-mutuelle.fr/wp-content/uploads/2022/06/Logo_quadri.svg"
          alt="SO'LYON Logo"
        />
      </div>
      <div className="quote-template-solyon__container">
        <div className="quote-template-solyon__content">
          <p>{format(translation.HEADER, { firstName, lastName })}</p>
          <p>{translation.P1}</p>
          <p>{translation.P2} </p>
          <div className="quote-template-solyon__product-card--container">
            {costs?.map(({ id, insurancePlan, cost, currency }) => {
              const insuranceUpsells = get(upsells, insurancePlan.id, []);
              const totalCost = insuranceUpsells.reduce(
                (acc, { cost }) => (acc += cost),
                cost
              );
              return (
                <Card
                  key={id}
                  icon={insurancePlan?.icon}
                  name={insurancePlan?.name}
                  cost={totalCost}
                  currency={currency}
                  period={translation.CARD.PER_MONTH}
                  perYear={translation.CARD.PER_YEAR}
                  allIncluded={translation.CARD.ALL_INCLUDED}
                  link={translation.CARD.LINK}
                  upsells={insuranceUpsells}
                />
              );
            })}
          </div>
          <p>{format(translation.P3, { phone: adminPhoneNumber })}</p>
          <br />
          <div className="quote-template-solyon__content">
            <p>{translation.WITH_SMILE}</p>
          </div>
          <div className="quote-template-solyon__info">
            <p className="quote-template-solyon__info--text">
              <span>{translation.YOUR_ADVISOR}</span>
            </p>
            <p className="quote-template-solyon__info--name">
              {adminFirstName} {adminLastName}
            </p>
            <p>{translation.NAME_ORGANIZATION}</p>
            <p>{adminEmail}</p>
            <p>{adminPhoneNumber}</p>
            <p>{adminAddress}</p>
          </div>
        </div>
      </div>
      <div className="quote-template-solyon__footer">
        <p className="quote-template-solyon__footer--title">
          {translation.NAME_ORGANIZATION}
        </p>
        <p>28 rue Narcisse Bertholey 69600 OULLINS</p>
      </div>
    </div>
  );
};

export default SolyonEmailTemplate;

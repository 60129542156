import { Switch } from "antd";
import { get, isEmpty, set } from "lodash";
import { useTranslation } from "react-i18next";
import { MdOutlineInsertComment } from "react-icons/md";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import {
  JOB_LIST,
  MEMBERSHIP_ORIGINS,
  SUBSCRIPTION_FORM_KEYS,
} from "utils/constants";

const Information = ({
  values,
  setFieldValue,
  errors,
  initialValues,
  formProps,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const INFORMATION = t(
    "SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.INFORMATION",
    {
      returnObjects: true,
    }
  );

  const DEFAULT_QUESTIONS = [
    "project.contact.fields.membershipOrigin",
    "project.fields.info.offerCDS",
    "project.contact.politicallyExposedPerson",
    ![JOB_LIST.HOSPITAL_CIVIL_SERVICE, JOB_LIST.RETIRED].includes(
      get(initialValues, "project.contact.profession")
    )
      ? "project.contact.optMadelin"
      : null,
    "project.fields.acceptProductDocuments",
    "project.fields.acceptProductStatuses",
    "project.fields.acceptFees",
    "project.fields.acceptNotice",
    "project.fields.acceptTeletransmission",
    "project.fields.acceptAgreement",
  ];
  const QUESTIONS = {
    "project.contact.fields.membershipOrigin": (
      <Select
        options={Object.entries(MEMBERSHIP_ORIGINS).map(([key, value]) => ({
          value,
          label: INFORMATION.MEMBERSHIP_ORIGINS[key],
        }))}
        label={INFORMATION.MEMBERSHIP_ORIGIN}
        placeholder={INFORMATION.MEMBERSHIP_ORIGIN}
        value={get(initialValues, "project.contact.fields.membershipOrigin")}
        onChange={(e) => {
          set(initialValues, "project.contact.fields.membershipOrigin", e);
        }}
      />
    ),
    "project.contact.optMadelin": (
      <div className="d-flex justify--between">
        <span className="contact-stepper__content--radio--title">
          {INFORMATION.MADELIN}
        </span>
        <Switch
          className="contact-stepper__content--radio--switch button__switch"
          checkedChildren={INFORMATION.OPTIONS.YES}
          unCheckedChildren={INFORMATION.OPTIONS.NO}
          defaultChecked={get(initialValues, "project.contact.optMadelin")}
          onChange={(e) => {
            set(initialValues, "project.contact.optMadelin", e);
          }}
        />
      </div>
    ),
    "project.fields.info.cssBeneficiary": (
      <div className="d-flex justify--between">
        <span className="contact-stepper__content--radio--title">
          {INFORMATION.CSH_BENEFICIARY}
        </span>
        <Switch
          className="contact-stepper__content--radio--switch button__switch"
          checkedChildren={INFORMATION.OPTIONS.YES}
          unCheckedChildren={INFORMATION.OPTIONS.NO}
          defaultChecked={get(
            initialValues,
            "project.fields.info.cssBeneficiary"
          )}
          onChange={(e) => {
            set(initialValues, "project.fields.info.cssBeneficiary", e);
          }}
        />
      </div>
    ),
    "project.fields.acceptProductDocuments": (
      <div className="d-flex justify--between">
        <span className="contact-stepper__content--radio--title">
          {INFORMATION.SO_LYON.DOCUMENT_INFOS}
        </span>
        <Switch
          className="contact-stepper__content--radio--switch button__switch"
          checkedChildren={INFORMATION.OPTIONS.YES}
          unCheckedChildren={INFORMATION.OPTIONS.NO}
          defaultChecked={get(
            initialValues,
            "project.fields.acceptProductDocuments"
          )}
          onChange={(e) => {
            set(initialValues, "project.fields.acceptProductDocuments", e);
          }}
        />
      </div>
    ),
    "project.fields.acceptProductStatuses": (
      <div className="d-flex justify--between">
        <span className="contact-stepper__content--radio--title">
          {INFORMATION.SO_LYON.STATUSES_AGREEMENT}
        </span>
        <Switch
          className="contact-stepper__content--radio--switch button__switch"
          checkedChildren={INFORMATION.OPTIONS.YES}
          unCheckedChildren={INFORMATION.OPTIONS.NO}
          defaultChecked={get(
            initialValues,
            "project.fields.acceptProductStatuses"
          )}
          onChange={(e) => {
            set(initialValues, "project.fields.acceptProductStatuses", e);
          }}
        />
      </div>
    ),
    "project.fields.acceptFees": (
      <div className="d-flex justify--between">
        <span className="contact-stepper__content--radio--title">
          {INFORMATION.SO_LYON.FEES}
        </span>
        <Switch
          className="contact-stepper__content--radio--switch button__switch"
          checkedChildren={INFORMATION.OPTIONS.YES}
          unCheckedChildren={INFORMATION.OPTIONS.NO}
          defaultChecked={get(initialValues, "project.fields.acceptFees")}
          onChange={(e) => {
            set(initialValues, "project.fields.acceptFees", e);
          }}
        />
      </div>
    ),
    "project.fields.acceptNotice": (
      <div className="d-flex justify--between">
        <span className="contact-stepper__content--radio--title">
          {INFORMATION.SO_LYON.NOTICE}
        </span>
        <Switch
          className="contact-stepper__content--radio--switch button__switch"
          checkedChildren={INFORMATION.OPTIONS.YES}
          unCheckedChildren={INFORMATION.OPTIONS.NO}
          defaultChecked={get(initialValues, "project.fields.acceptNotice")}
          onChange={(e) => {
            set(initialValues, "project.fields.acceptNotice", e);
          }}
        />
      </div>
    ),
    "project.fields.acceptTeletransmission": (
      <div className="d-flex justify--between">
        <span className="contact-stepper__content--radio--title">
          {INFORMATION.SO_LYON.TELETRANSMISSION}
        </span>
        <Switch
          className="contact-stepper__content--radio--switch button__switch"
          checkedChildren={INFORMATION.OPTIONS.YES}
          unCheckedChildren={INFORMATION.OPTIONS.NO}
          defaultChecked={get(
            initialValues,
            "project.fields.acceptTeletransmission"
          )}
          onChange={(e) => {
            set(initialValues, "project.fields.acceptTeletransmission", e);
          }}
        />
      </div>
    ),
    "project.fields.acceptAgreement": (
      <div className="d-flex justify--between">
        <span className="contact-stepper__content--radio--title">
          {INFORMATION.SO_LYON.AGREEMENT}
        </span>
        <Switch
          className="contact-stepper__content--radio--switch button__switch"
          checkedChildren={INFORMATION.OPTIONS.YES}
          unCheckedChildren={INFORMATION.OPTIONS.NO}
          defaultChecked={get(initialValues, "project.fields.acceptAgreement")}
          onChange={(e) => {
            set(initialValues, "project.fields.acceptAgreement", e);
          }}
        />
      </div>
    ),
    "project.fields.info.offerCDS": (
      <div className="d-flex justify--between">
        <span className="contact-stepper__content--radio--title">
          {INFORMATION.CDS_OFFER}
        </span>
        <Switch
          className="contact-stepper__content--radio--switch button__switch"
          checkedChildren={INFORMATION.OPTIONS.YES}
          unCheckedChildren={INFORMATION.OPTIONS.NO}
          defaultChecked={get(initialValues, "project.fields.info.offerCDS")}
          onChange={(e) => {
            set(initialValues, "project.fields.info.offerCDS", e);
          }}
        />
      </div>
    ),
    "project.contact.politicallyExposedPerson": (
      <div className="d-flex justify--between">
        <span className="contact-stepper__content--radio--title">
          {INFORMATION.POLITICALLY_EXPOSED_PERSON.LABEL}
        </span>
        <Switch
          className="contact-stepper__content--radio--switch button__switch"
          checkedChildren={INFORMATION.OPTIONS.YES}
          unCheckedChildren={INFORMATION.OPTIONS.NO}
          defaultChecked={get(
            initialValues,
            "project.contact.politicallyExposedPerson"
          )}
          onChange={(e) => {
            set(initialValues, "project.contact.politicallyExposedPerson", e);
          }}
        />
      </div>
    ),
  };

  return (
    <div
      className="subscription-form--wrapper"
      id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.INFORMATION}-section`}
    >
      <Form type="vertical" title={INFORMATION.TITLE}>
        <div className="grid--1">
          {DEFAULT_QUESTIONS.map((info) => (
            <div key={info}>{QUESTIONS[info]}</div>
          ))}
          <Input
            type="textarea"
            label={INFORMATION.COMMENT}
            placeholder={INFORMATION.COMMENT}
            icon={<MdOutlineInsertComment size={16} />}
            defaultValue={get(initialValues, "project.fields.info.comment")}
            errors={errors["project.fields.info.comment"]}
            touched={!isEmpty(errors["project.fields.info.comment"])}
            onChange={({ target: { value } }) => {
              set(initialValues, "project.fields.info.comment", value);
            }}
            autoSize={{ minRows: 2, maxRows: 10 }}
          />
        </div>
      </Form>
    </div>
  );
};

export default Information;

import WithRoutePermission from "shared/components/HOC/WithRoutePermission";
import { MODULES, SCREENS, SUBMODULES } from "utils/constants";
import Create from "./Create";
import Delete from "./Delete";
import Detail from "./Detail";
import List from "./List";

const routes = [
  {
    path: SCREENS.PRIVATE.ADMIN.STORES.LIST.path,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.STORE]: SUBMODULES.ADMIN_LIST }}
        WrappedComponent={List}
      />
    ),
  },
  {
    path: SCREENS.PRIVATE.ADMIN.STORES.CREATE.path,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.STORE]: SUBMODULES.ADMIN_CREATE }}
        WrappedComponent={Create}
      />
    ),
  },
  {
    path: `${SCREENS.PRIVATE.ADMIN.STORES.DETAIL.path}/:id`,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.STORE]: SUBMODULES.ADMIN_DETAIL }}
        WrappedComponent={Detail}
      />
    ),
  },
  {
    path: `${SCREENS.PRIVATE.ADMIN.STORES.DELETE.path}/:id`,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.STORE]: SUBMODULES.ADMIN_DELETE }}
        WrappedComponent={Delete}
      />
    ),
  },
];

export default routes;

import { get } from "lodash";
import Navbar from "../Components/Navbar/Navbar";
import View from "./View";

const Profile = ({ project, currentStatus, onNext, tracer }) => {
  return (
    <>
      <Navbar
        project={project}
        progress={currentStatus?.progress}
        showHelp={false}
      />
      <View contact={get(project, "contact", {})} onNext={onNext} />;
    </>
  );
};

export default Profile;

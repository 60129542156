import { CONTACT_POINT_SYSTEM, REGEX_EXPS, USER_TYPES } from "utils/constants";
import * as yup from "yup";

const validator = (errors) => {
  const requiredString = yup
    .string()
    .typeError(errors.REQUIRED)
    .required(errors.REQUIRED);
  const requiredName = yup
    .string()
    .typeError(errors.REQUIRED)
    .matches(REGEX_EXPS.name, errors.INVALID_NAME)
    .required(errors.REQUIRED);
  return yup.object().shape({
    meta: yup.object().shape({
      type: requiredString,
      network: yup.mixed().when("type", {
        is: (type) => type === USER_TYPES.INTERNAL_ADVISOR.type,
        then: requiredString,
      }),
      agency: yup.mixed().when("type", {
        is: (type) => type === USER_TYPES.INTERNAL_ADVISOR.type,
        then: requiredString,
      }),
    }),
    orias: yup.mixed().when("meta.type", {
      is: (type) =>
        [
          USER_TYPES.BROKER_REPRESENTATIVE.type,
          USER_TYPES.INSURANCE_REPRESENTATIVE.type,
        ].includes(type),
      then: requiredString,
    }),
    socialReason: yup.mixed().when("meta.type", {
      is: (type) =>
        [
          USER_TYPES.BROKER_REPRESENTATIVE.type,
          USER_TYPES.INSURANCE_REPRESENTATIVE.type,
        ].includes(type),
      then: requiredString,
    }),
    telecoms: yup.mixed().when("meta.type", {
      is: (type) => [USER_TYPES.INSURANCE_REPRESENTATIVE.type].includes(type),
      then: yup.object().shape({
        [CONTACT_POINT_SYSTEM.ADDRESS]: yup.object().shape({
          value: requiredString,
        }),

        [CONTACT_POINT_SYSTEM.ZIP_CODE]: yup.object().shape({
          value: requiredString,
        }),
        [CONTACT_POINT_SYSTEM.CITY]: yup.object().shape({
          value: requiredString,
        }),
        [CONTACT_POINT_SYSTEM.PHONE]: yup.object().shape({
          value: yup
            .string()
            .typeError(errors.REQUIRED)
            .test("isValid", errors.INVALID_VALUE, function (value) {
              return new RegExp(REGEX_EXPS.phone).test(value);
            })
            .required(),
        }),
        [CONTACT_POINT_SYSTEM.EMAIL]: yup.object().shape({
          value: yup
            .string()
            .typeError(errors.REQUIRED)
            .email(errors.EMAIL)
            .required(),
        }),
      }),
    }),
    code: yup.mixed().when("meta.type", {
      is: (type) => type === USER_TYPES.INSURANCE_REPRESENTATIVE.type,
      then: requiredString,
    }),
    rcsNumber: yup.mixed().when("meta.type", {
      is: (type) => type === USER_TYPES.INSURANCE_REPRESENTATIVE.type,
      then: requiredString,
    }),
    gender: requiredString,
    firstname: requiredName,
    lastname: requiredName,
    email: yup
      .string()
      .typeError(errors.REQUIRED)
      .email(errors.EMAIL)
      .required(errors.REQUIRED),
    phone: yup
      .string()
      .typeError(errors.REQUIRED)
      .nullable()
      .test("isValid", errors.INVALID_VALUE, function (value) {
        if (!value) return true;
        return new RegExp(REGEX_EXPS.phone).test(value);
      }),
  });
};

export default validator;

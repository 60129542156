import cuid from "cuid";
import { filter, get, map } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { CREATE_ATTACHMENT } from "utils/api/graphql/mutations/attachment";
import { SIGN_DOCUMENT } from "utils/api/graphql/mutations/esign";
import { ATTACHMENT_TYPES } from "utils/constants";
import Template from "../Template";
import View from "./View";

export const DOCUMENTS_TYPE = {
  cin: "CIN",
  paymentAccount: "PAYMENT_ACCOUNT",
  healthInsuranceRightsCertificate: "HEALTH_INSURANCE_RIGHTS_CERTIFICATE",
};

const SpouseJustification = ({
  currentStatus,
  tracer,
  project,
  onBack,
  updateUrl,
  onNext,
}) => {
  const [initialValues, setInitialValues] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("B2C_LMF.SPOUSE_JUSTIFICATION", { returnObjects: true });
  const subscriptionId =
    get(project, "fields.currentSubscription") ||
    localStorage.getItem("subscriptionId");
  const [signDocuments] = useMutation(SIGN_DOCUMENT, {
    refetchQueries: [
      {
        query: graphql.queries.SUBSCRIPTION,
        awaitRefetchQueries: true,
        variables: { where: { id: subscriptionId } },
      },
    ],
  });
  const { data: subscription } = useQuery(graphql.queries.SUBSCRIPTION, {
    variables: { where: { id: subscriptionId } },
  });

  const { loading: attachmentLoading } = useQuery(graphql.queries.ATTACHMENTS, {
    variables: {
      where: {
        OR: [
          {
            subscription: {
              id: subscriptionId,
            },
          },
        ],
      },
      isIn: {
        type: Object.values(DOCUMENTS_TYPE),
      },
    },
    onCompleted: ({ attachments: { data: attachmentsList } }) => {
      setInitialValues({
        healthInsuranceRightsCertificate: map(
          filter(
            attachmentsList,
            ({ type }) =>
              type === ATTACHMENT_TYPES.HEALTH_INSURANCE_RIGHTS_CERTIFICATE
          ),
          ({ id, name, fileUrl }) => ({
            uid: id,
            name,
            status: "done",
            url: fileUrl,
          })
        ),
        paymentAccount: map(
          filter(
            attachmentsList,
            ({ type }) => type === ATTACHMENT_TYPES.PAYMENT_ACCOUNT
          ),
          ({ id, name, fileUrl }) => ({
            uid: id,
            name,
            status: "done",
            url: fileUrl,
          })
        ),
        cin: map(
          filter(attachmentsList, ({ type }) => type === ATTACHMENT_TYPES.CIN),
          ({ id, name, fileUrl }) => ({
            uid: id,
            name,
            status: "done",
            url: fileUrl,
          })
        ),
        includedChildren: get(project, "fields.includedChildren"),
      });
      setLoading(false);
    },
  });
  const [addAttachment] = useMutation(CREATE_ATTACHMENT);
  const onSubmit = async ({ includedChildren, ...documents }) => {
    setLoading(true);
    const documentsToUpload = [];
    Object.entries(documents)?.map(([key, attachments]) =>
      attachments
        ?.filter((e) => !e.status)
        .forEach(({ name, contentType, base64 }) => {
          documentsToUpload.push({
            id: cuid(),
            subscription: { id: subscriptionId },
            name,
            content: base64,
            contentType,
            type: DOCUMENTS_TYPE[key],
          });
        })
    );
    await Promise.all(
      documentsToUpload.map((document, index) =>
        addAttachment({
          variables: {
            data: document,
          },
        })
      )
    );

    // const source = localStorage.getItem("source");
    // notification.open({
    //   message: TRANSLATION.E_SIGNATURE_SENT,
    //   duration: 5,
    //   icon: <IoCheckmarkCircleOutline color={COLORS.C_SUCCESS} />,
    // });
    // updateUrl({
    //   subscriptionId,
    //   url: get(
    //     subscription,
    //     "subscription.insurancePlan.config.generatedUrl",
    //     `${process.env.REACT_APP_BASE_URL}/#/public/process/init/${source}`
    //   ),
    // });
    onNext({
      payload: {
        fields: {
          ...get(project, "fields"),
          includedChildren,
        },
      },
    });

    // signDocuments({
    //   variables: {
    //     data: { id: subscriptionId },
    //   },
    //   onCompleted: () => {
    //     const source = localStorage.getItem("source");
    //     notification.open({
    //       message: TRANSLATION.E_SIGNATURE_SENT,
    //       duration: 5,
    //       icon: <IoCheckmarkCircleOutline color={COLORS.C_SUCCESS} />,
    //     });
    //     updateUrl({
    //       subscriptionId,
    //       url: get(
    //         subscription,
    //         "subscription.insurancePlan.config.generatedUrl",
    //         `${process.env.REACT_APP_BASE_URL}/#/public/process/init/${source}`
    //       ),
    //     });
    //     onNext({
    //       payload: {
    //         fields: {
    //           ...get(project, "fields"),
    //           includedChildren,
    //         },
    //       },
    //     });
    //   },
    // });
  };

  return (
    <Template
      progress={currentStatus.progress}
      onBack={onBack}
      loading={loading || attachmentLoading || !initialValues}
      View={
        <View
          locked={get(project, "locked", false)}
          onSubmit={onSubmit}
          initialValues={initialValues}
          translate={TRANSLATION}
        />
      }
      HeaderComponent={{
        subscription: get(subscription, "subscription"),
        tracer,
        project,
        contact: get(project, "contact"),
      }}
      contact={get(project, "contact")}
    />
  );
};
export default SpouseJustification;

import Item from "./Item";

const DocumentDownload = ({ items, title, download }) => {
  return (
    <div className="documentDownloadLmf__area">
      <h3 className="--title">{title}</h3>
      <div className="documentDownloadLmf__area--list">
        {items.map((item, index) => (
          <Item
            key={index}
            name={item.name}
            size={item.size}
            onDownload={() => download(item.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default DocumentDownload;

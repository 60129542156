import { get } from "lodash";
import Template from "../Template";
import View from "./View";

const SubscriptionDone = ({ currentStatus, project, onBack }) => {
  return (
    <Template
      progress={currentStatus.progress}
      HeaderComponent={null}
      View={<View />}
      contact={get(project, "contact")}
    />
  );
};
export default SubscriptionDone;

import React, { useMemo } from "react";

import PropTypes from "prop-types";

import Image from "./Image";
import Simple from "./Simple";

const MODES = {
  simple: Simple,
  image: Image,
};

const Radio = ({ variant = "simple", width, ...props }) => {
  const View = useMemo(() => MODES[variant], [variant]);
  return <View style={{ width }} {...props} />;
};

Radio.propTypes = {
  type: PropTypes.oneOf(Object.keys(MODES)),
};

export default React.memo(Radio);

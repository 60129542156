import { get } from "lodash";
import Navbar from "../Components/Navbar/Navbar";
import View from "./View";

const AdherentBirthDate = ({
  project,
  onNext,
  onBack,
  currentStatus,
  tracer,
}) => {
  return (
    <>
      <Navbar project={project} progress={currentStatus?.progress} />
      <View
        contact={get(project, "contact", {})}
        onNext={onNext}
        onBack={onBack}
        tracer={tracer}
      />
    </>
  );
};

export default AdherentBirthDate;

import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import { bindInputProps } from "utils/helpers/input";
import Button from "../Components/Button";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Input from "../Components/Input";
import validation from "./validation";

function View({ contact, onNext, onBack }) {
  const { t } = useTranslation();
  const { handleSubmit, values, setFieldValue, ...formProps } = useFormik({
    initialValues: contact,
    onSubmit: onNext,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
    ),
  });
  return (
    <div className="solyon">
      <div className="solyon__container">
        <div className="solyon__wrapper">
          <Header
            title="Indiquez votre numéro de sécurité sociale"
            highlight="numéro de sécurité sociale"
            subtitle="Votre numéro de sécurité sociale est requis pour activer votre contrat santé"
            onBack={onBack}
          />
          <div className="solyon__body">
            <div className="grid--1 max-500">
              <Input
                placeholder={"ex : 1 85 05 78 006 084 36"}
                label={"N° de sécurité sociale"}
                {...bindInputProps({
                  name: "socialSecurityNumber",
                  values,
                  ...formProps,
                })}
              />
              <div className="solyon-info">
                <strong>
                  Votre numéro de sécurité sociale se trouve sur votre carte
                  vitale. Cette information est strictement confidentielle et
                  utilisée uniquement pour gérer votre contrat.
                </strong>
              </div>
            </div>
            <div className="solyon__body--actions">
              <Button
                type={"primary"}
                alignment={"center"}
                disabled={!isEmpty(formProps.errors)}
                onClick={handleSubmit}
              >
                Continuer
              </Button>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default View;

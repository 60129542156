import classNames from "classnames";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { bindInputProps } from "utils/helpers/input";
import Button from "../Components/Button";
import Icon from "../Components/svg/Carte_TP-vert.svg";
import InputChecked from "../Components/svg/check_small.svg";
import InputError from "../Components/svg/error.svg";
import validation from "./validation";

const View = ({ initialValues, locked, onNext }) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("B2C_LMF.SOCIAL_SECURITY_NUMBER", {
    returnObjects: true,
  });

  const { handleSubmit, values, setFieldValue, ...formProps } = useFormik({
    initialValues,
    onSubmit: onNext,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
    ),
  });

  return (
    <>
      <h1 className="header-title">{TRANSLATION.TITLE}</h1>
      <span
        className="header-description"
        dangerouslySetInnerHTML={{ __html: TRANSLATION.DESCRIPTION }}
      ></span>
      <div className="__LMF--content LMF-content medium">
        <Input
          label={TRANSLATION.FORM.LABEL}
          placeholder={TRANSLATION.FORM.PLACEHOLDER}
          className={classNames({
            valid: get(values, "contact.socialSecurityNumber"),
          })}
          {...bindInputProps({
            name: "contact.socialSecurityNumber",
            values,
            setFieldValue,
            ...formProps,
          })}
          suffix={
            get(formProps.errors, `contact.socialSecurityNumber`) ? (
              <img src={InputError} />
            ) : (
              <img src={InputChecked} />
            )
          }
          disabled={locked}
        />
        <div className="__LMF--content__body--container__body--caption">
          <img src={Icon} />
          <span>{TRANSLATION.CAPTION}</span>
        </div>
      </div>
      <Button
        disabled={!isEmpty(formProps.errors)}
        onClick={handleSubmit}
        type="white"
        size="middle"
      >
        <span>{TRANSLATION.NEXT}</span>
      </Button>
    </>
  );
};

export default View;

import { useQuery } from "@apollo/client";
import cuid from "cuid";
import { flatten, get } from "lodash";
import { useState } from "react";
import Loading from "shared/components/Spin";
import { useMutation } from "shared/hooks/useApi";
import { MANUAL_SIGNATURE } from "utils/api/graphql/mutations/esign";
import { SUBSCRIPTIONS } from "utils/api/graphql/queries/subscription";
import { DOCUMENTS_TYPES, MODAL_STATES } from "utils/constants";
import View from "./View";

const SignProjectDrawer = ({ onClose, project = {} }) => {
  const filter = { where: { project: { id: project.id } } };
  const { data, loading } = useQuery(SUBSCRIPTIONS, { variables: filter });
  const [state, setState] = useState(MODAL_STATES.INITIAL);
  const [manualLoading, setManualLoading] = useState(false);
  const [manualSignature] = useMutation(MANUAL_SIGNATURE, {
    refetchQueries: [
      {
        query: SUBSCRIPTIONS,
        variables: filter,
      },
    ],
  });

  const onSign = ({ subscriptionId, signedDate, attachments }) => {
    setManualLoading(true);
    const documents = flatten(
      Object.entries(attachments).map(([key, value]) =>
        value.map(({ name, contentType, base64 }) => ({
          id: cuid(),
          subscription: { id: subscriptionId },
          name,
          content: base64,
          contentType,
          type: DOCUMENTS_TYPES[key],
        }))
      )
    );
    return manualSignature({
      variables: {
        data: {
          attachments: documents,
          subscription: { id: subscriptionId },
          project: { id: get(project, "id") },
          signedDate,
        },
      },
      onCompleted: () => {
        setState(MODAL_STATES.DONE);
        setManualLoading(false);
      },
    });
  };

  if (loading) return <Loading />;

  return (
    <View
      onClose={onClose}
      projectId={project.id}
      onSign={onSign}
      data={get(data, "subscriptions.data", [])}
      state={state}
      manualLoading={manualLoading}
      setState={setState}
    />
  );
};

export default SignProjectDrawer;

import { Col, Row } from "antd";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import validation from "./validation";

import Input from "shared/components/Input";

import classNames from "classnames";
import { get, isEmpty } from "lodash";
import { CURRENCY_SYMBOL } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import Button from "../Components/Button";
import lclIcon from "../Components/svg/LCL.svg";
import InputChecked from "../Components/svg/check_small.svg";
import InputError from "../Components/svg/error.svg";

const View = ({
  initialValues,
  modules = [],
  generalCost,
  onSubmit,
  locked,
}) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("B2C_LMF.BANK_CARD", { returnObjects: true });

  const { handleSubmit, values, setFieldValue, ...formProps } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
    ),
  });

  const totalCost = modules.reduce(
    (acc, { cost }) => (acc += cost),
    get(generalCost, "cost", 0)
  );

  return (
    <>
      <h1 className="header-title">{TRANSLATION.TITLE}</h1>
      <span
        className="header-description"
        dangerouslySetInnerHTML={{ __html: TRANSLATION.DESCRIPTION }}
      ></span>
      <div className="__LMF--content LMF-content">
        <Row gutter={[16, 16]}>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Input
              label={TRANSLATION.FORM.CARD_NUMBER.LABEL}
              placeholder={TRANSLATION.FORM.CARD_NUMBER.PLACEHOLDER}
              className={classNames({
                valid: get(values, `additionalInfo.bankCard.cardNumber`),
              })}
              inputMode="numeric"
              {...bindInputProps({
                name: `additionalInfo.bankCard.cardNumber`,
                values,
                setFieldValue,
                ...formProps,
              })}
              onChange={({ target: { value } }) => {
                setFieldValue(`additionalInfo.bankCard.cardNumber`, value);
              }}
              disabled={locked}
              suffix={
                get(formProps.errors, `additionalInfo.bankCard.cardNumber`) ? (
                  <img src={InputError} />
                ) : (
                  <img src={InputChecked} />
                )
              }
            />
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <Input
              label={TRANSLATION.FORM.EXPIRATION_DATE.LABEL}
              placeholder={TRANSLATION.FORM.EXPIRATION_DATE.PLACEHOLDER}
              className={classNames({
                valid: get(values, `additionalInfo.bankCard.expirationDate`),
              })}
              inputMode="numeric"
              {...bindInputProps({
                name: `additionalInfo.bankCard.expirationDate`,
                values,
                setFieldValue,
                ...formProps,
              })}
              onChange={({ target: { value } }) => {
                setFieldValue(`additionalInfo.bankCard.expirationDate`, value);
              }}
              disabled={locked}
              suffix={
                get(
                  formProps.errors,
                  `additionalInfo.bankCard.expirationDate`
                ) ? (
                  <img src={InputError} />
                ) : (
                  <img src={InputChecked} />
                )
              }
            />
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <Input
              label={TRANSLATION.FORM.CVC.LABEL}
              placeholder={TRANSLATION.FORM.CVC.PLACEHOLDER}
              className={classNames({
                valid: get(values, `additionalInfo.bankCard.ccv`),
              })}
              inputMode="numeric"
              {...bindInputProps({
                name: `additionalInfo.bankCard.ccv`,
                values,
                setFieldValue,
                ...formProps,
              })}
              onChange={({ target: { value } }) => {
                setFieldValue(`additionalInfo.bankCard.ccv`, value);
              }}
              disabled={locked}
              suffix={
                get(formProps.errors, `additionalInfo.bankCard.ccv`) ? (
                  <img src={InputError} />
                ) : (
                  <img src={InputChecked} />
                )
              }
            />
          </Col>
        </Row>
        <Button
          disabled={!isEmpty(formProps.errors)}
          onClick={handleSubmit}
          type={!isEmpty(formProps.errors) ? "white" : "primary"}
          size="middle"
          className="w-full"
        >
          <span>
            {TRANSLATION.PAY} {totalCost.toFixed(2)}
            {CURRENCY_SYMBOL[get(generalCost, "currency")]}
          </span>
        </Button>
        <p className="__LMF--hint bank-card">{TRANSLATION.HINT}</p>
      </div>
      <p className="__LMF--sponsor">
        <span>{TRANSLATION.SPONSOR}</span>
        <img src={lclIcon} />{" "}
      </p>
    </>
  );
};

export default View;

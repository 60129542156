import cuid from "cuid";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import {
  BANK_ACCOUNT_OWNER_TYPE,
  DEBIT_POSSIBLE_DATES,
  JOB_LIST,
  PAYMENT_FREQUENCIES,
} from "utils/constants";
import { bindInputProps, setDefaultIfEmpty } from "utils/helpers/input";
import Button from "../Components/Button";
import Card from "../Components/Card";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Input from "../Components/Input";
import Select from "../Components/Select";
import validation from "./validation";

function View({ initialValues, subscription, onNext, onBack, loading }) {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("DETAIL", { returnObjects: true });

  const { handleSubmit, values, setFieldValue, ...formProps } = useFormik({
    initialValues: initialValues,
    onSubmit: onNext,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
    ),
  });

  const IS_PAID_BY_ANOTHER_PERSON = {
    [true]: () => {
      setFieldValue("payment.payer.owner", {
        id: get(values, "payment.payer.owner.id", cuid()),
        isAdherent: false,
        type: BANK_ACCOUNT_OWNER_TYPE.PARTICULAR,
      });
    },
    [false]: () => {
      setFieldValue("payment.payer.owner", {
        id: get(values, "payment.payer.owner.id", cuid()),
        firstName: get(subscription, "project.contact.user.firstname"),
        lastName: get(subscription, "project.contact.user.lastname"),
        isAdherent: true,
        type: BANK_ACCOUNT_OWNER_TYPE.PARTICULAR,
      });
    },
  };

  const DEFAULT_DAYS = ["10", "26"];
  const RETIRED_DAYS = ["5", "10"];

  const JOB_DEBIT_DAYS = Object.fromEntries(
    Object.values(JOB_LIST).map((value) => [
      value,
      value === JOB_LIST.RETIRED ? RETIRED_DAYS : DEFAULT_DAYS,
    ])
  );

  const profession = get(subscription, "project.contact.profession");

  const allowedDebitDays =
    JOB_DEBIT_DAYS[profession] ||
    get(subscription, "insurancePlan.fields.payment.debitDay", DEBIT_POSSIBLE_DATES);


  return (
    <div className="solyon">
      <div className="solyon__container">
        <div className="solyon__wrapper">
          <Header
            title="Renseignez vos informations bancaires"
            highlight="informations bancaires"
            subtitle="Vos informations bancaires sont protégées et nécessaires pour gérer vos cotisations"
            onBack={onBack}
          />
          <div className="solyon__body">
            <div className="grid--1 max-500">
              <Input
                placeholder={"ex : FR76 3000 4001 2600 0100 6123 456"}
                label={"IBAN"}
                {...bindInputProps({
                  name: "payment.payer.IBAN",
                  values,
                  ...formProps,
                })}
              />
              <Input
                placeholder={"ex : PRNSFRP1"}
                label={"BIC"}
                {...bindInputProps({
                  name: "payment.payer.BIC",
                  values,
                  ...formProps,
                })}
              />
              {((get(values, "payment.payer.BIC") &&
                get(values, "payment.payer.IBAN")) ||
                (get(formProps, "touched.payment.payer.BIC") &&
                  get(formProps, "touched.payment.payer.IBAN"))) && (
                  <>
                    <Input
                      type="checkbox"
                      label={
                        "Cochez cette case si la cotisation sera payée par quelqu'un d’autre"
                      }
                      {...bindInputProps({
                        name: "payment.payer.owner.isAdherent",
                        values,
                        ...formProps,
                      })}
                      checked={!get(values, "payment.payer.owner.isAdherent")}
                      onChange={({ target: { checked } }) =>
                        IS_PAID_BY_ANOTHER_PERSON[checked]()
                      }
                      disabled={subscription.locked}
                    />
                    {!get(values, "payment.payer.owner.isAdherent") && (
                      <Card>
                        <div className="grid--2">
                          <Input
                            placeholder={"ex : Doe"}
                            label={"Nom titulaire du compte"}
                            {...bindInputProps({
                              name: "payment.payer.owner.lastName",
                              values,
                              ...formProps,
                            })}
                          />
                          <Input
                            placeholder={"ex : John"}
                            label={"Prénom titulaire du compte"}
                            {...bindInputProps({
                              name: "payment.payer.owner.firstName",
                              values,
                              ...formProps,
                            })}
                          />
                        </div>
                      </Card>
                    )}
                    <div className="grid--2">
                      <Select
                        options={setDefaultIfEmpty(
                          get(
                            subscription,
                            "insurancePlan.fields.payment.frequency"
                          ),
                          Object.values(PAYMENT_FREQUENCIES)
                        ).map((value) => ({
                          value,
                          label: TRANSLATION.PAYMENT_FREQUENCY.OPTIONS[value],
                        }))}
                        label={TRANSLATION.PAYMENT_FREQUENCY.LABEL}
                        placeholder={"Sélectionnez ..."}
                        {...bindInputProps({
                          name: "payment.frequency",
                          values,
                          ...formProps,
                        })}
                        disabled={subscription?.locked}
                      />
                      <Select
                        options={allowedDebitDays.map((value) => ({
                          value,
                          label: value,
                        }))}
                        label={TRANSLATION.DEBIT_DAY.LABEL}
                        placeholder={"Sélectionnez ..."}
                        {...bindInputProps({
                          name: "payment.debitDay",
                          values,
                          ...formProps,
                        })}
                        disabled={subscription?.locked}
                      />
                    </div>
                  </>
                )}
            </div>
            <div className="solyon__body--actions">
              <Button
                type={"primary"}
                alignment={"center"}
                disabled={!isEmpty(formProps.errors)}
                onClick={handleSubmit}
                loading={loading}
              >
                Continuer
              </Button>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default View;

import Item from "./Item";

const DocumentDownload = ({ items, download }) => {
  return (
    <div className="solyon-documents__container">
      {items.map((item, index) => (
        <Item
          key={`key-${index}`}
          name={item.name}
          size={item.size}
          onDownload={() => download(item.id)}
        />
      ))}
    </div>
  );
};

export default DocumentDownload;

import cuid from "cuid";
import { FieldArray, Formik } from "formik";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { BiCheck, BiChevronLeft } from "react-icons/bi";
import { BsPatchCheck } from "react-icons/bs";
import { MdAdd, MdDelete } from "react-icons/md";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import { ATTACHMENT_TYPES } from "utils/constants";
import { bindArrayInputProps, bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Create = ({ onSubmit, cancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.BRAND);
  const CREATE = t("CREATE", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });

  const handleFileUpload = async (index, file, setFieldValue) => {
    const { base64, size, uid, ...fileData } = file;
    setFieldValue(`models.${index}.iconAttachment`, {
      ...fileData,
      id: cuid(),
      content: base64,
      type: ATTACHMENT_TYPES.ICON,
    });
    setFieldValue(`models.${index}.image`, file.base64);
  };

  return (
    <Formik
      initialValues={{ id: cuid(), name: null, models: [{ id: cuid() }] }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, setFieldValue, handleSubmit, ...formProps }) => [
        <Header
          key={"header"}
          title={CREATE.HEADER.TITLE}
          subtitle={CREATE.HEADER.ACTION}
          actions={[
            {
              text: CREATE.HEADER.DISCARD,
              onClick: cancel,
              type: "primary--outlined",
              icon: <BiChevronLeft size={20} />,
            },
            {
              text: CREATE.HEADER.SAVE,
              onClick: handleSubmit,
              type: "primary",
              icon: <BiCheck size={20} />,
            },
          ]}
        />,
        <Card key="card" type={"simple"}>
          <div className="grid--1">
            <div className="grid--2">
              <Input
                label={FORM.BRAND}
                placeholder={FORM.BRAND}
                icon={<BsPatchCheck size={16} />}
                required
                {...bindInputProps({
                  name: "name",
                  ...formProps,
                })}
              />
            </div>
            <FieldArray
              name={"models"}
              render={(arrayHelpers) => [
                get(formProps, "values.models", []).map((_, i) => (
                  <div className="d-flex flex--wrap" key={_.id}>
                    <div className="grid--2 flex--grow-1 align--center">
                      <Input
                        placeholder={FORM.MODEL}
                        label={FORM.MODEL}
                        {...bindArrayInputProps({
                          parent: "models",
                          index: i,
                          name: "name",
                          ...formProps,
                        })}
                        required
                      />
                      <div className="d-flex">
                        <div className="flex--grow-1">
                          <Input
                            type="file"
                            variant="uploader"
                            listType="picture"
                            showUploadList={false}
                            maxCount={1}
                            onUpload={([file]) =>
                              handleFileUpload(i, file, setFieldValue)
                            }
                            fileList={
                              isEmpty(
                                get(formProps, `values.models.${i}.image`, [])
                              )
                                ? []
                                : [get(formProps, `values.models.${i}.image`)]
                            }
                          >
                            <Button type="primary--link">{FORM.ICON}</Button>
                            <div>
                              {get(formProps, `values.models.${i}.image`) && (
                                <img
                                  className="img-height-24"
                                  alt="icon"
                                  src={get(
                                    formProps,
                                    `values.models.${i}.image`
                                  )}
                                />
                              )}
                            </div>
                          </Input>
                        </div>
                        <MdDelete
                          size={24}
                          color={COLORS.C_DANGER}
                          onClick={() => arrayHelpers.remove(i)}
                        />
                      </div>
                    </div>
                  </div>
                )),
                <Button
                  type="primary--link"
                  key="add-row"
                  onClick={() => {
                    arrayHelpers.push({
                      id: cuid(),
                      insurancePlan: { id: null },
                      commissions: [],
                    });
                  }}
                >
                  <MdAdd /> {FORM.ADD_MODEL}
                </Button>,
              ]}
            />
          </div>
        </Card>,
      ]}
    </Formik>
  );
};

export default Create;

import { useQuery } from "@apollo/client";
import { Modal } from "antd";
import { get, isEmpty } from "lodash";
import { useState } from "react";
import { MdClose } from "react-icons/md";
import { GENERAL_COST } from "utils/api/graphql/queries/general-cost";
import { COVERAGE_LIMIT_CATEGORIES, CURRENCY_SYMBOL } from "utils/constants";
import Button from "../Components/Button";

const DetailsModal = ({ open, onClose, filter = {}, translation }) => {
  const coverageBenefitLimitValue = (value) => ({
    [COVERAGE_LIMIT_CATEGORIES.BASE_REFUND]: `${value} % BR`,
    [COVERAGE_LIMIT_CATEGORIES.REAL_COSTS]: translation.REAL_COSTS,
    [COVERAGE_LIMIT_CATEGORIES.INTEGRAL_RBT]: translation.INTEGRAL_RBT,
    [COVERAGE_LIMIT_CATEGORIES.COST]: `${value} €`,
    [COVERAGE_LIMIT_CATEGORIES.COST_PER_YEAR]: `${translation.UP_TO} ${value} € / ${translation.YEAR}`,
    [COVERAGE_LIMIT_CATEGORIES.TEXT]: value,
  });
  const [displayedData, setDisplayedData] = useState([]);
  useQuery(GENERAL_COST, {
    variables: filter,
    skip: isEmpty(filter),
    onCompleted: ({ generalCost }) => {
      const [integer, decimal = ""] = get(generalCost, "cost")
        ?.toString()
        ?.split(".") || ["-"];
      const coverages = get(
        generalCost,
        "insurancePlan.insurancePlanCoverages",
        []
      ).flatMap(({ coverageMappings }) =>
        coverageMappings.map(({ coverage, limits }) => ({
          name: get(coverage, "type", "-"),
          limits: limits
            .map(
              ({ value }) =>
                coverageBenefitLimitValue(value?.value)[value?.category]
            )
            .join(" - "),
        }))
      );
      setDisplayedData({
        integer,
        decimal,
        currency: CURRENCY_SYMBOL[get(generalCost, "currency")],
        icon: get(generalCost, "insurancePlan.icon"),
        name: get(generalCost, "insurancePlan.name"),
        cost: get(generalCost, "cost"),
        coverages,
      });
    },
  });

  return (
    <Modal
      footer={null}
      className="drawer__area"
      onCancel={onClose}
      open={open}
      centered
    >
      <div className="solyon-upsells__details">
        <div className="solyon-upsells__details--header">
          <div className="solyon-upsells__details--title">
            {"Les forfaits du module"}
          </div>
          <img
            className={"solyon-upsells__details--icon"}
            src={displayedData?.icon}
            alt={displayedData?.name}
          />
        </div>
        <table className="guarantees-table">
          <thead>
            <tr>
              <th>{displayedData?.name}</th>
              <th>{get(translation, "UPSELLS.LIMIT", "")}</th>
            </tr>
          </thead>
          <tbody>
            {get(displayedData, "coverages", []).map((guarantee, index) => (
              <tr key={`garantee-${index}`}>
                <td>{guarantee.name}</td>
                <td>{guarantee.limits}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Button type="gray-light" block onClick={onClose}>
          <MdClose />
          {get(translation, "UPSELLS.CLOSE", "")}
        </Button>
      </div>
    </Modal>
  );
};

export default DetailsModal;

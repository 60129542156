import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { BsTrash, BsUpload } from "react-icons/bs";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { bytesToMB } from "utils/helpers/files";
import { bindInputProps } from "utils/helpers/input";
import Button from "../Components/Button";
import validation from "./validation";

const View = ({ initialValues, onSubmit, locked, translate }) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const { isValid, handleSubmit, setFieldValue, values, ...formProps } =
    useFormik({
      initialValues,
      onSubmit: onSubmit,
      validationSchema: validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, {
          returnObjects: true,
        })
      ),
    });

  const onFileRemove = async ({ uid: id }) => {
    if (id.indexOf("AUTO") !== -1) return;
  };

  return (
    <>
      <h1 className="header-title">{translate.TITLE}</h1>
      <span
        className="header-description"
        dangerouslySetInnerHTML={{ __html: translate.DESCRIPTION }}
      ></span>
      <div className="__LMF--content LMF-content">
        <div className="justification__section--child">
          <Input
            type="file"
            variant="uploader"
            listType="picture"
            onUpload={(fileList) => {
              setFieldValue("cin", fileList);
            }}
            validTypes={translate.DOCUMENTS.ALLOWED_FILES_TYPE}
            onFileRemove={onFileRemove}
            fileList={get(values, "cin", [])}
          >
            <div className="upload">
              <div className="label">
                <span>{translate.DOCUMENTS.TYPES.CIN}</span>
                {get(values, "cin", []).map(({ name, size }, index) => (
                  <small key={`cin-${index}`}>
                    {name} - {bytesToMB(size)}MB
                  </small>
                ))}
              </div>
              <Button
                type={
                  get(values, "cin", []).length > 1 ? "danger-outline" : "white"
                }
                size="middle"
                onClick={(e) => {
                  if (get(values, "cin", []).length > 1) {
                    e.stopPropagation();
                    setFieldValue("cin", []);
                  }
                }}
              >
                {get(values, "cin", []).length > 1 ? (
                  <BsTrash size={20} />
                ) : (
                  <BsUpload size={20} />
                )}
              </Button>
            </div>
          </Input>
          <span className="justification__section--child__caption">
            <span>{translate.DOCUMENTS.ACCEPT_FILES}</span>
            <span>{translate.DOCUMENTS.MAX_SIZE}</span>
          </span>
          <span className="error-message">{get(formProps.errors, "cin")}</span>
        </div>
        <div className="justification__section--child">
          <Input
            type="file"
            variant="uploader"
            listType="file"
            onUpload={(fileList) => {
              setFieldValue("paymentAccount", fileList);
            }}
            validTypes={translate.DOCUMENTS.ALLOWED_FILES_TYPE}
            onFileRemove={onFileRemove}
            fileList={get(values, "paymentAccount", [])}
          >
            {/* {isEmpty(get(values, "paymentAccount")) && (
            )} */}
            <div className="upload">
              <div className="label">
                <span>{translate.DOCUMENTS.TYPES.RIB}</span>
                {get(values, "paymentAccount", []).map(
                  ({ name, size }, index) => (
                    <small key={`paymentAccount-${index}`}>
                      {name} - {bytesToMB(size)}MB
                    </small>
                  )
                )}
              </div>
              <Button
                type={
                  get(values, "paymentAccount", []).length
                    ? "danger-outline"
                    : "white"
                }
                size="middle"
                onClick={(e) => {
                  if (get(values, "paymentAccount", []).length) {
                    e.stopPropagation();
                    setFieldValue("paymentAccount", []);
                  }
                }}
              >
                {get(values, "paymentAccount", []).length ? (
                  <BsTrash size={20} />
                ) : (
                  <BsUpload size={20} />
                )}
              </Button>
            </div>
          </Input>
          <span className="justification__section--child__caption">
            <span>{translate.DOCUMENTS.ACCEPT_FILES}</span>
            <span>{translate.DOCUMENTS.MAX_SIZE}</span>
          </span>
          <span className="error-message">
            {get(formProps.errors, "paymentAccount")}
          </span>
        </div>
        <div className="justification__section--child">
          <Input
            type="file"
            variant="uploader"
            listType="picture"
            onUpload={(fileList) => {
              setFieldValue("healthInsuranceRightsCertificate", fileList);
            }}
            validTypes={translate.DOCUMENTS.ALLOWED_FILES_TYPE}
            onFileRemove={onFileRemove}
            fileList={get(values, "healthInsuranceRightsCertificate", [])}
          >
            {/* {isEmpty(get(values, "healthInsuranceRightsCertificate")) && (
            )} */}
            <div className="upload">
              <div className="label">
                <span>
                  {
                    translate.DOCUMENTS.TYPES
                      .HEALTH_INSURANCE_RIGHTS_CERTIFICATE
                  }
                </span>
                {get(values, "healthInsuranceRightsCertificate", []).map(
                  ({ name, size }, index) => (
                    <small key={`health-${index}`}>
                      {name} - {bytesToMB(size)}MB
                    </small>
                  )
                )}
              </div>
              <Button
                type={
                  get(values, "healthInsuranceRightsCertificate", []).length
                    ? "danger-outline"
                    : "white"
                }
                size="middle"
                onClick={(e) => {
                  if (
                    get(values, "healthInsuranceRightsCertificate", []).length
                  ) {
                    e.stopPropagation();
                    setFieldValue("healthInsuranceRightsCertificate", []);
                  }
                }}
              >
                {get(values, "healthInsuranceRightsCertificate", []).length ? (
                  <BsTrash size={20} />
                ) : (
                  <BsUpload size={20} />
                )}
              </Button>
            </div>
          </Input>
          <span className="justification__section--child__caption">
            <span>{translate.DOCUMENTS.ACCEPT_FILES}</span>
            <span>{translate.DOCUMENTS.MAX_SIZE}</span>
          </span>
          <span className="error-message">
            {get(formProps.errors, "healthInsuranceRightsCertificate")}
          </span>
        </div>
        <Input
          type="checkbox"
          label={translate.INCLUDED_CHILDREN}
          checked={get(values, "includedChildren")}
          {...bindInputProps({
            name: "includedChildren",
            values,
            ...formProps,
          })}
          onChange={({ target: { checked } }) => {
            setFieldValue("includedChildren", checked);
          }}
          disabled={locked}
        />
      </div>
      <Button
        disabled={!isEmpty(formProps.errors)}
        onClick={handleSubmit}
        type="white"
        size="middle"
      >
        <span>{translate.NEXT}</span>
      </Button>
    </>
  );
};

export default View;

import { get, isEmpty } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useEffect, useState } from "react";
import { useLazyQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { OFFERS } from "utils/api/graphql/queries/offers";
import Template from "../Template";
import View from "./View";
import useInsurancePlans from "./hooks/useInsurancePlans";

const Reinforcement = ({ project, onBack, onNext, tracer, currentStatus }) => {
  const [loading, setLoading] = useState(true);
  const subscriptionId =
    get(project, "fields.currentSubscription") ||
    localStorage.getItem("subscriptionId");

  const [selectedModules, setSelectedModules] = useState(
    project?.fields?.upsells || {}
  );

  const [getSubscription, { data }] = useLazyQuery(
    graphql.queries.SUBSCRIPTION
  );
  const [getGeneralCost, { data: generalCost }] = useLazyQuery(
    graphql.queries.GENERAL_COST,
    {
      variables: {
        where: {
          project: { id: get(data, "subscription.project.id") },
          insurancePlan: {
            id: get(data, "subscription.insurancePlan.id"),
          },
        },
      },
    }
  );
  const [getOffers, {}] = useLazyQuery(OFFERS);
  const {
    selectedInsurancePlans,
    loading: offerLoading,
    fetchMainOffers,
  } = useInsurancePlans({
    insurancePlan: get(data, "subscription.insurancePlan"),
    getOffers,
    project,
    setSelectedModules,
  });

  useEffect(() => {
    if (subscriptionId)
      getSubscription({
        variables: { where: { id: subscriptionId } },
        onCompleted: ({ subscription: data }) => {
          getGeneralCost();
          const subscription = omitDeep(data, "__typename");
          if (isEmpty(subscription)) return;
          getOffers({
            variables: {
              where: {
                AND: [
                  {
                    offerInsurancePlans: {
                      insurancePlan: {
                        id: get(subscription, "insurancePlan.id"),
                      },
                    },
                  },
                  { offerInsurancePlans: { main: true } },
                ],
              },
            },
            onCompleted: (_) => {
              fetchMainOffers(_);
              setLoading(false);
            },
          });
        },
      });
  }, []);

  const handleModuleCheck = (module) => {
    const id = get(module, "insurancePlan.id");
    setSelectedModules((prev) =>
      get(prev, get(data, "subscription.insurancePlan.id"))?.find(
        ({ insurancePlan }) => insurancePlan.id === id
      )
        ? {
            ...prev,
            [get(data, "subscription.insurancePlan.id")]: get(
              prev,
              get(data, "subscription.insurancePlan.id"),
              []
            ).filter(({ insurancePlan }) => insurancePlan.id !== id),
          }
        : {
            ...prev,
            [get(data, "subscription.insurancePlan.id")]: [
              ...get(prev, get(data, "subscription.insurancePlan.id"), []),
              module,
            ],
          }
    );
  };

  const onSubmit = ({ ignore = false }) => {
    onNext({
      payload: {
        fields: {
          ...get(project, "fields", {}),
          upsells: ignore ? undefined : selectedModules,
        },
      },
    });
  };

  return (
    <Template
      onBack={onBack}
      progress={currentStatus.progress}
      selectedModules={selectedModules}
      loading={loading}
      contact={get(data, "subscription.project.contact")}
      HeaderComponent={{
        subscription: get(data, "subscription"),
        tracer,
        project,
        modules: get(
          selectedModules,
          get(data, "subscription.insurancePlan.id")
        ),
        contact: get(project, "contact"),
      }}
      View={
        <View
          selectedModules={selectedModules}
          onCheck={handleModuleCheck}
          mainProduct={get(data, "subscription.insurancePlan.id")}
          disabled={false}
          products={selectedInsurancePlans}
          generalCost={omitDeep(get(generalCost, "generalCost"), "__typename")}
          onNext={onSubmit}
        />
      }
    />
  );
};
export default Reinforcement;

import cuid from "cuid";
import { filter, get, isEmpty, map } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { CREATE_ATTACHMENT } from "utils/api/graphql/mutations/attachment";
import { DOWNLOAD_SINGLE_DOCUMENT } from "utils/api/graphql/mutations/download-document";
import { SEND_SUBSCRIPTION_ADDITIONAL_DOCUMENTS } from "utils/api/graphql/mutations/subscription";
import { SUBSCRIPTION } from "utils/api/graphql/queries/subscription";
import { ATTACHMENT_TYPES, DOCUMENTS_TYPES } from "utils/constants";
import { downloadDocument } from "utils/helpers/files";
import Loader from "../Components/Loader";
import Navbar from "../Components/Navbar/Navbar";
import View from "./View";

export const DOCUMENTS_TYPE = {
  cin: DOCUMENTS_TYPES.CIN,
  paymentAccount: DOCUMENTS_TYPES.PAYMENT_ACCOUNT,
  socialSecurityCertificate: DOCUMENTS_TYPES.SOCIAL_SECURITY_CERTIFICATE,
  taxNotice: DOCUMENTS_TYPES.TAX_NOTICE,
  paySlip: DOCUMENTS_TYPES.PAY_SLIP,
};

const Justification = ({
  currentStatus,
  tracer,
  project,
  onBack,
  updateUrl,
  onNext,
}) => {
  const [initialValues, setInitialValues] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("B2C_LMF.JUSTIFICATION", { returnObjects: true });
  const subscriptionId =
    localStorage.getItem("subscriptionId") ||
    get(project, "fields.currentSubscription");

  const { data } = useQuery(SUBSCRIPTION, {
    variables: { where: { id: subscriptionId } },
  });
  const [sendAdditionalDocument] = useMutation(
    SEND_SUBSCRIPTION_ADDITIONAL_DOCUMENTS
  );
  const [downloadSingleDocument] = useMutation(DOWNLOAD_SINGLE_DOCUMENT);

  const { loading: attachmentLoading } = useQuery(graphql.queries.ATTACHMENTS, {
    variables: {
      where: {
        OR: [
          {
            subscription: {
              id: subscriptionId,
            },
          },
        ],
      },
      isIn: {
        type: Object.values(DOCUMENTS_TYPE),
      },
    },
    onCompleted: ({ attachments: { data: attachmentsList } }) => {
      setInitialValues({
        cin: map(
          filter(attachmentsList, ({ type }) => type === ATTACHMENT_TYPES.CIN),
          ({ id, name, fileUrl }) => ({
            uid: id,
            name,
            status: "done",
            url: fileUrl,
          })
        ),
        paymentAccount: map(
          filter(
            attachmentsList,
            ({ type }) => type === ATTACHMENT_TYPES.PAYMENT_ACCOUNT
          ),
          ({ id, name, fileUrl }) => ({
            uid: id,
            name,
            status: "done",
            url: fileUrl,
          })
        ),
        socialSecurityCertificate: map(
          filter(
            attachmentsList,
            ({ type }) => type === ATTACHMENT_TYPES.SOCIAL_SECURITY_CERTIFICATE
          ),
          ({ id, name, fileUrl }) => ({
            uid: id,
            name,
            status: "done",
            url: fileUrl,
          })
        ),
        taxNotice: map(
          filter(
            attachmentsList,
            ({ type }) => type === ATTACHMENT_TYPES.TAX_NOTICE
          ),
          ({ id, name, fileUrl }) => ({
            uid: id,
            name,
            status: "done",
            url: fileUrl,
          })
        ),
        paySlip: map(
          filter(
            attachmentsList,
            ({ type }) => type === ATTACHMENT_TYPES.PAY_SLIP
          ),
          ({ id, name, fileUrl }) => ({
            uid: id,
            name,
            status: "done",
            url: fileUrl,
          })
        ),
      });
      setLoading(false);
    },
  });
  const [addAttachment] = useMutation(CREATE_ATTACHMENT);
  const onSubmit = async (documents) => {
    setLoading(true);
    const documentsToUpload = [];
    Object.entries(documents)?.map(([key, attachments]) =>
      attachments
        ?.filter((e) => !e.status)
        .forEach(({ name, contentType, base64 }) => {
          documentsToUpload.push({
            id: cuid(),
            subscription: { id: subscriptionId },
            name,
            content: base64,
            contentType,
            type: DOCUMENTS_TYPE[key],
          });
        })
    );
    const uploadedDocuments = await Promise.all(
      documentsToUpload.map((document, index) =>
        addAttachment({
          variables: {
            data: document,
          },
        })
      )
    );
    sendAdditionalDocument({
      variables: {
        where: { id: subscriptionId },
        data: uploadedDocuments.map(({ data: { addAttachment } }) =>
          omitDeep(addAttachment, "__typename")
        ),
      },
    });
    const source = localStorage.getItem("source");
    updateUrl({
      subscriptionId,
      url: get(
        data,
        "subscription.insurancePlan.config.generatedUrl",
        `${process.env.REACT_APP_BASE_URL}/#/public/process/init/${source}`
      ),
    });
    onNext({
      payload: {},
    });
  };
  const downloadFile = async (id) => {
    await downloadSingleDocument({
      variables: { where: { id } },
      onCompleted: (_) => {
        const { document } = omitDeep(_.downloadSingleDocument, "__typename");
        downloadDocument(document);
      },
    });
  };
  if (attachmentLoading || isEmpty(initialValues) || isEmpty(data))
    return <Loader />;

  return (
    <>
      <Navbar
        project={project}
        progress={currentStatus?.progress}
        tracer={tracer}
      />
      <View
        onSubmit={onSubmit}
        initialValues={initialValues}
        translate={TRANSLATION}
        downloadFile={downloadFile}
        profession={get(project, "contact.profession")}
        insurancePlan={get(data, "subscription.insurancePlan")}
        onBack={onBack}
      />
    </>
  );
};
export default Justification;

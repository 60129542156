import { CONTACT_POINT_SYSTEM, REGEX_EXPS } from "utils/constants";
import * as yup from "yup";

const validation = (errors) => {
  const requiredString = yup
    .string()
    .typeError(errors.REQUIRED)
    .required(errors.REQUIRED);
  const requiredName = yup
    .string()
    .typeError(errors.REQUIRED)
    .matches(REGEX_EXPS.name, errors.INVALID_NAME)
    .required(errors.REQUIRED);
  return yup.object().shape({
    telecoms: yup.object().shape({
      [CONTACT_POINT_SYSTEM.EMAIL]: yup.object().shape({
        value: yup
          .string()
          .nullable()
          .email(errors.EMAIL)
          .required(errors.REQUIRED),
      }),
      [CONTACT_POINT_SYSTEM.PHONE]: yup.object().shape({
        value: yup
          .string()
          .typeError(errors.REQUIRED)
          .required(errors.REQUIRED)
          .test("isValid", errors.INVALID_VALUE, async function (value) {
            return new RegExp(REGEX_EXPS.internationalMobilePhone).test(
              value?.replace(/\s+/g, "")
            );
          }),
      }),
    }),
    acceptContact: yup.boolean().oneOf([true]).required(),
    user: yup.object().shape({
      firstname: requiredName,
      lastname: requiredName,
      gender: requiredString,
    }),
  });
};

export default validation;

import * as yup from "yup";

const validation = (messages) => {
  return yup.object().shape({
    fields: yup.object().shape({
      acceptProductDocuments: yup.boolean().oneOf([true], " "),
      acceptProductStatuses: yup.boolean().oneOf([true], " "),
      acceptFees: yup.boolean().oneOf([true], " "),
      acceptNotice: yup.boolean().oneOf([true], " "),
      acceptTeletransmission: yup.boolean().oneOf([true], " "),
      acceptAgreement: yup.boolean().oneOf([true], " "),
    }),
  });
};

export default validation;

import { get } from "lodash";
import { useQuery } from "shared/hooks/useApi";
import { COVERAGE_CATEGORIES } from "utils/api/graphql/queries/coverage-categories";
import Loader from "../Components/Loader";
import Navbar from "../Components/Navbar/Navbar";
import View from "./View";

const Needs = ({ project, onNext, onBack, currentStatus, tracer }) => {
  const { data, loading } = useQuery(COVERAGE_CATEGORIES, {
    variables: { gt: { weight: 0 } },
  });
  if (loading) return <Loader />;
  return (
    <>
      <Navbar
        project={project}
        progress={currentStatus?.progress}
        tracer={tracer}
      />
      <View
        categories={get(data, "coverageCategories.data") || []}
        fields={get(project, "fields", {})}
        onNext={onNext}
        onBack={onBack}
      />
    </>
  );
};

export default Needs;

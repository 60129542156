import { message, Modal } from "antd";
import { find, get } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { CREATE_LINK } from "utils/api/graphql/mutations/link";
import { TRACER } from "utils/api/graphql/queries/tracers";
import {
  MODAL_STATES,
  PROCESS_TYPES,
  SCREENS,
  TEMPLATE_TYPES,
  TEMPLATES,
  THEMES_PALETTE,
} from "utils/constants";
import CopyOrSendLink from "./CopyOrSendLink";
import Initial from "./GenerateLink";
const B2C_TEMPLATES_MAPPING = {
  [TEMPLATE_TYPES.DEFAULT]: {
    [true]:
      TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.DEFAULT]
        .B2C_SUBSCRIPTION,
    [false]:
      TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.DEFAULT]
        .B2C_SUBSCRIPTION,
  },
  [TEMPLATE_TYPES.RADIANCE]: {
    [true]:
      TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.RADIANCE]
        .JUSTIFICATIONS,
    [false]:
      TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.RADIANCE]
        .B2C_SUBSCRIPTION,
  },
  [TEMPLATE_TYPES.RADIANCE_COSA]: {
    [true]:
      TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.RADIANCE_COSA]
        .JUSTIFICATIONS,
    [false]:
      TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.RADIANCE_COSA]
        .B2C_SUBSCRIPTION,
  },
  [TEMPLATE_TYPES.B2C_SOLYON]: {
    [true]:
      TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.B2C_SOLYON]
        .GENERATE_E_SIGN_DOCUMENTS,
    [false]:
      TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.B2C_SOLYON]
        .EFFECTIVE_DATE,
  },
  [TEMPLATE_TYPES.B2C_LMF]: {
    [true]:
      TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.B2C_LMF]
        .JUSTIFICATIONS,
    [false]:
      TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.B2C_LMF]
        .EFFECTIVE_DATE,
  },
};
const GenerateLinkModal = ({ open, setOpen, template: processTemplate }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const GENERATE_LINK = t("LIST.SUBSCRIPTION.GENERATE_SUBSCRIPTION_LINK", {
    returnObjects: true,
  });

  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");
  const [addLink] = useMutation(CREATE_LINK);
  const [getTracer] = useLazyQuery(TRACER);
  const onClose = () => setOpen({});

  const onGenerateLink = () => {
    const correspondingTheme = THEMES_PALETTE[processTemplate].PROSPECT_PROCESS;

    const template = get(
      B2C_TEMPLATES_MAPPING,
      `${correspondingTheme}.${open?.isInfoCompleted || false}`
    );
    setSelectedOption(template);
    getTracer({
      variables: {
        where: {
          flow: {
            process: {
              theme: correspondingTheme,
            },
          },
        },
      },
      onCompleted: ({ tracer }) => {
        const statuses = get(tracer, "flow.process.processStatuses", []);
        const selectedStatus = find(statuses, { template })?.id;
        addLink({
          variables: {
            data: {
              url: open.baseUrl,
              subscription: { id: open.subscriptionId },
              project: { id: open.projectId },
              fields: {
                process: { id: get(tracer, "flow.process.id") },
                status: { id: selectedStatus },
              },
            },
          },
          onCompleted: ({ addLink }) => {
            setOpen((prev) => ({
              ...prev,
              link: `${open.baseUrl}?l=${addLink.id}`,
            }));
          },
        });
      },
    });
  };

  useEffect(() => {
    if (open?.projectId && open?.subscriptionId) onGenerateLink();
  }, [open.projectId, open.subscriptionId]);

  const onCopyLink = () => {
    navigator.clipboard.writeText(open?.link);
    message.success(GENERATE_LINK.COPIED);
    setOpen((prev) => ({ ...prev, copied: true }));
  };

  const onSendLink = () => {
    navigate(SCREENS.PRIVATE.BROKER.SUBSCRIPTIONS.SEND_LINK.path, {
      state: open,
    });
  };

  const CONTENT_STATE = {
    [MODAL_STATES.INITIAL]: <Initial onGenerateLink={onGenerateLink} />,
    [MODAL_STATES.DONE]: (
      <CopyOrSendLink
        onCopyLink={onCopyLink}
        onSendLink={onSendLink}
        link={open?.link}
        selectedOption={selectedOption}
        linkCopied={open?.copied}
      />
    ),
  };
  return (
    <Modal
      title={GENERATE_LINK[selectedOption]?.TITLE}
      open={open?.projectId && open?.subscriptionId}
      onCancel={onClose}
      footer={null}
      className="generate-link-modal"
    >
      {CONTENT_STATE[MODAL_STATES.DONE]}
    </Modal>
  );
};

export default GenerateLinkModal;

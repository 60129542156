import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import Button from "screens/Private/Broker/Projects/Detail/Templates/Comparison/Solyon/Components/Button";
import Header from "screens/Private/Broker/Projects/Detail/Templates/Comparison/Solyon/Components/Header";
import Input from "screens/Private/Broker/Projects/Detail/Templates/Comparison/Solyon/Components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

function View({ contact, onSubmit, onClose, locked }) {
  const { t } = useTranslation();
  const { handleSubmit, values, setFieldValue, ...formProps } = useFormik({
    initialValues: {
      ...contact,
      acceptContact: get(contact, "acceptContact") || false,
      acceptDigital: true,
    },
    onSubmit,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
    ),
  });
  return (
    <div className="solyon-help__modal">
      <Header
        small
        title="Validez vos informations afin qu'un conseiller puisse prendre contact avec vous"
        highlight="conseiller puisse prendre contact avec vous"
      />
      <div className="grid--1">
        <div className="grid--2">
          <Input
            placeholder={"ex : 0610203040"}
            label={"N° de téléphone"}
            {...bindInputProps({
              name: `telecoms.${CONTACT_POINT_SYSTEM.PHONE}.value`,
              values,
              ...formProps,
            })}
            disabled={locked}
            onChange={({ target: { value } }) => {
              setFieldValue(
                `telecoms.${CONTACT_POINT_SYSTEM.PHONE}.value`,
                value
              );
              setFieldValue(
                "showAccept",
                values.showAccept ||
                  !!(
                    value &&
                    get(values, `telecoms.${CONTACT_POINT_SYSTEM.EMAIL}.value`)
                  )
              );
            }}
          />
          <Input
            placeholder={"ex : John"}
            label={"Email"}
            {...bindInputProps({
              name: `telecoms.${CONTACT_POINT_SYSTEM.EMAIL}.value`,
              values,
              ...formProps,
            })}
            disabled={locked}
            onChange={({ target: { value } }) => {
              setFieldValue(
                `telecoms.${CONTACT_POINT_SYSTEM.EMAIL}.value`,
                value
              );
              setFieldValue(
                "showAccept",
                values.showAccept ||
                  !!(
                    value &&
                    get(values, `telecoms.${CONTACT_POINT_SYSTEM.PHONE}.value`)
                  )
              );
            }}
          />
        </div>
      </div>
      <div className="solyon-help__modal--actions">
        <Button type={"gray-light"} onClick={onClose}>
          <MdClose /> Fermer
        </Button>
        <div className="flex--grow-1">
          <Button
            block
            type={"primary"}
            alignment={"center"}
            disabled={
              !get(values, "user.firstname") || !isEmpty(formProps.errors)
            }
            onClick={handleSubmit}
          >
            Valider mes informations
          </Button>
        </div>
      </div>
    </div>
  );
}

export default View;

import * as yup from "yup";

const validation = (errors) => {
  return yup.object().shape({
    fields: yup.object().shape({
      acceptProductDocuments: yup.boolean().oneOf([true], errors.REQUIRED),
      acceptProductStatuses: yup.boolean().oneOf([true], errors.REQUIRED),
      acceptFees: yup.boolean().oneOf([true], errors.REQUIRED),
      acceptNotice: yup.boolean().oneOf([true, false], errors.REQUIRED),
      acceptTeletransmission: yup
        .boolean()
        .oneOf([true, false], errors.REQUIRED),
      acceptAgreement: yup.boolean().oneOf([true], errors.REQUIRED),
    }),
  });
};

export default validation;

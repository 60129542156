import { Col, Row } from "antd";
import classNames from "classnames";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import Input from "shared/components/Input";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import Button from "../Button";
import InputChecked from "../svg/check_small.svg";
import InputError from "../svg/error.svg";
import validation from "./validation";

const View = ({
  contact,
  setOpen,
  onSubmit,
  initialValues,
  errorMessages,
  TRANSLATION,
}) => {
  const { handleSubmit, values, setFieldValue, ...formProps } = useFormik({
    initialValues,
    onSubmit: onSubmit,
    validationSchema: validation(errorMessages),
  });

  return (
    <>
      <span
        className="LMF-help-modal__title"
        dangerouslySetInnerHTML={{ __html: TRANSLATION.TITLE }}
      ></span>
      <div className="LMF-content">
        <Row gutter={[16, 16]}>
          {isEmpty(contact) && (
            <>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Input
                  label={TRANSLATION.FORM.LAST_NAME.LABEL}
                  placeholder={TRANSLATION.FORM.LAST_NAME.PLACEHOLDER}
                  {...bindInputProps({
                    name: `contact.user.lastname`,
                    values,
                    setFieldValue,
                    ...formProps,
                  })}
                  onChange={({ target: { value } }) => {
                    setFieldValue(`contact.user.lastname`, value);
                  }}
                  className={classNames({
                    valid: get(values, "contact.user.lastname"),
                  })}
                  suffix={
                    get(formProps.errors, `contact.user.lastname`) ? (
                      <img src={InputError} />
                    ) : (
                      <img src={InputChecked} />
                    )
                  }
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Input
                  label={TRANSLATION.FORM.FIRST_NAME.LABEL}
                  placeholder={TRANSLATION.FORM.FIRST_NAME.PLACEHOLDER}
                  {...bindInputProps({
                    name: `contact.user.firstname`,
                    values,
                    setFieldValue,
                    ...formProps,
                  })}
                  onChange={({ target: { value } }) => {
                    setFieldValue(`contact.user.firstname`, value);
                  }}
                  className={classNames({
                    valid: get(values, "contact.user.firstname"),
                  })}
                  suffix={
                    get(formProps.errors, `contact.user.firstname`) ? (
                      <img src={InputError} />
                    ) : (
                      <img src={InputChecked} />
                    )
                  }
                />
              </Col>
            </>
          )}
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              label={TRANSLATION.FORM.PHONE.LABEL}
              placeholder={TRANSLATION.FORM.PHONE.PLACEHOLDER}
              inputMode="numeric"
              {...bindInputProps({
                name: `contact.telecoms.${CONTACT_POINT_SYSTEM.PHONE}.value`,
                values,
                setFieldValue,
                ...formProps,
              })}
              onChange={({ target: { value } }) => {
                setFieldValue(
                  `contact.telecoms.${CONTACT_POINT_SYSTEM.PHONE}.value`,
                  value
                );
              }}
              className={classNames({
                valid: get(
                  values,
                  `contact.telecoms.${CONTACT_POINT_SYSTEM.PHONE}.value`
                ),
              })}
              suffix={
                get(
                  formProps.errors,
                  `contact.telecoms.${CONTACT_POINT_SYSTEM.PHONE}.value`
                ) ? (
                  <img src={InputError} />
                ) : (
                  <img src={InputChecked} />
                )
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              label={TRANSLATION.FORM.EMAIL.LABEL}
              placeholder={TRANSLATION.FORM.EMAIL.PLACEHOLDER}
              inputMode="numeric"
              {...bindInputProps({
                name: `contact.telecoms.${CONTACT_POINT_SYSTEM.EMAIL}.value`,
                values,
                setFieldValue,
                ...formProps,
              })}
              onChange={({ target: { value } }) => {
                setFieldValue(
                  `contact.telecoms.${CONTACT_POINT_SYSTEM.EMAIL}.value`,
                  value
                );
              }}
              className={classNames({
                valid: get(
                  values,
                  `contact.telecoms.${CONTACT_POINT_SYSTEM.EMAIL}.value`
                ),
              })}
              suffix={
                get(
                  formProps.errors,
                  `contact.telecoms.${CONTACT_POINT_SYSTEM.EMAIL}.value`
                ) ? (
                  <img src={InputError} />
                ) : (
                  <img src={InputChecked} />
                )
              }
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Input
              type="checkbox"
              label={TRANSLATION.FORM.OPT_INS.CONTACT}
              {...bindInputProps({
                name: "contact.fields.acceptContact",
                values,
                ...formProps,
              })}
              onChange={({ target: { checked } }) => {
                setFieldValue("contact.fields.acceptContact", checked);
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Input
              type="checkbox"
              label={TRANSLATION.FORM.OPT_INS.MAIL_ME}
              {...bindInputProps({
                name: "contact.fields.acceptDigital",
                values,
                ...formProps,
              })}
              onChange={({ target: { checked } }) => {
                setFieldValue("contact.fields.acceptDigital", checked);
              }}
            />
          </Col>
        </Row>
      </div>
      <div className="modal-actions">
        <Button
          onClick={() => setOpen(false)}
          className="w-40"
          type="white"
          size="middle"
        >
          <span>{TRANSLATION.CLOSE}</span>
        </Button>
        <Button
          disabled={!isEmpty(formProps.errors)}
          onClick={handleSubmit}
          type={!isEmpty(formProps.errors) ? "white" : "primary"}
          size="middle"
          className="w-full"
        >
          <span>{TRANSLATION.CONFIRM}</span>
        </Button>
      </div>
    </>
  );
};

export default View;

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { notification } from "antd";
import cuid from "cuid";
import { filter, get, isNil, last, sortBy } from "lodash";
import { useState } from "react";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { COLORS } from "shared/style/colors";
import { GENERATE_QUOTE_DOCUMENTS } from "utils/api/graphql/mutations/attachment";
import { CREATE_LINK } from "utils/api/graphql/mutations/link";
import { SEND_B2C_QUOTE } from "utils/api/graphql/mutations/quotes";
import { CREATE_SUBSCRIPTION } from "utils/api/graphql/mutations/subscription";
import { OFFERS } from "utils/api/graphql/queries/offers";
import { JOB_LIST } from "utils/constants";
import { filterByConditions } from "../../../..";
import useInsurancePlans from "../../../Manual/Solyon/Comparison/Widgets/Upsells/hooks/useInsurancePlans";
import Loader from "../Components/Loader";
import Navbar from "../Components/Navbar/Navbar";
import View from "./View";

const Upsells = ({ processId, project, onNext, onBack, tracer }) => {
  const MAX_MODULES = 2;
  const [addSubscription] = useMutation(CREATE_SUBSCRIPTION);
  const [generateQuoteDocuments] = useMutation(GENERATE_QUOTE_DOCUMENTS);
  const [sendQuoteMutation] = useMutation(SEND_B2C_QUOTE);
  const [addLink] = useMutation(CREATE_LINK);

  const [selectedModules, setSelectedModules] = useState(
    get(project, "fields.upsells", [])
  );

  const [getOffers, { loading: offersLoading }] = useLazyQuery(OFFERS);
  const { selectedInsurancePlans, fetchMainOffers, loading } =
    useInsurancePlans({
      insurancePlan: { id: get(project, "fields.lastSelected.0") },
      getOffers,
      project,
      setSelectedModules,
      offerWhere: [{ offerInsurancePlans: { weight: 1 } }],
    });
  useQuery(OFFERS, {
    fetchPolicy: "no-cache",
    variables: {
      where: {
        AND: [
          {
            offerInsurancePlans: {
              insurancePlan: { id: get(project, "fields.lastSelected.0") },
            },
          },
          { offerInsurancePlans: { main: true } },
        ],
      },
    },
    onCompleted: (offers) => {
      fetchMainOffers(offers);
    },
  });

  const handleModule = ({ module }) => {
    const id = get(module, "insurancePlan.id");
    setSelectedModules((prev) => {
      const exist = get(prev, get(project, "fields.lastSelected.0"))?.find(
        ({ insurancePlan }) => insurancePlan.id === id
      );
      if (
        get(prev, get(project, "fields.lastSelected.0"))?.length ===
          MAX_MODULES &&
        !exist
      )
        return prev;
      return exist
        ? {
            ...prev,
            [get(project, "fields.lastSelected.0")]: get(
              prev,
              get(project, "fields.lastSelected.0"),
              []
            ).filter(({ insurancePlan }) => insurancePlan.id !== id),
          }
        : {
            ...prev,
            [get(project, "fields.lastSelected.0")]: [
              ...get(prev, get(project, "fields.lastSelected.0"), []),
              module,
            ],
          };
    });
  };

  const onSubmit = async ({ ignore = false }) => {
    if (
      get(project, "contact.profession") === JOB_LIST.HOSPITAL_CIVIL_SERVICE &&
      get(project, "fields.hasCds")
    )
      return onNext({
        payload: {
          fields: {
            ...project.fields,
            upsells: ignore ? undefined : selectedModules,
          },
        },
      });
    const insurancePlanId = get(project, "fields.lastSelected.0");
    const insurancePlans = [
      ...get(project, "fields.insurancePlans", []).filter(
        (id) => id !== insurancePlanId
      ),
      insurancePlanId,
    ];
    const status = get(
      last(
        sortBy(
          filter(
            project.statuses,
            ({ status }) => status.process.id === processId
          ),
          "createdDate"
        )
      ),
      "status"
    );
    const { possibleStatuses } = status;
    const [possibleStatus] = filterByConditions(
      sortBy(possibleStatuses, "order"),
      {
        ...project,
        status,
      }
    );

    addSubscription({
      variables: {
        data: {
          id: cuid(),
          project: { id: project.id },
          insurancePlan: {
            id: insurancePlanId,
          },
        },
      },
      onCompleted: ({
        addSubscription: { id: subscriptionId, insurancePlan },
      }) => {
        onNext({
          payload: {
            locked: true,
            visible: true,
            fields: {
              ...project.fields,
              insurancePlans: insurancePlans,
              ...(!isNil(possibleStatus?.nextStatus) && {
                nextQuoteStatus: possibleStatus.nextStatus,
              }),
              currentSubscription: subscriptionId,
              upsells: ignore ? undefined : selectedModules,
            },
          },
          onCompleted: () => {
            generateQuoteDocuments({
              variables: {
                data: {
                  project: { id: project.id },
                  insurancePlans: [{ id: insurancePlanId }],
                },
              },
              onCompleted: () => {
                const source = localStorage.getItem("source");
                addLink({
                  variables: {
                    data: {
                      url: get(
                        insurancePlan,
                        "config.generatedUrl",
                        `${process.env.REACT_APP_BASE_URL}/#/public/process/init/${source}`
                      ),
                      project: { id: project.id },
                      subscription: { id: subscriptionId },
                      fields: {
                        process: { id: processId },
                        status: { id: get(possibleStatus, "nextStatus") },
                      },
                    },
                  },
                  onCompleted: ({ addLink }) => {
                    sendQuoteMutation({
                      variables: {
                        data: {
                          link: addLink?.id,
                          project: { id: project.id },
                          insurancePlans: [{ id: insurancePlanId }],
                        },
                      },
                      onCompleted: () =>
                        notification.open({
                          message: "Devis envoyé",
                          duration: 5,
                          icon: (
                            <IoCheckmarkCircleOutline
                              color={COLORS.C_SUCCESS}
                            />
                          ),
                        }),
                    });
                  },
                });
              },
            });
          },
        });
      },
    });
  };

  if (offersLoading || loading) return <Loader />;

  return (
    <>
      <Navbar progress={60} project={project} tracer={tracer} showCart />
      <View
        contact={get(project, "contact", {})}
        onNext={onSubmit}
        onBack={onBack}
        upsells={selectedInsurancePlans}
        selectedModules={selectedModules}
        projectId={project?.id}
        handleModule={handleModule}
        insurancePlanId={get(project, "fields.lastSelected.0")}
        maxSelects={MAX_MODULES}
      />
    </>
  );
};

export default Upsells;

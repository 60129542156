import { NAME_SPACES } from "shared/locales/constants";
import brand from "./brand";
import broker from "./broker";
import commission from "./commission";
import commissionStatement from "./commission-statement";
import coverage from "./coverage";
import coverageBenefit from "./coverage-benefit";
import coverageCategory from "./coverage-category";
import dashboard from "./dashboard";
import flow from "./flow";
import insurancePlan from "./insurance-plan";
import offer from "./offer";
import organization from "./organization";
import pricer from "./pricer";
import process from "./process";
import qualification from "./qualification";
import qualifier from "./qualifier";
import source from "./source";
import store from "./store";
import template from "./template";
import tracer from "./tracer";
import user from "./user";
import webhook from "./webhook";
import workflow from "./workflow";

const translation = {
  [NAME_SPACES.PRIVATE.ADMIN.SOURCE]: source,
  [NAME_SPACES.PRIVATE.ADMIN.DASHBOARD]: dashboard,
  [NAME_SPACES.PRIVATE.ADMIN.TRACER]: tracer,
  [NAME_SPACES.PRIVATE.ADMIN.FLOW]: flow,
  [NAME_SPACES.PRIVATE.ADMIN.PROCESS]: process,
  [NAME_SPACES.PRIVATE.ADMIN.USER]: user,
  [NAME_SPACES.PRIVATE.ADMIN.INSURANCE_PLAN]: insurancePlan,
  [NAME_SPACES.PRIVATE.ADMIN.COVERAGE]: coverage,
  [NAME_SPACES.PRIVATE.ADMIN.QUALIFICATION]: qualification,
  [NAME_SPACES.PRIVATE.ADMIN.COVERAGE_BENEFIT]: coverageBenefit,
  [NAME_SPACES.PRIVATE.ADMIN.QUALIFICATION]: qualification,
  [NAME_SPACES.PRIVATE.ADMIN.QUALIFIER]: qualifier,
  [NAME_SPACES.PRIVATE.ADMIN.WEBHOOK]: webhook,
  [NAME_SPACES.PRIVATE.ADMIN.BROKER]: broker,
  [NAME_SPACES.PRIVATE.ADMIN.WORKFLOW]: workflow,
  [NAME_SPACES.PRIVATE.ADMIN.PRICER]: pricer,
  [NAME_SPACES.PRIVATE.ADMIN.COMMISSION_STATEMENT]: commissionStatement,
  [NAME_SPACES.PRIVATE.ADMIN.COMMISSION]: commission,
  [NAME_SPACES.PRIVATE.ADMIN.COVERAGE_CATEGORY]: coverageCategory,
  [NAME_SPACES.PRIVATE.ADMIN.ORGANIZATION]: organization,
  [NAME_SPACES.PRIVATE.ADMIN.OFFER]: offer,
  [NAME_SPACES.PRIVATE.ADMIN.TEMPLATE]: template,
  [NAME_SPACES.PRIVATE.ADMIN.BRAND]: brand,
  [NAME_SPACES.PRIVATE.ADMIN.STORE]: store,
};

export default translation;

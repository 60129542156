import { useEffect } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";

const View = ({ onNext, onBack, signatureLink }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.yousign.tech/iframe-sdk-1.5.0.min.js";
    script.integrity =
      "sha384-wrNTU81R+b2K+9A1aE1c3blhReNpdRK6Tn1fzXOXhWGZGUk+Btp0dyfnk45pVpOr";
    script.crossOrigin = "anonymous";
    script.onload = () => {
      const yousign = new window.Yousign({
        signatureLink,
        iframeContainerId: "iframe-container",
        isSandbox: process.env.REACT_APP_YOUSIGN_SANDBOX === "true",
      });
      yousign.onSuccess((_) => onNext(_));
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [signatureLink]);

  return (
    <div className="solyon">
      <div className="solyon__container">
        <div className="solyon__wrapper">
          <Header
            title="Signez votre adhésion"
            highlight="Signez"
            subtitle="Signature électronique"
          />
          <div className="solyon__body">
            <div id="iframe-container"></div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default View;

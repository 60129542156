import { get, isEmpty } from "lodash";
import moment from "moment";
import { FiEdit } from "react-icons/fi";
import {
  MdClose,
  MdOutlineCake,
  MdOutlineDiversity1,
  MdOutlineFace,
} from "react-icons/md";
import {
  DATE_FORMAT,
  RELATIONSHIP,
  TEMPLATE_TYPES,
  TEMPLATES,
} from "utils/constants";
import Button from "../../../../Button";

const NUMBERS_MAPPING = {
  1: "Premier",
  2: "Deuxième",
  3: "Troisième",
  4: "Quatrième",
  5: "Cinquième",
  6: "Sixième",
  7: "Septième",
  8: "Huitième",
  9: "Neuvième",
  10: "Dixième",
  11: "Onzième",
  12: "Douzième",
};

function View({ contact, updateProjectStatus, onClose, locked }) {
  const EDIT_BLOCK = [
    {
      icon: <MdOutlineFace size={24} />,
      label: "Vous êtes",
      value: `${get(contact, "user.firstname")} ${get(
        contact,
        "user.lastname"
      )}`,
      template: TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].ADHERENT_INFO,
      isBeforeLocked: true,
    },
    ...(!isEmpty(get(contact, "user.birthDate"))
      ? [
          {
            icon: <MdOutlineCake size={24} />,
            label: "Date de naissance",
            value: `${moment(get(contact, "user.birthDate")).format(
              DATE_FORMAT
            )}`,
            template:
              TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON]
                .ADHERENT_BIRTHDATE,
            isBeforeLocked: true,
          },
        ]
      : []),

    ...(!isEmpty(get(contact, "relatedPersons", [])) &&
    get(contact, "relatedPersons.0.identity.user.firstname", [])
      ? [
          {
            icon: <MdOutlineDiversity1 size={24} />,
            label: "Vos bénéficiaires",
            value: [
              get(contact, "relatedPersons", [])
                .filter(
                  ({ relationship }) => relationship === RELATIONSHIP.SPOUSE
                )
                .map(({ identity }, i) => (
                  <div>
                    {`${get(identity, "user.firstname")} ${get(
                      identity,
                      "user.lastname"
                    )}`}{" "}
                    <span>{"(Votre conjoint)"}</span>
                  </div>
                )),
              get(contact, "relatedPersons", [])
                .filter(
                  ({ relationship }) => relationship === RELATIONSHIP.CHILD
                )
                .map(({ identity }, i) => (
                  <div>
                    {`${get(identity, "user.firstname")} ${get(
                      identity,
                      "user.lastname"
                    )}`}{" "}
                    <span>{`(Votre ${NUMBERS_MAPPING[i + 1]} enfant)`}</span>
                  </div>
                )),
            ],
            template:
              TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].RELATED_PERSONS,
          },
        ]
      : []),
  ];

  return (
    <div className="solyon-help__modal">
      <div className="solyon-recap__blocks">
        <div className="solyon-recap__title">Informations de l'adhérent</div>
        {EDIT_BLOCK.map(
          ({ icon, label, value, template, isBeforeLocked = false }) => (
            <div className="solyon-recap__edit" key={label}>
              {icon}
              <div className="solyon-recap__edit--text">
                <span>{label}</span>
                <div className="solyon-recap__edit--text--value">{value}</div>
              </div>
              <div className="solyon-recap__edit--icon">
                <FiEdit
                  onClick={() =>
                    updateProjectStatus({ template, isBeforeLocked })
                  }
                />
              </div>
            </div>
          )
        )}
      </div>
      <Button block type={"gray-light"} onClick={onClose}>
        <MdClose /> Fermer
      </Button>
    </div>
  );
}

export default View;

import axios from "axios";
import { saveAs } from "file-saver";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const downloadFiles = async (base64 = "", zipName = "documents") => {
  if (!base64) return;
  const arrayBuffer = await fetch("data:application/zip;base64," + base64).then(
    (_) => _.arrayBuffer()
  );
  const zipBlob = new Blob([arrayBuffer], { type: "application/zip" });
  saveAs(zipBlob, `${zipName}.zip`);
};

export const bytesToMB = (bytes) => {
  if (isNaN(bytes) || bytes < 0) return bytes;
  return (bytes / (1024 * 1024)).toFixed(2);
};

export const downloadDocument = async ({ base64, contentType, name }) => {
  try {
    if (!base64) return;
    const arrayBuffer = await fetch(
      `data:${contentType};base64,` + base64
    ).then((_) => _.arrayBuffer());
    const blob = new Blob([arrayBuffer], { contentType });
    saveAs(blob, name);
  } catch (error) {
    console.error("Error downloading Document:", error);
  }
};

export const downloadUploadedDocument = async ({
  base64,
  contentType,
  name,
}) => {
  try {
    if (!base64) return;
    const arrayBuffer = await fetch(base64).then((res) => res.arrayBuffer());
    const blob = new Blob([arrayBuffer], { type: contentType });
    saveAs(blob, name);
  } catch (error) {
    console.error("Error downloading document:", error);
  }
};

export const downloadPDFFromURL = async ({ fileUrl, fileName }) => {
  try {
    const response = await axios.get(fileUrl, { responseType: "arraybuffer" });
    const pdfBlob = new Blob([response.data], { type: "application/pdf" });
    saveAs(pdfBlob, `${fileName}.pdf`);
  } catch (error) {
    console.error("Error downloading PDF:", error);
  }
};

export const downloadGeneratedPDF = (content = [], filename) => {
  const docDefinition = {
    content,
  };
  return pdfMake.createPdf(docDefinition).download(`${filename}.pdf`);
};

export const generatedPDF = async (content = [], callback = () => {}) => {
  const docDefinition = {
    content,
  };
  return pdfMake.createPdf(docDefinition).getBase64(callback);
};

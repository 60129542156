import cuid from "cuid";
import { find, get } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useMemo } from "react";
import { useNavigate } from "react-router";
import { filterByConditions } from "screens/Private/Broker/Projects/Detail";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { ACTION_TYPES, SCREENS } from "utils/constants";
import Cart from "./Cart";
import Help from "./Help";
import Profile from "./Profile";

const Actions = ({
  tracer,
  contact,
  modules,
  project,
  showCart = false,
  showHelp = true,
}) => {
  const insurancePlanId = useMemo(() => {
    const subscriptionId =
      localStorage.getItem("subscriptionId") ||
      get(project, "fields.currentSubscription");
    return (
      get(
        get(project, "subscriptions", []).find(
          ({ id }) => id === subscriptionId
        ),
        "insurancePlan.id"
      ) || get(project, "fields.lastSelected.0")
    );
  }, []);
  const [updateProject] = useMutation(graphql.mutations.UPDATE_PROJECT);
  const [duplicateProject] = useMutation(graphql.mutations.DUPLICATE_PROJECT);
  const { data: generalCost, loading } = useQuery(
    graphql.queries.GENERAL_COST,
    {
      fetchPolicy: "no-cache",
      variables: {
        where: {
          project: { id: get(project, "id") },
          insurancePlan: {
            id: insurancePlanId,
          },
        },
      },
    }
  );

  const navigate = useNavigate();

  const findStatus = (data, template) => {
    return find(data, ({ template: _ }) => _ === template);
  };

  const updateProjectStatus = ({ template }) => {
    const status = findStatus(
      get(tracer, "flow.process.processStatuses", []),
      template
    );

    const actionsToExecute = filterByConditions(
      get(status, "actions", []),
      project
    );
    const syncActions = actionsToExecute
      .filter(({ type }) => type === ACTION_TYPES.SYNC_PROCESSES)
      .map(({ args: { status } }) => ({
        id: cuid(),
        status: { id: status },
      }));
    updateProject({
      variables: {
        where: { id: project.id },
        data: {
          statuses: [{ id: cuid(), status: { id: status.id } }, ...syncActions],
        },
      },
      onCompleted: ({ updateProject }) => {
        navigate(
          `${SCREENS.PRIVATE.GUEST.PROJECTS.DETAIL.path}/${updateProject?.id}`,
          {
            state: {
              process: get(omitDeep(tracer, "__typename"), "flow.process"),
              tracer: omitDeep(tracer, "__typename"),
            },
          }
        );
      },
    });
  };

  const duplicateProjectStatus = ({ template }) => {
    const {
      insurancePlans,
      currentSubscription,
      nextQuoteStatus,
      selectedToCompare,
      upsells,
      ...fields
    } = project?.fields;
    const status = findStatus(
      get(tracer, "flow.process.processStatuses", []),
      template
    );

    const actionsToExecute = filterByConditions(
      get(status, "actions", []),
      project
    );
    const syncActions = actionsToExecute
      .filter(({ type }) => type === ACTION_TYPES.SYNC_PROCESSES)
      .map(({ args: { status } }) => ({
        id: cuid(),
        status: { id: status },
      }));

    duplicateProject({
      variables: {
        where: { id: project.id },
        data: {
          statuses: [{ id: cuid(), status: { id: status.id } }, ...syncActions],
          fields,
        },
      },
      onCompleted: ({ duplicateProject }) => {
        localStorage.setItem("projectId", duplicateProject?.id);
        navigate(
          `${SCREENS.PRIVATE.GUEST.PROJECTS.DETAIL.path}/${duplicateProject?.id}`,
          {
            state: {
              process: get(omitDeep(tracer, "__typename"), "flow.process"),
              tracer: omitDeep(tracer, "__typename"),
            },
          }
        );
      },
    });
  };

  const onUpdateStatus = ({ template }) => {
    if (project.locked) return duplicateProjectStatus({ template });
    updateProjectStatus({ template });
  };

  return (
    <>
      {showHelp &&
        (!get(project, "contact.user.firstname") ? (
          <Help project={project} />
        ) : (
          [
            <Help project={project} />,
            <Profile project={project} updateStatus={onUpdateStatus} />,
          ]
        ))}
      {showCart && (
        <Cart
          loading={loading}
          project={project}
          generalCost={get(generalCost, "generalCost")}
          updateStatus={onUpdateStatus}
          upsells={get(project, `fields.upsells.${insurancePlanId}`, [])}
        />
      )}
    </>
  );
};

export default Actions;

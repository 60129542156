import { useQuery } from "@apollo/client";
import cuid from "cuid";
import { defaults, get, keyBy, map } from "lodash";
import { SUBSCRIPTION } from "utils/api/graphql/queries/subscription";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import Navbar from "../Components/Navbar/Navbar";
import View from "./View";

const AdherentTelecoms = ({
  project,
  onNext,
  onBack,
  currentStatus,
  tracer,
  updateUrl,
}) => {
  const telecoms = defaults(keyBy(get(project, "contact.telecoms"), "system"), {
    [CONTACT_POINT_SYSTEM.ADDRESS]: { value: null },
    [CONTACT_POINT_SYSTEM.CITY]: { value: null },
    [CONTACT_POINT_SYSTEM.ZIP_CODE]: { value: null },
  });
  const subscriptionId =
    localStorage.getItem("subscriptionId") ||
    get(project, "fields.currentSubscription");
  const { data } = useQuery(SUBSCRIPTION, {
    variables: { where: { id: subscriptionId } },
  });
  const onSubmit = ({ telecoms }) => {
    const updatedTelecoms = map(telecoms, (item, key) =>
      defaults(item, { id: cuid(), system: key })
    );
    const source = localStorage.getItem("source");
    updateUrl({
      subscriptionId,
      url: get(
        data,
        "subscription.insurancePlan.config.generatedUrl",
        `${process.env.REACT_APP_BASE_URL}/#/public/process/init/${source}`
      ),
    });
    onNext({
      payload: {
        contact: {
          id: get(project, "contact.id") || cuid(),
          telecoms: updatedTelecoms,
        },
      },
    });
  };
  return (
    <>
      <Navbar
        project={project}
        progress={currentStatus?.progress}
        tracer={tracer}
        showCart
      />
      <View
        telecoms={telecoms}
        onNext={onSubmit}
        onBack={onBack}
        locked={project?.locked}
      />
    </>
  );
};

export default AdherentTelecoms;

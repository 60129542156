import { notification } from "antd";
import cuid from "cuid";
import { find, get, intersection, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { GENERATE_QUOTE_DOCUMENTS } from "utils/api/graphql/mutations/attachment";
import { BUILD_GENERAL_COSTS } from "utils/api/graphql/mutations/costs";
import { UPDATE_PROJECT } from "utils/api/graphql/mutations/projects";
import {
  CREATE_SUBSCRIPTIONS,
  UPDATE_SUBSCRIPTION,
} from "utils/api/graphql/mutations/subscription";
import { GENERAL_COSTS } from "utils/api/graphql/queries/general-cost";
import {
  MODAL_STATES,
  PROCESS_TYPES,
  SCREENS,
  SUBSCRIPTION_STATUSES,
  TEMPLATES,
  TEMPLATE_TYPES,
} from "utils/constants";
import { downloadFiles } from "utils/helpers/files";
import View from "./View";

const Comparison = ({
  project,
  onNext,
  onBack,
  setCurrentStatus,
  setDisplayProject,
}) => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.ERRORS);
  const API_ERRORS = t("API", { returnObjects: true });
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [hideDetail, setHideDetail] = useState({});
  const [addSubscriptions] = useMutation(CREATE_SUBSCRIPTIONS);
  const [updateSubscription] = useMutation(UPDATE_SUBSCRIPTION);
  const [duplicateProject] = useMutation(graphql.mutations.DUPLICATE_PROJECT);
  const [updateProject] = useMutation(UPDATE_PROJECT);

  const findStatus = (data, template) => {
    return find(data, ({ template: _ }) => _ === template);
  };
  const [getGeneralCosts, { data }] = useLazyQuery(GENERAL_COSTS, {
    onCompleted: () => setLoading(false),
  });
  const [buildGeneralCosts] = useMutation(BUILD_GENERAL_COSTS, {
    variables: {
      data: {
        project: { id },
        insurancePlanFilter: {
          isIn: {
            id: get(project, "fields.selectedToCompare", []),
          },
        },
      },
    },
    onCompleted: ({ buildGeneralCosts }) => {
      const selectedInsurancePlans =
        intersection(
          buildGeneralCosts?.map(({ insurancePlan }) => insurancePlan?.id),
          get(project, "fields.selectedToCompare", [])
        ) || [];

      getGeneralCosts({
        variables: {
          where: { project: { id } },
          isIn: {
            insurancePlan: {
              id: selectedInsurancePlans,
            },
          },
        },
      });
    },
  });

  const duplicate = () => {
    const contactStatus = find(
      get(state, "tracer.flow.process.processStatuses"),
      ({ template }) =>
        template === TEMPLATES[PROCESS_TYPES.B2B].THEMES.DEFAULT.CONTACT
    );
    duplicateProject({
      variables: {
        where: { id: project.id },
        data: {
          visible: true,
          statuses: [{ id: cuid(), status: { id: get(contactStatus, "id") } }],
        },
      },
      onCompleted: ({ duplicateProject }) => {
        navigate(
          `${SCREENS.PRIVATE.BROKER.PROJECTS.DETAIL.path}/${duplicateProject.id}`,
          {
            state: {
              process: get(state, "tracer.flow.process"),
              tracer: get(state, "tracer"),
            },
          }
        );
      },
    });
  };

  const [generateQuoteDocuments] = useMutation(GENERATE_QUOTE_DOCUMENTS);

  const onDownload = async ({
    selectedData = [],
    payload = {},
    setState,
    setSelectedData,
  }) => {
    setState(MODAL_STATES.PENDING);

    return updateProject({
      variables: {
        where: { id: project.id },
        data: {
          fields: get(payload, "fields"),
        },
      },
      onCompleted: () => {
        generateQuoteDocuments({
          variables: {
            data: {
              project: { id },
              insurancePlans: selectedData.map((id) => ({ id })),
            },
          },
          onCompleted: async ({ generateQuoteDocuments: { base64 } }) => {
            downloadFiles(base64);
            addSubscriptions({
              variables: {
                data: selectedData.map((insurancePlanId) => ({
                  id: cuid(),
                  project: { id: project.id },
                  insurancePlan: {
                    id: insurancePlanId,
                  },
                })),
              },
              onCompleted: () => {
                setState(MODAL_STATES.DONE);
                setTimeout(() => {
                  setSelectedData([]);
                  setState(MODAL_STATES.INITIAL);
                  setOpenModal(false);
                }, 3000);

                onNext({ payload });
              },
            });
          },
          onError: () => {
            setTimeout(() => {
              notification.error({
                message: API_ERRORS.GENERIC,
              });
              setState(MODAL_STATES.INITIAL);
            }, 2000);
          },
        });
      },
    });
  };

  const onSubscribe = async ({ payload = {}, actionsTemplate }) => {
    if (actionsTemplate?.status === SUBSCRIPTION_STATUSES.DRAFT)
      updateSubscription({
        variables: {
          where: { id: actionsTemplate?.id },
          data: { status: SUBSCRIPTION_STATUSES.IN_PROGRESS },
        },
      });
    onNext({
      payload,
      actionsTemplate,
    });
  };

  const onSendQuote = async ({ payload = {} }) => {
    if (project.locked) {
      const status = findStatus(
        get(state, "tracer.flow.process.processStatuses"),
        TEMPLATES[PROCESS_TYPES.B2B].THEMES[TEMPLATE_TYPES.DEFAULT].QUOTE
      );
      updateProject({
        variables: {
          where: { id: project.id },
          data: {
            fields: get(payload, "fields"),
          },
        },
      });
      setDisplayProject((prev) => ({
        ...prev,
        fields: get(payload, "fields"),
      }));
      return setCurrentStatus(status);
    }
    onNext({
      payload,
    });
  };

  useEffect(() => {
    const signedSubscription = find(
      get(project, "subscriptions", []),
      ({ status }) => status === SUBSCRIPTION_STATUSES.SIGNED
    );
    if (!isEmpty(signedSubscription))
      navigate(
        `${SCREENS.PRIVATE.BROKER.SUBSCRIPTIONS.DETAIL.path}/${signedSubscription.id}`
      );
    if (project.locked) {
      getGeneralCosts({
        variables: {
          where: {
            project: { id: project.id },
          },
          isIn: {
            insurancePlan: { id: get(project, "fields.selectedToCompare", []) },
          },
        },
      });
    } else buildGeneralCosts();
  }, []);

  return (
    <View
      project={project}
      onSubscribe={onSubscribe}
      onDownload={onDownload}
      onSendQuote={onSendQuote}
      onBack={onBack}
      data={get(data, "generalCosts.data", [])}
      loading={loading}
      openModal={openModal}
      duplicateProject={duplicate}
      setOpenModal={setOpenModal}
      hideDetail={hideDetail}
      setHideDetail={setHideDetail}
    />
  );
};

export default Comparison;

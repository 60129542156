import cuid from "cuid";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { INITIAL_VALUES } from "utils/constants";
import View from "./View";

const CreateOffer = ({ onClose, open }) => {
  const [addOffer] = useMutation(graphql.mutations.CREATE_OFFER, {
    refetchQueries: [
      {
        query: graphql.queries.OFFERS,
        awaitRefetchQueries: true,
        variables: INITIAL_VALUES,
      },
    ],
    onCompleted: () => onClose(),
  });

  const onSubmit = ({ processes = [], ...data }) => {
    addOffer({
      variables: {
        data: {
          id: cuid(),
          ...data,
          processes: processes?.map((id) => ({ id })),
        },
      },
    });
  };
  return <View open={open} onClose={onClose} onSubmit={onSubmit} />;
};

export default CreateOffer;

import cuid from "cuid";
import { defaultTo, defaultsDeep, get, head, isEmpty } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useCallback, useState } from "react";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { GENERATE_SUBSCRIPTION_DOCUMENTS } from "utils/api/graphql/mutations/attachment";
import { BANK_ACCOUNT_OWNER_TYPE, PAYMENT_TYPES } from "utils/constants";
import Template from "../Template";
import View from "./View";

const Payment = ({
  currentStatus,
  tracer,
  project,
  onBack,
  onNext,
  updateUrl,
}) => {
  const [generateSubscriptionDocuments] = useMutation(
    GENERATE_SUBSCRIPTION_DOCUMENTS
  );
  const [initialValues, setInitialValues] = useState(null);
  const subscriptionId =
    get(project, "fields.currentSubscription") ||
    localStorage.getItem("subscriptionId");

  const initSubscription = useCallback(({ subscription: data }) => {
    const subscription = omitDeep(data, "__typename");
    const shouldBeAdherent =
      get(subscription, "payment.payer.owner.isAdherent", true) ||
      get(subscription, "insurancePlan.fields.payment.shouldBeAdherent", false);
    const initialValues = {
      isPayerReceiver: isEmpty(get(subscription, "payment.receiver.id"))
        ? true
        : get(subscription, "payment.receiver.id") ===
          get(subscription, "payment.payer.id"),
      contact: get(subscription, "project.contact"),
      payment: {
        id: cuid(),
        type:
          head(get(subscription, "insurancePlan.fields.payment.type")) ||
          PAYMENT_TYPES.DEBIT,
        frequency: null,
        debitDay: null,
        ...subscription.payment,
        payer: {
          id: cuid(),
          IBAN: null,
          BIC: null,
          ...subscription.payment?.payer,
          owner: {
            id: cuid(),
            ...subscription.payment?.payer?.owner,
            firstName: defaultTo(
              subscription.payment?.payer?.owner?.firstName,
              shouldBeAdherent
                ? get(subscription, "project.contact.user.firstname")
                : null
            ),
            lastName: defaultTo(
              subscription.payment?.payer?.owner?.lastName,
              shouldBeAdherent
                ? get(subscription, "project.contact.user.lastname")
                : null
            ),
            isAdherent: defaultTo(
              subscription.payment?.payer?.owner?.isAdherent,
              shouldBeAdherent
            ),
            type: get(subscription, "insurancePlan.fields.tns")
              ? BANK_ACCOUNT_OWNER_TYPE.COMPANY
              : BANK_ACCOUNT_OWNER_TYPE.PARTICULAR,
          },
        },
        receiver: defaultsDeep(subscription.payment?.receiver, {
          id: cuid(),
          IBAN: null,
          BIC: null,
          owner: { id: cuid() },
        }),
      },
    };
    setInitialValues(initialValues);
  }, []);

  const { data: subscription } = useQuery(graphql.queries.SUBSCRIPTION, {
    variables: { where: { id: subscriptionId } },
    onCompleted: initSubscription,
  });

  const [updateSubscription] = useMutation(
    graphql.mutations.UPDATE_SUBSCRIPTION,
    {
      refetchQueries: [
        {
          query: graphql.queries.SUBSCRIPTION,
          awaitRefetchQueries: true,
          variables: { where: { id: subscriptionId } },
        },
      ],
    }
  );

  const onSubmit = ({ payment }) => {
    updateSubscription({
      variables: {
        where: {
          id: subscriptionId,
        },
        data: {
          payment: omitDeep(payment, "__typename"),
        },
      },
      onCompleted: (_) => {
        if (!get(subscription, "subscription.locked"))
          generateSubscriptionDocuments({
            variables: {
              data: { id: subscriptionId },
            },
          });
        const source = localStorage.getItem("source");
        updateUrl({
          subscriptionId,
          url: get(
            omitDeep(subscription, "__typename"),
            "subscription.insurancePlan.config.generatedUrl",
            `${process.env.REACT_APP_BASE_URL}/#/public/process/init/${source}`
          ),
        });
        onNext({
          payload: {},
        });
      },
    });
  };

  return (
    <Template
      progress={currentStatus.progress}
      onBack={onBack}
      loading={!initialValues}
      HeaderComponent={{
        subscription: get(subscription, "subscription"),
        tracer,
        project,
        contact: get(project, "contact"),
      }}
      View={
        <View
          initialValues={initialValues}
          locked={get(project, "locked", false)}
          onSubmit={onSubmit}
          subscription={get(subscription, "subscription")}
        />
      }
      contact={get(project, "contact")}
    />
  );
};
export default Payment;

import classNames from "classnames";
import { get, isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import Button from "../Components/Button";
import Cost from "../Components/Cost";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import DetailsModal from "./DetailsModal";
import UpsellsCard from "./UpsellsCard";

function View({
  onNext,
  onBack,
  upsells,
  selectedModules,
  handleModule,
  projectId,
  insurancePlanId,
  maxSelects,
}) {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const [openDetails, setOpenDetails] = useState(null);

  const { totalCost, currency } = useMemo(
    () => ({
      totalCost:
        get(selectedModules, insurancePlanId)
          ?.reduce((acc, curr) => acc + curr.cost, 0)
          ?.toFixed(2) || 0,
      currency: get(upsells, `0.currency`),
    }),
    [get(selectedModules, insurancePlanId, [])?.length]
  );

  const DETAIL = t("DETAIL", { returnObjects: true });
  return (
    <div className="solyon">
      <div className="solyon__container">
        <div className="solyon__wrapper">
          <Header
            title="Renforcez votre couverture avec nos modules complémentaires"
            highlight="modules complémentaires"
            subtitle="Ajoutez jusqu'à 2 modules pour renforcer votre couverture"
            onBack={onBack}
          />
          <div className="solyon__body">
            <div className="solyon-upsells">
              {upsells.map((module) => (
                <UpsellsCard
                  key={module?.id}
                  max={maxSelects}
                  maxReached={
                    get(selectedModules, insurancePlanId, [])?.length ===
                    maxSelects
                  }
                  notSelected={
                    !get(selectedModules, insurancePlanId)?.find(
                      ({ insurancePlan }) =>
                        insurancePlan.id === get(module, "insurancePlan.id")
                    )
                  }
                  icon={get(module, "insurancePlan.icon") || ""}
                  name={get(module, "insurancePlan.name") || ""}
                  cost={get(module, "cost")}
                  currency={get(module, "currency")}
                  onAdd={() => handleModule({ module })}
                  onDetailsClick={() =>
                    setOpenDetails(get(module, "insurancePlan.id"))
                  }
                />
              ))}
              {/* <div className="solyon-upsells-card__max">{`${max} modules au maximum`}</div> */}
              {get(selectedModules, insurancePlanId, [])?.length ===
                maxSelects && (
                <div className="solyon-upsells__max">{`Vous pouvez choisir ${maxSelects} modules au maximum`}</div>
              )}
            </div>
            <div className="solyon__body--actions">
              <div className={"solyon-upsells-recap"}>
                <div className={"solyon-upsells-recap__card"}>
                  <div
                    className={classNames("solyon-upsells-recap__container", {
                      "solyon-upsells-recap--gray": isEmpty(
                        get(selectedModules, insurancePlanId)
                      ),
                    })}
                  >
                    <div className="solyon-upsells-recap__text">
                      Supplément à payer par mois
                    </div>
                    <Cost cost={totalCost} currency={currency} />
                    <Button
                      type={"primary"}
                      alignment={"center"}
                      onClick={onNext}
                    >
                      Continuer
                    </Button>
                  </div>
                </div>
                {/* {!isEmpty(get(selectedModules, insurancePlanId)) && ( */}
                <div
                  onClick={() => onNext({ ignore: true })}
                  className={classNames("solyon-upsells__item--link", {
                    "solyon-upsells__item--link--hidden": !isEmpty(
                      get(selectedModules, insurancePlanId)
                    ),
                  })}
                >
                  Ignorer cette étape
                </div>
                {/* )} */}
              </div>
            </div>
            {openDetails && (
              <DetailsModal
                centered
                open={openDetails}
                onClose={() => setOpenDetails(null)}
                filter={{
                  where: {
                    insurancePlan: { id: openDetails },
                    project: { id: projectId },
                  },
                }}
                translation={DETAIL.TEMPLATES.COMPARISON}
              />
            )}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default View;

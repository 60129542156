import cuid from "cuid";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import { DATE_FORMAT } from "utils/constants";
import { bindDateInputProps, bindInputProps } from "utils/helpers/input";
import Button from "../Components/Button";
import Card from "../Components/Card";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Input from "../Components/Input";
import validation from "./validation";
import { useMediaQuery } from "react-responsive";

function View({
  contract,
  ria,
  onSubmit,
  onBack,
  deltas,
  locked,
  fields,
  terminationRequest,
}) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const { handleSubmit, values, setFieldValue, ...formProps } = useFormik({
    initialValues: {
      contract,
      ria,
      locked,
      fields,
      terminationRequest,
    },
    onSubmit: onSubmit,
    validateOnMount: true,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true }),
      {
        deltaRia: get(deltas, "start.ria"),
        deltaStart: get(deltas, "start.normal"),
        deltaEndRia: get(deltas, "end.ria"),
        deltaEnd: get(deltas, "end.normal"),
      }
    ),
  });
  const isValid = useMemo(() => {
    const projectRia = values?.ria;
    const selfOccupation = values?.fields?.selfOccupation;
    const contractNumber = values?.terminationRequest?.contractNumber;
    const currentCompany =
      values?.terminationRequest?.brokerageMandate?.currentCompany;

    return (
      !projectRia ||
      (projectRia && selfOccupation) ||
      (projectRia && !isEmpty(contractNumber) && !isEmpty(currentCompany))
    );
  }, [
    values?.fields?.selfOccupation,
    values?.ria,
    values?.terminationRequest?.brokerageMandate?.currentCompany,
    values?.terminationRequest?.contractNumber,
  ]);

  useEffect(() => {
    formProps.validateForm();
  }, [values?.contract?.issuanceDate]);

  return (
    <div className="solyon">
      <div className="solyon__container">
        <div className="solyon__wrapper">
          <Header
            title="À partir de quelle date souhaitez-vous être couvert ?"
            highlight="souhaitez-vous être,couvert ?"
            subtitle="Souscription : Date d’effet"
          />
          <div className="solyon__body">
            <div>
              <div className="solyon__body--inputs">
                <Input
                   width={isMobile ? '100%' : 316}
                  type="date"
                  format={DATE_FORMAT}
                  placeholder={"JJ / MM / AAAA"}
                  label={"Date d’effet"}
                  disabledDate={(current) => current < moment()}
                  {...bindDateInputProps({
                    name: "contract.issuanceDate",
                    setFieldValue,
                    values,
                    ...formProps,
                  })}
                  touched
                />
                <Input
                  type="checkbox"
                  label={
                    "Cochez cette case si vous souhaitez résilier un contrat existant souscrit depuis plus d’un an."
                  }
                  checked={get(values, "ria") || false}
                  onChange={({ target: { checked } }) =>
                    setFieldValue("ria", checked)
                  }
                />
                {values?.ria && (
                  <Card>
                    <div className="grid--1">
                      <Input
                        type="radio"
                        label="Comment souhaitez-vous gérer la résiliation de votre ancien contrat ?"
                        values={[
                          {
                            value: true,
                            label: "Je me charge de résilier moi-même.",
                          },
                          {
                            value: false,
                            label:
                              "Je souhaite que So’Lyon Mutuelle s'en occupe pour moi.",
                          },
                        ]}
                        defaultValue={get(values, "fields.selfOccupation")}
                        onChange={(value) => {
                          setFieldValue("fields.selfOccupation", value);
                          if (!value) {
                            setFieldValue(
                              "contract.issuanceDate",
                              moment(get(values, "contract.issuanceDate"))
                                .add(deltas.start.ria, "days")
                                .toISOString()
                            );
                          }
                          setFieldValue(
                            "terminationRequest",
                            value
                              ? null
                              : {
                                  id: cuid(),
                                  brokerageMandate: { id: cuid() },
                                }
                          );
                        }}
                      />
                      {get(values, "fields.selfOccupation") === false && (
                        <>
                          <Input
                            label="N° de référence de votre contrat"
                            placeholder="ex : 123456789"
                            {...bindInputProps({
                              name: "terminationRequest.contractNumber",
                              values,
                              ...formProps,
                            })}
                          />
                          <Input
                            label="Nom de mon assureur ou de ma mutuelle"
                            placeholder="ex : ABC Mutuelle"
                            {...bindInputProps({
                              name: "terminationRequest.brokerageMandate.currentCompany",
                              values,
                              ...formProps,
                            })}
                          />
                        </>
                      )}
                    </div>
                  </Card>
                )}
              </div>
            </div>
            <div className="solyon__body--actions">
              <Button
                type={"primary"}
                alignment={"center"}
                disabled={!isEmpty(formProps.errors) || !isValid}
                onClick={handleSubmit}
              >
                <div className="d-flex justify--center">Continuer</div>
              </Button>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default View;

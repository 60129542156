import axios from "axios";
import { get, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import { CREATE_BRAND } from "utils/api/graphql/mutations/brand";
import { BRANDS } from "utils/api/graphql/queries/brands";
import { DEVICES_API, INITIAL_VALUES, SCREENS } from "utils/constants";
import View from "./View";

const Create = () => {
  const navigate = useNavigate();
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.BRANDS.LIST.path);
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState([]);
  const [devices, setDevices] = useState([]);
  const getDevices = async () => {
    const response = await axios.get(DEVICES_API);
    const deviceList = get(response, "data.data", []).filter(
      ({ device_list, brand_name }) => !isEmpty(device_list) && !!brand_name
    );
    setBrands(deviceList);
    setLoading(false);
  };

  useEffect(() => {
    getDevices();
  }, []);

  const [addBrand] = useMutation(CREATE_BRAND, {
    refetchQueries: [
      {
        query: BRANDS,
        awaitRefetchQueries: true,
        variables: INITIAL_VALUES,
      },
    ],
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.ADMIN.BRANDS.LIST.path);
    },
  });
  const onSubmit = ({ models, ...rest }) => {
    return addBrand({
      variables: {
        data: {
          ...rest,
          models: models.map((model) => {
            return {
              id: model?.id,
              name: model?.name,
              image: model?.iconAttachment,
            };
          }),
        },
      },
    });
  };

  return (
    <View
      onSubmit={onSubmit}
      cancel={cancel}
      brands={brands}
      setDevices={setDevices}
      devices={devices}
      loading={loading}
    />
  );
};

export default Create;

import { MODULES } from "utils/constants";
import Brokers from "./Brokers";
// import CommissionStatements from "./CommissionStatements";
// import Commissions from "./Commissions";
import Brands from "./Brands";
import CoverageBenefits from "./CoverageBenefits";
import CoverageCategory from "./CoverageCategory";
import Coverages from "./Coverages";
import Dashboard from "./Dashboard";
import Flows from "./Flows";
import InsurancePlans from "./InsurancePlans";
import Offers from "./Offers";
import Organizations from "./Organizations";
import Pricers from "./Pricers";
import Processes from "./Processes";
import Qualifications from "./Qualifications";
import Qualifiers from "./Qualifiers";
import Sources from "./Sources";
import Stores from "./Stores";
import Templates from "./Templates";
import Tracers from "./Tracers";
import Ui from "./Ui";
import Users from "./Users";
import Webhooks from "./Webhooks";
import Workflows from "./Workflows";

const routes = {
  [MODULES.DASHBOARD]: Dashboard,
  [MODULES.SOURCE]: Sources,
  [MODULES.TRACER]: Tracers,
  [MODULES.FLOW]: Flows,
  [MODULES.PROCESS]: Processes,
  [MODULES.USER]: Users,
  [MODULES.INSURANCE_PLAN]: InsurancePlans,
  [MODULES.COVERAGE]: Coverages,
  [MODULES.COVERAGE_BENEFIT]: CoverageBenefits,
  [MODULES.QUALIFIER]: Qualifiers,
  [MODULES.QUALIFICATION]: Qualifications,
  [MODULES.WEBHOOK]: Webhooks,
  [MODULES.BROKER]: Brokers,
  [MODULES.WORKFLOW]: Workflows,
  [MODULES.PRICER]: Pricers,
  // [MODULES.COMMISSION_STATEMENT]: CommissionStatements,
  // [MODULES.COMMISSION]: Commissions,
  [MODULES.ORGANIZATION]: Organizations,
  [MODULES.COVERAGE_CATEGORY]: CoverageCategory,
  [MODULES.OFFER]: Offers,
  [MODULES.TEMPLATE]: Templates,
  [MODULES.BRAND]: Brands,
  [MODULES.STORE]: Stores,
};
const screens = (permissions) => [
  ...Ui,
  ...Object.entries(routes)
    .filter(([module]) =>
      permissions?.find(
        ({ module: permissionModule, granted }) =>
          granted && module === permissionModule
      )
    )
    .reduce((acc, [_, item]) => [...acc, ...item], []),
];

export default screens;

import { REGEX_EXPS } from "utils/constants";
import * as yup from "yup";

const validator = (messages) => {
  const requiredString = yup
    .string()
    .typeError(messages.REQUIRED)
    .required(messages.REQUIRED);
  const requiredName = yup
    .string()
    .typeError(messages.REQUIRED)
    .matches(REGEX_EXPS.name, messages.INVALID_NAME)
    .required(messages.REQUIRED);
  return yup.object().shape({
    name: requiredString,
    user: yup.object().shape({
      gender: requiredString,
      firstname: requiredName,
      lastname: requiredName,
      phone: requiredString,
      email: requiredString,
    }),
  });
};

export default validator;

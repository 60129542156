import { notification } from "antd";
import cuid from "cuid";
import { defaults, filter, get, keyBy, last, map, sortBy } from "lodash";
import { useEffect } from "react";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import { COLORS } from "shared/style/colors";
import graphql from "utils/api/graphql";
import { GENERATE_QUOTE_DOCUMENTS } from "utils/api/graphql/mutations/attachment";
import { CREATE_LINK } from "utils/api/graphql/mutations/link";
import { SEND_B2C_QUOTE } from "utils/api/graphql/mutations/quotes";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import { filterByConditions } from "../../../..";
import Template from "../Template";
import View from "./View";

const AdherentDetails = ({
  project,
  tracer,
  currentStatus,
  onNext,
  processId,
  onBack,
}) => {
  const [sendQuoteMutation] = useMutation(SEND_B2C_QUOTE);
  const [addLink] = useMutation(CREATE_LINK);
  const [generateQuoteDocuments] = useMutation(GENERATE_QUOTE_DOCUMENTS);
  const subscriptionId =
    get(project, "fields.currentSubscription") ||
    localStorage.getItem("subscriptionId");

  const [getSubscription, { data }] = useLazyQuery(
    graphql.queries.SUBSCRIPTION
  );

  useEffect(() => {
    if (subscriptionId)
      getSubscription({
        variables: { where: { id: subscriptionId } },
      });
  }, []);

  const telecoms = defaults(keyBy(get(project, "contact.telecoms"), "system"), {
    [CONTACT_POINT_SYSTEM.ZIP_CODE]: { value: null },
  });

  const next = ({ contact, contract }) => {
    const status = get(
      last(
        sortBy(
          filter(
            project.statuses,
            ({ status }) => status.process.id === processId
          ),
          "createdDate"
        )
      ),
      "status"
    );
    const { possibleStatuses } = status;
    const [possibleStatus] = filterByConditions(
      sortBy(possibleStatuses, "order"),
      {
        ...project,
        status,
      }
    );
    const mappedTelecoms = map(get(contact, "telecoms"), (item, key) =>
      defaults(item, { id: cuid(), system: key })
    );

    onNext({
      payload: {
        contact: {
          ...contact,
          telecoms: mappedTelecoms,
        },
        contract,
      },
      onCompleted: () => {
        generateQuoteDocuments({
          variables: {
            data: {
              project: { id: project.id },
              insurancePlans: [
                { id: get(data, "subscription.insurancePlan.id") },
              ],
            },
          },
          onCompleted: () => {
            const source = localStorage.getItem("source");
            addLink({
              variables: {
                data: {
                  url: get(
                    data,
                    "subscription.insurancePlan.config.generatedUrl",
                    `${process.env.REACT_APP_BASE_URL}/#/public/process/init/${source}`
                  ),
                  project: { id: project.id },
                  subscription: { id: subscriptionId },
                  fields: {
                    process: { id: processId },
                    status: { id: get(possibleStatus, "nextStatus") },
                  },
                },
              },
              onCompleted: ({ addLink }) => {
                sendQuoteMutation({
                  variables: {
                    data: {
                      link: addLink?.id,
                      project: { id: project.id },
                      insurancePlans: [
                        { id: get(data, "subscription.insurancePlan.id") },
                      ],
                    },
                  },
                  onCompleted: () =>
                    notification.open({
                      message: "Devis envoyé",
                      duration: 5,
                      icon: (
                        <IoCheckmarkCircleOutline color={COLORS.C_SUCCESS} />
                      ),
                    }),
                });
              },
            });
          },
        });
      },
    });
  };

  const initialValues = {
    contact: {
      ...get(project, "contact", {}),
      telecoms,
      fields: {
        ...get(project, "contact.fields", {}),
        acceptDigital: get(project, "contact.fields.acceptDigital", false),
        acceptContact: get(project, "contact.fields.acceptContact", false),
      },
    },
    contract: get(project, "contract"),
  };

  return (
    <Template
      onBack={onBack}
      progress={currentStatus.progress}
      tracer={tracer}
      subscriptionId={subscriptionId}
      project={project}
      HeaderComponent={{
        subscription: get(data, "subscription"),
        tracer,
        project,
      }}
      View={
        <View
          onNext={next}
          initialValues={initialValues}
          fields={get(project, "fields", {})}
          locked={project.locked}
          disabled={project?.locked}
        />
      }
    />
  );
};

export default AdherentDetails;

import cuid from "cuid";
import dayjs from "dayjs";
import { get } from "lodash";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { DATE_FORMAT } from "utils/constants";
import { isMajor } from "utils/helpers/date";
import Button from "../Components/Button";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Input from "../Components/Input";

function View({ contact, onNext, onBack }) {
  const [selectedValue, setSelectedValue] = useState(
    get(contact, "user.birthDate")
  );

  const handleDateChange = (date) => {
    if (!date) return setSelectedValue(date);
    if (date.isValid() && date.isBefore(isMajor)) {
      const dateWithTime = date
        .set("hour", 2)
        .set("minute", 0)
        .set("second", 0);
      setSelectedValue(dateWithTime);
    }
  };

  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div className="solyon">
      <div className="solyon__container">
        <div className="solyon__wrapper">
          <Header
            title="Votre devis en un instant. Quelle est votre date de naissance ?"
            highlight="date de naissance"
            subtitle="Informations de l’adhérent"
            onBack={onBack}
          />
          <div className="solyon__body">
            <Input
              width={isMobile ? "100%" : 316}
              type="date"
              format={DATE_FORMAT}
              placeholder={"JJ / MM / AAAA"}
              label={"Date de naissance"}
              defaultValue={selectedValue && dayjs(selectedValue)}
              value={selectedValue && dayjs(selectedValue)}
              disabledDate={(current) => current > isMajor}
              defaultPickerValue={
                !selectedValue && dayjs().subtract(30, "years")
              }
              onChange={handleDateChange}
              onBlur={({ target: { value } }) => {
                const date = dayjs(value, DATE_FORMAT);
                if (date.isValid())
                  setSelectedValue(date ? date.add(2, "h") : date);
              }}
            />

            <div className="solyon__body--actions">
              <Button
                type={"primary"}
                alignment={"center"}
                disabled={!selectedValue}
                onClick={() =>
                  onNext({
                    payload: {
                      contact: {
                        ...contact,
                        user: {
                          id: cuid(),
                          ...get(contact, "user", {}),
                          birthDate: selectedValue,
                        },
                      },
                    },
                  })
                }
              >
                <div className="d-flex justify--center">Continuer</div>
              </Button>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default View;

import cuid from "cuid";
import { defaults, get, keyBy, map } from "lodash";
import { useEffect } from "react";
import { useLazyQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import Template from "../Template";
import View from "./View";

const AdherentAddress = ({
  project,
  tracer,
  currentStatus,
  onNext,
  onBack,
}) => {
  const subscriptionId =
    get(project, "fields.currentSubscription") ||
    localStorage.getItem("subscriptionId");

  const [getSubscription, { data }] = useLazyQuery(
    graphql.queries.SUBSCRIPTION
  );

  useEffect(() => {
    if (subscriptionId)
      getSubscription({
        variables: { where: { id: subscriptionId } },
      });
  }, []);

  const telecoms = defaults(keyBy(get(project, "contact.telecoms"), "system"), {
    [CONTACT_POINT_SYSTEM.ZIP_CODE]: { value: null },
  });

  const next = ({ telecoms }) => {
    const mappedTelecoms = map(telecoms, (item, key) =>
      defaults(item, { id: cuid(), system: key })
    );
    onNext({
      payload: {
        contact: {
          ...get(project, "contact"),
          telecoms: mappedTelecoms,
        },
      },
    });
  };

  const initialValues = {
    telecoms,
  };

  return (
    <Template
      onBack={onBack}
      progress={currentStatus.progress}
      contact={get(project, "contact")}
      HeaderComponent={{
        subscription: get(data, "subscription"),
        tracer,
        project,
        contact: get(project, "contact"),
      }}
      View={
        <View
          onNext={next}
          initialValues={initialValues}
          locked={project.locked}
        />
      }
    />
  );
};

export default AdherentAddress;

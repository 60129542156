import { Radio, Slider } from "antd";
import { range } from "lodash";
import { useMediaQuery } from "react-responsive";

const options = { 1: "Faible", 2: "Modéré", 3: "Élevé", 4: "Très élevé" };

const NeedsWeb = ({ categories, selectedValues, setSelectedValues }) => {
  const levels = range(1, 5);
  const handleChange = (name, value) => {
    setSelectedValues((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="solyon-scroll-container">
      <div className="solyon__needs">
        <div className="selection-grid">
          {categories.map((category) => (
            <div className="grid--1" key={category.value}>
              <div className="selection-card">
                <div className="selection-header">
                  <img src={category.icon} alt="" className="selection-icon" />
                  <h3 className="selection-title">{category.label}</h3>
                </div>

                <Radio.Group
                  className="selection-radio-group"
                  onChange={(e) => handleChange(category.value, e.target.value)}
                  value={selectedValues[category.value]}
                >
                  {levels.map((option) => (
                    <Radio
                      key={option}
                      value={option}
                      className={`selection-radio ${
                        selectedValues[category.value] === option
                          ? "selected"
                          : ""
                      }`}
                    >
                      {options[option]}
                    </Radio>
                  ))}
                </Radio.Group>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const NeedsMobile = ({ categories, selectedValues, setSelectedValues }) => {
  const handleChange = (name, value) => {
    setSelectedValues((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="solyon__needs">
      <div className="solyon__needs--slides">
        {categories.map((category) => (
          <div key={category.value}>
            <div className="--item">
              <div className="--head">
                <img src={category.icon} alt="" />
                <div className="--content">
                  <span>{category.label}</span>
                  <small>
                    {category.subLabel ||
                      "(frais de séjour, frais de transport, chirurgie, etc.)"}
                  </small>
                </div>
              </div>

              <Slider
                min={1}
                max={4}
                marks={options}
                value={selectedValues[category.value]}
                onChange={(value) => handleChange(category.value, value)}
                tooltip={{ open: false }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Needs = ({ categories = [], selectedValues, setSelectedValues }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return isMobile ? (
    <NeedsMobile
      categories={categories}
      selectedValues={selectedValues}
      setSelectedValues={setSelectedValues}
    />
  ) : (
    <NeedsWeb
      categories={categories}
      selectedValues={selectedValues}
      setSelectedValues={setSelectedValues}
    />
  );
};

export default Needs;

import { get } from "lodash";
import moment from "moment";
import format from "string-template";
import {
  CONTACT_POINT_SYSTEM,
  GENDER,
  MAX_FEES_VALUE,
  REGEX_EXPS,
  SALE_MODES,
} from "utils/constants";
import { isMajor } from "utils/helpers/date";
import * as yup from "yup";
import { showExerciseFrame } from "./Forms/Activity";

const validation = (messages, { hasRia, hasPartner, checkUser }) => {
  const validateSocialSecurityNumber = function (value) {
    if (!value) return true;
    const prefix = `${
      (get(this.parent, "user.gender") === GENDER.MALE && "[17]") ||
      (get(this.parent, "user.gender") === GENDER.MALE && "[28]") ||
      "[1278]"
    }${moment(get(this.parent, "user.birthDate")).format("YY")}${moment(
      moment(get(this.parent, "user.birthDate"))
    ).format("MM")}`;
    const regexPattern = new RegExp(`^${prefix}(?:[0-9]{8}|[0-9]{10})$`);
    return new RegExp(regexPattern).test(value);
  };
  const string = yup.string().typeError(messages.NOT_STRING).nullable();
  const nameValidation = yup
    .string()
    .typeError(messages.NOT_STRING)
    .nullable()
    .notRequired()
    .test(
      "is-valid-name",
      messages.INVALID_NAME,
      (value) => !value || REGEX_EXPS.name.test(value)
    );
  const requiredString = yup
    .string()
    .typeError(messages.REQUIRED)
    .required(messages.REQUIRED);

  const relatedPersonValidationSchema = ({ birthDate }) =>
    yup.object().shape({
      identity: yup.object().shape({
        user: yup.object().shape({
          lastname: nameValidation,
          firstname: nameValidation,
          birthDate,
        }),
        socioProfessionalCategory: string,
        socialRegime: string,
        socialSecurityNumber: yup
          .string()
          .typeError(messages.REQUIRED)
          .nullable()
          .optional()
          .test(
            "securityNumberFormat",
            messages.INVALID_VALUE,
            validateSocialSecurityNumber
          ),
      }),
    });
  return yup.object().shape({
    createdDate: yup.date(),
    isPayerReceiver: yup.string().typeError(messages.NOT_STRING),
    additionalInfo: yup
      .object()
      .shape({
        brokerageFees: yup
          .number()
          .max(MAX_FEES_VALUE, `${messages.MAX} ${MAX_FEES_VALUE}`)
          .min(0, `${messages.MIN} 0`)
          .nullable()
          .optional(),
      })
      .optional(),
    project: yup.object().shape({
      projectType: yup.mixed().when("contract.saleMode", {
        is: (saleMode) => saleMode === SALE_MODES.DISTANT,
        then: string,
      }),
      contract: yup.object().shape({
        issuanceDate: yup.date(),
        caseType: string,
        saleMode: string,
      }),
      terminationRequest:
        hasRia &&
        yup.object().shape({
          sender: string,
          address: string,
          contractNumber: string,
          brokerageMandate: yup.object().shape({
            lastName: nameValidation,
            firstName: nameValidation,
            currentCompany: string,
          }),
        }),
      contact: yup.object().shape({
        user: yup.object().shape({
          gender: string,
          lastname: nameValidation,
          firstname: nameValidation,
          birthName: nameValidation,
          birthDate: yup.date(),
        }),
        socialRegime: string,
        socialSecurityNumber: yup
          .string()
          .typeError(messages.REQUIRED)
          .nullable()
          .optional()
          .test(
            "securityNumberFormat",
            messages.INVALID_VALUE,
            validateSocialSecurityNumber
          ),
        socioProfessionalCategory: string,
        politicallyExposedPerson: yup.boolean().nullable(),
        exerciseFrame: yup.mixed().when("socioProfessionalCategory", {
          is: showExerciseFrame,
          then: string,
        }),
        siretNumber: string,
        profession: requiredString,
        acceptContact: yup.boolean().typeError(messages.NOT_BOOLEAN).nullable(),
        acceptDigital: yup.boolean().typeError(messages.NOT_BOOLEAN).nullable(),
        fields: yup.object().shape({
          affiliateOrganizationCode: yup
            .string()
            .typeError(messages.NOT_STRING)
            .nullable(),
        }),
      }),
    }),
    telecoms: yup.object().shape({
      contact: yup.object().shape({
        [CONTACT_POINT_SYSTEM.STREET]: yup.object().shape({
          value: requiredString,
        }),
        [CONTACT_POINT_SYSTEM.COMPLEMENT]: yup.object().shape({
          value: string,
        }),
        [CONTACT_POINT_SYSTEM.RESIDENCE]: yup.object().shape({
          value: string,
        }),
        [CONTACT_POINT_SYSTEM.ZIP_CODE]: yup.object().shape({
          value: requiredString,
        }),
        [CONTACT_POINT_SYSTEM.CITY]: yup.object().shape({
          value: requiredString,
        }),
        [CONTACT_POINT_SYSTEM.PHONE]: yup.object().shape({
          value: yup
            .string()
            .typeError(messages.REQUIRED)
            .nullable()
            .test("isValid", messages.INVALID_VALUE, function (value) {
              return value
                ? new RegExp(REGEX_EXPS.internationalMobilePhone).test(
                    value.replace(/\s+/g, "")
                  )
                : true;
            }),
          // .test(
          //   "phoneConflict",
          //   messages.PHONE_CONFLICT,
          //   async function (value) {
          //     const isValidRegex = new RegExp(REGEX_EXPS.phone).test(value);
          //     if (!value || !isValidRegex) {
          //       return true;
          //     }
          // const {
          //   data: { user },
          // } = await checkUser({
          //   variables: {
          //     where: { phone: value },
          //   },
          // });
          // return !user?.id;
          // }
          // ),
        }),
        [CONTACT_POINT_SYSTEM.LANDLINE]: yup.object().shape({
          value: yup
            .string()
            .typeError(messages.REQUIRED)
            .nullable()
            .test("isValid", messages.INVALID_VALUE, async function (value) {
              return value ? new RegExp(REGEX_EXPS.phone).test(value) : true;
            }),
        }),
        [CONTACT_POINT_SYSTEM.EMAIL]: yup.object().shape({
          value: yup.string().nullable().email(messages.EMAIL),
          // .test(
          //   "emailConflict",
          //   messages.EMAIL_CONFLICT,
          //   async function (value) {
          //     if (!value || !yup.string().email().isValidSync(value)) {
          //       return true;
          //     }
          //     const {
          //       data: { user },
          //     } = await checkUser({
          //       variables: {
          //         where: { email: value },
          //       },
          //     });
          //     return !user?.id;
          //   }
          // ),
        }),
      }),
      payer: yup.object().shape({
        [CONTACT_POINT_SYSTEM.STREET]: yup.object().shape({
          value: string,
        }),
        [CONTACT_POINT_SYSTEM.ZIP_CODE]: yup.object().shape({
          value: string,
        }),
        [CONTACT_POINT_SYSTEM.CITY]: yup.object().shape({
          value: string,
        }),
      }),
    }),
    spouse:
      hasPartner &&
      relatedPersonValidationSchema({
        birthDate: yup
          .date()
          .typeError(messages.REQUIRED)
          .max(isMajor, messages.RADIANCE.SPOUSE_AGE)
          .min(
            moment().subtract(100, "year").startOf("year"),
            messages.RADIANCE.SPOUSE_AGE
          )
          .required(messages.REQUIRED),
      }),
    children: yup.array().of(
      yup.object().shape({
        identity: yup.object().shape({
          user: yup.object().shape({
            lastname: nameValidation,
            firstname: nameValidation,
            birthDate: yup
              .date()
              .typeError(messages.REQUIRED)
              .max(moment(), messages.MIN_TODAY)
              .required(messages.REQUIRED),
          }),
          socioProfessionalCategory: string,
          socialRegime: string,
        }),
      })
    ),
    payment: yup.object().shape({
      type: string,
      frequency: string,
      payer: yup.object().shape({
        BIC: string,
        IBAN: yup
          .string()
          .typeError(messages.NOT_STRING)
          .max(34, format(messages.MAX_LENGTH, { length: 34 }))
          .matches(
            /^(fr|cz|nl)/i,
            format(messages.INVALID_PREFIX, { prefixes: '"FR", "CZ" or "NL"' })
          )
          .nullable(),
        owner: yup.object().shape({
          isAdherent: yup.boolean().typeError(messages.NOT_BOOLEAN),
          firstName: nameValidation,
          lastName: nameValidation,
        }),
      }),
      receiver: yup.object().shape({
        BIC: string,
        IBAN: yup
          .string()
          .typeError(messages.NOT_STRING)
          .max(34, format(messages.MAX_LENGTH, { length: 34 }))
          .matches(
            /^(fr|cz|nl)/i,
            format(messages.INVALID_PREFIX, { prefixes: '"FR", "CZ" or "NL"' })
          )
          .nullable(),
        owner: yup.object().shape({
          firstName: nameValidation,
          lastName: nameValidation,
        }),
      }),
    }),
  });
};

export default validation;

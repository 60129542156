import { useQuery, useSubscription } from "@apollo/client";
import { Popover, Table } from "antd";
import cuid from "cuid";
import {
  find,
  first,
  get,
  intersection,
  isEmpty,
  last,
  filter as lodashFilter,
  sortBy,
} from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsViewList } from "react-icons/bs";
import { IoDuplicateOutline } from "react-icons/io5";
import {
  MdDeleteOutline,
  MdOpenInNew,
  MdOutlineEventBusy,
  MdOutlineLock,
  MdOutlineLockOpen,
} from "react-icons/md";
import Avatar from "shared/components/Avatar";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Pagination from "shared/components/Pagination";
import Select from "shared/components/Select";

import Status from "shared/components/Status";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import graphql from "utils/api/graphql";
import { QUALIFICATIONS } from "utils/api/graphql/queries/qualifications";
import { USERS } from "utils/api/graphql/queries/users";
import {
  ACTIONS,
  DATE_TIME_FORMAT,
  PROCESS_TAGS as PROCESS_TAGS_STATUSES,
  PROCESS_TYPES,
  SUBSCRIPTION_STATUSES,
  THEMES_PALETTE,
  USER_TYPES,
} from "utils/constants";

const LOCK_ICON = {
  [true]: <MdOutlineLock size={24} color={COLORS.C_GRAY} />,
  [false]: <MdOutlineLockOpen size={24} color={COLORS.C_GRAY} />,
};

const ProjectsTable = ({
  count,
  data,
  setValues,
  type,
  skip,
  duplicate,
  onDelete,
  onDetail,
  onAssign,
  isAssigning,
  setTracer,
  showDrawerSubscription,
  showDrawerSign,
  tracers,
  setContactId,
  setProject,
  setOpen,
  onChangeStatus,
  actions,
  pagination,
}) => {
  const allFieldsNull = useMemo(
    () =>
      data.every(
        (item) =>
          !get(
            get(THEMES_PALETTE, get(item, "fields.processTemplate", "DEFAULT")),
            "NAME"
          )
      ),
    []
  );
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const FILTER = t("FILTER", { returnObjects: true });
  const LIST = t("LIST", { returnObjects: true });
  const PROCESS_TAGS = t("PROCESS_TAGS", { returnObjects: true });
  const viewContactCard = (contactId, project) => {
    const status = project.statuses.find(
      ({ status }) => get(status, "process.type") === PROCESS_TYPES.B2B
    );
    const tracer = tracers.find(
      ({ flow }) => flow.process.id === get(status, "status.process.id")
    );
    setTracer(tracer);
    setContactId(contactId);
    setProject(project);
    setOpen(true);
  };
  const [qualifiers, setQualifiers] = useState({});
  const [isReady, setIsReady] = useState(false);
  const contactIds = useMemo(() => {
    if (!data) return [];
    return data.map(({ contact }) => contact?.id).filter(Boolean);
  }, [data]);
  const { refetch } = useQuery(QUALIFICATIONS, {
    variables: {
      withDeleted: true,
      where: {
        AND: [
          { last: true },
          { OR: contactIds.map((id) => ({ contact: { id } })) },
        ],
      },
    },
    onCompleted: ({ qualifications }) => {
      const newQualifiers = {};
      qualifications?.data.forEach(({ qualifier, contact }) => {
        newQualifiers[contact?.id] = qualifier?.name;
      });

      setQualifiers(newQualifiers);
    },
  });

  useSubscription(graphql.subscriptions.QUALIFICATION_ADDED, {
    skip: !isReady || contactIds.length === 0,
    variables: {
      withDeleted: true,
      where: {
        AND: [
          { last: true },
          { OR: contactIds.map((id) => ({ contact: { id } })) },
        ],
      },
    },
    onData: ({ data: { data } }) => {
      setQualifiers((prev) => ({
        ...prev,
        [data?.qualificationAdded?.contact?.id]:
          data?.qualificationAdded?.qualifier?.name,
      }));

      refetch();
    },
  });

  useEffect(() => {
    if (data) setIsReady(true);
  }, [data]);

  const columns = [
    {
      width: 48,
      // responsive: ["sm"],
      dataIndex: "contact",
      render: (contact) => {
        return (
          <>
            <Avatar
              mode={"text"}
              size={"medium"}
              value={`${get(contact, "user.firstname", "")?.substring(
                0,
                1
              )}${get(contact, "user.lastname", "")?.substring(0, 1)}`}
            />
          </>
        );
      },
      fixed: true,
    },
    {
      title: LIST.CLIENT,
      dataIndex: "contact",
      render: (contact, project) => (
        <div className="d-flex">
          <div
            className="name--client"
            onClick={() => viewContactCard(get(contact, "id"), project)}
          >
            {`${get(contact, "user.firstname", "")} ${get(
              contact,
              "user.lastname",
              ""
            )}`}
          </div>
        </div>
      ),
      fixed: true,
    },
    ...(allFieldsNull
      ? []
      : [
          {
            title: LIST.OFFER,
            dataIndex: "fields",
            render: (fields) => {
              const text = get(
                get(THEMES_PALETTE, fields.processTemplate, "DEFAULT"),
                "NAME"
              );
              return text ? (
                <Status
                  status="gray"
                  style={{
                    color: get(
                      get(THEMES_PALETTE, fields.processTemplate, "DEFAULT"),
                      "COLOR"
                    ),
                    background: get(
                      get(THEMES_PALETTE, fields.processTemplate, "DEFAULT"),
                      "BACKGROUND"
                    ),
                  }}
                  text={text}
                />
              ) : null;
            },
          },
        ]),
    {
      title: LIST.STATUS,
      dataIndex: "statuses",
      render: (record, project, i) => {
        const status = project.statuses.find(
          ({ status }) => get(status, "process.type") === PROCESS_TYPES.B2B
        );
        const tracer = tracers.find(
          ({ flow }) => flow.process.id === get(status, "status.process.id")
        );

        const currentStatus = get(
          last(
            sortBy(
              lodashFilter(
                record,
                ({ status }) => status?.process?.type === PROCESS_TYPES.B2B
              ),
              "createdDate"
            )
          ),
          "status"
        );

        const statusText =
          project.expired && currentStatus?.tag !== PROCESS_TAGS_STATUSES.CLOSE
            ? PROCESS_TAGS.EXPIRED
            : get(currentStatus, "name", PROCESS_TAGS.IN_PROGRESS);

        const statusesOptions = get(tracer, "flow.process.processStatuses", [])
          .filter(({ tag }) =>
            [
              PROCESS_TAGS_STATUSES.SIGNED,
              PROCESS_TAGS_STATUSES.CLOSE,
            ].includes(tag)
          )
          .map((status) => {
            return {
              label: get(status, "name"),
              value: get(status, "id"),
              onClick:
                status.tag === PROCESS_TAGS_STATUSES.SIGNED
                  ? () =>
                      showDrawerSign({
                        id: project.id,
                        statuses: project.statuses,
                      })
                  : () =>
                      onChangeStatus({
                        project,
                        status,
                      }),
              disabled:
                status.tag === PROCESS_TAGS_STATUSES.SIGNED && !project.locked,
              popover:
                status.tag === PROCESS_TAGS_STATUSES.SIGNED && !project.locked
                  ? LIST.STATUS_POPOVER
                  : "",
            };
          });

        return ![PROCESS_TAGS_STATUSES.SIGNED].includes(
          get(currentStatus, "tag")
        ) ? (
          <div className="select_200">
            <Select
              popupMatchSelectWidth={false}
              type="tag"
              value={statusText}
              options={statusesOptions}
            />
          </div>
        ) : (
          <Status
            type="custom"
            style={{
              color: get(currentStatus, "color"),
              background: get(currentStatus, "background"),
            }}
            text={statusText}
          />
        );
      },
    },
    {
      title: LIST.LAST_ACTION,
      dataIndex: "contact",
      render: (contact) => (
        <div style={{ minWidth: 150 }} className="text-sec">
          {qualifiers[contact?.id] || ""}{" "}
        </div>
      ),
    },
    {
      title: LIST.CREATION_DATE,
      dataIndex: "createdDate",
      render: (record) => moment(record).format(DATE_TIME_FORMAT),
    },
    {
      title: LIST.ADVISOR,
      dataIndex: "author",
      render: (record, project) => {
        const status = project.statuses.find(
          ({ status }) => get(status, "process.type") === PROCESS_TYPES.B2B
        );
        const tracer = tracers.find(
          ({ flow }) => flow.process.id === get(status, "status.process.id")
        );
        const currentStatus = get(
          find(
            project.statuses,
            ({ status }) =>
              status?.process?.id === get(tracer, "flow.process.id")
          ),
          "status"
        );
        return (
          <div className="select_200">
            <Select
              type="async"
              value={record?.id}
              node={{
                query: USERS,
                variables: {
                  where: {
                    AND: [
                      { isActive: true },
                      {
                        OR: [
                          {
                            meta: { type: USER_TYPES.INTERNAL_ADVISOR.type },
                          },
                          {
                            meta: {
                              type: USER_TYPES.INTERNAL_SUPERVISOR.type,
                            },
                          },
                          { meta: { type: USER_TYPES.BROKER_EMPLOYEE.type } },
                          { meta: { type: USER_TYPES.BROKER_ADMIN.type } },
                          {
                            meta: {
                              type: USER_TYPES.BROKER_REPRESENTATIVE.type,
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
                accessors: {
                  root: "users.data",
                  label: {
                    separator: " ",
                    keys: ["firstname", "lastname"],
                  },
                },
              }}
              placeholder={FILTER.BROKER}
              onChange={(value) =>
                onAssign({ projectId: project.id, authorId: value })
              }
              disabled={
                isAssigning[project?.id] ||
                currentStatus?.tag === PROCESS_TAGS_STATUSES.SIGNED
              }
              loading={isAssigning[project?.id]}
            />
          </div>
        );
      },
    },
    {
      width: 62,
      dataIndex: "locked",
      align: "right",
      render: (record, row) => (
        <div className="d-flex justify--end">
          {row.expired && (
            <Popover content={LIST.EXPIRED_POPOVER}>
              <MdOutlineEventBusy size={24} color={COLORS.C_DANGER} />
            </Popover>
          )}
          {record && (
            <Popover
              content={row.expired ? LIST.EXPIRED_LOCKED : LIST.LOCKED_POPOVER}
            >
              {LOCK_ICON[record]}
            </Popover>
          )}
        </div>
      ),
    },
    ...(actions[ACTIONS.UPDATE_SUBSCRIPTION] ||
    actions[ACTIONS.DELETE_PROJECT] ||
    actions[ACTIONS.DUPLICATE_PROJECT] ||
    actions[ACTIONS.UPDATE_PROJECT]
      ? [
          {
            title: LIST.ACTIONS,
            dataIndex: "id",
            align: "right",
            render: (id, project) => {
              const status = project.statuses.find(
                ({ status }) =>
                  get(status, "process.type") === PROCESS_TYPES.B2B
              );
              const tracer = tracers.find(
                ({ flow }) =>
                  flow.process.id === get(status, "status.process.id")
              );
              const currentStatus = get(
                find(
                  project.statuses,
                  ({ status }) =>
                    status?.process?.id === get(tracer, "flow.process.id")
                ),
                "status"
              );
              const disableDetail =
                currentStatus?.tag === PROCESS_TAGS_STATUSES.CLOSE;
              const isSigned = get(project, "subscriptions", [])?.some(
                (subscription) =>
                  get(subscription, "status") === SUBSCRIPTION_STATUSES.SIGNED
              );
              return (
                get(project, "author") && (
                  <div className="d-flex justify--end">
                    {!isEmpty(get(project, "subscriptions", [])) &&
                      intersection(
                        [
                          PROCESS_TAGS_STATUSES.CLOSE,
                          PROCESS_TAGS_STATUSES.EXPIRED,
                          PROCESS_TAGS_STATUSES.SIGNED,
                        ],
                        get(project, "statuses", []).map(
                          ({ status: { tag } }) => tag
                        )
                      ).length === 0 &&
                      actions[ACTIONS.UPDATE_SUBSCRIPTION] &&
                      !project?.expired && (
                        <Button
                          type="primary--light"
                          icon={<BsViewList />}
                          onClick={() => showDrawerSubscription(id)}
                        >
                          {LIST.RESUME_SUBSCRIPTION}
                        </Button>
                      )}

                    {actions[ACTIONS.DUPLICATE_PROJECT] && (
                      <Popover content={LIST.DUPLICATE}>
                        <IoDuplicateOutline
                          size={24}
                          onClick={() => {
                            const status = project.statuses.find(
                              ({ status }) =>
                                get(status, "process.type") ===
                                PROCESS_TYPES.B2B
                            );
                            const tracer = tracers.find(
                              ({ flow }) =>
                                flow.process.id ===
                                get(status, "status.process.id")
                            );
                            setTracer(tracer);
                            duplicate({
                              variables: {
                                where: { id },
                                data: {
                                  visible: true,
                                  statuses: [
                                    {
                                      id: cuid(),
                                      status: {
                                        id: get(
                                          first(
                                            sortBy(
                                              get(
                                                tracer,
                                                "flow.process.processStatuses"
                                              ),
                                              "order"
                                            )
                                          ),
                                          "id"
                                        ),
                                      },
                                    },
                                  ],
                                },
                              },
                            });
                          }}
                        />
                      </Popover>
                    )}

                    {actions[ACTIONS.DELETE_PROJECT] && (
                      <Popover content={LIST.DELETE}>
                        <MdDeleteOutline
                          color={
                            isSigned
                              ? COLORS.C_DANGER_BACKGROUND
                              : COLORS.C_DANGER
                          }
                          size={24}
                          onClick={() => (isSigned ? null : onDelete(id))}
                        />
                      </Popover>
                    )}
                    {actions[ACTIONS.PROJECT] &&
                      actions[ACTIONS.UPDATE_PROJECT] && (
                        <Popover content={LIST.ACCESS}>
                          <MdOpenInNew
                            color={
                              disableDetail || project.expired
                                ? COLORS.C_PRIMARY_BACKGROUND
                                : COLORS.C_PRIMARY
                            }
                            size={24}
                            onClick={() =>
                              disableDetail || project.expired
                                ? null
                                : onDetail(project)
                            }
                          />
                        </Popover>
                      )}
                  </div>
                )
              );
            },
          },
        ]
      : []),
  ];

  return (
    <Card type={"simple"}>
      <Table
        dataSource={data}
        columns={columns}
        className="table__wrapper"
        rowKey={"id"}
        pagination={false}
        scroll={{ x: true }}
        tableLayout="auto"
      />
      <Pagination
        footprint={type}
        count={count}
        setValues={setValues}
        skip={skip || 0}
        take={pagination.take}
      />
    </Card>
  );
};

export default ProjectsTable;

import cuid from "cuid";
import { defaultTo, get, head, isEmpty } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useCallback, useState } from "react";
import { useMutation, useQuery } from "shared/hooks/useApi";
import { UPDATE_SUBSCRIPTION } from "utils/api/graphql/mutations/subscription";
import { SUBSCRIPTION } from "utils/api/graphql/queries/subscription";
import { BANK_ACCOUNT_OWNER_TYPE, PAYMENT_TYPES } from "utils/constants";
import Loader from "../Components/Loader";
import Navbar from "../Components/Navbar/Navbar";
import View from "./View";

const BankInformation = ({
  project,
  onNext,
  onBack,
  updateUrl,
  currentStatus,
  tracer,
}) => {
  const [initialValues, setInitialValues] = useState(null);
  const subscriptionId =
    localStorage.getItem("subscriptionId") ||
    get(project, "fields.currentSubscription");
  // const [generateSubscriptionDocuments, { loading: generateLoading }] =
  //   useMutation(GENERATE_SUBSCRIPTION_DOCUMENTS);

  const initSubscription = useCallback(({ subscription: data }) => {
    const subscription = omitDeep(data, "__typename");
    const shouldBeAdherent =
      get(subscription, "payment.payer.owner.isAdherent", true) ||
      get(subscription, "insurancePlan.fields.payment.shouldBeAdherent", false);
    const initialValues = {
      payment: {
        id: cuid(),
        type:
          head(get(subscription, "insurancePlan.fields.payment.type")) ||
          PAYMENT_TYPES.DEBIT,
        frequency: null,
        debitDay: null,
        ...subscription.payment,
        payer: {
          id: cuid(),
          IBAN: null,
          BIC: null,
          ...subscription.payment?.payer,
          owner: {
            id: cuid(),
            ...subscription.payment?.payer?.owner,
            firstName: defaultTo(
              subscription.payment?.payer?.owner?.firstName,
              shouldBeAdherent
                ? get(subscription, "project.contact.user.firstname")
                : null
            ),
            lastName: defaultTo(
              subscription.payment?.payer?.owner?.lastName,
              shouldBeAdherent
                ? get(subscription, "project.contact.user.lastname")
                : null
            ),
            isAdherent: defaultTo(
              subscription.payment?.payer?.owner?.isAdherent,
              shouldBeAdherent
            ),
            type: get(subscription, "insurancePlan.fields.tns")
              ? BANK_ACCOUNT_OWNER_TYPE.COMPANY
              : BANK_ACCOUNT_OWNER_TYPE.PARTICULAR,
          },
        },
      },
    };
    setInitialValues(initialValues);
  }, []);

  const { data, loading } = useQuery(SUBSCRIPTION, {
    variables: { where: { id: subscriptionId } },
    onCompleted: initSubscription,
  });
  const [updateSubscription] = useMutation(UPDATE_SUBSCRIPTION, {
    refetchQueries: [
      {
        query: SUBSCRIPTION,
        awaitRefetchQueries: true,
        variables: { where: { id: subscriptionId } },
      },
    ],
  });
  const onSubmit = ({ payment }) => {
    updateSubscription({
      variables: {
        where: {
          id: subscriptionId,
        },
        data: {
          payment: {
            ...omitDeep(payment, "__typename"),
            receiver: get(payment, "payer"),
          },
        },
      },
      onCompleted: (_) => {
        // await generateSubscriptionDocuments({
        //   variables: {
        //     data: { id: subscriptionId },
        //   },
        // });
        const source = localStorage.getItem("source");
        updateUrl({
          subscriptionId,
          url: get(
            data,
            "subscription.insurancePlan.config.generatedUrl",
            `${process.env.REACT_APP_BASE_URL}/#/public/process/init/${source}`
          ),
        });
        onNext({
          payload: {},
        });
      },
    });
  };

  if (loading || isEmpty(initialValues)) return <Loader />;

  return (
    <>
      <Navbar
        project={project}
        progress={currentStatus?.progress}
        tracer={tracer}
        showCart
      />
      <View
        subscription={omitDeep(get(data, "subscription", {}), "__typename")}
        onNext={onSubmit}
        onBack={onBack}
        initialValues={initialValues}
        // loading={generateLoading}
      />
    </>
  );
};

export default BankInformation;

import { find, get, isEmpty } from "lodash";
import moment from "moment";
import { useMemo } from "react";
import { BiIdCard } from "react-icons/bi";
import {
  MdOutlineCake,
  MdOutlineDiversity1,
  MdOutlineFace,
  MdOutlineHandshake,
  MdOutlineVerifiedUser,
} from "react-icons/md";
import { RiCalendarEventLine } from "react-icons/ri";
import { useMediaQuery } from "react-responsive";
import {
  CONTACT_POINT_SYSTEM,
  CURRENCY_SYMBOL,
  DATE_FORMAT,
  RELATIONSHIP,
  TEMPLATE_TYPES,
  TEMPLATES,
} from "utils/constants";
import Cost from "../Components/Cost";
import Header from "../Components/Header";
import DocumentDownload from "./DocumentDownload";

const NUMBERS_MAPPING = {
  1: "Premier",
  2: "Deuxième",
  3: "Troisième",
  4: "Quatrième",
  5: "Cinquième",
  6: "Sixième",
  7: "Septième",
  8: "Huitième",
  9: "Neuvième",
  10: "Dixième",
  11: "Onzième",
  12: "Douzième",
};

function View({
  subscription,
  generalCost,
  updateSponsorship,
  attachments,
  downloadFile,
}) {
  const totalCost = useMemo(
    () =>
      get(
        subscription,
        `project.fields.upsells.${get(subscription, "insurancePlan.id")}`,
        []
      )
        ?.reduce((acc, { cost }) => (acc += cost), get(generalCost, "cost", 0))
        ?.toFixed(2),
    []
  );
  const EDIT_BLOCK = [
    {
      icon: <MdOutlineFace size={24} />,
      label: "Vous êtes",
      value: `${get(subscription, "project.contact.user.firstname")} ${get(
        subscription,
        "project.contact.user.lastname"
      )}`,
      template: TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].ADHERENT_INFO,
      isBeforeLocked: true,
    },
    {
      icon: <MdOutlineCake size={24} />,
      label: "Date de naissance",
      value: `${moment(
        get(subscription, "project.contact.user.birthDate")
      ).format(DATE_FORMAT)}`,
    },
    {
      icon: <BiIdCard size={24} />,
      label: "Adresse postale",
      value: (
        <>
          {get(
            find(get(subscription, "project.contact.telecoms"), {
              system: CONTACT_POINT_SYSTEM.ADDRESS,
            }),
            "value"
          )}
          <br />
          {`${get(
            find(get(subscription, "project.contact.telecoms"), {
              system: CONTACT_POINT_SYSTEM.CITY,
            }),
            "value"
          )} - ${get(
            find(get(subscription, "project.contact.telecoms"), {
              system: CONTACT_POINT_SYSTEM.ZIP_CODE,
            }),
            "value"
          )}`}
        </>
      ),
      template:
        TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].ADHERENT_TELECOMS,
    },
    ...(!isEmpty(get(subscription, "project.contact.relatedPersons", []))
      ? [
          {
            icon: <MdOutlineDiversity1 size={24} />,
            label: "Vos bénéficiaires",
            value: [
              get(subscription, "project.contact.relatedPersons", [])
                .filter(
                  ({ relationship }) => relationship === RELATIONSHIP.SPOUSE
                )
                .map(({ identity }, i) => (
                  <div>
                    {`${get(identity, "user.firstname") || ""} ${
                      get(identity, "user.lastname") || ""
                    }`}{" "}
                    <span>{"(Votre conjoint)"}</span>
                  </div>
                )),
              get(subscription, "project.contact.relatedPersons", [])
                .filter(
                  ({ relationship }) => relationship === RELATIONSHIP.CHILD
                )
                .map(({ identity }, i) => (
                  <div>
                    {`${get(identity, "user.firstname") || ""} ${
                      get(identity, "user.lastname") || ""
                    }`}{" "}
                    <span>{`(Votre ${NUMBERS_MAPPING[i + 1]} enfant)`}</span>
                  </div>
                )),
            ],
            template:
              TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].RELATED_PERSONS,
          },
        ]
      : []),
  ];

  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div className="solyon">
      <div className="solyon__container">
        <div className="solyon__wrapper">
          <Header
            title="Adhésion réussie ! Tous les documents vous ont été envoyés par e-mail"
            highlight="Adhésion réussie !"
            subtitle={<MdOutlineVerifiedUser size={48} />}
          />
          <div className="solyon__body">
            <div className="solyon-recap">
              <div className="solyon-recap__col">
                {!isMobile && <div className="solyon-recap__title">Offre</div>}
                <div className="solyon-product-card">
                  <img
                    className="solyon-product-card__icon"
                    alt={get(subscription, "insurancePlan.name")}
                    src={get(subscription, "insurancePlan.icon")}
                  />
                  <Cost
                    cost={totalCost}
                    currency={get(generalCost, "currency")}
                    showYearly
                  />
                </div>
                <div className="solyon-recap__prices">
                  <div className="solyon-recap__title">Inclut dans le prix</div>
                  <div className="solyon-recap__prices--row">
                    <div>{get(generalCost, "insurancePlan.name")}</div>
                    <span>{`${generalCost?.cost || ""} ${
                      CURRENCY_SYMBOL[generalCost?.currency] || ""
                    }`}</span>
                  </div>
                  {get(
                    subscription,
                    `project.fields.upsells.${get(
                      subscription,
                      "insurancePlan.id"
                    )}`,
                    []
                  ).map((module) => (
                    <div className="solyon-recap__prices--row">
                      <div>{get(module, "insurancePlan.name")}</div>
                      <span>{`${module?.cost} ${
                        CURRENCY_SYMBOL[module?.currency]
                      }`}</span>
                    </div>
                  ))}
                </div>

                {get(subscription, "additionalInfo.sponsor.firstName") && (
                  <div className="solyon-recap__partnership">
                    <div className="solyon-recap__partnership--row">
                      <MdOutlineHandshake size={24} />
                      <span className="solyon-recap__partnership--row--grow">
                        Parrain So’lyon
                      </span>
                      <div className="solyon-recap__partnership--row--value">
                        {`${get(
                          subscription,
                          "additionalInfo.sponsor.firstName",
                          ""
                        )} ${get(
                          subscription,
                          "additionalInfo.sponsor.lastName",
                          ""
                        )}`}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="solyon-recap__col">
                <div className="solyon-recap__title">
                  Informations de l’adhérent
                </div>
                <div className="solyon-recap__blocks">
                  {EDIT_BLOCK.map(({ icon, label, value }) => (
                    <div className="solyon-recap__edit" key={label}>
                      {icon}
                      <div className="solyon-recap__edit--text">
                        <span>{label}</span>
                        <div className="solyon-recap__edit--text--value">
                          {value}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="solyon-recap__title p-top-16">
                  Date d’effet{" "}
                </div>
                <div className="solyon-recap__edit">
                  <RiCalendarEventLine size={24} />
                  <div className="solyon-recap__edit--text">
                    <span>Date d’effet</span>
                    <div className="solyon-recap__edit--text--value">
                      {moment(
                        get(subscription, "project.contract.issuanceDate")
                      ).format(DATE_FORMAT)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="solyon-recap__col v-center">
                <div className="solyon-recap__title">
                  Documents précontractuels
                </div>

                <DocumentDownload
                  title={"Vos documents pré-contractuels"}
                  items={attachments}
                  download={(id) => downloadFile(id)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default View;

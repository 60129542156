import { Col, Row } from "antd";
import classNames from "classnames";
import cuid from "cuid";
import { useFormik } from "formik";
import { defaultTo, get, isEmpty } from "lodash";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import {
  BANK_ACCOUNT_OWNER_TYPE,
  DEBIT_POSSIBLE_DATES,
  PAYMENT_FREQUENCIES,
} from "utils/constants";
import { bindInputProps, setDefaultIfEmpty } from "utils/helpers/input";
import Button from "../Components/Button";
import InputChecked from "../Components/svg/check_small.svg";
import InputError from "../Components/svg/error.svg";
import validation from "./validation";

const View = ({ locked, onSubmit, subscription, initialValues }) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("B2C_LMF.PAYMENT", { returnObjects: true });
  useEffect(() => {
    if (get(initialValues, "payment.payer.owner.isAdherent"))
      PAYMENT_PAYER[false]();
  }, []);

  const { isValid, handleSubmit, setFieldValue, values, ...formProps } =
    useFormik({
      initialValues,
      onSubmit: onSubmit,
      validationSchema: validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, {
          returnObjects: true,
        })
      ),
    });

  const PAYMENT_PAYER = {
    [true]: () => {
      if (locked) return;
      setFieldValue("payment.payer.owner", {
        id: get(values, "payment.payer.owner.id", cuid()),
        isAdherent: false,
        type: BANK_ACCOUNT_OWNER_TYPE.PARTICULAR,
      });
      setFieldValue("payment.payer.owner.telecoms", []);
    },
    [false]: () => {
      if (locked) return;
      setFieldValue("payment.payer.owner", {
        id: get(values, "payment.payer.owner.id", cuid()),
        firstName: get(values, "contact.user.firstname"),
        lastName: get(values, "contact.user.lastname"),
        isAdherent: true,
        type: BANK_ACCOUNT_OWNER_TYPE.PARTICULAR,
      });
      setFieldValue(
        "payment.payer.owner.telecoms",
        get(subscription, "project.contact.telecoms", [])
      );
    },
  };
  const PAYMENT_RECEIVER_VALUES = {
    [true]: (payer) => {
      setFieldValue("payment.receiver", payer);
    },
    [false]: () => {
      setFieldValue(
        "payment.receiver",
        defaultTo(get(values, "payment.receiver"), {
          id: cuid(),
          owner: {
            id: cuid(),
          },
        })
      );
    },
  };
  return (
    <>
      <h1 className="header-title">{TRANSLATION.TITLE}</h1>
      <span
        className="header-description"
        dangerouslySetInnerHTML={{ __html: TRANSLATION.DESCRIPTION }}
      ></span>
      <div className="__LMF--content LMF-content">
        <div className="payment__section">
          <div
            className={classNames({
              blur: get(values, "payment.payer.owner.isAdherent") === false,
            })}
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Input
                  label={TRANSLATION.FORM.LAST_NAME.LABEL}
                  placeholder={TRANSLATION.FORM.LAST_NAME.PLACEHOLDER}
                  value={get(values, "contact.user.lastname")}
                  readOnly={true}
                  className={classNames({
                    valid: true,
                  })}
                  suffix={<img src={InputChecked} />}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Input
                  label={TRANSLATION.FORM.FIRST_NAME.LABEL}
                  placeholder={TRANSLATION.FORM.FIRST_NAME.PLACEHOLDER}
                  value={get(values, "contact.user.firstname")}
                  className={classNames({
                    valid: true,
                  })}
                  suffix={<img src={InputChecked} />}
                  readOnly={true}
                />
              </Col>
            </Row>
          </div>
          <Input
            type="checkbox"
            label={TRANSLATION.FORM.PAYER_ACCOUNT.LABEL}
            {...bindInputProps({
              name: "payment.payer.owner.isAdherent",
              values,
              ...formProps,
            })}
            checked={get(values, "payment.payer.owner.isAdherent") === false}
            onChange={({ target: { checked } }) => PAYMENT_PAYER[checked]()}
            disabled={locked}
          />
          {get(values, "payment.payer.owner.isAdherent") === false && (
            <div className="payment__section--payer-container">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Input
                    label={TRANSLATION.FORM.LAST_NAME.LABEL}
                    placeholder={TRANSLATION.FORM.LAST_NAME.PLACEHOLDER}
                    {...bindInputProps({
                      name: `payment.payer.owner.lastName`,
                      values,
                      ...formProps,
                    })}
                    onChange={({ target: { value } }) => {
                      setFieldValue("payment.payer.owner.lastName", value);
                      PAYMENT_RECEIVER_VALUES[get(values, "isPayerReceiver")]({
                        ...get(values, "payment.payer"),
                        owner: {
                          ...get(values, "payment.payer.owner"),
                          lastName: value,
                        },
                      });
                    }}
                    className={classNames({
                      valid: get(values, "payment.payer.owner.lastName"),
                    })}
                    suffix={
                      get(formProps.errors, `payment.payer.owner.lastName`) ? (
                        <img src={InputError} />
                      ) : (
                        <img src={InputChecked} />
                      )
                    }
                    disabled={locked}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Input
                    label={TRANSLATION.FORM.FIRST_NAME.LABEL}
                    placeholder={TRANSLATION.FORM.FIRST_NAME.PLACEHOLDER}
                    {...bindInputProps({
                      name: `payment.payer.owner.firstName`,
                      values,
                      ...formProps,
                    })}
                    onChange={({ target: { value } }) => {
                      setFieldValue("payment.payer.owner.firstName", value);
                      PAYMENT_RECEIVER_VALUES[get(values, "isPayerReceiver")]({
                        ...get(values, "payment.payer"),
                        owner: {
                          ...get(values, "payment.payer.owner"),
                          firstName: value,
                        },
                      });
                    }}
                    className={classNames({
                      valid: get(values, "payment.payer.owner.firstName"),
                    })}
                    suffix={
                      get(formProps.errors, `payment.payer.owner.firstName`) ? (
                        <img src={InputError} />
                      ) : (
                        <img src={InputChecked} />
                      )
                    }
                    disabled={locked}
                  />
                </Col>
              </Row>
            </div>
          )}
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Select
                options={setDefaultIfEmpty(
                  get(subscription, "insurancePlan.fields.payment.frequency"),
                  Object.values(PAYMENT_FREQUENCIES)
                ).map((value) => ({
                  value,
                  label: TRANSLATION.FORM.PAYMENT_FREQUENCY.OPTIONS[value],
                }))}
                label={TRANSLATION.FORM.PAYMENT_FREQUENCY.LABEL}
                placeholder={TRANSLATION.FORM.PAYMENT_FREQUENCY.PLACEHOLDER}
                {...bindInputProps({
                  name: "payment.frequency",
                  values,
                  ...formProps,
                })}
                className={classNames({
                  valid: get(values, "payment.frequency"),
                })}
                disabled={locked}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Select
                options={setDefaultIfEmpty(
                  get(subscription, "insurancePlan.fields.payment.debitDay"),
                  DEBIT_POSSIBLE_DATES
                ).map((value) => ({
                  value,
                  label: value,
                }))}
                label={TRANSLATION.FORM.DEBIT_DAY.LABEL}
                placeholder={TRANSLATION.FORM.DEBIT_DAY.PLACEHOLDER}
                {...bindInputProps({
                  name: "payment.debitDay",
                  values,
                  ...formProps,
                })}
                className={classNames({
                  valid: get(values, "payment.debitDay"),
                })}
                disabled={locked}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Input
                label={TRANSLATION.FORM.IBAN.LABEL}
                placeholder={TRANSLATION.FORM.IBAN.PLACEHOLDER}
                {...bindInputProps({
                  name: `payment.payer.IBAN`,
                  values,
                  ...formProps,
                })}
                onChange={({ target: { value } }) => {
                  setFieldValue("payment.payer.IBAN", value);
                  PAYMENT_RECEIVER_VALUES[get(values, "isPayerReceiver")]({
                    ...get(values, "payment.payer"),
                    IBAN: value,
                  });
                }}
                className={classNames({
                  valid: get(values, "payment.payer.IBAN"),
                })}
                suffix={
                  get(formProps.errors, `payment.payer.IBAN`) ? (
                    <img src={InputError} />
                  ) : (
                    <img src={InputChecked} />
                  )
                }
                disabled={locked}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Input
                label={TRANSLATION.FORM.BIC.LABEL}
                placeholder={TRANSLATION.FORM.BIC.PLACEHOLDER}
                {...bindInputProps({
                  name: `payment.payer.BIC`,
                  values,
                  ...formProps,
                })}
                onChange={({ target: { value } }) => {
                  setFieldValue("payment.payer.BIC", value);
                  PAYMENT_RECEIVER_VALUES[get(values, "isPayerReceiver")]({
                    ...get(values, "payment.payer"),
                    BIC: value,
                  });
                }}
                className={classNames({
                  valid: get(values, "payment.payer.BIC"),
                })}
                suffix={
                  get(formProps.errors, `payment.payer.BIC`) ? (
                    <img src={InputError} />
                  ) : (
                    <img src={InputChecked} />
                  )
                }
                disabled={locked}
              />
            </Col>
          </Row>
          <div className="payment__section--refund-container">
            <div className="payment__section--refund-container--action">
              <div className="payment__section--refund-container--action__left">
                <span>{TRANSLATION.REFUND_ACCOUNT.TITLE}</span>
              </div>
              <div className="payment__section--refund-container--right">
                <div className="d-flex">
                  <Button
                    size="middle"
                    type={classNames({
                      primary: get(values, "isPayerReceiver") === true,
                      white: get(values, "isPayerReceiver", false) === false,
                    })}
                    onClick={() => {
                      if (locked) return;
                      setFieldValue("isPayerReceiver", true);
                      setFieldValue(
                        "payment.receiver",
                        get(values, "payment.payer")
                      );
                    }}
                  >
                    {TRANSLATION.YES}
                  </Button>
                  <Button
                    size="middle"
                    type={classNames({
                      primary: get(values, "isPayerReceiver") === false,
                      white: get(values, "isPayerReceiver", true) === true,
                    })}
                    onClick={() => {
                      if (locked) return;
                      setFieldValue("isPayerReceiver", false);
                      setFieldValue("payment.receiver", {
                        id: cuid(),
                        owner: {
                          id: cuid(),
                        },
                      });
                    }}
                  >
                    {TRANSLATION.NO}
                  </Button>
                </div>
              </div>
            </div>
            {get(values, "isPayerReceiver") === false && (
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Input
                    label={TRANSLATION.FORM.LAST_NAME.LABEL}
                    placeholder={TRANSLATION.FORM.LAST_NAME.PLACEHOLDER}
                    {...bindInputProps({
                      name: `payment.receiver.owner.lastName`,
                      values,
                      ...formProps,
                    })}
                    className={classNames({
                      valid: get(values, "payment.receiver.owner.lastName"),
                    })}
                    suffix={
                      get(
                        formProps.errors,
                        `payment.receiver.owner.lastName`
                      ) ? (
                        <img src={InputError} />
                      ) : (
                        <img src={InputChecked} />
                      )
                    }
                    disabled={locked}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Input
                    label={TRANSLATION.FORM.FIRST_NAME.LABEL}
                    placeholder={TRANSLATION.FORM.FIRST_NAME.PLACEHOLDER}
                    {...bindInputProps({
                      name: `payment.receiver.owner.firstName`,
                      values,
                      ...formProps,
                    })}
                    className={classNames({
                      valid: get(values, "payment.receiver.owner.firstName"),
                    })}
                    suffix={
                      get(
                        formProps.errors,
                        `payment.receiver.owner.firstName`
                      ) ? (
                        <img src={InputError} />
                      ) : (
                        <img src={InputChecked} />
                      )
                    }
                    disabled={locked}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Input
                    label={TRANSLATION.FORM.IBAN.LABEL}
                    placeholder={TRANSLATION.FORM.IBAN.PLACEHOLDER}
                    {...bindInputProps({
                      name: `payment.receiver.IBAN`,
                      values,
                      ...formProps,
                    })}
                    className={classNames({
                      valid: get(values, "payment.receiver.IBAN"),
                    })}
                    suffix={
                      get(formProps.errors, `payment.receiver.IBAN`) ? (
                        <img src={InputError} />
                      ) : (
                        <img src={InputChecked} />
                      )
                    }
                    disabled={locked}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Input
                    label={TRANSLATION.FORM.BIC.LABEL}
                    placeholder={TRANSLATION.FORM.BIC.PLACEHOLDER}
                    {...bindInputProps({
                      name: `payment.receiver.BIC`,
                      values,
                      ...formProps,
                    })}
                    className={classNames({
                      valid: get(values, "payment.receiver.BIC"),
                    })}
                    suffix={
                      get(formProps.errors, `payment.receiver.BIC`) ? (
                        <img src={InputError} />
                      ) : (
                        <img src={InputChecked} />
                      )
                    }
                    disabled={locked}
                  />
                </Col>
              </Row>
            )}
          </div>
        </div>
      </div>
      <Button
        disabled={!isEmpty(formProps.errors)}
        onClick={handleSubmit}
        type="primary"
        size="middle"
      >
        <span>{TRANSLATION.NEXT}</span>
      </Button>
    </>
  );
};

export default View;

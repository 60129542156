import { useFormik } from "formik";
import { find, get, isEmpty } from "lodash";
import moment from "moment";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiEdit } from "react-icons/fi";
import {
  MdOutlineCake,
  MdOutlineDiversity1,
  MdOutlineFace,
  MdOutlineHandshake,
} from "react-icons/md";
import { NAME_SPACES } from "shared/locales/constants";
import {
  CONTACT_POINT_SYSTEM,
  CURRENCY_SYMBOL,
  DATE_FORMAT,
  MEMBERSHIP_ORIGINS,
  RELATIONSHIP,
  TEMPLATE_TYPES,
  TEMPLATES,
} from "utils/constants";
import { bindInputProps } from "utils/helpers/input";

import { BiIdCard } from "react-icons/bi";
import { RiCalendarEventLine } from "react-icons/ri";
import Button from "../Components/Button";
import Card from "../Components/Card";
import Cost from "../Components/Cost";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Input from "../Components/Input";
import Select from "../Components/Select";
import DocumentDownload from "./DocumentDownload";
import validation from "./validation";

const NUMBERS_MAPPING = {
  1: "Premier",
  2: "Deuxième",
  3: "Troisième",
  4: "Quatrième",
  5: "Cinquième",
  6: "Sixième",
  7: "Septième",
  8: "Huitième",
  9: "Neuvième",
  10: "Dixième",
  11: "Onzième",
  12: "Douzième",
};

function View({
  subscription,
  onSubmit,
  generalCost,
  updateSponsorship,
  updateProjectStatus,
  attachments,
  downloadFile,
  documentsLoading,
}) {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const { t: trans } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const INFORMATION = trans(
    "SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.INFORMATION",
    {
      returnObjects: true,
    }
  );
  const [expand, setExpand] = useState(false);
  const [origin, setOrigin] = useState(
    get(subscription, "project.contact.fields.membershipOrigin", null)
  );
  const totalCost = useMemo(
    () =>
      get(
        subscription,
        `project.fields.upsells.${get(subscription, "insurancePlan.id")}`,
        []
      )
        ?.reduce((acc, { cost }) => (acc += cost), get(generalCost, "cost", 0))
        ?.toFixed(2),
    []
  );

  const EDIT_BLOCK = [
    {
      icon: <MdOutlineFace size={24} />,
      label: "Vous êtes",
      value: `${get(subscription, "project.contact.user.firstname")} ${get(
        subscription,
        "project.contact.user.lastname"
      )}`,
      template: TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].ADHERENT_INFO,
      isBeforeLocked: true,
    },
    {
      icon: <MdOutlineCake size={24} />,
      label: "Date de naissance",
      value: `${moment(
        get(subscription, "project.contact.user.birthDate")
      ).format(DATE_FORMAT)}`,
      template:
        TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].ADHERENT_BIRTHDATE,
      isBeforeLocked: true,
    },
    {
      icon: <BiIdCard size={24} />,
      label: "Adresse postale",
      value: (
        <>
          {get(
            find(get(subscription, "project.contact.telecoms"), {
              system: CONTACT_POINT_SYSTEM.ADDRESS,
            }),
            "value"
          )}
          <br />
          {`${get(
            find(get(subscription, "project.contact.telecoms"), {
              system: CONTACT_POINT_SYSTEM.CITY,
            }),
            "value"
          )} - ${get(
            find(get(subscription, "project.contact.telecoms"), {
              system: CONTACT_POINT_SYSTEM.ZIP_CODE,
            }),
            "value"
          )}`}
        </>
      ),
      template:
        TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].ADHERENT_TELECOMS,
    },
    ...(!isEmpty(get(subscription, "project.contact.relatedPersons", []))
      ? [
          {
            icon: <MdOutlineDiversity1 size={24} />,
            label: "Vos bénéficiaires",
            value: [
              get(subscription, "project.contact.relatedPersons", [])
                .filter(
                  ({ relationship }) => relationship === RELATIONSHIP.SPOUSE
                )
                .map(({ identity }, i) => (
                  <div>
                    {`${get(identity, "user.firstname", "") || ""} ${
                      get(identity, "user.lastname", "") || ""
                    }`}{" "}
                    <span>{"(Votre conjoint)"}</span>
                  </div>
                )),
              get(subscription, "project.contact.relatedPersons", [])
                .filter(
                  ({ relationship }) => relationship === RELATIONSHIP.CHILD
                )
                .map(({ identity }, i) => (
                  <div>
                    {`${get(identity, "user.firstname", "") || ""} ${
                      get(identity, "user.lastname", "") || ""
                    }`}{" "}
                    <span>{`(Votre ${NUMBERS_MAPPING[i + 1]} enfant)`}</span>
                  </div>
                )),
            ],
            template:
              TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].RELATED_PERSONS,
          },
        ]
      : []),
  ];

  const { handleSubmit, values, setFieldValue, ...formProps } = useFormik({
    initialValues: {
      additionalInfo: get(subscription, "additionalInfo"),
    },
    onSubmit: updateSponsorship,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
    ),
  });

  const onCloseSponsor = () => {
    setExpand(false);
  };

  return (
    <div className="solyon with-p-btm">
      <div className="solyon__container">
        <div className="solyon__wrapper">
          <Header
            title="Nous y sommes ! Votre adhésion est prête pour être signée"
            highlight="Votre adhésion est prête"
            subtitle="Souscription : Récapitulatif"
          />
          <div className="solyon__body">
            <div className="solyon-recap">
              <div className="solyon-recap__col">
                <div className="solyon-recap__title">Offre</div>
                <div className="solyon-product-card">
                  <img
                    className="solyon-product-card__icon"
                    alt={get(subscription, "insurancePlan.name")}
                    src={get(subscription, "insurancePlan.icon")}
                  />
                  <Cost
                    cost={totalCost}
                    currency={get(generalCost, "currency")}
                    showYearly
                  />
                </div>
                <div className="solyon-recap__prices">
                  <div className="solyon-recap__title">Inclut dans le prix</div>
                  <div className="solyon-recap__prices--row">
                    <div>{get(generalCost, "insurancePlan.name")}</div>
                    <span>{`${generalCost?.cost || ""} ${
                      CURRENCY_SYMBOL[generalCost?.currency] || ""
                    }`}</span>
                  </div>
                  {get(
                    subscription,
                    `project.fields.upsells.${get(
                      subscription,
                      "insurancePlan.id"
                    )}`,
                    []
                  ).map((module) => (
                    <div className="solyon-recap__prices--row">
                      <div>{get(module, "insurancePlan.name")}</div>
                      <span>{`${module?.cost} ${
                        CURRENCY_SYMBOL[module?.currency]
                      }`}</span>
                    </div>
                  ))}
                </div>

                {!expand &&
                get(subscription, "additionalInfo.sponsor.firstName", "") &&
                get(subscription, "additionalInfo.sponsor.lastName", "") ? (
                  <div
                    className="solyon-recap__partnership "
                    onClick={() => setExpand(true)}
                  >
                    <div className="solyon-recap__partnership--row">
                      <MdOutlineDiversity1 size={24} />
                      <span className="solyon-recap__partnership--row--grow">
                        Parrain So’lyon
                      </span>
                      <div className="solyon-recap__partnership--row--value">
                        {`${get(
                          subscription,
                          "additionalInfo.sponsor.firstName",
                          ""
                        )} ${get(
                          subscription,
                          "additionalInfo.sponsor.lastName",
                          ""
                        )}`}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="solyon-recap__partnership">
                    <div
                      className="solyon-recap__partnership--row"
                      onClick={() => setExpand(true)}
                    >
                      <MdOutlineHandshake size={24} />
                      <span>Avez-vous un parrain So’lyon ? </span>
                    </div>
                    {expand && (
                      <>
                        <Input
                          placeholder={"ex : Doe"}
                          label={"Nom"}
                          {...bindInputProps({
                            name: "additionalInfo.sponsor.lastName",
                            values,
                            ...formProps,
                          })}
                        />
                        <Input
                          placeholder={"ex : John"}
                          label={"Prénom"}
                          {...bindInputProps({
                            name: "additionalInfo.sponsor.firstName",
                            values,
                            ...formProps,
                          })}
                        />
                        <Button
                          block
                          type={"primary"}
                          alignment={"center"}
                          onClick={() => {
                            handleSubmit();
                            setExpand(false);
                          }}
                          disabled={!isEmpty(formProps.errors)}
                        >
                          Enregistrer et fermer
                        </Button>
                        <Button
                          block
                          type={"gray-light"}
                          alignment={"center"}
                          onClick={onCloseSponsor}
                        >
                          Fermer
                        </Button>
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className="solyon-recap__col">
                <div className="solyon-recap__title">
                  Informations de l’adhérent
                </div>
                <div className="solyon-recap__blocks">
                  {EDIT_BLOCK.map(
                    ({
                      icon,
                      label,
                      value,
                      template,
                      isBeforeLocked = false,
                    }) => (
                      <div className="solyon-recap__edit" key={label}>
                        {icon}
                        <div className="solyon-recap__edit--text">
                          <span>{label}</span>
                          <div className="solyon-recap__edit--text--value">
                            {value}
                          </div>
                        </div>
                        <div className="solyon-recap__edit--icon">
                          <FiEdit
                            onClick={() =>
                              updateProjectStatus({ template, isBeforeLocked })
                            }
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
                <div className="solyon-recap__title p-top-16">
                  Date d’effet{" "}
                </div>
                <div className="solyon-recap__edit">
                  <RiCalendarEventLine size={24} />
                  <div className="solyon-recap__edit--text">
                    <span>Date d’effet</span>
                    <div className="solyon-recap__edit--text--value">
                      {moment(
                        get(subscription, "project.contract.issuanceDate")
                      ).format(DATE_FORMAT)}
                    </div>
                  </div>
                  <div className="solyon-recap__edit--icon">
                    <FiEdit
                      onClick={() =>
                        updateProjectStatus({
                          template:
                            TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON]
                              .EFFECTIVE_DATE,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="solyon-recap__col">
                <div className="solyon-recap__title">
                  Documents précontractuels
                </div>

                <DocumentDownload
                  title={"Vos documents pré-contractuels"}
                  items={attachments}
                  download={(id) => downloadFile(id)}
                />
                <div className="solyon__body--actions">
                  <Card>
                    <div className="grid--1">
                      <Select
                        options={Object.entries(MEMBERSHIP_ORIGINS).map(
                          ([key, value]) => ({
                            value,
                            label: INFORMATION.MEMBERSHIP_ORIGINS[key],
                          })
                        )}
                        label={INFORMATION.MEMBERSHIP_ORIGIN}
                        placeholder={"Sélectionnez ..."}
                        value={origin}
                        onChange={(value) => setOrigin(value)}
                      />
                      <div>
                        <Button
                          block
                          type={"primary"}
                          loading={documentsLoading}
                          disabled={!origin || documentsLoading}
                          onClick={() => onSubmit({ origin })}
                        >
                          Je signe mon adhésion
                        </Button>
                        <div className="solyon-recap__info">
                          En cliquant sur le bouton “Je signe mon adhésion”,
                          j'affirme mon accord et mon acceptation des
                          Engagements et Consentements liés à cette action,
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default View;

import * as yup from "yup";

const validation = (messages, { isFPH, isTax }) => {
  return yup.object().shape({
    paySlip:
      isFPH &&
      yup
        .array()
        .of(
          yup.object().shape({
            name: yup.string().required(messages.REQUIRED),
          })
        )
        .required(messages.REQUIRED_ATTACHMENT)
        .min(1, messages.REQUIRED_ATTACHMENT)
        .max(1, messages.ONE_FILE),
    taxNotice:
      isTax &&
      yup
        .array()
        .of(
          yup.object().shape({
            name: yup.string().required(messages.REQUIRED),
          })
        )
        .required(messages.REQUIRED_ATTACHMENT)
        .min(1, messages.REQUIRED_ATTACHMENT)
        .max(1, messages.ONE_FILE),
    socialSecurityCertificate: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required(messages.REQUIRED),
        })
      )
      .required(messages.REQUIRED_ATTACHMENT)
      .min(1, messages.REQUIRED_ATTACHMENT)
      .max(1, messages.ONE_FILE),
    paymentAccount: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required(messages.REQUIRED),
        })
      )
      .required(messages.REQUIRED_ATTACHMENT)
      .min(1, messages.REQUIRED_ATTACHMENT)
      .max(1, messages.ONE_FILE),
    cin: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required(messages.REQUIRED),
        })
      )
      .required(messages.REQUIRED_ATTACHMENT)
      .min(1, messages.REQUIRED_ATTACHMENT)
      .max(1, messages.ONE_FILE),
  });
};

export default validation;

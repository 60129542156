import { Spin } from "antd";
import { get } from "lodash";
import { useState } from "react";
import { CURRENCY_SYMBOL, TEMPLATE_TYPES, TEMPLATES } from "utils/constants";
import CartModal from "./Modal";

function Cart({ project, generalCost, updateStatus, loading, upsells = [] }) {
  const [open, setOpen] = useState(false);
  const totalAmount = upsells
    .reduce((acc, { cost }) => (acc += cost), get(generalCost, "cost", 0))
    .toFixed(2);
  const onSubmit = () => {
    updateStatus({
      template: TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].PRODUCTS,
    });
  };

  const onClose = () => setOpen(false);

  const onOpen = () => setOpen(true);

  return (
    <>
      <div className="solyon-cart" onClick={onOpen}>
        {loading ? (
          <Spin />
        ) : (
          <>
            {totalAmount}
            <span className="currency">
              <small>
                {CURRENCY_SYMBOL[generalCost?.currency] || CURRENCY_SYMBOL.EUR}
              </small>
            </span>
          </>
        )}
      </div>
      <CartModal
        open={open}
        onSubmit={onSubmit}
        onClose={onClose}
        generalCost={generalCost}
        project={project}
        totalAmount={totalAmount}
        upsells={upsells}
      />
    </>
  );
}

export default Cart;

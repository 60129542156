import * as yup from "yup";

const validation = (messages) => {
  const requiredString = yup
    .string()
    .typeError(messages.REQUIRED)
    .required(messages.REQUIRED);
  return yup.object().shape({
    additionalInfo: yup.object().shape({
      bankCard: yup.object().shape({
        cardNumber: requiredString,
        expirationDate: requiredString,
        ccv: requiredString,
      }),
    }),
  });
};

export default validation;

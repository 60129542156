import cuid from "cuid";
import { get, head } from "lodash";
import moment from "moment";
import format from "string-template";
import {
  CONTACT_POINT_SYSTEM,
  DATE_FORMAT,
  REGEX_EXPS,
  SOCIAL_REGIMES,
  SOCIO_PROFESSIONAL_CATEGORIES,
} from "utils/constants";
import { isMajor } from "utils/helpers/date";
import * as yup from "yup";

const validator = (
  messages,
  { addressZipCodeCity, setCity, deltaRia, deltaStart, deltaEnd, deltaEndRia }
) => {
  const requiredString = yup
    .string()
    .typeError(messages.REQUIRED)
    .required(messages.REQUIRED);
  const requiredName = yup
    .string()
    .typeError(messages.REQUIRED)
    .matches(REGEX_EXPS.name, messages.INVALID_NAME)
    .required(messages.REQUIRED);
  const childValidationSchema = yup.object().shape({
    identity: yup.object().shape({
      user: yup.object().shape({
        birthDate: yup
          .date()
          .typeError(messages.REQUIRED)
          .max(moment(), messages.MIN_TODAY)
          .required(messages.REQUIRED),
      }),
      // socialRegime: requiredString,
    }),
  });
  return yup.object().shape({
    contract: yup.mixed().when("locked", {
      is: (locked) => locked,
      then: yup.object().shape({
        issuanceDate: yup
          .date()
          .typeError(messages.REQUIRED)
          .required(messages.REQUIRED),
      }),
      otherwise: yup.mixed().when("ria", {
        is: (ria) => ria,
        then: yup.object().shape({
          issuanceDate: yup
            .date()
            .typeError(messages.REQUIRED)
            .min(
              moment().startOf("day").add(deltaRia, "days"),
              format(messages.MIN_DATE, {
                date: moment().add(deltaRia, "days").format(DATE_FORMAT),
              })
            )
            .max(
              deltaEndRia === 0
                ? moment().endOf("year")
                : moment().endOf("day").add(deltaEndRia, "days"),
              format(messages.MAX_DATE, {
                date:
                  deltaEndRia === 0
                    ? moment().endOf("year").format(DATE_FORMAT)
                    : moment()
                        .endOf("day")
                        .add(deltaEndRia, "days")
                        .format(DATE_FORMAT),
              })
            )
            .required(messages.REQUIRED),
        }),
        otherwise: yup.object().shape({
          issuanceDate: yup
            .date()
            .typeError(messages.REQUIRED)
            .min(
              moment().startOf("day").add(deltaStart, "days"),
              format(messages.MIN_DATE, {
                date: moment().add(deltaStart, "days").format(DATE_FORMAT),
              })
            )
            .max(
              deltaEnd === 0
                ? moment().endOf("year")
                : moment().endOf("day").add(deltaEnd, "days"),
              format(messages.MAX_DATE, {
                date:
                  deltaEnd === 0
                    ? moment().endOf("year").format(DATE_FORMAT)
                    : moment()
                        .endOf("day")
                        .add(deltaEnd, "days")
                        .format(DATE_FORMAT),
              })
            )
            .required(messages.REQUIRED),
        }),
      }),
    }),
    contact: yup.object().shape({
      user: yup.object().shape({
        // gender: requiredString,
        lastname: requiredName,
        firstname: requiredName,
        birthDate: yup
          .date()
          .typeError(messages.REQUIRED)
          .max(isMajor, messages.AGE_18)
          .required(messages.REQUIRED),
      }),
      socialRegime: requiredString,
      profession: requiredString,
      socioProfessionalCategory: yup.mixed().when("socialRegime", {
        is: (regime) => regime === SOCIAL_REGIMES.REGIME_SALARIES_AGRICOLES,
        then: requiredString,
      }),
      exerciseFrame: yup.mixed().when("socioProfessionalCategory", {
        is: (category) =>
          [
            SOCIO_PROFESSIONAL_CATEGORIES.FARMERS,
            SOCIO_PROFESSIONAL_CATEGORIES.ARTISANS,
            SOCIO_PROFESSIONAL_CATEGORIES.LIBERAL_PROFESSIONS_AND_SIMILAR,
            SOCIO_PROFESSIONAL_CATEGORIES.TRADERS_AND_SIMILAR,
            SOCIO_PROFESSIONAL_CATEGORIES.ENTREPRENEURS,
          ].includes(category),
        then: requiredString,
      }),
    }),
    zipCode: yup.object().shape({
      value: yup
        .string()
        .typeError(messages.REQUIRED)
        .test("isValid", messages.INVALID_VALUE, async function (value) {
          const isValidRegex = new RegExp(REGEX_EXPS.zipCode).test(value);
          if (!isValidRegex) return false;
          const {
            data: {
              franceAddressZipCodeCity: { response },
            },
          } = await addressZipCodeCity({
            variables: {
              where: { zipCode: value },
            },
          });
          setCity(
            response.length === 1
              ? {
                  id: cuid(),
                  system: CONTACT_POINT_SYSTEM.CITY,
                  value: get(head(response), "value"),
                }
              : {
                  id: cuid(),
                  system: CONTACT_POINT_SYSTEM.CITY,
                  value: null,
                }
          );
          return response.length;
        }),
    }),
    phoneNumber: yup.object().shape({
      value: yup
        .string()
        .nullable()
        .test("isValid", messages.INVALID_VALUE, async function (value) {
          return !value
            ? true
            : new RegExp(REGEX_EXPS.internationalMobilePhone).test(
                value?.replace(/\s+/g, "")
              );
        }),
    }),
    children: yup.array().of(childValidationSchema),
    hasSpouse: yup.boolean().nullable(),
    spouse: yup.object().when("hasSpouse", {
      is: (hasSpouse) => hasSpouse,
      then: yup.object().shape({
        identity: yup.object().shape({
          user: yup.object().shape({
            birthDate: yup
              .date()
              .typeError(messages.REQUIRED)
              .max(isMajor, messages.RADIANCE.SPOUSE_AGE)
              .min(
                moment().subtract(100, "year").startOf("year"),
                messages.RADIANCE.SPOUSE_AGE
              )
              .required(messages.REQUIRED),
          }),
          // socialRegime: requiredString,
        }),
      }),
    }),
  });
};

export default validator;

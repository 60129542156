import { Col, Row } from "antd";
import classNames from "classnames";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { useLazyQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { FRANCE_ADDRESS_ZIP_CODE_CITY } from "utils/api/graphql/queries/referentials";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import Button from "../Components/Button";
import InputChecked from "../Components/svg/check_small.svg";
import InputError from "../Components/svg/error.svg";
import validation from "./validation";

const View = ({ onNext, initialValues, locked }) => {
  const [cities, setCities] = useState([]);
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("B2C_LMF.ADHERENT_ADDRESS", { returnObjects: true });
  const [addressZipCodeCity] = useLazyQuery(FRANCE_ADDRESS_ZIP_CODE_CITY);
  useEffect(() => {
    addressZipCodeCity({
      variables: {
        where: {
          zipCode: get(
            initialValues,
            `telecoms.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
          ),
        },
      },
      onCompleted: ({ franceAddressZipCodeCity: { response } }) => {
        setCities(response);
      },
    });
  }, []);
  const { handleSubmit, values, setFieldValue, ...formProps } = useFormik({
    initialValues,
    onSubmit: onNext,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
    ),
  });

  return (
    <>
      <h1 className="header-title">{TRANSLATION.TITLE}</h1>
      <span
        className="header-description"
        dangerouslySetInnerHTML={{ __html: TRANSLATION.DESCRIPTION }}
      ></span>
      <div className="__LMF--content LMF-content">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              label={TRANSLATION.FORM.STREET.LABEL}
              placeholder={TRANSLATION.FORM.STREET.PLACEHOLDER}
              className={classNames({
                valid: get(
                  values,
                  `telecoms.${CONTACT_POINT_SYSTEM.STREET}.value`
                ),
              })}
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.STREET}.value`,
                values,
                setFieldValue,
                ...formProps,
              })}
              onChange={({ target: { value } }) => {
                setFieldValue(
                  `telecoms.${CONTACT_POINT_SYSTEM.STREET}.value`,
                  value
                );
              }}
              suffix={
                get(
                  formProps.errors,
                  `telecoms.${CONTACT_POINT_SYSTEM.STREET}.value`
                ) ? (
                  <img src={InputError} />
                ) : (
                  <img src={InputChecked} />
                )
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              label={TRANSLATION.FORM.ADDRESS_LOCATION.LABEL}
              placeholder={TRANSLATION.FORM.ADDRESS_LOCATION.PLACEHOLDER}
              optional={TRANSLATION.FORM.OPTIONAL}
              className={classNames({
                valid: get(
                  values,
                  `telecoms.${CONTACT_POINT_SYSTEM.ADDRESS_LOCATION}.value`
                ),
              })}
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.ADDRESS_LOCATION}.value`,
                values,
                setFieldValue,
                ...formProps,
              })}
              onChange={({ target: { value } }) => {
                setFieldValue(
                  `telecoms.${CONTACT_POINT_SYSTEM.ADDRESS_LOCATION}.value`,
                  value
                );
              }}
              suffix={
                get(
                  formProps.errors,
                  `telecoms.${CONTACT_POINT_SYSTEM.ADDRESS_LOCATION}.value`
                ) ? (
                  <img src={InputError} />
                ) : (
                  <img src={InputChecked} />
                )
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              label={TRANSLATION.FORM.ZIP_CODE.LABEL}
              placeholder={TRANSLATION.FORM.ZIP_CODE.PLACEHOLDER}
              className={classNames({
                valid: get(
                  values,
                  `telecoms.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                ),
              })}
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`,
                values,
                setFieldValue,
                ...formProps,
              })}
              onChange={({ target: { value } }) => {
                setFieldValue(
                  `telecoms.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`,
                  value
                );
              }}
              suffix={
                get(
                  formProps.errors,
                  `telecoms.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                ) ? (
                  <img src={InputError} />
                ) : (
                  <img src={InputChecked} />
                )
              }
              disabled={true}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Select
              label={TRANSLATION.FORM.CITY.LABEL}
              placeholder={TRANSLATION.FORM.CITY.PLACEHOLDER}
              showSearch
              options={cities}
              className={classNames({
                valid: get(
                  values,
                  `telecoms.${CONTACT_POINT_SYSTEM.CITY}.value`
                ),
              })}
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.CITY}.value`,
                values,
                setFieldValue,
                ...formProps,
              })}
              onChange={(value) => {
                setFieldValue(
                  `telecoms.${CONTACT_POINT_SYSTEM.CITY}.value`,
                  value
                );
              }}
              suffix={
                get(
                  formProps.errors,
                  `telecoms.${CONTACT_POINT_SYSTEM.CITY}.value`
                ) ? (
                  <img src={InputError} />
                ) : (
                  <img src={InputChecked} />
                )
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              label={TRANSLATION.FORM.COMPLEMENT.LABEL}
              placeholder={TRANSLATION.FORM.COMPLEMENT.PLACEHOLDER}
              optional={TRANSLATION.FORM.OPTIONAL}
              className={classNames({
                valid: get(
                  values,
                  `telecoms.${CONTACT_POINT_SYSTEM.COMPLEMENT}.value`
                ),
              })}
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.COMPLEMENT}.value`,
                values,
                setFieldValue,
                ...formProps,
              })}
              onChange={({ target: { value } }) => {
                setFieldValue(
                  `telecoms.${CONTACT_POINT_SYSTEM.COMPLEMENT}.value`,
                  value
                );
              }}
              suffix={
                get(
                  formProps.errors,
                  `telecoms.${CONTACT_POINT_SYSTEM.COMPLEMENT}.value`
                ) ? (
                  <img src={InputError} />
                ) : (
                  <img src={InputChecked} />
                )
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              label={TRANSLATION.FORM.RESIDENCE.LABEL}
              placeholder={TRANSLATION.FORM.RESIDENCE.PLACEHOLDER}
              optional={TRANSLATION.FORM.OPTIONAL}
              className={classNames({
                valid: get(
                  values,
                  `telecoms.${CONTACT_POINT_SYSTEM.RESIDENCE}.value`
                ),
              })}
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.RESIDENCE}.value`,
                values,
                setFieldValue,
                ...formProps,
              })}
              onChange={({ target: { value } }) => {
                setFieldValue(
                  `telecoms.${CONTACT_POINT_SYSTEM.RESIDENCE}.value`,
                  value
                );
              }}
              suffix={
                get(
                  formProps.errors,
                  `telecoms.${CONTACT_POINT_SYSTEM.RESIDENCE}.value`
                ) ? (
                  <img src={InputError} />
                ) : (
                  <img src={InputChecked} />
                )
              }
            />
          </Col>
        </Row>
      </div>
      <Button
        disabled={!isEmpty(formProps.errors)}
        onClick={handleSubmit}
        type="primary"
        size="middle"
      >
        <span>{TRANSLATION.NEXT}</span>
      </Button>
      <p className="__LMF--hint custom">{TRANSLATION.HINT}</p>
      <p className="__LMF--link">{TRANSLATION.POLICY}</p>
    </>
  );
};

export default View;

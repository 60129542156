import { get } from "lodash";
import { CURRENCY_SYMBOL } from "utils/constants";

const PricingItem = ({ title, price, per, currency, breakdown = [] }) => {
  return (
    <div className={`pricinglmf__item`}>
      <div className="--header">
        <div className="--name">
          <small>Formule</small>
          <h3>{title}</h3>
        </div>
        <div className="--price">
          <span className="main">
            {price} {CURRENCY_SYMBOL[currency]}
          </span>
          <small>{per}</small>
        </div>
      </div>

      <div className="--breakdown">
        {breakdown.map((item, index) => (
          <div className="row" key={index}>
            <span>{get(item, "insurancePlan.name")}</span>
            <span>
              {get(item, "cost")}
              {CURRENCY_SYMBOL[get(item, "currency")]}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingItem;

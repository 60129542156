import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import { JOB_LIST } from "utils/constants";
import { downloadUploadedDocument } from "utils/helpers/files";
import Button from "../Components/Button";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import DocumentUpload from "./DocumentUpload";
import validation from "./validation";

function View({
  initialValues,
  insurancePlan,
  onSubmit,
  downloadFile,
  profession,
  onBack,
}) {
  const isTax =
    profession !== JOB_LIST.HOSPITAL_CIVIL_SERVICE &&
    (get(insurancePlan, "config.additionalDocuments", []) || []).includes(
      "proof.taxNotice"
    );
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const { isValid, handleSubmit, setFieldValue, values, ...formProps } =
    useFormik({
      initialValues,
      onSubmit: onSubmit,
      validationSchema: validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, {
          returnObjects: true,
        }),
        { isFPH: profession === JOB_LIST.HOSPITAL_CIVIL_SERVICE, isTax }
      ),
    });

  const onFileRemove = async (name) => {
    setFieldValue(name, []);
  };
  const handleDownload = ({ uid, size, base64, contentType, name }) => {
    if (!size) return downloadFile(uid);
    downloadUploadedDocument({
      base64,
      contentType,
      name,
    });
  };

  return (
    <div className="solyon">
      <div className="solyon__container">
        <div className="solyon__wrapper">
          <Header
            title="Dernière chose ! Nous aurons besoin de vos pièces justificatives"
            highlight="vos pièces justificatives"
            subtitle="Souscription : Pièces justificatives"
          />
          <div className="solyon__body">
            <div className="solyon-documents__container">
              <DocumentUpload
                label={"Relevé d’Identité Bancaire (RIB)"}
                items={values.paymentAccount}
                onDownload={handleDownload}
                onUpload={(item) => {
                  setFieldValue("paymentAccount", item);
                }}
                onFileRemove={() => onFileRemove("paymentAccount")}
              />
              <DocumentUpload
                label={"Carte d’identité nationale"}
                items={values.cin}
                onDownload={handleDownload}
                onUpload={(item) => {
                  setFieldValue("cin", item);
                }}
                onFileRemove={() => onFileRemove("cin")}
              />
              <DocumentUpload
                label={"Attestation de sécurité sociale"}
                items={values.socialSecurityCertificate}
                onDownload={handleDownload}
                onUpload={(item) => {
                  setFieldValue("socialSecurityCertificate", item);
                }}
                onFileRemove={() => onFileRemove("socialSecurityCertificate")}
              />
              {profession === JOB_LIST.HOSPITAL_CIVIL_SERVICE && (
                <DocumentUpload
                  label={"Bulletin de salaire"}
                  items={values.paySlip}
                  onDownload={handleDownload}
                  onUpload={(item) => {
                    setFieldValue("paySlip", item);
                  }}
                  onFileRemove={() => onFileRemove("paySlip")}
                />
              )}
              {isTax && (
                <DocumentUpload
                  label={"Avis d’imposition"}
                  items={values.taxNotice}
                  onDownload={handleDownload}
                  onUpload={(item) => {
                    setFieldValue("taxNotice", item);
                  }}
                  onFileRemove={() => onFileRemove("taxNotice")}
                />
              )}
              <div className="solyon-recap__info">
                <p>Formats de fichiers pris en charge : JPEG, PNG ou PDF</p>
                <p>Taille maximale : 5 Mo</p>
              </div>
            </div>
            <div className="solyon__body--actions">
              <Button
                type={"primary"}
                alignment={"center"}
                disabled={!isEmpty(formProps.errors) || isEmpty(values, "cin")}
                onClick={handleSubmit}
              >
                Continuer
              </Button>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default View;

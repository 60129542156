import { Modal } from "antd";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import { CURRENCY_SYMBOL } from "utils/constants";
import Button from "../Button";

const Cart = ({ open, setOpen, modules = [], onEdit, generalCost }) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("B2C_LMF.CART", { returnObjects: true });
  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      width={400}
      centered
      footer={null}
      className="LMF-formula-modal"
    >
      <span className="LMF-formula-modal__title">{TRANSLATION.TITLE}</span>
      <div className="LMF-formula-modal__content">
        <div className="LMF-formula-modal__content--product">
          <span className="LMF-formula-modal__content--product__title">
            {get(generalCost, "insurancePlan.name")}
          </span>
          <span className="LMF-formula-modal__content--product__price">
            <span className="price">
              {modules
                .reduce(
                  (acc, { cost }) => (acc += cost),
                  get(generalCost, "cost", 0)
                )
                .toFixed(2)}{" "}
              {CURRENCY_SYMBOL[generalCost?.currency]}
            </span>
            <small className="frequency">{TRANSLATION.PER_MONTH}</small>
          </span>
        </div>
        <div className="LMF-formula-modal__content--product__detail">
          <span className="LMF-formula-modal__content--product__detail--title">
            {TRANSLATION.OFFER_INCLUDED}
          </span>
          <ul className="LMF-formula-modal__content--product__detail--prices">
            <li>
              <span>{get(generalCost, "insurancePlan.name")}</span>
              <span>
                {get(generalCost, "cost")}
                {CURRENCY_SYMBOL[generalCost?.currency]}
              </span>
            </li>
            {modules.map((module, index) => (
              <li key={`modules-${index}`}>
                <span>{get(module, "insurancePlan.name")}</span>
                <span>
                  {get(module, "cost")}
                  {CURRENCY_SYMBOL[module?.currency]}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="LMF-formula-modal__actions">
        <Button
          onClick={() => {
            onEdit();
            setOpen(false);
          }}
          className="w-full"
          type="primary"
          size="middle"
        >
          <span>{TRANSLATION.EDIT}</span>
        </Button>
        <Button
          onClick={() => setOpen(false)}
          className="w-full"
          type="white"
          size="middle"
        >
          <span>{TRANSLATION.CLOSE}</span>
        </Button>
      </div>
    </Modal>
  );
};

export default Cart;

import { Drawer } from "antd";
import { Formik } from "formik";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { QUALIFIER_TAGS } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Detail = ({ onSubmit, open, onClose }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.QUALIFIER);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });

  return (
    <Drawer
      open={!isEmpty(open)}
      className="drawer__area"
      title={DETAIL.TITLE}
      width={500}
      onClose={onClose}
      placement="right"
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: open?.name || null,
          description: open?.description || null,
          tag: open?.tag || null,
        }}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values);
          resetForm();
        }}
        validationSchema={validation(
          t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
        )}
      >
        {({ handleSubmit, ...formProps }) => (
          <div className="grid--1">
            <Input
              label={FORM.NAME}
              placeholder={FORM.NAME}
              required
              {...bindInputProps({ name: "name", ...formProps })}
            />
            <Input
              label={FORM.DESCRIPTION}
              placeholder={FORM.DESCRIPTION}
              {...bindInputProps({ name: "description", ...formProps })}
            />

            <Select
              placeholder={FORM.TAG}
              label={FORM.TAG}
              showSearch
              options={Object.entries(QUALIFIER_TAGS).map(([label, value]) => ({
                label: FORM.TAGS[label],
                value,
              }))}
              {...bindInputProps({ name: "tag", ...formProps })}
            />
            <Button type="primary" size="full--width" onClick={handleSubmit}>
              {FORM.SUBMIT}
            </Button>
          </div>
        )}
      </Formik>
    </Drawer>
  );
};

export default Detail;

import { useSubscription } from "@apollo/client";
import PropTypes from "prop-types";
import Loader from "shared/components/Loader";
import { useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { INITIAL_VALUES } from "../utils/constants";

const GeneralCosts = ({ View, filter, fetchPolicy, ...props }) => {
  const variables = {
    ...filter,
    skip: parseInt(filter.skip) ?? INITIAL_VALUES.skip,
    take: parseInt(filter.take) ?? INITIAL_VALUES.take,
  };
  const query = useQuery(graphql.queries.GENERAL_COSTS, {
    fetchPolicy,
    variables,
  });
  useSubscription(graphql.subscriptions.GENERAL_COST_BUILT, { variables });
  const WrappedView = Loader(View);
  return (
    <WrappedView
      {...{
        filter,
        ...props,
        ...query,
        type: "generalCosts",
      }}
    />
  );
};

GeneralCosts.propTypes = {
  View: PropTypes.func.isRequired,
  filter: PropTypes.object,
};

GeneralCosts.defaultProps = {
  filter: {},
};

export default GeneralCosts;

import { REGEX_EXPS } from "utils/constants";
import * as yup from "yup";

const validator = (messages) => {
  const requiredName = yup
    .string()
    .typeError(messages.REQUIRED)
    .matches(REGEX_EXPS.name, messages.INVALID_NAME)
    .required(messages.REQUIRED);

  return yup.object().shape({
    contact: yup.object().shape({
      user: yup.object().shape({
        lastname: requiredName,
        firstname: requiredName,
      }),
    }),

    phoneNumber: yup.object().shape({
      value: yup
        .string()
        .typeError(messages.INVALID_POLYNISIE)
        .required(messages.REQUIRED)
        .nullable()
        .test("isValid", messages.INVALID_POLYNISIE, async function (value) {
          return !value
            ? true
            : new RegExp(REGEX_EXPS.phonePolynisie).test(
                value?.replace(/\s+/g, "")
              );
        }),
    }),
  });
};

export default validator;

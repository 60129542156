import cuid from "cuid";
import { find, get } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { filterByConditions } from "screens/Private/Broker/Projects/Detail";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import {
  ACTION_TYPES,
  PROCESS_TYPES,
  SCREENS,
  TEMPLATES,
  TEMPLATE_TYPES,
} from "utils/constants";
import View from "./View";

const Cart = ({ tracer, contact, modules, project, subscription }) => {
  const [updateProject] = useMutation(graphql.mutations.UPDATE_PROJECT);
  const [getGeneralCost, { data: generalCost, loading }] = useLazyQuery(
    graphql.queries.GENERAL_COST,
    {
      fetchPolicy: "no-cache",
      variables: {
        where: {
          project: { id: get(subscription, "project.id") },
          insurancePlan: {
            id: get(subscription, "insurancePlan.id"),
          },
        },
      },
    }
  );

  useEffect(() => {
    if (subscription) getGeneralCost();
  }, [subscription]);
  const navigate = useNavigate();

  const findStatus = (data, template) => {
    return find(data, ({ template: _ }) => _ === template);
  };

  const onEdit = () => {
    updateProjectStatus({
      template:
        TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.B2C_LMF].PRODUCTS,
    });
  };

  const onRelatedPersonEdit = () => {
    updateProjectStatus({
      template:
        TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.B2C_LMF]
          .BENEFICIARIES,
    });
  };

  const onProfileEdit = () => {
    updateProjectStatus({
      template:
        TEMPLATES[PROCESS_TYPES.B2C].THEMES[TEMPLATE_TYPES.B2C_LMF]
          .ADHERENT_DETAILS,
    });
  };

  const updateProjectStatus = ({ template }) => {
    const status = findStatus(
      get(tracer, "flow.process.processStatuses", []),
      template
    );
    const actionsToExecute = filterByConditions(
      get(status, "actions", []),
      project
    );
    const syncActions = actionsToExecute
      .filter(({ type }) => type === ACTION_TYPES.SYNC_PROCESSES)
      .map(({ args: { status } }) => ({
        id: cuid(),
        status: { id: status },
      }));
    updateProject({
      variables: {
        where: { id: project.id },
        data: {
          statuses: [{ id: cuid(), status: { id: status.id } }, ...syncActions],
        },
      },
      onCompleted: ({ updateProject }) => {
        navigate(
          `${SCREENS.PRIVATE.GUEST.PROJECTS.DETAIL.path}/${updateProject?.id}`,
          {
            state: {
              process: get(omitDeep(tracer, "__typename"), "flow.process"),
              tracer: omitDeep(tracer, "__typename"),
            },
          }
        );
      },
    });
  };

  if (loading) return <span></span>;

  return (
    <View
      onEdit={onEdit}
      onRelatedPersonEdit={onRelatedPersonEdit}
      onProfileEdit={onProfileEdit}
      contact={contact}
      subscription={subscription}
      modules={
        modules ||
        get(project, `fields.upsells.${get(subscription, "insurancePlan.id")}`)
      }
      generalCost={get(generalCost, "generalCost")}
    />
  );
};

export default Cart;

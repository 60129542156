import { TEMPLATE_TYPES, TEMPLATES } from "utils/constants";
import AdherentBirthDate from "./AdherenBirthdate";
import AdherentInfo from "./AdherentInfo";
import AdherentTelecoms from "./AdherentTelecoms";
import BankInformation from "./BankInformation";
import Loading from "./Components/Loader";
import Default from "./Default";
import EffectiveDate from "./EffectiveDate";
import ElectronicSignature from "./ESign";
import GenerateESignDocuments from "./GenerateESignDocuments";
import Income from "./Income";
import Justification from "./Justification";
import Needs from "./Needs";
import Products from "./Products";
import Profile from "./Profile";
import ProtectIncome from "./ProtectIncome";
import RelatedPersons from "./RelatedPersons";
import Rgpd from "./Rgpd";
import SocialSecurityNumber from "./SocialSecurityNumber";
import SubscriptionDone from "./SubscriptionDone";
import Upsells from "./Upsells";

const Templates = {
  Loading: <Loading />,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].PROFILE]: Profile,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].ADHERENT_BIRTHDATE]:
    AdherentBirthDate,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].INCOME]: Income,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].ADHERENT_INFO]: AdherentInfo,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].NEEDS]: Needs,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].PRODUCTS]: Products,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].UPSELLS]: Upsells,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].PROTECT_INCOME]:
    ProtectIncome,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].EFFECTIVE_DATE]:
    EffectiveDate,
  ProtectIncome,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].ADHERENT_TELECOMS]:
    AdherentTelecoms,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON]
    .ADHERENT_SOCIAL_SECURITY_NUMBER]: SocialSecurityNumber,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].RELATED_PERSONS]:
    RelatedPersons,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].ADHERENT_BANK_INFORMATION]:
    BankInformation,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].GENERATE_E_SIGN_DOCUMENTS]:
    GenerateESignDocuments,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].E_SIGN]: ElectronicSignature,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].RGPD]: Rgpd,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].JUSTIFICATIONS]:
    Justification,
  [TEMPLATES.B2C.THEMES[TEMPLATE_TYPES.B2C_SOLYON].SUBSCRIPTION_DONE]:
    SubscriptionDone,
  default: Default,
};
export default Templates;

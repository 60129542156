import { get } from "lodash";
import { useState } from "react";
import { MdArrowDropDown, MdOutlineAccountBox } from "react-icons/md";
import ProfileModal from "./Modal";
import { useMediaQuery } from "react-responsive";

function Profile({ project, updateStatus }) {
  const [open, setOpen] = useState(false);

  const onClose = () => setOpen(false);

  const onOpen = () => setOpen(true);

  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <>
      {!isMobile ? (
        <div className="solyon-contact" onClick={onOpen}>
          <span>{`${get(project, "contact.user.firstname", "")} ${get(
            project,
            "contact.user.lastname",
            ""
          )}`}</span>
          <MdArrowDropDown size={16} />
        </div>
      ) : (
        <div className="solyon-contact" onClick={onOpen}>
          <MdOutlineAccountBox size={24}  color="#80bc00"/>
        </div>
      )}
      <ProfileModal
        open={open}
        updateStatus={updateStatus}
        onClose={onClose}
        project={project}
      />
    </>
  );
}

export default Profile;

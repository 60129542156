import { MdClose, MdOutlineMarkEmailRead } from "react-icons/md";
import Button from "screens/Private/Broker/Projects/Detail/Templates/Comparison/Solyon/Components/Button";
import Header from "../../../../Header";

function EmailSent({ onClose }) {
  return (
    <div className="solyon-help__modal">
      <Header
        small
        subtitle={<MdOutlineMarkEmailRead size={46} />}
        title="Nous allons vous recontacter dans les plus brefs délais."
        highlight="Nous allons vous recontacter"
      />
      <Button block type={"gray-light"} onClick={onClose}>
        <MdClose /> Fermer
      </Button>
    </div>
  );
}

export default EmailSent;

import { get } from "lodash";
import { MdCheck, MdOpenInNew } from "react-icons/md";
import helpIcon from "shared/assets/images/lmf/lmf_help.svg";
import helpIconWhite from "shared/assets/images/lmf/lmf_help_white.svg";
import { COLORS } from "shared/style/colors";
import { CURRENCY_SYMBOL } from "utils/constants";
import Button from "../Button";

const ProductCard = ({
  planName,
  price,
  perMonth,
  info,
  action,
  isRecommended,
  link,
  onHelpClick,
}) => {
  return (
    <div className={`pricing-lmf__card ${isRecommended ? "recommended" : ""}`}>
      {isRecommended && (
        <div className="recommendation-badge">Notre recommandation</div>
      )}
      <div className="pricing-lmf__card__header">
        <h3 className={`name__color--${isRecommended ? "white" : "primary"}`}>
          {planName}
        </h3>
        <div className="pricing-lmf__card__price">
          <div className={`price ${isRecommended && "recommended"} `}>
            <span className="amount">
              {price?.cost}
              {CURRENCY_SYMBOL[price?.currency]}
              <span className="currency">
                {perMonth && <small> /mois</small>}
              </span>
            </span>
          </div>
          <img src={isRecommended ? helpIconWhite : helpIcon} alt="help" onClick={() => onHelpClick()} />
        </div>
      </div>
      <div className="content">
        <Button
          type={isRecommended ? "white" : "primary"}
          onClick={action?.onClick}
          loading={action?.loading}
          disabled={action?.disabled}
          className="w-100 h-auto"
          size="large"
        >
          {action?.label}
        </Button>

        <div
          className={`pricing-lmf__card__benefits ${
            isRecommended && "recommended"
          } `}
        >
          <span className="title">Les garanties phares</span>
          <ul className="list">
            {get(info, "items", [])
              .slice(0, 3)
              .map(({ coverage, limit, complement }, index) => (
                <li key={index}>
                  <div className="icon">
                    <MdCheck color={COLORS.C_LMF_SECONDARY} size={20} />
                  </div>
                  <span>
                    {coverage} <span>{limit}</span> <sup>{complement}</sup>
                  </span>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <div
        className={`pricing-lmf__card__footer ${
          isRecommended && "recommended"
        } `}
      >
        <span onClick={link?.onClick}>{link?.label}</span>
        <span>
          <span>Simulateur de remboursement</span>
          <MdOpenInNew size={16} color={COLORS.C_LMF_SECONDARY} />
        </span>
      </div>
    </div>
  );
};

export default ProductCard;

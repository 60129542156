import moment from "moment";
import format from "string-template";
import { DATE_FORMAT } from "utils/constants";
import * as yup from "yup";

const validator = (
  messages,
  { deltaRia, deltaStart, deltaEnd, deltaEndRia }
) => {
  return yup.object().shape({
    contract: yup.mixed().when("ria", {
      is: (ria) => {
        return ria;
      },
      then: yup.object().shape({
        issuanceDate: yup
          .date()
          .typeError(messages.REQUIRED)
          .min(
            moment().startOf("day").add(deltaRia, "days"),
            format(messages.MIN_DATE, {
              date: moment().add(deltaRia, "days").format(DATE_FORMAT),
            })
          )
          .max(
            deltaEndRia === 0
              ? moment().endOf("year")
              : moment().endOf("day").add(deltaEndRia, "days"),
            format(messages.MAX_DATE, {
              date:
                deltaEndRia === 0
                  ? moment().endOf("year").format(DATE_FORMAT)
                  : moment()
                      .endOf("day")
                      .add(deltaEndRia, "days")
                      .format(DATE_FORMAT),
            })
          )
          .required(messages.REQUIRED),
      }),
      otherwise: yup.object().shape({
        issuanceDate: yup
          .date()
          .typeError(messages.REQUIRED)
          .min(
            moment().startOf("day").add(deltaStart, "days"),
            format(messages.MIN_DATE, {
              date: moment().add(deltaStart, "days").format(DATE_FORMAT),
            })
          )
          .max(
            deltaEnd === 0
              ? moment().endOf("year")
              : moment().endOf("day").add(deltaEnd, "days"),
            format(messages.MAX_DATE, {
              date:
                deltaEnd === 0
                  ? moment().endOf("year").format(DATE_FORMAT)
                  : moment()
                      .endOf("day")
                      .add(deltaEnd, "days")
                      .format(DATE_FORMAT),
            })
          )
          .required(messages.REQUIRED),
      }),
    }),
  });
};

export default validator;

import { get } from "lodash";
import { useState } from "react";
import { MdArrowDropDown } from "react-icons/md";
import { CURRENCY_SYMBOL } from "utils/constants";
import Button from "../Button";
import icon from "../svg/money-icon.svg";
import Cart from "./Cart";
import Profile from "./Profile";

const View = ({
  generalCost,
  subscription,
  modules = [],
  onEdit,
  onRelatedPersonEdit,
  onProfileEdit,
  contact,
}) => {
  const [open, setOpen] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  return (
    <>
      <Profile
        open={openProfile}
        setOpen={setOpenProfile}
        onEdit={onProfileEdit}
        onRelatedPersonEdit={onRelatedPersonEdit}
        contact={contact}
      />
      <Cart
        open={open}
        setOpen={setOpen}
        generalCost={generalCost}
        subscription={subscription}
        onEdit={onEdit}
        modules={modules}
      />
      {contact && (
        <div
          className="profile-card-container"
          onClick={() => setOpenProfile(true)}
        >
          <span>
            {get(contact, "user.firstname")} {get(contact, "user.lastname")}
          </span>
          <MdArrowDropDown size={20} />
        </div>
      )}
      <Button
        onClick={() => setOpen(true)}
        type="primary"
        className="cart-button"
      >
        <img src={icon} />
        <span className="amount">
          {modules
            .reduce(
              (acc, { cost }) => (acc += cost),
              get(generalCost, "cost", 0)
            )
            .toFixed(2)}
          {CURRENCY_SYMBOL[generalCost?.currency]}
          <span className="currency">
            <small> /mois</small>
          </span>
        </span>
      </Button>
    </>
  );
};

export default View;

import { Col, Row } from "antd";
import classNames from "classnames";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { GENDER } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import Button from "../Components/Button";
import Radio from "../Components/Radio";
import InputChecked from "../Components/svg/check_small.svg";
import InputError from "../Components/svg/error.svg";
import validation from "./validation";

const View = ({ initialValues, locked, onSubmit }) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("B2C_LMF.RELATED_PERSONS", { returnObjects: true });
  const { isValid, handleSubmit, setFieldValue, values, ...formProps } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema: validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, {
          returnObjects: true,
        }),
        {
          hasPartner: !isEmpty(get(initialValues, "spouse")),
        }
      ),
    });

  return (
    <>
      <h1 className="header-title">{TRANSLATION.TITLE}</h1>
      <span
        className="header-description"
        dangerouslySetInnerHTML={{ __html: TRANSLATION.DESCRIPTION }}
      ></span>
      <div className="__LMF--content LMF-content related-person medium">
        {get(values, "spouse") && (
          <div className="related-person--children">
            <span className="related-person--children_title">
              {TRANSLATION.SPOUSE}
            </span>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Radio
                  type="simple"
                  disabled={false}
                  values={[
                    {
                      value: GENDER.MALE,
                      label: TRANSLATION.GENDER.OPTIONS.MR,
                    },
                    {
                      value: GENDER.FEMALE,
                      label: TRANSLATION.GENDER.OPTIONS.MRS,
                    },
                  ]}
                  {...bindInputProps({
                    name: `spouse.identity.user.gender`,
                    values,
                    setFieldValue,
                    ...formProps,
                  })}
                  onChange={(value) => {
                    setFieldValue(`spouse.identity.user.gender`, value);
                  }}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Input
                  label={TRANSLATION.LAST_NAME.LABEL}
                  placeholder={TRANSLATION.LAST_NAME.PLACEHOLDER}
                  {...bindInputProps({
                    name: `spouse.identity.user.lastname`,
                    values,
                    ...formProps,
                  })}
                  className={classNames({
                    valid: get(values, "spouse.identity.user.lastname"),
                  })}
                  suffix={
                    get(formProps.errors, `spouse.identity.user.lastname`) ? (
                      <img src={InputError} />
                    ) : (
                      <img src={InputChecked} />
                    )
                  }
                  disabled={locked}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Input
                  label={TRANSLATION.FIRST_NAME.LABEL}
                  placeholder={TRANSLATION.FIRST_NAME.PLACEHOLDER}
                  {...bindInputProps({
                    name: `spouse.identity.user.firstname`,
                    values,
                    ...formProps,
                  })}
                  className={classNames({
                    valid: get(values, "spouse.identity.user.firstname"),
                  })}
                  suffix={
                    get(formProps.errors, `spouse.identity.user.firstname`) ? (
                      <img src={InputError} />
                    ) : (
                      <img src={InputChecked} />
                    )
                  }
                  disabled={locked}
                />
              </Col>
            </Row>
          </div>
        )}
        {get(values, "children", []).map((_, i) => (
          <div key={`child_${i}`} className="related-person--children">
            <span className="related-person--children_title">
              {TRANSLATION.CHILDREN[`COUNT_${i + 1}`]}
            </span>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Radio
                  type="simple"
                  disabled={false}
                  values={[
                    {
                      value: GENDER.MALE,
                      label: TRANSLATION.GENDER.OPTIONS.MR,
                    },
                    {
                      value: GENDER.FEMALE,
                      label: TRANSLATION.GENDER.OPTIONS.MRS,
                    },
                  ]}
                  {...bindInputProps({
                    name: `children.${i}.identity.user.gender`,
                    values,
                    setFieldValue,
                    ...formProps,
                  })}
                  onChange={(value) => {
                    setFieldValue(`children.${i}.identity.user.gender`, value);
                  }}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Input
                  label={TRANSLATION.LAST_NAME.LABEL}
                  placeholder={TRANSLATION.LAST_NAME.PLACEHOLDER}
                  {...bindInputProps({
                    name: `children.${i}.identity.user.lastname`,
                    values,
                    ...formProps,
                  })}
                  className={classNames({
                    valid: get(values, "children.${i}.identity.user.lastname"),
                  })}
                  suffix={
                    get(
                      formProps.errors,
                      `children.${i}.identity.user.lastname`
                    ) ? (
                      <img src={InputError} />
                    ) : (
                      <img src={InputChecked} />
                    )
                  }
                  disabled={locked}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Input
                  label={TRANSLATION.FIRST_NAME.LABEL}
                  placeholder={TRANSLATION.FIRST_NAME.PLACEHOLDER}
                  {...bindInputProps({
                    name: `children.${i}.identity.user.firstname`,
                    values,
                    ...formProps,
                  })}
                  className={classNames({
                    valid: get(values, "children.${i}.identity.user.firstname"),
                  })}
                  suffix={
                    get(
                      formProps.errors,
                      `children.${i}.identity.user.firstname`
                    ) ? (
                      <img src={InputError} />
                    ) : (
                      <img src={InputChecked} />
                    )
                  }
                  disabled={locked}
                />
              </Col>
            </Row>
          </div>
        ))}
      </div>
      <Button
        disabled={!isEmpty(formProps.errors)}
        onClick={handleSubmit}
        type="white"
        size="middle"
      >
        <span>{TRANSLATION.NEXT}</span>
      </Button>
    </>
  );
};

export default View;

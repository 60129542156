import { useState } from "react";
import { Drawer, Button } from "antd";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";

function Footer() {
  const [expanded, setExpanded] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 768 });

  const content = (
    <div className="solyon-footer__content">
      <p>
        <strong>Informations relatives aux frais de gestions</strong>
        <span>Taux de redistribution </span>Ce ratio représente la part des
        cotisations ou primes collectées par l'organisme assureur au titre de
        l'ensemble des garanties couvrant le remboursement ou l'indemnisation
        des frais occasionnés par une maladie, une maternité ou un accident
        utilisé pour le versement des prestations correspondant à ces garanties
        75 %.
      </p>
      <p>
        <span>Frais de gestion</span> Ces frais de gestion recouvrent
        l'ensemble des sommes engagées pour concevoir les contrats, les
        commercialiser (dont le réseau commercial, le marketing, les
        commissions des intermédiaires), les souscrire (dont l'encaissement
        des cotisations, la gestion des résiliations, le suivi comptable et
        juridique) et les gérer (dont le remboursement, la gestion du tiers
        payant, l'information client, l'assistance, les services, les
        prestations complémentaires), c'est-à-dire accomplir toutes les tâches
        incombant à l'organisme assureur dans le respect des garanties
        contractuelles. Ces frais sont de 22,37 %.
      </p>
      <p>
        <strong>Résiliation infra-annuelle</strong>
        Vous pouvez, à l'expiration d'un délai d'un an à compter de la
        première souscription, résilier votre contrat sans frais ni pénalités,
        à l'adresse So'Lyon Mutuelle, TSA 81938, 92894 Nanterre Cedex 9. Cette
        résiliation prendrait effet un mois après réception.
      </p>
      <p>
        <strong>Protection des données à caractère personnel</strong>
        Les informations recueillies dans le présent bulletin sont nécessaires
        pour la bonne exécution de votre contrat et font l'objet d'un
        traitement informatique destiné au fichier adhérent tenu par la
        mutuelle. Vous bénéficiez d'un droit d'accès, de rectification,
        d'opposition et d'effacement des informations vous concernant qui peut
        être exercé auprès de la mutuelle par courriel :
        protection.donnees@solyon-mutuelle.fr ou par courrier : So'Lyon
        Mutuelle — DPO - 28 rue Narcisse Bertholey 69600 Oullins-Pierre-Bénite.
      </p>
      <p>
        <strong>Droit de rétractation en cas de vente à distance</strong>
        Dans le cas d'une adhésion par internet ou courrier, vous disposez
        d'un délai de rétractation de 14 jours à partir de la date de
        signature du bulletin d'adhésion.
      </p>
      <p>
        <strong>Droit de rétractation en cas de démarchage à domicile</strong>
        En application de l'article L-221-18-1 du Code de la Mutualité, toute
        personne physique qui fait l'objet d'un démarchage à son domicile, à
        sa résidence ou à son lieu de travail, même à sa demande, et qui
        adhère dans ce cadre à un règlement ou à un contrat collectif à
        adhésion facultative à des fins qui n'entrent pas dans le cadre de son
        activité commerciale ou professionnelle, a la faculté d'y renoncer par
        lettre recommandée ou par envoi recommandé électronique avec demande
        d'avis de réception pendant le délai de quatorze jours calendaires
        révolus à compter du jour de la signature du bulletin d'adhésion sans
        avoir à justifier de motifs ni à supporter de pénalités.
      </p>
    </div>
  );

  return (
    <div className="solyon-footer">
      {isMobile ? (
        <>
          <span
            className="solyon-footer__button"
            onClick={() => setIsDrawerVisible(true)}
          >
            Mentions légales
          </span>

          <Drawer
            title="Mentions Légales"
            placement="bottom"
            className="solyon-footer__drawer"
            closable={true}
            onClose={() => setIsDrawerVisible(false)}
            open={isDrawerVisible}
            height="80vh" 
          >
            {content}
          </Drawer>
        </>
      ) : (
        <div onClick={() => setExpanded((prev) => !prev)}>
          <div
            className={classNames("solyon-footer__content", {
              "solyon-footer__content--expanded": expanded,
              "solyon-footer__content--collapsed": !expanded,
            })}
          >
            {content}
          </div>
          <div className="solyon-footer__more">
            {expanded ? "Afficher moins" : "Afficher plus"}
          </div>
        </div>
      )}
    </div>
  );
}

export default Footer;

import { REGEX_EXPS } from "utils/constants";
import * as yup from "yup";

const validation = (errors) => {
  return yup.object().shape({
    name: yup.string().typeError(errors.REQUIRED).required(errors.REQUIRED),
    email: yup
      .string()
      .typeError(errors.REQUIRED)
      .email(errors.EMAIL)
      .required(errors.REQUIRED),
    phone: yup
      .string()
      .typeError(errors.REQUIRED)
      .test("isValid", errors.INVALID_POLYNISIE, function (value) {
        if (!value) return true;
        return new RegExp(REGEX_EXPS.phonePolynisie).test(value);
      }),
  });
};

export default validation;

import cuid from "cuid";
import { find, get, isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import format from "string-template";
import graphql from "utils/api/graphql";
import { GENERATE_QUOTE_DOCUMENTS } from "utils/api/graphql/mutations/attachment";
import { BUILD_GENERAL_COSTS } from "utils/api/graphql/mutations/costs";
import { UPDATE_PROJECT } from "utils/api/graphql/mutations/projects";
import {
  CREATE_SUBSCRIPTIONS,
  UPDATE_SUBSCRIPTION,
} from "utils/api/graphql/mutations/subscription";
import { GENERAL_COSTS } from "utils/api/graphql/queries/general-cost";
import {
  INSURANCE_PLAN_PARAMS_RULES,
  INSURANCE_PLAN_RISKS,
  PROCESS_TYPES,
  SCREENS,
  SUBSCRIPTION_STATUSES,
  TEMPLATES,
} from "utils/constants";
import { downloadFiles } from "utils/helpers/files";
import View from "./View";

const Comparison = ({
  project,
  onNext,
  onBack,
  setCurrentStatus,
  setDisplayProject,
}) => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.ERRORS);
  const API_ERRORS = t("API", { returnObjects: true });
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [hideDetail, setHideDetail] = useState({});
  const [addSubscriptions] = useMutation(CREATE_SUBSCRIPTIONS);
  const [updateSubscription] = useMutation(UPDATE_SUBSCRIPTION);
  const [duplicateProject] = useMutation(graphql.mutations.DUPLICATE_PROJECT);
  const [updateProject] = useMutation(UPDATE_PROJECT);

  const generateSQLCondition = (ruleName, condition, value) => {
    return format(
      `EXISTS (SELECT 1 FROM public.rule AS r WHERE r."insurancePlanParamsId" = params.id AND r.name = '{ruleName}' AND ({condition})) OR NOT EXISTS (SELECT 1 FROM public.rule AS r WHERE r."insurancePlanParamsId" = params.id AND r.name = '{ruleName}')`,
      { ruleName, condition: condition.replace(":value", value) }
    );
  };

  const sqlConditions = [
    generateSQLCondition(
      INSURANCE_PLAN_PARAMS_RULES.IS_PHONE_SIM_OR_ESIM,
      "r.value IS NULL OR r.value = :value",
      `'${get(project, "fields.eligibilityCheck.isSimOrESim")}'`
    ),
    generateSQLCondition(
      INSURANCE_PLAN_PARAMS_RULES.IS_PHONE_UNDER_THREE_YEARS,
      "r.value IS NULL OR r.value = :value",
      `'${get(project, "fields.eligibilityCheck.isPhoneUnderThreeYears")}'`
    ),
    generateSQLCondition(
      INSURANCE_PLAN_PARAMS_RULES.IS_PHONE_PURCHASED_INTERNALLY,
      "r.value IS NULL OR r.value = :value",
      `'${get(project, "fields.eligibilityCheck.isPhonePurchasedFromVini")}'`
    ),
    generateSQLCondition(
      INSURANCE_PLAN_PARAMS_RULES.IS_PHONE_NEW_OR_GRADE_A,
      "r.value IS NULL OR r.value = :value",
      `'${get(project, "fields.eligibilityCheck.isPhoneNewOrGradeA")}'`
    ),
    generateSQLCondition(
      INSURANCE_PLAN_PARAMS_RULES.IS_PHONE_FOR_PRIVATE_USE,
      "r.value IS NULL OR r.value = :value",
      `'${get(project, "fields.eligibilityCheck.isPhoneForPrivateUse")}'`
    ),
    generateSQLCondition(
      INSURANCE_PLAN_PARAMS_RULES.IS_CUSTOMER_IN_FRENCH_POLYNESIA,
      "r.value IS NULL OR r.value = :value",
      `'${get(project, "fields.eligibilityCheck.isCustomerInFrenchPolynesia")}'`
    ),
  ];

  const [getGeneralCosts, { data }] = useLazyQuery(GENERAL_COSTS, {
    onCompleted: () => setLoading(false),
  });
  const [buildGeneralCosts] = useMutation(BUILD_GENERAL_COSTS, {
    variables: {
      data: {
        project: { id },
        insurancePlanFilter: {
          where: { risk: INSURANCE_PLAN_RISKS.TERMINAL },
          raw: sqlConditions,
        },
      },
    },
    onCompleted: ({ buildGeneralCosts }) => {
      const selectedInsurancePlans = buildGeneralCosts?.map(
        ({ insurancePlan }) => insurancePlan?.id
      );

      getGeneralCosts({
        variables: {
          where: { project: { id } },
          isIn: {
            insurancePlan: {
              id: selectedInsurancePlans,
            },
          },
          orderBy: [{ cost: "desc" }],
        },
      });
    },
  });

  const duplicate = () => {
    const contactStatus = find(
      get(state, "tracer.flow.process.processStatuses"),
      ({ template }) =>
        template === TEMPLATES[PROCESS_TYPES.B2B].THEMES.DEFAULT.CONTACT
    );
    duplicateProject({
      variables: {
        where: { id: project.id },
        data: {
          visible: true,
          statuses: [{ id: cuid(), status: { id: get(contactStatus, "id") } }],
        },
      },
      onCompleted: ({ duplicateProject }) => {
        navigate(
          `${SCREENS.PRIVATE.BROKER.PROJECTS.DETAIL.path}/${duplicateProject.id}`,
          {
            state: {
              process: get(state, "tracer.flow.process"),
              tracer: get(state, "tracer"),
            },
          }
        );
      },
    });
  };
  const [generateQuoteDocuments] = useMutation(GENERATE_QUOTE_DOCUMENTS);
  const onDownload = async ({ payload = {} }) => {
    const selectedData = get(payload, "fields.insurancePlans", []);
    return addSubscriptions({
      variables: {
        data: selectedData.map((insurancePlanId) => ({
          id: cuid(),
          project: { id: project.id },
          insurancePlan: {
            id: insurancePlanId,
          },
        })),
      },
      onCompleted: () => {
        generateQuoteDocuments({
          variables: {
            data: {
              project: { id },
              insurancePlans: selectedData.map((id) => ({ id })),
            },
          },
          onCompleted: async ({ generateQuoteDocuments: { base64 } }) => {
            downloadFiles(base64);
            onNext({
              payload: {
                ...payload,
                contract: {
                  ...get(project, "contract", {}),
                  issuanceDate: moment().add(30, "days"),
                },
              },
            });
          },
        });
      },
    });
  };

  const onSubscribe = async ({ payload = {}, actionsTemplate }) => {
    if (actionsTemplate?.status === SUBSCRIPTION_STATUSES.DRAFT)
      updateSubscription({
        variables: {
          where: { id: actionsTemplate?.id },
          data: { status: SUBSCRIPTION_STATUSES.IN_PROGRESS },
        },
      });
    onNext({
      payload,
      actionsTemplate,
    });
  };

  const onSendQuote = async ({ payload = {} }) => {
    onNext({
      payload,
    });
  };

  useEffect(() => {
    const signedSubscription = find(
      get(project, "subscriptions", []),
      ({ status }) => status === SUBSCRIPTION_STATUSES.SIGNED
    );
    if (!isEmpty(signedSubscription))
      navigate(
        `${SCREENS.PRIVATE.BROKER.SUBSCRIPTIONS.DETAIL.path}/${signedSubscription.id}`
      );
    if (project.locked) {
      getGeneralCosts({
        variables: {
          where: {
            project: { id: project.id },
            insurancePlan: { risk: INSURANCE_PLAN_RISKS.TERMINAL },
          },
          orderBy: [{ cost: "desc" }],
        },
      });
    } else buildGeneralCosts();
  }, []);

  if (loading) return <Loading />;

  return (
    <View
      project={project}
      onSubscribe={onSubscribe}
      onDownload={onDownload}
      onSendQuote={onSendQuote}
      onBack={onBack}
      data={get(data, "generalCosts.data", [])}
      loading={loading}
      openModal={openModal}
      duplicateProject={duplicate}
      setOpenModal={setOpenModal}
      hideDetail={hideDetail}
      setHideDetail={setHideDetail}
    />
  );
};

export default Comparison;

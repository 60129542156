import Card from "antd/es/card/Card";
import Offers from "entities/Offers";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdAdd } from "react-icons/md";
import Header from "shared/components/Header";
import Search from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { INITIAL_VALUES } from "utils/constants";
import CreateOffer from "../Create";
import EditOffer from "../Edit";
import View from "./View";

const List = () => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.OFFER);
  const LIST = t("LIST", { returnObjects: true });
  const [pagination, setPagination] = useState(INITIAL_VALUES);
  const [search, setSearch] = useState("");
  const [createOffer, setCreateOffer] = useState(false);
  const [editOffer, setEditOffer] = useState("");
  const filter = {
    like: isEmpty(search)
      ? undefined
      : {
          offerInsurancePlans: { insurancePlan: { name: `%${search}%` } },
        },
  };

  const onEdit = (id) => {
    setEditOffer(id);
  };

  const buttons = [
    {
      icon: <MdAdd size={24} />,
      text: LIST.HEADER.SAVE,
      onClick: () => setCreateOffer(!createOffer),
    },
  ];

  return (
    <>
      <Header title={LIST.HEADER.TITLE} actions={buttons} />
      <Card type="simple">
        <div className="m-bottom-20 grid--4">
          <Search
            placeholder={LIST.FILTERS.SEARCH}
            type="search"
            onChange={({ target: { value } }) => {
              setPagination((prev) => ({ ...prev, skip: 0 }));
              setSearch(value);
            }}
          />
        </div>
        <Offers
          View={View}
          filter={{ ...filter, ...pagination }}
          setValues={setPagination}
          skip={pagination.skip || 0}
          onEdit={onEdit}
          NoData={View}
        />
        {createOffer && (
          <CreateOffer
            open={createOffer}
            onClose={() => setCreateOffer(false)}
          />
        )}
        {editOffer && (
          <EditOffer onClose={() => setEditOffer(null)} id={editOffer} />
        )}
      </Card>
    </>
  );
};

export default List;

import { useNavigate, useParams } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { STORES } from "utils/api/graphql/queries/stores";
import { SCREENS } from "utils/constants";
import View from "./View";

const Detail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.STORES.LIST.path);
  const [updateStore] = useMutation(graphql.mutations.UPDATE_STORE, {
    refetchQueries: [
      {
        query: STORES,
        awaitRefetchQueries: true,
      },
    ],
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.ADMIN.STORES.LIST.path);
    },
  });

  const { data, loading, error } = useQuery(graphql.queries.STORE, {
    variables: { where: { id } },
  });
  const onSubmit = (data) => {
    return updateStore({
      variables: {
        where: {
          id,
        },
        data,
      },
    });
  };

  if (loading || error) return <Loading />;

  return <View onSubmit={onSubmit} data={data.store} cancel={cancel} />;
};

export default Detail;

import React from "react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper as SwiperReact, SwiperSlide } from "swiper/react";

function Swiper({ children, items, ...props }) {
  const slides = React.Children.toArray(children);

  return (
    <div className="solyon-swiper relative">
      <SwiperReact
        // slidesPerView={3}
        slidesPerView={"auto"}
        autoplay={{
          delay: 8000,
          disableOnInteraction: false,
        }}
        modules={[Navigation]}
        spaceBetween={16}
        // loop={true}
        centerInsufficientSlides={true}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        breakpoints={{
          100: { slidesPerView: 1.2, centeredSlides: true },
          320: { slidesPerView: 1.3, centeredSlides: true },
          520: { slidesPerView: 1.4, centeredSlides: true },
          640: { slidesPerView: 2, centeredSlides: false },
          768: { slidesPerView: 2, centeredSlides: false },
          840: { slidesPerView: 3, centeredSlides: false },
          1024: { slidesPerView: 3, centeredSlides: false },
          1099: { slidesPerView: 3, centeredSlides: false },
          1199: { slidesPerView: 3, centeredSlides: false },
        }}
        {...props}
      >
        {slides.map((child, index) => (
          <SwiperSlide key={index}>{child}</SwiperSlide>
        ))}
      </SwiperReact>
    </div>
  );
}

export default React.memo(Swiper);

import classNames from "classnames";
import { get } from "lodash";
import { useMemo } from "react";
import { ACTIVITY_TIME_OPTIONS, ESTABLISHMENTS } from "utils/constants";
import Button from "../Components/Button";
import Cost from "../Components/Cost";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Input from "../Components/Input";
import Select from "../Components/Select";

function View({ onNext, onBack, upsells, selectedValue, setSelectedValue }) {
  const { totalCost, currency } = useMemo(
    () => ({
      totalCost:
        upsells?.reduce((acc, curr) => acc + curr.cost, 0)?.toFixed(2) || 0,
      currency: get(upsells, `0.currency`),
    }),
    [upsells?.length]
  );

  return (
    <div className="solyon">
      <div className="solyon__container">
        <div className="solyon__wrapper">
          <Header
            title="Protégez votre revenu en cas d’arrêt de travail"
            highlight="Protégez votre revenu"
            subtitle="Modules et suppléments"
            onBack={onBack}
          />
          <div className="solyon__body">
            <div className="grid--1 max-500">
              <div className="grid--2">
                <Input
                  placeholder={"ex : 123"}
                  label={"Indice majoré"}
                  defaultValue={selectedValue.cappedIndex}
                  disabled
                />
                <Select
                  options={ACTIVITY_TIME_OPTIONS.map((value) => ({
                    label: `${value}%`,
                    value,
                  }))}
                  placeholder={"Sélectionnez ..."}
                  label={"Temps d'activité"}
                  defaultValue={selectedValue.activityTime}
                  disabled
                />
              </div>
              <>
                <Select
                  options={ESTABLISHMENTS.map((value) => ({
                    label: value,
                    value,
                  }))}
                  placeholder={"Sélectionnez ..."}
                  label={"Établissement employeur"}
                  defaultValue={selectedValue.establishment}
                  disabled
                />
                <Input
                  type="checkbox"
                  label={
                    "Je déclare être en activité, ne pas être suspendu de mes fonctions, ne pas être en disposition de disponibilité d’office, ne pas être en arrêt de travail ou ne pas avoir été en arrêt de travail, dans les deux mois qui précèdent mon adhésion, ne pas avoir repris des fonctions dans le cadre d’un temps partiel thérapeutique."
                  }
                  checked={get(selectedValue, "isActive") || false}
                  onChange={({ target: { checked } }) =>
                    setSelectedValue((prev) => ({ ...prev, isActive: checked }))
                  }
                />
              </>
            </div>
            <div className="solyon__body--actions">
              <div className={"solyon-upsells-recap"}>
                <div className={"solyon-upsells-recap__card"}>
                  <div
                    className={classNames("solyon-upsells-recap__container", {
                      "solyon-upsells-recap--gray": !selectedValue.isActive,
                    })}
                  >
                    <div className="solyon-upsells-recap__text">
                      Supplément à payer par mois
                    </div>
                    <Cost cost={totalCost} currency={currency} />
                    <Button
                      type={"primary"}
                      alignment={"center"}
                      onClick={onNext}
                    >
                      Je sécurise mon revenu
                    </Button>
                  </div>
                </div>
                <div
                  onClick={() => onNext({ ignore: true })}
                  className={classNames("solyon-upsells__item--link", {})}
                >
                  Je ne souhaite pas ajouter ce module
                </div>
              </div>
            </div>
            <div className="solyon-info">
              <strong>
                Avec la garantie complément de salaire* de So’Lyon Mutuelle,
                vous percevez 100% de votre traitement indiciaire brut,
              </strong>
              de votre nouvelle bonification indiciaire et de vos primes
              régulières en cas d’arrêt de travail pour maladie. En cas de
              longue maladie, So’Lyon Mutuelle complète le traitement de
              l’employeur pendant deux ans et en cas de maladie longue durée
              pendant trois ans.
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default View;

import { filter, find, get, isEmpty } from "lodash";
import moment from "moment";
import {
  CONTACT_POINT_SYSTEM,
  DATE_FORMAT,
  RELATIONSHIP,
} from "utils/constants";
import Button from "../Button";

const MemberInfo = ({ data, onEdit }) => {
  const telecoms = get(data, "project.contact.telecoms");
  const children = filter(get(data, "project.contact.relatedPersons", []), {
    relationship: RELATIONSHIP.CHILD,
  });
  const spouse = get(data, "project.contact.relatedPersons", []).find(
    ({ relationship }) => relationship === RELATIONSHIP.SPOUSE
  );

  return (
    <div className="member-info">
      <div className="member-info__section">
        <div className="member-info__item">
          <div className="content">
            <h3 className="title">Date d’effet souhaitée</h3>
            <p>
              {moment(get(data, "project.contract.issuanceDate")).format(
                DATE_FORMAT
              )}
            </p>
          </div>
          <Button type="link" onClick={() => onEdit("EFFECTIVE_DATE")}>
            Modifier
          </Button>
        </div>
      </div>

      <div className="member-info__section">
        <h3 className="title m-bottom-8">Informations de l’adhérent</h3>
        <div className="member-info__item">
          <div className="content">
            <span>Vous êtes</span>
            <p>
              {get(data, "project.contact.user.firstname")}{" "}
              {get(data, "project.contact.user.lastname")}
            </p>
          </div>
          <Button type="link" onClick={() => onEdit("ADHERENT_DETAILS")}>
            Modifier
          </Button>
        </div>
        <div className="member-info__item">
          <div className="content">
            <span>Date de naissance</span>
            <p>
              {moment(get(data, "project.contact.user.birthDate")).format(
                DATE_FORMAT
              )}
            </p>
          </div>
          <Button type="link" onClick={() => onEdit("CONTACT")}>
            Modifier
          </Button>
        </div>
        <div className="member-info__item">
          <div className="content">
            <span>N° de téléphone</span>
            <p>
              {get(
                find(telecoms, { system: CONTACT_POINT_SYSTEM.PHONE }),
                "value"
              )}
            </p>
          </div>
          <Button type="link" onClick={() => onEdit("ADHERENT_DETAILS")}>
            Modifier
          </Button>
        </div>
        <div className="member-info__item">
          <div className="content">
            <span>Adresse email</span>
            <p>
              {get(
                find(telecoms, { system: CONTACT_POINT_SYSTEM.EMAIL }),
                "value"
              )}
            </p>
          </div>
          <Button type="link" onClick={() => onEdit("ADHERENT_DETAILS")}>
            Modifier
          </Button>
        </div>

        <div className="member-info__item">
          <div className="content">
            <span>Adresse postale</span>
            <p>
              {get(
                find(telecoms, { system: CONTACT_POINT_SYSTEM.STREET }),
                "value"
              )}{" "}
              <br />
              {get(
                find(telecoms, { system: CONTACT_POINT_SYSTEM.ZIP_CODE }),
                "value"
              )}{" "}
              -{" "}
              {get(
                find(telecoms, { system: CONTACT_POINT_SYSTEM.CITY }),
                "value"
              )}
            </p>
          </div>
          <Button type="link" onClick={() => onEdit("ADDRESS_ADHERENT")}>
            Modifier
          </Button>
        </div>
        <div className="member-info__item">
          <div className="content">
            <span>N° de Sécurité Sociale</span>
            <p>{get(data, "project.contact.socialSecurityNumber")}</p>
          </div>
          <Button type="link" onClick={() => onEdit("SOCIAL_SECURITY_NUMBER")}>
            Modifier
          </Button>
        </div>
        {(!isEmpty(spouse) || children.length > 0) && (
          <div className="member-info__item">
            <div className="content">
              <span>Personne(s) protégée(s)</span>
              <p>Vous et votre famille</p>
            </div>
            <Button type="link" onClick={() => onEdit("BENEFICIARIES")}>
              Modifier
            </Button>
          </div>
        )}
        <div className="member-info__item">
          <div className="content">
            <span>Informations bancaires</span>
            <p><b>Titulaire du compte : </b>{get(data, "payment.payer.owner.firstName")} {get(data, "payment.payer.owner.lastName")}</p>
            <p><b>Périodicité du prélèvement : </b>{get(data, "payment.debitDay")}</p>
            <p><b>IBAN : </b>{get(data, "payment.payer.IBAN")}</p>
            <p><b>BIC : </b>{get(data, "payment.payer.BIC")}</p>
          </div>
          <Button type="link" onClick={() => onEdit("PAYMENT")}>
            Modifier
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MemberInfo;

import PropTypes from "prop-types";
import { useMemo } from "react";
import Card from "./Card";
import List from "./List";
import Simple from "./Simple";

const MODES = {
  card: Card,
  list: List,
  simple: Simple,
};

const Radio = ({ type, ...props }) => {
  const View = useMemo(() => MODES[type], [type]);
  return <View {...props} />;
};

Radio.propTypes = {
  type: PropTypes.oneOf(Object.keys(MODES)),
};

Radio.defaultProps = {
  type: "card",
};

export default Radio;
 
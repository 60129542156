import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { MdArrowForward } from "react-icons/md";
import { NAME_SPACES } from "shared/locales/constants";
import { CLIENT_CATEGORY } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import Button from "../Components/Button";
import Radio from "../Components/Radio";
import TNS from "../Components/svg/TNS.svg";
import businessLeader from "../Components/svg/businessLeader.svg";
import particular from "../Components/svg/particular.svg";
import territorialAgent from "../Components/svg/territorialAgent.svg";
import validation from "./validation";

const View = ({ contact, onNext }) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("B2C_LMF.PROFILE", { returnObjects: true });

  const { handleSubmit, values, ...formProps } = useFormik({
    initialValues: { contact },
    onSubmit: onNext,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
    ),
  });

  return (
    <>
      <h1 className="header-title">{TRANSLATION.TITLE}</h1>
      <span
        className="header-description"
        dangerouslySetInnerHTML={{ __html: TRANSLATION.DESCRIPTION }}
      ></span>
      <Radio
        disabled={false}
        values={[
          {
            label: TRANSLATION.OPTIONS.PARTICULAR.TITLE,
            value: CLIENT_CATEGORY.PARTICULAR,
            icon: particular,
            subLabel: TRANSLATION.OPTIONS.PARTICULAR.DESCRIPTION,
          },
          {
            label: TRANSLATION.OPTIONS.TNS.TITLE,
            value: CLIENT_CATEGORY.TNS,
            icon: TNS,
            subLabel: TRANSLATION.OPTIONS.TNS.DESCRIPTION,
          },
          {
            label: TRANSLATION.OPTIONS.TERRITORIAL_AGENT.TITLE,
            value: CLIENT_CATEGORY.TERRITORIAL_AGENT,
            icon: territorialAgent,
            subLabel: TRANSLATION.OPTIONS.TERRITORIAL_AGENT.DESCRIPTION,
          },
          {
            label: TRANSLATION.OPTIONS.BUSINESS_LEADER.TITLE,
            value: CLIENT_CATEGORY.BUSINESS_LEADER,
            icon: businessLeader,
            subLabel: TRANSLATION.OPTIONS.BUSINESS_LEADER.DESCRIPTION,
          },
        ]}
        {...bindInputProps({
          name: "contact.fields.profile",
          values,
          ...formProps,
        })}
        onChange={(value) => {
          formProps.setFieldValue("contact.fields.profile", value);
        }}
      />
      <Button
        disabled={!isEmpty(formProps.errors)}
        onClick={handleSubmit}
        type="primary"
        className="next"
        size="large"
      >
        <span>{TRANSLATION.NEXT}</span>
        <MdArrowForward />
      </Button>
    </>
  );
};

export default View;

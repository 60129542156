import { Radio } from "antd";
import classNames from "classnames";
import { useState } from "react";
import Label from "../../Label";

const Simple = ({
  errors,
  touched,
  label,
  icon,
  onChange,
  values = [],
  value,
  required,
  subLabel,
  row = false,
  ...rest
}) => {
  const [checkedValue, setCheckedValue] = useState(value);
  const onSelect = (e) => {
    setCheckedValue(e.target.value);
    onChange(e.target.value);
  };
  return (
    <div
      className={classNames("solyon-radio", {
        "solyon-radio__error": touched && errors,
      })}
    >
      <Label label={label} icon={icon} required={required} />
      <div className={`solyon-input--field`}>
        <Radio.Group
          onChange={onSelect}
          value={checkedValue}
          defaultValue={value}
          className={classNames({ row })}
          {...rest}
        >
          {values.map(({ label, value: _value }) => (
            <Radio
              key={`radio.${_value}`}
              value={_value}
              className={`${_value === checkedValue && "checked"}`}
            >
              <div className="solyon-radio__label"> {label}</div>
            </Radio>
          ))}
        </Radio.Group>
        {touched && errors && <p className={"input--errors"}>{errors}</p>}
      </div>
    </div>
  );
};

export default Simple;

import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Header from "shared/components/Header";
import { NAME_SPACES } from "shared/locales/constants";

import { get, isEmpty } from "lodash";
import { useState } from "react";
import { BsPatchCheck } from "react-icons/bs";
import { MdOutlineWidgets } from "react-icons/md";
import { RiPriceTag3Line } from "react-icons/ri";
import Card from "shared/components/Card";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { ActionStepper } from "shared/components/SmartphoneWidgets";
import { useQuery } from "shared/hooks/useApi";
import { BRANDS } from "utils/api/graphql/queries/brands";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const TerminalInfo = ({ onNext, onBack, project }) => {
  const [brands, setBrands] = useState([]);
  const [data, setData] = useState([]);
  const [devices, setDevices] = useState([]);
  const { loading } = useQuery(BRANDS, {
    variables: {
      orderBy: [{ name: "ASC" }, { models: { name: "DESC" } }],
    },
    onCompleted: ({ brands }) => getDevices(get(brands, "data", [])),
  });

  const getDevices = (terminalBrands = []) => {
    const brands = terminalBrands.map(
      ({ logo: brandLogo, models, ...rest }) => ({
        models: models.map(({ image, ..._ }) => ({
          image: image || brandLogo,
          ..._,
        })),
        ...rest,
      })
    );
    setBrands(brands.map(({ name }) => ({ label: name, value: name })));
    const devices =
      get(
        brands?.find(
          ({ name }) => name === get(project, "fields.terminal.brand")
        ),
        "models",
        []
      ).map(({ name, image }) => ({
        label: (
          <div className="d-flex ">
            <img
              width={16}
              height={16}
              src={image}
              alt={name}
              style={{ marginRight: 8 }}
            />
            {name}
          </div>
        ),
        value: name,
        image,
      })) || [];
    setDevices(devices);
    setData(brands);
  };

  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", {
    returnObjects: true,
  });
  const TERMINAL_INFO = t("DETAIL.TEMPLATES.TERMINAL_INFO", {
    returnObjects: true,
  });

  const onSubmit = (terminal) => {
    onNext({
      payload: {
        fields: {
          ...project.fields,
          terminal: {
            ...terminal,
            image: get(
              devices.find(({ value }) => value === terminal?.model),
              "image",
              ""
            ),
          },
        },
      },
    });
  };

  return (
    <>
      <Header key={"header"} title={DETAIL.NEW_QUOTE} />
      <Formik
        onSubmit={onSubmit}
        initialValues={get(project, "fields.terminal", {
          brand: null,
          model: null,
          purchasePrice: null,
        })}
        validationSchema={validation(
          t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
        )}
      >
        {({ handleSubmit, setFieldValue, ...formProps }) => [
          <Card
            key="card"
            type={"custom"}
            styleType={"bordered"}
            size={"medium"}
            head
            title={TERMINAL_INFO.TITLE}
            subtitle={TERMINAL_INFO.SUBTITLE}
          >
            <div className="m-top-24" />
            <h1 className="title">
              <span>{TERMINAL_INFO.TERMINAL_INFO}</span>
            </h1>
            <div className="m-top-8" />
            <Card type={"custom"} styleType={"shadow"}>
              <div className="grid--1">
                <Select
                  loading={loading}
                  showSearch
                  options={brands}
                  label={TERMINAL_INFO.BRAND}
                  placeholder={TERMINAL_INFO.SELECT_BRAND}
                  icon={<BsPatchCheck size={16} />}
                  required
                  {...bindInputProps({
                    name: "brand",
                    ...formProps,
                  })}
                  onChange={(value) => {
                    setFieldValue("brand", value);
                    setFieldValue("model", null);
                    const devices = get(
                      data?.find(({ name }) => name === value),
                      "models",
                      []
                    ).map(({ name, image }) => ({
                      label: (
                        <div className="d-flex ">
                          <img
                            width={16}
                            height={16}
                            src={image}
                            alt={name}
                            style={{ marginRight: 8 }}
                          />
                          {name}
                        </div>
                      ),
                      value: name,
                      image,
                    }));

                    setDevices(devices);
                  }}
                />
                <Select
                  showSearch
                  optionFilterProp="value"
                  options={devices}
                  label={TERMINAL_INFO.MODEL}
                  placeholder={TERMINAL_INFO.SELECT_MODEL}
                  icon={<MdOutlineWidgets size={16} />}
                  required
                  {...bindInputProps({
                    name: "model",
                    ...formProps,
                  })}
                />
                <Input
                  type="number"
                  label={TERMINAL_INFO.PURCHASE_PRICE}
                  placeholder="0"
                  suffix="XPF"
                  icon={<RiPriceTag3Line size={16} />}
                  required
                  defaultBackground
                  {...bindInputProps({
                    name: "purchasePrice",
                    ...formProps,
                  })}
                />
              </div>
            </Card>
          </Card>,
          <Card type={"custom"} styleType={"transparent"} key="actions">
            <ActionStepper
              onPrevious={onBack}
              onNext={handleSubmit}
              isNextDisabled={!isEmpty(formProps.errors)}
            />
          </Card>,
        ]}
      </Formik>
    </>
  );
};

export default TerminalInfo;

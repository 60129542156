import { Drawer } from "antd";
import classNames from "classnames";
import cuid from "cuid";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import { ATTACHMENT_TYPES, DATE_FORMAT, RELATIONSHIP } from "utils/constants";
import { downloadDocument } from "utils/helpers/files";
import { bindDateInputProps } from "utils/helpers/input";
import validation from "./validation";
import ProductCard from "./widgets/ProductCard";

import { FaMinus, FaPlus } from "react-icons/fa";
import { MdClose, MdOutlineDiversity1 } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
  hundredYearsAgoStart,
  isMajor,
  twentySevenYearsAgoStart,
} from "utils/helpers/date";
import Button from "../Components/Button";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Input from "../Components/Input";
import Label from "../Components/Label";
import Swiper from "../Components/Swiper";

const View = ({
  onSubmit,
  products,
  disabledProducts,
  contact,
  refetchCosts,
  downloadSingleDocument,
  locked,
  duplicateProject,
  onBack,
}) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [drawerVisible, setDrawerVisible] = useState(false);

  // const FLAGSHIP_GUARANTEES_MOCK = {
  //   title: "Les garanties phares",
  //   items: [
  //     {
  //       coverage: "Couronne en céramique jusqu’à",
  //       limit: "180 €",
  //     },
  //     {
  //       coverage: "Équipement verres simples jusqu’à",
  //       limit: "140 €",
  //     },
  //     {
  //       coverage: "Hospitalisation en chambre individuelle à",
  //       limit: "45 € par jour",
  //     },
  //   ],
  // };
  const TRANSLATION = t("DETAIL", { returnObjects: true });
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const onChangeRelatedPersons = async ({ spouse, children }) => {
    await refetchCosts({
      contact: {
        id: contact.id,
        relatedPersons: [spouse, ...children],
      },
    });
    setAddBeneficiaries((prev) => ({ ...prev, visible: false }));
  };

  const { handleSubmit, values, ...formProps } = useFormik({
    initialValues: {
      spouse: get(contact, "relatedPersons", []).find(
        ({ relationship }) => relationship === RELATIONSHIP.SPOUSE
      ),
      children: get(contact, "relatedPersons", []).filter(
        ({ relationship }) => relationship === RELATIONSHIP.CHILD
      ),
    },
    onSubmit: onChangeRelatedPersons,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
    ),
  });
  const [childrenNumber, setChildrenNumber] = useState(
    get(values, "children", []).length
  );

  const [addBeneficiaries, setAddBeneficiaries] = useState({
    visible: false,
    spouse: !isEmpty(get(values, "spouse")),
    children: !isEmpty(get(values, "children")),
    status: !(
      isEmpty(get(values, "spouse")) && isEmpty(get(values, "children"))
    ),
  });
  const [disabled, setDisabled] = useState({});

  useEffect(() => {
    const children = get(values, "children", []);
    const oldChildrenLength = children.length;

    if (oldChildrenLength < childrenNumber) {
      formProps.setFieldValue("children", [
        ...children,
        {
          id: cuid(),
          relationship: RELATIONSHIP.CHILD,
          identity: {
            id: cuid(),
            user: {
              id: cuid(),
              birthDate: null,
            },
          },
        },
      ]);
    } else if (oldChildrenLength > childrenNumber) {
      formProps.setFieldValue("children", children.slice(0, childrenNumber));
    }
  }, [childrenNumber]);

  useEffect(() => {
    if (prevRef.current && nextRef.current) {
      prevRef.current.classList.add("custom-prev");
      nextRef.current.classList.add("custom-next");
    }
  }, []);

  const handleYesClick = () => {
    setAddBeneficiaries((prev) => ({
      ...prev,
      visible: true,
      status: true,
    }));
  };

  const handleNoClick = () => {
    if (!addBeneficiaries?.status) return;

    formProps.setFieldValue("spouse", undefined);
    formProps.setFieldValue("children", []);
    setChildrenNumber(0);
    setAddBeneficiaries({
      spouse: false,
      children: false,
      visible: false,
      status: false,
    });
    handleSubmit();
  };

  return (
    <div className="solyon">
      <div className="solyon__container">
        <div className="solyon__wrapper">
          <Header
            title="Vous y êtes ! Voici nos offres spécialement conçues pour vous"
            highlight="nos offres"
            subtitle="Offres"
            // onBack={onBack}
          />
          <div className="solyon__body">
            {/* <div className="related__persons">
            <div className="--head">
              <div className="--left">
                <MdOutlineDiversity1 size={24} />
                <span className="question">
                  {TRANSLATION.PRODUCTS.ADD_BENEFICIARY}
                </span>
              </div>

              <div className="--actions">
                <Button
                  size="small"
                  type={classNames({
                    primary: addBeneficiaries?.status,
                    "gray-light": !addBeneficiaries?.status,
                  })}
                  onClick={handleYesClick}
                >
                  {TRANSLATION.YES}
                </Button>

                <Button
                  size="small"
                  type={classNames({
                    primary: !addBeneficiaries?.status,
                    "gray-light": addBeneficiaries?.status,
                  })}
                  onClick={handleNoClick}
                >
                  {TRANSLATION.NO}
                </Button>
              </div>
            </div>

            <div
              className={classNames("--forms", {
                hidden: !addBeneficiaries.visible,
              })}
            >
              <div className="beneficiary_container--expand--row">
                <Input
                  type="checkbox"
                  label={TRANSLATION.PRODUCTS.SPOUSE}
                  checked={addBeneficiaries.spouse}
                  onChange={({ target: { checked } }) => {
                    setAddBeneficiaries((prev) => ({
                      ...prev,
                      spouse: checked,
                    }));
                    if (checked)
                      formProps.setFieldValue("spouse", {
                        id: cuid(),
                        relationship: RELATIONSHIP.SPOUSE,
                        identity: {
                          id: cuid(),
                          user: {
                            id: cuid(),
                            birthDate: null,
                          },
                        },
                      });
                    else formProps.setFieldValue("spouse", undefined);
                  }}
                />
                <Input
                  width={261}
                  type="date"
                  label={TRANSLATION.BIRTH_DATE.LABEL}
                  placeholder={"JJ / MM / AAAA"}
                  format={DATE_FORMAT}
                  disabled={!addBeneficiaries.spouse}
                  showToday={false}
                  {...bindDateInputProps({
                    name: "spouse.identity.user.birthDate",
                    values,
                    ...formProps,
                  })}
                  defaultPickerValue={
                    !get(values, `spouse.identity.user.birthDate`) &&
                    dayjs().subtract(24, "years")
                  }
                  disabledDate={(current) =>
                    current > isMajor || current < hundredYearsAgoStart
                  }
                />
              </div>
              <div className="divider" />
              <div className="beneficiary_container--expand--row">
                <Input
                  type="checkbox"
                  label={TRANSLATION.PRODUCTS.CHILDREN}
                  checked={addBeneficiaries.children}
                  onChange={({ target: { checked } }) => {
                    if (!checked) {
                      setChildrenNumber(0);
                      formProps.setFieldValue("children", []);
                    } else if (childrenNumber === 0) setChildrenNumber(1);
                    setAddBeneficiaries((prev) => ({
                      ...prev,
                      children: checked,
                    }));
                  }}
                />
                <div className="children-count">
                  <Button
                    type={"primary"}
                    className="children-count__button"
                    onClick={() => setChildrenNumber((prev) => +prev - 1)}
                    disabled={
                      childrenNumber < 2 || !addBeneficiaries.children
                    }
                  >
                    <FaMinus size={14} />
                  </Button>
                  <div className="flex--grow-1">
                    <Input
                      type="number"
                      min={0}
                      max={12}
                      placeholder={"0"}
                      name={"gender"}
                      value={childrenNumber}
                      onChange={({ target: { value } }) => {
                        setChildrenNumber(+value);
                      }}
                      disabled={!addBeneficiaries.children}
                    />
                  </div>
                  <Button
                    type={"primary"}
                    className="children-count__button"
                    onClick={() => setChildrenNumber((prev) => +prev + 1)}
                    disabled={
                      childrenNumber > 11 || !addBeneficiaries.children
                    }
                  >
                    <FaPlus size={14} />
                  </Button>
                </div>
              </div>
              <div className="children-birthday">
                {Array(childrenNumber)
                  .fill()
                  .map((_, i) => (
                    <div
                      key={`child-${i}`}
                      className="children-birthday--row"
                    >
                      <Label
                        label={`${TRANSLATION.NUMBERS_MAPPING[i + 1] || i + 1
                          } ${TRANSLATION.CHILDREN_BIRTHDATES.CHILD}`}
                      />
                      <Input
                        width={261}
                        label={TRANSLATION.BIRTH_DATE.LABEL}
                        placeholder={"JJ / MM / AAAA"}
                        type="date"
                        variant="simple"
                        format={DATE_FORMAT}
                        disabledDate={(current) => {
                          return (
                            current > dayjs() ||
                            current < twentySevenYearsAgoStart
                          );
                        }}
                        defaultPickerValue={
                          !get(
                            values,
                            `children.${i}.identity.user.birthDate`
                          ) && dayjs().subtract(1, "years")
                        }
                        showToday={false}
                        {...bindDateInputProps({
                          name: `children.${i}.identity.user.birthDate`,
                          values,
                          ...formProps,
                        })}
                      />
                    </div>
                  ))}
              </div>
              <Button
                block
                type="primary"
                onClick={() => handleSubmit()}
                disabled={
                  !isEmpty(formProps.errors) ||
                  (childrenNumber === 0 && !addBeneficiaries.spouse)
                }
              >
                {TRANSLATION.PRODUCTS.SUBMIT}
              </Button>

            </div>

          </div> */}
            <div className="related_persons">
              <div className="beneficiary_container">
                <div
                  className="beneficiary_container--main"
                  {...(isMobile && {
                    onClick: () => {
                      if (isMobile) setDrawerVisible(true);
                      else
                        setAddBeneficiaries((prev) => ({
                          ...prev,
                          visible: !prev.visible,
                        }));
                    },
                  })}
                >
                  <div className="beneficiary_container--main--left">
                    <MdOutlineDiversity1 size={24} />

                    {isMobile ? (
                      <span className="question">
                        {addBeneficiaries.spouse || childrenNumber > 0
                          ? TRANSLATION.PRODUCTS.YOUR_BENEFICIARIES
                          : TRANSLATION.PRODUCTS.ADD_BENEFICIARY}
                      </span>
                    ) : (
                      <span className="question">
                        {TRANSLATION.PRODUCTS.ADD_BENEFICIARY}
                      </span>
                    )}
                  </div>

                  {isMobile &&
                    (addBeneficiaries.spouse || childrenNumber > 0) && (
                      <span className="selected--beneficiaries">
                        {addBeneficiaries.spouse && childrenNumber > 0
                          ? "Conjoint et " +
                            childrenNumber +
                            " enfant" +
                            (childrenNumber > 1 ? "s" : "")
                          : addBeneficiaries.spouse
                          ? "Conjoint"
                          : childrenNumber > 0
                          ? childrenNumber +
                            " enfant" +
                            (childrenNumber > 1 ? "s" : "")
                          : ""}
                      </span>
                    )}

                  <div className="--action">
                    <Button
                      size="small"
                      type={classNames({
                        primary: addBeneficiaries?.status,
                        "gray-light": !addBeneficiaries?.status,
                      })}
                      onClick={handleYesClick}
                    >
                      {TRANSLATION.YES}
                    </Button>

                    <Button
                      size="small"
                      type={classNames({
                        primary: !addBeneficiaries?.status,
                        "gray-light": addBeneficiaries?.status,
                      })}
                      onClick={handleNoClick}
                    >
                      {TRANSLATION.NO}
                    </Button>
                  </div>
                </div>
                {!isMobile && (
                  <div
                    className={classNames("beneficiary_container--expand", {
                      hidden: !addBeneficiaries.visible,
                    })}
                  >
                    <div className="beneficiary_container--expand--row">
                      <Input
                        type="checkbox"
                        label={TRANSLATION.PRODUCTS.SPOUSE}
                        checked={addBeneficiaries.spouse}
                        onChange={({ target: { checked } }) => {
                          setAddBeneficiaries((prev) => ({
                            ...prev,
                            spouse: checked,
                          }));
                          if (checked)
                            formProps.setFieldValue("spouse", {
                              id: cuid(),
                              relationship: RELATIONSHIP.SPOUSE,
                              identity: {
                                id: cuid(),
                                user: {
                                  id: cuid(),
                                  birthDate: null,
                                },
                              },
                            });
                          else formProps.setFieldValue("spouse", undefined);
                        }}
                      />
                      <Input
                        // width={261}
                        type="date"
                        label={TRANSLATION.BIRTH_DATE.LABEL}
                        placeholder={"JJ / MM / AAAA"}
                        format={DATE_FORMAT}
                        disabled={!addBeneficiaries.spouse}
                        showToday={false}
                        {...bindDateInputProps({
                          name: "spouse.identity.user.birthDate",
                          values,
                          ...formProps,
                        })}
                        defaultPickerValue={
                          !get(values, `spouse.identity.user.birthDate`) &&
                          dayjs().subtract(24, "years")
                        }
                        disabledDate={(current) =>
                          current > isMajor || current < hundredYearsAgoStart
                        }
                      />
                    </div>
                    <div className="divider" />
                    <div className="beneficiary_container--expand--row">
                      <Input
                        type="checkbox"
                        label={TRANSLATION.PRODUCTS.CHILDREN}
                        checked={addBeneficiaries.children}
                        onChange={({ target: { checked } }) => {
                          if (!checked) {
                            setChildrenNumber(0);
                            formProps.setFieldValue("children", []);
                          } else if (childrenNumber === 0) setChildrenNumber(1);
                          setAddBeneficiaries((prev) => ({
                            ...prev,
                            children: checked,
                          }));
                        }}
                      />
                      <div className="children-count">
                        <Button
                          type={"primary"}
                          className="children-count__button"
                          onClick={() => setChildrenNumber((prev) => +prev - 1)}
                          disabled={
                            childrenNumber < 2 || !addBeneficiaries.children
                          }
                        >
                          <FaMinus size={14} />
                        </Button>
                        <div className="flex--grow-1">
                          <Input
                            type="number"
                            min={0}
                            max={12}
                            placeholder={"0"}
                            name={"gender"}
                            value={childrenNumber}
                            onChange={({ target: { value } }) => {
                              setChildrenNumber(+value);
                            }}
                            disabled={!addBeneficiaries.children}
                          />
                        </div>
                        <Button
                          type={"primary"}
                          className="children-count__button"
                          onClick={() => setChildrenNumber((prev) => +prev + 1)}
                          disabled={
                            childrenNumber > 11 || !addBeneficiaries.children
                          }
                        >
                          <FaPlus size={14} />
                        </Button>
                      </div>
                    </div>
                    <div className="children-birthday">
                      {Array(childrenNumber)
                        .fill()
                        .map((_, i) => (
                          <div
                            key={`child-${i}`}
                            className="children-birthday--row"
                          >
                            <Label
                              label={`${
                                TRANSLATION.NUMBERS_MAPPING[i + 1] || i + 1
                              } ${TRANSLATION.CHILDREN_BIRTHDATES.CHILD}`}
                            />
                            <Input
                              // width={"100%"}
                              label={TRANSLATION.BIRTH_DATE.LABEL}
                              placeholder={"JJ / MM / AAAA"}
                              type="date"
                              variant="simple"
                              format={DATE_FORMAT}
                              disabledDate={(current) => {
                                return (
                                  current > dayjs() ||
                                  current < twentySevenYearsAgoStart
                                );
                              }}
                              defaultPickerValue={
                                !get(
                                  values,
                                  `children.${i}.identity.user.birthDate`
                                ) && dayjs().subtract(1, "years")
                              }
                              showToday={false}
                              {...bindDateInputProps({
                                name: `children.${i}.identity.user.birthDate`,
                                values,
                                ...formProps,
                              })}
                            />
                          </div>
                        ))}
                    </div>
                    <Button
                      block
                      type="primary"
                      onClick={() => handleSubmit()}
                      disabled={
                        !isEmpty(formProps.errors) ||
                        (childrenNumber === 0 && !addBeneficiaries.spouse)
                      }
                    >
                      {TRANSLATION.PRODUCTS.SUBMIT}
                    </Button>
                  </div>
                )}
              </div>
              {!isMobile && (childrenNumber > 0 || addBeneficiaries.spouse) && (
                <div className="beneficiary_chips">
                  {addBeneficiaries.spouse &&
                    get(values, "spouse.identity.user.birthDate") && (
                      <div className="beneficiary_chips--button">
                        <span>{TRANSLATION.PRODUCTS.SPOUSE}</span>
                        <MdClose
                          size={20}
                          onClick={() => {
                            formProps.setFieldValue("spouse", undefined);
                            setAddBeneficiaries((prev) => ({
                              ...prev,
                              spouse: false,
                            }));
                            handleSubmit();
                          }}
                        />
                      </div>
                    )}
                  {get(values, "children", [])
                    .filter((child) => get(child, "identity.user.birthDate"))
                    .map((_, i) => (
                      <div
                        key={`child_${i}`}
                        className="beneficiary_chips--button"
                      >
                        <span>{`${TRANSLATION.CHILDREN.CHILD.TITLE} ${
                          i + 1
                        }`}</span>

                        <MdClose
                          onClick={() => {
                            values.children.splice(i, 1);
                            setChildrenNumber((prev) => +prev - 1);
                            if (childrenNumber === 1)
                              setAddBeneficiaries((prev) => ({
                                ...prev,
                                children: false,
                              }));
                            formProps.setFieldValue(
                              "children",
                              get(values, "children", [])
                            );
                            handleSubmit();
                          }}
                          size={20}
                        />
                      </div>
                    ))}
                </div>
              )}
            </div>
            <div className="solyon-products">
              <Swiper>
                {products.map(({ id, cost, currency, insurancePlan }, i) => (
                  <ProductCard
                    price={{
                      cost,
                      currency,
                      period: TRANSLATION.PRODUCTS.PER_MONTH,
                    }}
                    name={get(insurancePlan, "name")}
                    icon={get(insurancePlan, "icon")}
                    action={{
                      onClick: () => {
                        setDisabled({ [id]: true });
                        onSubmit(insurancePlan.id);
                      },
                      label: TRANSLATION.PRODUCTS.CHOOSE_OFFER,
                      loading: disabled[id],
                      disabled: !isEmpty(disabled) || addBeneficiaries.visible,
                    }}
                    disabled={disabledProducts.includes(insurancePlan.id)}
                    info={{
                      title: "Les garanties phares",
                      items: get(
                        insurancePlan,
                        `fields.flagshipGuarantees`,
                        []
                      ),
                    }}
                    link={{
                      onClick: () => {
                        downloadSingleDocument({
                          variables: {
                            where: {
                              insurancePlans: { id: insurancePlan?.id },
                              type: ATTACHMENT_TYPES.BROCHURE,
                            },
                          },
                          onCompleted: (_) => {
                            const { document } = omitDeep(
                              _.downloadSingleDocument,
                              "__typename"
                            );
                            downloadDocument(document);
                          },
                        });
                      },
                      label: TRANSLATION.PRODUCTS.COVERAGE_DETAIL,
                    }}
                    recommendation={{
                      label: "Recommandé",
                      value: i === 1,
                    }}
                  />
                ))}
              </Swiper>
              <div className="swiper__arrows">
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>
              </div>
            </div>
          </div>
          <Footer />

          <Drawer
            title={TRANSLATION.PRODUCTS.ADD_BENEFICIARY}
            placement="bottom"
            className="solyon__drawer"
            closable
            onClose={() => setDrawerVisible(false)}
            open={drawerVisible}
            height="85vh"
          >
            {(childrenNumber > 0 || addBeneficiaries.spouse) && (
              <div className="beneficiary_chips">
                {addBeneficiaries.spouse &&
                  get(values, "spouse.identity.user.birthDate") && (
                    <div className="beneficiary_chips--button">
                      <span>{TRANSLATION.PRODUCTS.SPOUSE}</span>
                      <MdClose
                        size={20}
                        onClick={() => {
                          formProps.setFieldValue("spouse", undefined);
                          setAddBeneficiaries((prev) => ({
                            ...prev,
                            spouse: false,
                          }));
                          handleSubmit();
                        }}
                      />
                    </div>
                  )}
                {get(values, "children", [])
                  .filter((child) => get(child, "identity.user.birthDate"))
                  .map((_, i) => (
                    <div
                      key={`child_${i}`}
                      className="beneficiary_chips--button"
                    >
                      <span>{`${TRANSLATION.CHILDREN.CHILD.TITLE} ${
                        i + 1
                      }`}</span>

                      <MdClose
                        onClick={() => {
                          values.children.splice(i, 1);
                          setChildrenNumber((prev) => +prev - 1);
                          if (childrenNumber === 1)
                            setAddBeneficiaries((prev) => ({
                              ...prev,
                              children: false,
                            }));
                          formProps.setFieldValue(
                            "children",
                            get(values, "children", [])
                          );
                          handleSubmit();
                        }}
                        size={20}
                      />
                    </div>
                  ))}
              </div>
            )}
            <div className="beneficiary_container">
              <div
                className={classNames("beneficiary_container--expand", {
                  // hidden: !addBeneficiaries.visible,
                })}
              >
                <div className="beneficiary_container--expand--row">
                  <Input
                    type="checkbox"
                    label={TRANSLATION.PRODUCTS.SPOUSE}
                    checked={addBeneficiaries.spouse}
                    onChange={({ target: { checked } }) => {
                      setAddBeneficiaries((prev) => ({
                        ...prev,
                        spouse: checked,
                      }));
                      if (checked)
                        formProps.setFieldValue("spouse", {
                          id: cuid(),
                          relationship: RELATIONSHIP.SPOUSE,
                          identity: {
                            id: cuid(),
                            user: {
                              id: cuid(),
                              birthDate: null,
                            },
                          },
                        });
                      else formProps.setFieldValue("spouse", undefined);
                    }}
                  />
                  <Input
                    // width={261}
                    type="date"
                    label={TRANSLATION.BIRTH_DATE.LABEL}
                    placeholder={"JJ / MM / AAAA"}
                    format={DATE_FORMAT}
                    disabled={!addBeneficiaries.spouse}
                    showToday={false}
                    {...bindDateInputProps({
                      name: "spouse.identity.user.birthDate",
                      values,
                      ...formProps,
                    })}
                    defaultPickerValue={
                      !get(values, `spouse.identity.user.birthDate`) &&
                      dayjs().subtract(24, "years")
                    }
                    disabledDate={(current) =>
                      current > isMajor || current < hundredYearsAgoStart
                    }
                  />
                </div>
                <div className="divider" />
                <div className="beneficiary_container--expand--row childs">
                  <Input
                    type="checkbox"
                    label={TRANSLATION.PRODUCTS.CHILDREN}
                    checked={addBeneficiaries.children}
                    onChange={({ target: { checked } }) => {
                      if (!checked) {
                        setChildrenNumber(0);
                        formProps.setFieldValue("children", []);
                      } else if (childrenNumber === 0) setChildrenNumber(1);
                      setAddBeneficiaries((prev) => ({
                        ...prev,
                        children: checked,
                      }));
                    }}
                  />
                  <div className="children-count">
                    <Button
                      type={"primary"}
                      className="children-count__button"
                      onClick={() => setChildrenNumber((prev) => +prev - 1)}
                      disabled={
                        childrenNumber < 2 || !addBeneficiaries.children
                      }
                    >
                      <FaMinus size={14} />
                    </Button>
                    <div className="flex--grow-1">
                      <Input
                        type="number"
                        min={0}
                        max={12}
                        placeholder={"0"}
                        name={"gender"}
                        value={childrenNumber}
                        onChange={({ target: { value } }) => {
                          setChildrenNumber(+value);
                        }}
                        disabled={!addBeneficiaries.children}
                      />
                    </div>
                    <Button
                      type={"primary"}
                      className="children-count__button"
                      onClick={() => setChildrenNumber((prev) => +prev + 1)}
                      disabled={
                        childrenNumber > 11 || !addBeneficiaries.children
                      }
                    >
                      <FaPlus size={14} />
                    </Button>
                  </div>
                </div>
                <div className="children-birthday">
                  {Array(childrenNumber)
                    .fill()
                    .map((_, i) => (
                      <div
                        key={`child-${i}`}
                        className="children-birthday--row"
                      >
                        <Label
                          label={`${
                            TRANSLATION.NUMBERS_MAPPING[i + 1] || i + 1
                          } ${TRANSLATION.CHILDREN_BIRTHDATES.CHILD}`}
                        />
                        <Input
                          // width={"100%"}
                          label={TRANSLATION.BIRTH_DATE.LABEL}
                          placeholder={"JJ / MM / AAAA"}
                          type="date"
                          variant="simple"
                          format={DATE_FORMAT}
                          disabledDate={(current) => {
                            return (
                              current > dayjs() ||
                              current < twentySevenYearsAgoStart
                            );
                          }}
                          defaultPickerValue={
                            !get(
                              values,
                              `children.${i}.identity.user.birthDate`
                            ) && dayjs().subtract(1, "years")
                          }
                          showToday={false}
                          {...bindDateInputProps({
                            name: `children.${i}.identity.user.birthDate`,
                            values,
                            ...formProps,
                          })}
                        />
                      </div>
                    ))}
                </div>
                <Button
                  block
                  type="primary"
                  onClick={() => {
                    handleSubmit();
                    setDrawerVisible(false);
                  }}
                  disabled={
                    !isEmpty(formProps.errors) ||
                    (childrenNumber === 0 && !addBeneficiaries.spouse)
                  }
                >
                  {TRANSLATION.PRODUCTS.SUBMIT}
                </Button>
              </div>
            </div>
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default View;

import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Header from "shared/components/Header";
import { NAME_SPACES } from "shared/locales/constants";

import { get } from "lodash";
import { BiCalendarCheck, BiShoppingBag } from "react-icons/bi";
import { BsPatchCheck, BsSim } from "react-icons/bs";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { RiRoadMapLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Alert from "shared/components/Alert";
import Card from "shared/components/Card";
import Input from "shared/components/Input";
import { ActionStepper } from "shared/components/SmartphoneWidgets";
import { SCREENS } from "utils/constants";

const EligibilityCheck = ({ onNext, project }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", {
    returnObjects: true,
  });
  const ELIGIBILITY_CHECK = t("DETAIL.TEMPLATES.ELIGIBILITY_CHECK", {
    returnObjects: true,
  });

  const onSubmit = (eligibilityCheck) => {
    onNext({
      payload: {
        fields: {
          ...project.fields,
          eligibilityCheck,
        },
      },
    });
  };

  const onCancel = () => {
    navigate(SCREENS.PRIVATE.BROKER.PROJECTS.LIST.path, { replace: true });
  };

  const areAllValuesTrue = (eligibility) => {
    const values = Object.values(eligibility);
    return values.every((value) => value === true);
  };

  return (
    <>
      <Header key={"header"} title={DETAIL.NEW_QUOTE} />
      <Formik
        onSubmit={onSubmit}
        initialValues={get(project, "fields.eligibilityCheck", {
          isSimOrESim: false,
          isPhoneNewOrGradeA: false,
          isPhonePurchasedFromVini: false,
          isPhoneUnderThreeYears: false,
          isPhoneForPrivateUse: false,
          isCustomerInFrenchPolynesia: false,
        })}
      >
        {({ values, setFieldValue }) => [
          <Card
            key="card"
            type={"custom"}
            styleType={"bordered"}
            size={"medium"}
            head
            title={ELIGIBILITY_CHECK.TITLE}
          >
            <div className="m-top-24" />
            <Alert type="warning" message={ELIGIBILITY_CHECK.ALERT} showIcon />
            <div className="m-top-24" />
            <Card type={"custom"} styleType={"shadow"}>
              <div className="grid--1 grid-gap-32">
                <Input
                  name="isSimOrESim"
                  type="switch"
                  label={ELIGIBILITY_CHECK.IS_SIM_OR_ESIM}
                  icon={<BsSim size={16} />}
                  defaultChecked={get(values, "isSimOrESim")}
                  onChange={(e) => setFieldValue("isSimOrESim", e)}
                />
                <Input
                  name="isPhoneNewOrGradeA"
                  type="switch"
                  label={ELIGIBILITY_CHECK.IS_NEW_OR_GRADE_A}
                  icon={<BsPatchCheck size={16} />}
                  defaultChecked={get(values, "isPhoneNewOrGradeA")}
                  onChange={(e) => setFieldValue("isPhoneNewOrGradeA", e)}
                />
                <Input
                  name="isPhonePurchasedFromVini"
                  type="switch"
                  label={ELIGIBILITY_CHECK.IS_PHONE_FROM_VINI}
                  icon={<BiShoppingBag size={16} />}
                  defaultChecked={get(values, "isPhonePurchasedFromVini")}
                  onChange={(e) => setFieldValue("isPhonePurchasedFromVini", e)}
                />
                <Input
                  name="isPhoneUnderThreeYears"
                  type="switch"
                  label={ELIGIBILITY_CHECK.IS_PHONE_UNDER_THREE_YEARS}
                  icon={<BiCalendarCheck size={16} />}
                  defaultChecked={get(values, "isPhoneUnderThreeYears")}
                  onChange={(e) => setFieldValue("isPhoneUnderThreeYears", e)}
                />
                <Input
                  name="isPhoneForPrivateUse"
                  type="switch"
                  label={ELIGIBILITY_CHECK.IS_PHONE_FOR_PRIVATE_USE}
                  icon={<MdOutlineAdminPanelSettings size={16} />}
                  defaultChecked={get(values, "isPhoneForPrivateUse")}
                  onChange={(e) => setFieldValue("isPhoneForPrivateUse", e)}
                />
                <Input
                  name="isCustomerInFrenchPolynesia"
                  type="switch"
                  label={ELIGIBILITY_CHECK.IS_CUSTOMER_IN_FRENCH_POLYNESIA}
                  icon={<RiRoadMapLine size={16} />}
                  defaultChecked={get(values, "isCustomerInFrenchPolynesia")}
                  onChange={(e) =>
                    setFieldValue("isCustomerInFrenchPolynesia", e)
                  }
                />
              </div>
            </Card>
          </Card>,
          <Card type={"custom"} styleType={"transparent"} key="actions">
            <ActionStepper
              prevLabel={ELIGIBILITY_CHECK.CANCEL}
              onPrevious={onCancel}
              onNext={() => onSubmit(values)}
              isNextDisabled={!areAllValuesTrue(values)}
            />
          </Card>,
        ]}
      </Formik>
    </>
  );
};

export default EligibilityCheck;

import {
  CONTACT_POINT_SYSTEM,
  PAYMENT_TYPES,
  REGEX_EXPS,
  SUBSCRIBER_TYPES,
} from "utils/constants";
import { isMajor } from "utils/helpers/date";
import * as yup from "yup";

const validation = (messages, { paymentType, subscriberType }) => {
  const string = yup.string().typeError(messages.NOT_STRING).nullable();

  const nameValidation = yup
    .string()
    .typeError(messages.NOT_STRING)
    .nullable()
    .notRequired()
    .test(
      "is-valid-name",
      messages.INVALID_NAME,
      (value) => !value || REGEX_EXPS.name.test(value)
    );
  return yup.object().shape({
    project: yup.object().shape({
      contact: yup.object().shape({
        user: yup.object().shape({
          gender: yup
            .string()
            .typeError(messages.REQUIRED_CHOICE)
            .required(messages.REQUIRED_CHOICE),
          firstname: nameValidation,
          birthName: nameValidation,
          lastname: nameValidation,
          birthDate: yup
            .date()
            .typeError(messages.REQUIRED)
            .max(isMajor, messages.AGE_18)
            .required(messages.REQUIRED),
        }),
      }),
      fields: yup.object().shape({
        serialNumber: string,
        imei: yup
          .string()
          .typeError(messages.REQUIRED)
          .required(messages.REQUIRED)
          .nullable()
          .test("isValid", messages.INVALID_IMEI_VALUE, function (value) {
            return value
              ? new RegExp(REGEX_EXPS.imei).test(value.replace(/\s+/g, ""))
              : true;
          }),
        purchaseDate: yup
          .string()
          .typeError(messages.REQUIRED)
          .required(messages.REQUIRED),
        purchasePlace: yup
          .string()
          .typeError(messages.REQUIRED)
          .required(messages.REQUIRED),
      }),
    }),
    telecoms: yup.object().shape({
      contact: yup.object().shape({
        [CONTACT_POINT_SYSTEM.MAILBOX]: yup.object().shape({
          value: yup
            .string()
            .nullable()
            .test(
              "isValid",
              messages.INVALID_MAILBOX_VALUE,
              async function (value) {
                return !value
                  ? true
                  : new RegExp(REGEX_EXPS.mailbox).test(
                      value?.replace(/\s+/g, "")
                    );
              }
            ),
        }),
        [CONTACT_POINT_SYSTEM.DEPARTMENT_OF_BIRTH]: yup.object().shape({
          value: string,
        }),
        [CONTACT_POINT_SYSTEM.CITY]: yup.object().shape({
          value: string,
        }),
        [CONTACT_POINT_SYSTEM.BIRTH_ZIP_CODE]: yup.object().shape({
          value: string,
        }),
        [CONTACT_POINT_SYSTEM.BIRTH_CITY]: yup.object().shape({
          value: string,
        }),
        [CONTACT_POINT_SYSTEM.BIRTH_COUNTRY]: yup.object().shape({
          value: string,
        }),
        [CONTACT_POINT_SYSTEM.ADDRESS]: yup.object().shape({
          value: yup
            .string()
            .typeError(messages.NOT_STRING)
            .test("isValid", messages.INVALID_ADDRESS, async function (value) {
              return !value
                ? true
                : new RegExp(REGEX_EXPS.address).test(
                    value?.replace(/\s+/g, "")
                  );
            }),
        }),
        [CONTACT_POINT_SYSTEM.ZIP_CODE]: yup.object().shape({
          value: string,
        }),
        [CONTACT_POINT_SYSTEM.COMPLEMENT]: yup.object().shape({
          value: yup
            .string()
            .nullable()
            .test("isValid", messages.INVALID_ADDRESS, async function (value) {
              return !value
                ? true
                : new RegExp(REGEX_EXPS.address).test(
                    value?.replace(/\s+/g, "")
                  );
            }),
        }),
        [CONTACT_POINT_SYSTEM.EMAIL]: yup.object().shape({
          value: yup.string().nullable().email(messages.EMAIL),
        }),
        [CONTACT_POINT_SYSTEM.PHONE]: yup.object().shape({
          value: yup
            .string()
            .typeError(messages.INVALID_POLYNISIE)
            .nullable()
            .test(
              "isValid",
              messages.INVALID_POLYNISIE,
              async function (value) {
                return !value
                  ? true
                  : new RegExp(REGEX_EXPS.phonePolynisie).test(
                      value?.replace(/\s+/g, "")
                    );
              }
            ),
        }),
      }),
    }),
    payment: yup.object().shape({
      type: yup
        .string()
        .typeError(messages.REQUIRED_PAYMENT_TYPE)
        .required(messages.REQUIRED_PAYMENT_TYPE),
      ...(paymentType === PAYMENT_TYPES.DEBIT && {
        frequency: string,
        debitDay: string,
        payer: yup.object().shape({
          BIC: string,
          IBAN: yup
            .string()
            .typeError(messages.NOT_STRING)
            .required(messages.REQUIRED)
            .max(27, messages.MAX_LENGTH)
            .matches(
              /^(FR76)(12149|12239|14168|17469)\d{5}[A-Z0-9]{11}\d{2}$/i,
              messages.NOT_SUPPORTED_IBAN
            )
            .nullable(),
          owner: yup.object().shape({
            isAdherent: yup.boolean().typeError(messages.NOT_BOOLEAN),
            firstName: nameValidation,
            lastName: nameValidation,
          }),
        }),
        receiver: yup.object().shape({
          BIC: string,
          IBAN: yup
            .string()
            .typeError(messages.NOT_STRING)
            .test("isValid", messages.INVALID_IBAN, async function (value) {
              return value
                ? new RegExp(REGEX_EXPS.franceIban).test(
                    value.replace(/\s+/g, "")
                  )
                : true;
            })
            .nullable(),
          owner: yup.object().shape({
            firstName: nameValidation,
            lastName: nameValidation,
          }),
        }),
      }),
    }),
    additionalInfo: yup.object().shape({
      terminalUser: yup.object().shape({
        subscriberType: string,
        ...(subscriberType === SUBSCRIBER_TYPES.OTHER && {
          firstName: nameValidation,
          lastName: nameValidation,
        }),
      }),
    }),
  });
};

export default validation;

import cuid from "cuid";
import { find, get, isEmpty } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "shared/hooks/useApi";
import { GENERATE_SUBSCRIPTION_DOCUMENTS } from "utils/api/graphql/mutations/attachment";
import { DOWNLOAD_SINGLE_DOCUMENT } from "utils/api/graphql/mutations/download-document";
import { SIGN_DOCUMENT } from "utils/api/graphql/mutations/esign";
import {
  DUPLICATE_PROJECT,
  UPDATE_PROJECT,
} from "utils/api/graphql/mutations/projects";
import { UPDATE_SUBSCRIPTION } from "utils/api/graphql/mutations/subscription";
import { ATTACHMENTS } from "utils/api/graphql/queries/attachments";
import { GENERAL_COST } from "utils/api/graphql/queries/general-cost";
import { SUBSCRIPTION } from "utils/api/graphql/queries/subscription";
import {
  ACTION_TYPES,
  DOCUMENTS_TYPES,
  INSURANCE_PLAN_ATTACHMENTS_TYPES,
  SCREENS,
} from "utils/constants";
import { downloadDocument } from "utils/helpers/files";
import { filterByConditions } from "../../../..";
import Loader from "../Components/Loader";
import Navbar from "../Components/Navbar/Navbar";
import View from "./View";

const DOCUMENTS_TYPE = [
  DOCUMENTS_TYPES.DUTY_OF_ADVICE,
  DOCUMENTS_TYPES.MEMBERSHIP_FORM,
  DOCUMENTS_TYPES.SEPA_DIRECT_DEBIT_MANDATE,
  DOCUMENTS_TYPES.TERMINATION_MANDATE,
  DOCUMENTS_TYPES.TERMINATION_LETTER,
  INSURANCE_PLAN_ATTACHMENTS_TYPES.IPID,
  INSURANCE_PLAN_ATTACHMENTS_TYPES.INFO_NOTICE,
  INSURANCE_PLAN_ATTACHMENTS_TYPES.BROCHURE,
];

const GenerateESignDocuments = ({
  project,
  tracer,
  onNext,
  onBack,
  updateUrl,
  currentStatus,
}) => {
  const navigate = useNavigate();
  const subscriptionId =
    localStorage.getItem("subscriptionId") ||
    get(project, "fields.currentSubscription");
  const [loading, setLoading] = useState(true);
  const [documentsLoading, setDocumentsLoading] = useState(false);
  const [updateProject] = useMutation(UPDATE_PROJECT);
  const [duplicateProject] = useMutation(DUPLICATE_PROJECT);
  const [downloadSingleDocument] = useMutation(DOWNLOAD_SINGLE_DOCUMENT);
  const [getAttachments, { data: attachmentsList }] = useLazyQuery(ATTACHMENTS);
  const [getCost, { data: costData, loading: generalCostLoading }] =
    useLazyQuery(GENERAL_COST);
  const [generateSubscriptionDocuments] = useMutation(
    GENERATE_SUBSCRIPTION_DOCUMENTS
  );
  const { data } = useQuery(SUBSCRIPTION, {
    variables: { where: { id: subscriptionId } },
    onCompleted: ({ subscription }) => {
      getCost({
        variables: {
          where: {
            project: { id: project.id },
            insurancePlan: { id: get(subscription, "insurancePlan.id") },
          },
        },
      });

      getAttachments({
        fetchPolicy: "no-cache",
        variables: {
          where: {
            OR: [
              {
                AND: [
                  {
                    project: null,
                  },
                  {
                    insurancePlans: {
                      id: get(subscription, "insurancePlan.id", ""),
                    },
                  },
                ],
              },
              {
                subscription: {
                  id: get(subscription, "id", ""),
                },
              },
              {
                AND: [
                  {
                    project: {
                      id: get(subscription, "project.id"),
                    },
                  },
                  {
                    insurancePlans: {
                      id: get(subscription, "insurancePlan.id", ""),
                    },
                  },
                ],
              },
            ],
          },
          isIn: {
            type: DOCUMENTS_TYPE,
          },
        },
        onCompleted: () => {
          setLoading(false);
        },
      });
    },
  });
  const [updateSubscription] = useMutation(UPDATE_SUBSCRIPTION, {
    refetchQueries: [
      {
        query: SUBSCRIPTION,
        awaitRefetchQueries: true,
        variables: { where: { id: subscriptionId } },
      },
    ],
  });

  const [signDocuments] = useMutation(SIGN_DOCUMENT, {
    refetchQueries: [
      {
        query: SUBSCRIPTION,
        awaitRefetchQueries: true,
        variables: { where: { id: subscriptionId } },
      },
    ],
  });

  const findStatus = (data, template) => {
    return find(data, ({ template: _ }) => _ === template);
  };

  const updateProjectStatus = ({ template }) => {
    const status = findStatus(
      get(tracer, "flow.process.processStatuses", []),
      template
    );

    const actionsToExecute = filterByConditions(
      get(status, "actions", []),
      project
    );
    const syncActions = actionsToExecute
      .filter(({ type }) => type === ACTION_TYPES.SYNC_PROCESSES)
      .map(({ args: { status } }) => ({
        id: cuid(),
        status: { id: status },
      }));
    updateProject({
      variables: {
        where: { id: project.id },
        data: {
          statuses: [{ id: cuid(), status: { id: status.id } }, ...syncActions],
        },
      },
      onCompleted: ({ updateProject }) => {
        navigate(
          `${SCREENS.PRIVATE.GUEST.PROJECTS.DETAIL.path}/${updateProject?.id}`,
          {
            state: {
              process: get(omitDeep(tracer, "__typename"), "flow.process"),
              tracer: omitDeep(tracer, "__typename"),
            },
          }
        );
      },
    });
  };

  const duplicateProjectStatus = ({ template }) => {
    const {
      insurancePlans,
      currentSubscription,
      nextQuoteStatus,
      selectedToCompare,
      upsells,
      ...fields
    } = project?.fields;
    const status = findStatus(
      get(tracer, "flow.process.processStatuses", []),
      template
    );

    const actionsToExecute = filterByConditions(
      get(status, "actions", []),
      project
    );
    const syncActions = actionsToExecute
      .filter(({ type }) => type === ACTION_TYPES.SYNC_PROCESSES)
      .map(({ args: { status } }) => ({
        id: cuid(),
        status: { id: status },
      }));

    duplicateProject({
      variables: {
        where: { id: project.id },
        data: {
          statuses: [{ id: cuid(), status: { id: status.id } }, ...syncActions],
          fields,
        },
      },
      onCompleted: ({ duplicateProject }) => {
        localStorage.setItem("projectId", duplicateProject?.id);
        navigate(
          `${SCREENS.PRIVATE.GUEST.PROJECTS.DETAIL.path}/${duplicateProject?.id}`,
          {
            state: {
              process: get(omitDeep(tracer, "__typename"), "flow.process"),
              tracer: omitDeep(tracer, "__typename"),
            },
          }
        );
      },
    });
  };

  const onUpdateStatus = ({ template, isBeforeLocked = false }) => {
    if (isBeforeLocked) return duplicateProjectStatus({ template });
    updateProjectStatus({ template });
  };

  const downloadFile = async (id) => {
    await downloadSingleDocument({
      variables: { where: { id } },
      onCompleted: (_) => {
        const { document } = omitDeep(_.downloadSingleDocument, "__typename");
        downloadDocument(document);
      },
    });
  };
  const updateSponsorship = ({ additionalInfo = {} }) => {
    updateSubscription({
      variables: {
        where: {
          id: subscriptionId,
        },
        data: {
          additionalInfo: {
            ...additionalInfo,
            sponsor: {
              firstName: get(additionalInfo, "sponsor.firstName"),
              lastName: get(additionalInfo, "sponsor.lastName"),
              fullName: `${get(additionalInfo, "sponsor.firstName")} ${get(
                additionalInfo,
                "sponsor.lastName"
              )}`,
            },
          },
        },
      },
    });
  };

  const onSubmit = ({ origin }) => {
    setDocumentsLoading(true);
    updateProject({
      variables: {
        where: { id: project.id },
        data: {
          contact: {
            id: get(project, "contact.id"),
            fields: {
              ...get(project, "contact.fields", {}),
              membershipOrigin: origin,
            },
          },
        },
      },
      onCompleted: async () => {
        generateSubscriptionDocuments({
          variables: {
            data: { id: subscriptionId },
          },
          onCompleted: () => {
            signDocuments({
              variables: {
                data: { id: subscriptionId },
              },
              onCompleted: () => {
                const source = localStorage.getItem("source");
                updateUrl({
                  subscriptionId,
                  url: get(
                    data,
                    "subscription.insurancePlan.config.generatedUrl",
                    `${process.env.REACT_APP_BASE_URL}/#/public/process/init/${source}`
                  ),
                });
                onNext({
                  payload: {},
                  onCompleted: () => setDocumentsLoading(false),
                });
              },
            });
          },
        });
      },
    });
  };
  if (loading || generalCostLoading || isEmpty(costData)) return <Loader />;

  return (
    <>
      <Navbar
        project={project}
        progress={currentStatus?.progress}
        tracer={tracer}
      />
      <View
        subscription={omitDeep(get(data, "subscription", {}), "__typename")}
        generalCost={omitDeep(get(costData, "generalCost", {}), "__typename")}
        onSubmit={onSubmit}
        onBack={onBack}
        updateSponsorship={updateSponsorship}
        updateProjectStatus={onUpdateStatus}
        attachments={get(attachmentsList, "attachments.data", [])}
        downloadFile={downloadFile}
        documentsLoading={documentsLoading}
      />
    </>
  );
};

export default GenerateESignDocuments;

import { get } from "lodash";
import moment from "moment";
import { useLazyQuery, useQuery } from "shared/hooks/useApi";
import { COVERAGE_CATEGORIES } from "utils/api/graphql/queries/coverage-categories";
import { INSURANCE_PLANS } from "utils/api/graphql/queries/insurance-plans";
import { INSURANCE_PLAN_PARAMS_RULES } from "utils/constants";
import { getMinDeltaStart } from "utils/helpers/array";
import Loader from "../../../Comparison/Solyon/Components/Loader";
import View from "./View";

const CollectionNeeds = ({ project, onNext, onBack }) => {
  const [getDeltaStart] = useLazyQuery(INSURANCE_PLANS, {
    variables: {
      where: {
        isStandalone: true,
      },
    },
  });
  const next = async (fields) => {
    let issuanceDate = get(project, "contract.issuanceDate");

    if (!issuanceDate || project.expired)
      return getDeltaStart({
        onCompleted: ({ insurancePlans: { data: insurancePlans } }) => {
          const deltaRia = getMinDeltaStart({ insurancePlans, minValue: 30 });
          const deltaStart = getMinDeltaStart({
            insurancePlans,
            minValue: 1,
            key: INSURANCE_PLAN_PARAMS_RULES.DELTA_START,
          });
          const deltaS = project.ria ? deltaRia : deltaStart;

          issuanceDate = moment().add(deltaS, "days");

          onNext({
            payload: {
              contract: {
                id: get(project, "contract.id"),
                issuanceDate,
              },
              fields,
            },
          });
        },
      });
    onNext({
      payload: {
        fields,
      },
    });
  };
  const { data, loading } = useQuery(COVERAGE_CATEGORIES, {
    variables: { gt: { weight: 0 } },
  });

  if (loading) return <Loader />;

  return (
    <View
      data={get(data, "coverageCategories.data") || []}
      next={next}
      project={project}
      onBack={onBack}
    />
  );
};

export default CollectionNeeds;

import { MdAttachFile, MdOutlineFileDownload } from "react-icons/md";

const Item = ({ name, size, onDownload }) => {
  return (
    <div className="solyon-document">
      <MdAttachFile className="solyon-document__icon" size={24} />
      <div className="solyon-document__content">
        <div className="solyon-document__content--name">{name}</div>
        <div className="solyon-document__content--size">{`.pdf`}</div>
      </div>
      <div className="solyon-document__action" onClick={onDownload}>
        <MdOutlineFileDownload size={16} />
      </div>
    </div>
  );
};
export default Item;

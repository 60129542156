import { get, max } from "lodash";
import { useMemo, useState } from "react";
import { useQuery } from "shared/hooks/useApi";
import { INSURANCE_PLAN } from "utils/api/graphql/queries/insurance-plans";
import { SUBSCRIPTION } from "utils/api/graphql/queries/subscription";
import { CASE_TYPES, INSURANCE_PLAN_PARAMS_RULES } from "utils/constants";
import Loader from "../Components/Loader";
import Navbar from "../Components/Navbar/Navbar";
import View from "./View";

function findRules({ params, ruleName }) {
  let results = [];

  params.forEach((plan) => {
    if (plan.rules && Array.isArray(plan.rules)) {
      plan.rules.forEach((rule) => {
        if (rule.name === ruleName) {
          results.push(rule.value);
        }
      });
    }
  });

  return results;
}

const EffectiveDate = ({
  project,
  onNext,
  onBack,
  currentStatus,
  tracer,
  updateUrl,
}) => {
  const subscriptionId =
    localStorage.getItem("subscriptionId") ||
    get(project, "fields.currentSubscription");
  const insurancePlanId = useMemo(() => {
    return (
      get(
        get(project, "subscriptions", []).find(
          ({ id }) => id === subscriptionId
        ),
        "insurancePlan.id"
      ) || get(project, "fields.lastSelected.0")
    );
  }, []);
  const DEFAULT_DELTA_START = 1;
  const DEFAULT_DELTA_START_RIA = 40;
  const DEFAULT_DELTA_END_RIA = 0;
  const DEFAULT_DELTA_END = 0;
  const [loading, setLoading] = useState(false);
  const [deltas, setDeltas] = useState({
    start: { normal: DEFAULT_DELTA_START, ria: DEFAULT_DELTA_START_RIA },
    end: { normal: DEFAULT_DELTA_END, ria: DEFAULT_DELTA_END_RIA },
  });
  const { data } = useQuery(SUBSCRIPTION, {
    variables: { where: { id: subscriptionId } },
  });
  useQuery(INSURANCE_PLAN, {
    variables: {
      where: {
        id: insurancePlanId,
      },
    },
    onCompleted: ({ insurancePlan }) => {
      const deltaStartRia =
        max(
          findRules({
            params: get(insurancePlan, "params"),
            ruleName: INSURANCE_PLAN_PARAMS_RULES.DELTA_START_RIA,
          })
        ) || DEFAULT_DELTA_START_RIA;
      const deltaStart =
        max(
          findRules({
            params: get(insurancePlan, "params"),
            ruleName: INSURANCE_PLAN_PARAMS_RULES.DELTA_START,
          })
        ) || DEFAULT_DELTA_START;
      const deltaEnd =
        max(
          findRules({
            params: get(insurancePlan, "params"),
            ruleName: INSURANCE_PLAN_PARAMS_RULES.DELTA_END,
          })
        ) || DEFAULT_DELTA_END;
      const deltaEndRia =
        max(
          findRules({
            params: get(insurancePlan, "params"),
            ruleName: INSURANCE_PLAN_PARAMS_RULES.DELTA_END_RIA,
          })
        ) || DEFAULT_DELTA_END_RIA;
      setDeltas({
        start: { normal: deltaStart, ria: deltaStartRia },
        end: { normal: deltaEnd, ria: deltaEndRia },
      });
    },
  });

  const onSubmit = ({ contract, ria, fields, terminationRequest }) => {
    setLoading(true);
    const source = localStorage.getItem("source");
    updateUrl({
      subscriptionId,
      url: get(
        data,
        "subscription.insurancePlan.config.generatedUrl",
        `${process.env.REACT_APP_BASE_URL}/#/public/process/init/${source}`
      ),
    });
    onNext({
      payload: {
        contract: {
          ...contract,
          caseType: ria
            ? CASE_TYPES.RETURN_TO_COMPETITION
            : CASE_TYPES.NEW_CONTRACT,
        },
        ria,
        fields,
        terminationRequest,
      },
      onCompleted: () => setLoading(false),
    });
  };

  if (loading) return <Loader />;

  return (
    <>
      <Navbar
        project={project}
        progress={currentStatus?.progress}
        tracer={tracer}
        showCart
      />
      <View
        contract={get(project, "contract", {})}
        fields={get(project, "fields", {})}
        terminationRequest={get(project, "terminationRequest", {})}
        ria={project?.ria}
        locked={project?.locked}
        deltas={deltas}
        onSubmit={onSubmit}
        onBack={onBack}
      />
    </>
  );
};

export default EffectiveDate;

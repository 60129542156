import { Modal } from "antd";
import cuid from "cuid";
import { defaults, get, head, keyBy, map } from "lodash";
import { useState } from "react";
import { CONTACT_POINT_SYSTEM, QUALIFIER_TAGS } from "utils/constants";
import EmailSent from "./EmailSent";
import View from "./View";
import { QUALIFIERS } from "utils/api/graphql/queries/qualifiers";
import { CREATE_QUALIFICATION } from "utils/api/graphql/mutations/qualifications";
import { useMutation, useQuery } from "@apollo/client";

const AdherentInfo = ({ open, project, onSubmit, onClose }) => {
  const [saved, setSaved] = useState(false);
  const telecoms = defaults(keyBy(get(project, "contact.telecoms"), "system"), {
    [CONTACT_POINT_SYSTEM.PHONE]: { value: null },
    [CONTACT_POINT_SYSTEM.EMAIL]: { value: null },
  });

  const { data, loading, error } = useQuery(QUALIFIERS, {
    variables: {
      where: {
        tag: QUALIFIER_TAGS.CALL_LATER,
      },
      take: 1,
    },
  });

  const qualifierSelected = head(data?.qualifiers?.data) || null;

  const [addQualification] = useMutation(CREATE_QUALIFICATION, {
    refetchQueries: [
      {
        query: QUALIFIERS,
        awaitRefetchQueries: true,
        variables: {
          withDeleted: true,
          where: {
            qualifications: {
              contact: { id: get(project, "contact.id") },
            },
          },
        },
      },
    ],
  });

  const onSubmitFrom = ({ telecoms, showTelecoms, showAccept, ...contact }) => {
    const updatedTelecoms = map(telecoms, (item, key) =>
      defaults(item, { id: cuid(), system: key })
    );

    const currentContact = {
      ...contact,
      id: get(project, "contact.id") || cuid(),
      telecoms: updatedTelecoms,
      user: {
        id: cuid(),
        ...contact.user,
      },
    };

    addQualification({
      variables: {
        data: {
          id: cuid(),
          qualifier: { id: qualifierSelected?.id },
          contact: { id: currentContact.id },
        },
      },
    });

    onSubmit({
      contact: currentContact,
      visible: true,
    });

    setSaved(true);
  };

  return (
    <Modal centered open={open} onCancel={onClose} width={584} footer={null}>
      {saved ? (
        <EmailSent onClose={onClose} />
      ) : (
        <View
          contact={{ ...get(project, "contact", {}), telecoms }}
          onSubmit={onSubmitFrom}
          onClose={onClose}
          locked={project?.locked}
        />
      )}
    </Modal>
  );
};

export default AdherentInfo;

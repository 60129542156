import { Col, Row } from "antd";
import classNames from "classnames";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import Button from "../Components/Button";
import InputChecked from "../Components/svg/check_small.svg";
import InputError from "../Components/svg/error.svg";
import validation from "./validation";

const View = ({
  onNext,
  initialValues,
  locked,
}) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("B2C_LMF.ADHERENT_DETAILS", { returnObjects: true });
  const { handleSubmit, values, setFieldValue, ...formProps } = useFormik({
    initialValues,
    onSubmit: onNext,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
    ),
  });

  return (
    <>
      <h1 className="header-title">{TRANSLATION.TITLE}</h1>
      <span
        className="header-description"
        dangerouslySetInnerHTML={{ __html: TRANSLATION.DESCRIPTION }}
      ></span>
      <div className="__LMF--content LMF-content">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              label={TRANSLATION.FORM.LAST_NAME.LABEL}
              placeholder={TRANSLATION.FORM.LAST_NAME.PLACEHOLDER}
              className={classNames({
                valid: get(values, "contact.user.lastname"),
              })}
              {...bindInputProps({
                name: `contact.user.lastname`,
                values,
                setFieldValue,
                ...formProps,
              })}
              onChange={({ target: { value } }) => {
                setFieldValue(`contact.user.lastname`, value);
              }}
              suffix={
                get(formProps.errors, `contact.user.lastname`) ? (
                  <img src={InputError} />
                ) : (
                  <img src={InputChecked} />
                )
              }
              disabled={locked}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              label={TRANSLATION.FORM.FIRST_NAME.LABEL}
              placeholder={TRANSLATION.FORM.FIRST_NAME.PLACEHOLDER}
              className={classNames({
                valid: get(values, "contact.user.firstname"),
              })}
              {...bindInputProps({
                name: `contact.user.firstname`,
                values,
                setFieldValue,
                ...formProps,
              })}
              onChange={({ target: { value } }) => {
                setFieldValue(`contact.user.firstname`, value);
              }}
              suffix={
                get(formProps.errors, `contact.user.firstname`) ? (
                  <img src={InputError} />
                ) : (
                  <img src={InputChecked} />
                )
              }
              disabled={locked}
            />
          </Col>
          {get(values, "contact.user.firstname") &&
            get(values, "contact.user.lastname") && (
              <>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Input
                    label={TRANSLATION.FORM.PHONE.LABEL}
                    placeholder={TRANSLATION.FORM.PHONE.PLACEHOLDER}
                    inputMode="numeric"
                    className={classNames({
                      valid: get(
                        values,
                        `contact.telecoms.${CONTACT_POINT_SYSTEM.PHONE}.value`
                      ),
                    })}
                    {...bindInputProps({
                      name: `contact.telecoms.${CONTACT_POINT_SYSTEM.PHONE}.value`,
                      values,
                      setFieldValue,
                      ...formProps,
                    })}
                    onChange={({ target: { value } }) => {
                      setFieldValue(
                        `contact.telecoms.${CONTACT_POINT_SYSTEM.PHONE}.value`,
                        value
                      );
                    }}
                    suffix={
                      get(
                        formProps.errors,
                        `contact.telecoms.${CONTACT_POINT_SYSTEM.PHONE}.value`
                      ) ? (
                        <img src={InputError} />
                      ) : (
                        <img src={InputChecked} />
                      )
                    }
                    disabled={locked}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Input
                    label={TRANSLATION.FORM.EMAIL.LABEL}
                    placeholder={TRANSLATION.FORM.EMAIL.PLACEHOLDER}
                    className={classNames({
                      valid: get(
                        values,
                        `contact.telecoms.${CONTACT_POINT_SYSTEM.EMAIL}.value`
                      ),
                    })}
                    inputMode="numeric"
                    {...bindInputProps({
                      name: `contact.telecoms.${CONTACT_POINT_SYSTEM.EMAIL}.value`,
                      values,
                      setFieldValue,
                      ...formProps,
                    })}
                    onChange={({ target: { value } }) => {
                      setFieldValue(
                        `contact.telecoms.${CONTACT_POINT_SYSTEM.EMAIL}.value`,
                        value
                      );
                    }}
                    suffix={
                      get(
                        formProps.errors,
                        `contact.telecoms.${CONTACT_POINT_SYSTEM.EMAIL}.value`
                      ) ? (
                        <img src={InputError} />
                      ) : (
                        <img src={InputChecked} />
                      )
                    }
                    disabled={locked}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Input
                    type="checkbox"
                    label={TRANSLATION.FORM.OPT_INS.CONTACT}
                    checked={get(values, "contact.fields.acceptContact")}
                    {...bindInputProps({
                      name: "contact.fields.acceptContact",
                      values,
                      ...formProps,
                    })}
                    onChange={({ target: { checked } }) => {
                      setFieldValue("contact.fields.acceptContact", checked);
                    }}
                    disabled={locked}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Input
                    type="checkbox"
                    label={TRANSLATION.FORM.OPT_INS.MAIL_ME}
                    checked={get(values, "contact.fields.acceptDigital")}
                    {...bindInputProps({
                      name: "contact.fields.acceptDigital",
                      values,
                      ...formProps,
                    })}
                    onChange={({ target: { checked } }) => {
                      setFieldValue("contact.fields.acceptDigital", checked);
                    }}
                    disabled={locked}
                  />
                </Col>
              </>
            )}
        </Row>
      </div>
      <Button
        disabled={!isEmpty(formProps.errors)}
        onClick={handleSubmit}
        type="primary"
        size="middle"
      >
        <span>{TRANSLATION.NEXT}</span>
      </Button>
      <p className="__LMF--hint custom">{TRANSLATION.HINT}</p>
      <p className="__LMF--link">{TRANSLATION.POLICY}</p>
    </>
  );
};

export default View;

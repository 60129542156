import { REGEX_EXPS, USER_TYPES } from "utils/constants";
import * as yup from "yup";

const validator = (errors) => {
  const requiredString = yup
    .string()
    .typeError(errors.REQUIRED)
    .required(errors.REQUIRED);
  const requiredName = yup
    .string()
    .typeError(errors.REQUIRED)
    .matches(REGEX_EXPS.name, errors.INVALID_NAME)
    .required(errors.REQUIRED);

  const adminRequired = yup.mixed().when("user.meta.type", {
    is: USER_TYPES.BROKER_ADMIN.type,
    then: requiredString,
  });
  return yup.object().shape({
    code: adminRequired,
    socialReason: adminRequired,
    orias: adminRequired,
    organization: yup.object().when("user.meta.type", {
      is: USER_TYPES.BROKER_ADMIN.type,
      then: yup.object().shape({
        name: requiredString,
        form: requiredString,
      }),
    }),
    user: yup.object().shape({
      meta: yup.object().shape({
        type: requiredString,
        network: yup.mixed().when("type", {
          is: (type) => type === USER_TYPES.INTERNAL_ADVISOR.type,
          then: requiredString,
        }),
        agency: yup.mixed().when("type", {
          is: (type) => type === USER_TYPES.INTERNAL_ADVISOR.type,
          then: requiredString,
        }),
      }),
      gender: requiredString,
      firstname: requiredName,
      lastname: requiredName,
      email: yup
        .string()
        .typeError(errors.REQUIRED)
        .email(errors.EMAIL)
        .required(errors.REQUIRED),
      phone: yup
        .string()
        .typeError(errors.REQUIRED)
        .nullable()
        .test("isValid", errors.INVALID_VALUE, function (value) {
          if (!value) return true;
          return new RegExp(REGEX_EXPS.phone).test(value);
        }),
      address: yup.mixed().when("meta.type", {
        is: (type) => type === USER_TYPES.INTERNAL_ADVISOR.type,
        then: requiredString,
      }),
      city: yup.mixed().when("meta.type", {
        is: (type) => type === USER_TYPES.INTERNAL_ADVISOR.type,
        then: requiredString,
      }),
      zipCode: yup.mixed().when("meta.type", {
        is: (type) => type === USER_TYPES.INTERNAL_ADVISOR.type,
        then: yup
          .string()
          .typeError(errors.REQUIRED)
          .test("isValid", errors.INVALID_VALUE, async function (value) {
            return new RegExp(REGEX_EXPS.zipCode).test(value);
          }),
      }),
    }),
    distributions: yup.array().of(
      yup.object().shape({
        insurancePlan: yup.object().shape({
          id: requiredString,
        }),
        commissions: yup.array().of(requiredString).min(1, errors.REQUIRED),
      })
    ),
  });
};

export default validator;

import { Col, Row } from "antd";
import { get, isEmpty, set, uniq } from "lodash";
import { useTranslation } from "react-i18next";
import {
  MdOutlineAddRoad,
  MdOutlineAlternateEmail,
  MdOutlineCall,
  MdOutlinePinDrop,
  MdOutlineSouthAmerica,
} from "react-icons/md";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { CONTACT_POINT_SYSTEM, SUBSCRIPTION_FORM_KEYS } from "utils/constants";

const Contact = ({
  formProps,
  values,
  setPercent,
  setFieldValue,
  isFieldRequired,
  initialValues,
  errors,
  polynesiecities: cities,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("ANSET_SUBSCRIPTION", { returnObjects: true });
  const BLOCK = t("ANSET_SUBSCRIPTION.ADDITIONAL_INFORMATION", {
    returnObjects: true,
  });

  return (
    <div
      className="subscription-form--wrapper"
      id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.CONTACT}-section`}
    >
      <Form
        type="vertical"
        title={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.CONTACT.TITLE}
      >
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <Form type="highlight" title={SUBSCRIPTION.FORM.MAILBOX.TITLE}>
              <div className="grid--1">
                <Input
                  label={SUBSCRIPTION.FORM.MAILBOX.LABEL}
                  icon={<MdOutlinePinDrop size={16} />}
                  placeholder={SUBSCRIPTION.FORM.MAILBOX.PLACEHOLDER}
                  defaultValue={get(
                    initialValues,
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.MAILBOX}.value`
                  )}
                  errors={
                    errors[
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.MAILBOX}.value`
                    ]
                  }
                  onChange={({ target: { value } }) => {
                    set(
                      initialValues,
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.MAILBOX}.value`,
                      value
                    );
                  }}
                  touched={
                    !isEmpty(
                      errors[
                        `telecoms.contact.${CONTACT_POINT_SYSTEM.MAILBOX}.value`
                      ]
                    )
                  }
                  required={isFieldRequired(
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.MAILBOX}.value`
                  )}
                />
                <Select
                  icon={<MdOutlineAddRoad size={16} />}
                  label={SUBSCRIPTION.FORM.ZIP_CODE.LABEL}
                  placeholder={SUBSCRIPTION.FORM.ZIP_CODE.PLACEHOLDER}
                  showSearch
                  theme="light"
                  options={uniq(cities.map(({ zipCode }) => zipCode)).map(
                    (zipCode) => ({
                      label: zipCode,
                      value: zipCode,
                    })
                  )}
                  defaultValue={get(
                    initialValues,
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.POSTAL_ZIP_CODE}.value`
                  )}
                  errors={
                    errors[
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.POSTAL_ZIP_CODE}.value`
                    ]
                  }
                  touched={
                    !isEmpty(
                      errors[
                        `telecoms.contact.${CONTACT_POINT_SYSTEM.POSTAL_ZIP_CODE}.value`
                      ]
                    )
                  }
                  onChange={(value) => {
                    set(
                      initialValues,
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.POSTAL_ZIP_CODE}.value`,
                      value
                    );
                    const { city } = cities.find(
                      ({ zipCode }) => zipCode === value
                    );
                    set(
                      initialValues,
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.POSTAL_CITY}.value`,
                      city
                    );
                    setPercent(initialValues);
                  }}
                  required={isFieldRequired(
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.POSTAL_ZIP_CODE}.value`
                  )}
                />
                <Select
                  theme="light"
                  icon={<MdOutlineSouthAmerica size={16} />}
                  label={SUBSCRIPTION.FORM.CITY.LABEL}
                  placeholder={SUBSCRIPTION.FORM.CITY.PLACEHOLDER}
                  showSearch
                  options={cities
                    .filter(
                      ({ zipCode }) =>
                        zipCode ===
                        get(
                          initialValues,
                          `telecoms.contact.${CONTACT_POINT_SYSTEM.POSTAL_ZIP_CODE}.value`
                        )
                    )
                    .map(({ city }) => ({
                      label: city,
                      value: city,
                    }))}
                  defaultValue={get(
                    initialValues,
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.POSTAL_CITY}.value`
                  )}
                  value={get(
                    initialValues,
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.POSTAL_CITY}.value`
                  )}
                  errors={
                    errors[
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.POSTAL_CITY}.value`
                    ]
                  }
                  touched={
                    !isEmpty(
                      errors[
                        `telecoms.contact.${CONTACT_POINT_SYSTEM.POSTAL_CITY}.value`
                      ]
                    )
                  }
                  onChange={(value) => {
                    set(
                      initialValues,
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.POSTAL_CITY}.value`,
                      value
                    );
                    setPercent(initialValues);
                  }}
                  required={isFieldRequired(
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.POSTAL_CITY}.value`
                  )}
                />
              </div>
            </Form>
          </Col>
          <Col lg={24} xs={24}>
            <Form type="highlight" title={SUBSCRIPTION.FORM.ADDRESS.TITLE}>
              <div className="grid--1">
                <Input
                  label={SUBSCRIPTION.FORM.ADDRESS.LABEL}
                  icon={<MdOutlinePinDrop size={16} />}
                  placeholder={SUBSCRIPTION.FORM.ADDRESS.PLACEHOLDER}
                  defaultValue={get(
                    initialValues,
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS}.value`
                  )}
                  errors={
                    errors[
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS}.value`
                    ]
                  }
                  onChange={({ target: { value } }) => {
                    set(
                      initialValues,
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS}.value`,
                      value
                    );
                  }}
                  touched={
                    !isEmpty(
                      errors[
                        `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS}.value`
                      ]
                    )
                  }
                  required={isFieldRequired(
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS}.value`
                  )}
                />
                <Input
                  label={SUBSCRIPTION.FORM.COMPLEMENT.LABEL}
                  icon={<MdOutlinePinDrop size={16} />}
                  placeholder={SUBSCRIPTION.FORM.COMPLEMENT.PLACEHOLDER}
                  defaultValue={get(
                    initialValues,
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.COMPLEMENT}.value`
                  )}
                  errors={
                    errors[
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.COMPLEMENT}.value`
                    ]
                  }
                  onChange={({ target: { value } }) => {
                    set(
                      initialValues,
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.COMPLEMENT}.value`,
                      value
                    );
                  }}
                  touched={
                    !isEmpty(
                      errors[
                        `telecoms.contact.${CONTACT_POINT_SYSTEM.COMPLEMENT}.value`
                      ]
                    )
                  }
                  required={isFieldRequired(
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.COMPLEMENT}.value`
                  )}
                />

                <Select
                  icon={<MdOutlineAddRoad size={16} />}
                  label={SUBSCRIPTION.FORM.ZIP_CODE.LABEL}
                  placeholder={SUBSCRIPTION.FORM.ZIP_CODE.PLACEHOLDER}
                  theme="light"
                  showSearch
                  options={uniq(cities.map(({ zipCode }) => zipCode)).map(
                    (zipCode) => ({
                      label: zipCode,
                      value: zipCode,
                    })
                  )}
                  defaultValue={get(
                    initialValues,
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                  )}
                  errors={
                    errors[
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                    ]
                  }
                  touched={
                    !isEmpty(
                      errors[
                        `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                      ]
                    )
                  }
                  onChange={(value) => {
                    set(
                      initialValues,
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`,
                      value
                    );
                    const { city } = cities.find(
                      ({ zipCode }) => zipCode === value
                    );
                    set(
                      initialValues,
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.CITY}.value`,
                      city
                    );
                    setPercent(initialValues);
                  }}
                  required={isFieldRequired(
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                  )}
                />
                <Select
                  icon={<MdOutlineSouthAmerica size={16} />}
                  label={SUBSCRIPTION.FORM.CITY.LABEL}
                  placeholder={SUBSCRIPTION.FORM.CITY.PLACEHOLDER}
                  theme="light"
                  showSearch
                  options={cities
                    .filter(
                      ({ zipCode }) =>
                        zipCode ===
                        get(
                          initialValues,
                          `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                        )
                    )
                    .map(({ city }) => ({
                      label: city,
                      value: city,
                    }))}
                  defaultValue={get(
                    initialValues,
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.CITY}.value`
                  )}
                  value={get(
                    initialValues,
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.CITY}.value`
                  )}
                  errors={
                    errors[
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.CITY}.value`
                    ]
                  }
                  touched={
                    !isEmpty(
                      errors[
                        `telecoms.contact.${CONTACT_POINT_SYSTEM.CITY}.value`
                      ]
                    )
                  }
                  onChange={(value) => {
                    set(
                      initialValues,
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.CITY}.value`,
                      value
                    );
                    setPercent(initialValues);
                  }}
                  required={isFieldRequired(
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.CITY}.value`
                  )}
                />
              </div>
            </Form>
          </Col>
          <Col lg={24} xs={24}>
            <Form type="highlight" title={"Coordonnées de contact"}>
              <div className="grid--1">
                <Input
                  placeholder={BLOCK.TERMINAL.FIELDS.VINI.PLACEHOLDER}
                  label={BLOCK.TERMINAL.FIELDS.VINI.LABEL}
                  icon={<MdOutlineCall size={16} />}
                  defaultValue={get(
                    initialValues,
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.PHONE}.value`
                  )}
                  errors={
                    errors[
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.PHONE}.value`
                    ]
                  }
                  touched={
                    !isEmpty(
                      errors[
                        `telecoms.contact.${CONTACT_POINT_SYSTEM.PHONE}.value`
                      ]
                    )
                  }
                  onChange={({ target: { value } }) => {
                    set(
                      initialValues,
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.PHONE}.value`,
                      value
                    );
                  }}
                  onBlur={() => setPercent(initialValues)}
                  required={isFieldRequired(
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.PHONE}.value`
                  )}
                />
                <Input
                  label={SUBSCRIPTION.FORM.EMAIL.LABEL}
                  icon={<MdOutlineAlternateEmail size={16} />}
                  placeholder={SUBSCRIPTION.FORM.EMAIL.PLACEHOLDER}
                  defaultValue={get(
                    initialValues,
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`
                  )}
                  errors={
                    errors[
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`
                    ]
                  }
                  touched={
                    !isEmpty(
                      errors[
                        `telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`
                      ]
                    )
                  }
                  onChange={({ target: { value } }) => {
                    set(
                      initialValues,
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`,
                      value
                    );
                  }}
                  onBlur={() => setPercent(initialValues)}
                  required={isFieldRequired(
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`
                  )}
                />
              </div>
            </Form>
          </Col>
          {/* {get(
            initialValues,
            `telecoms.contact.${CONTACT_POINT_SYSTEM.COUNTRY}.value`
          ) !== "FRANCE" && (
            <Col lg={24} xs={24}>
              <Input
                label={SUBSCRIPTION.FORM.COUNTRY.LABEL}
                icon={<MdOutlineMap size={16} />}
                placeholder={SUBSCRIPTION.FORM.COUNTRY.PLACEHOLDER}
                defaultValue={get(
                  initialValues,
                  `telecoms.contact.${CONTACT_POINT_SYSTEM.COUNTRY}.value`
                )}
                errors={
                  errors[
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.COUNTRY}.value`
                  ]
                }
                touched={
                  !isEmpty(
                    errors[
                      `telecoms.contact.${CONTACT_POINT_SYSTEM.COUNTRY}.value`
                    ]
                  )
                }
                onChange={({ target: { value } }) => {
                  set(
                    initialValues,
                    `telecoms.contact.${CONTACT_POINT_SYSTEM.COUNTRY}.value`,
                    value
                  );
                }}
                onBlur={() => setPercent(initialValues)}
                required={isFieldRequired(
                  `telecoms.contact.${CONTACT_POINT_SYSTEM.COUNTRY}.value`
                )}
              />
            </Col>
          )} */}
        </Row>
      </Form>
    </div>
  );
};

export default Contact;

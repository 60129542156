import { Modal } from "antd";
import { filter, find, get } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import {
  CONTACT_POINT_SYSTEM,
  DATE_FORMAT,
  RELATIONSHIP,
} from "utils/constants";
import Button from "../Button";

const Profile = ({ contact, open, setOpen, onEdit, onRelatedPersonEdit }) => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("B2C_LMF.PROFILE_CART", { returnObjects: true });

  const getTelecom = (system) =>
    get(find(get(contact, "telecoms", []), { system }), "value", "");

  const children = filter(get(contact, "relatedPersons", []), {
    relationship: RELATIONSHIP.CHILD,
  });
  const spouse = get(contact, "relatedPersons", []).find(
    ({ relationship }) => relationship === RELATIONSHIP.SPOUSE
  );

  return (
    <Modal
      open={open}
      centered
      onCancel={() => setOpen(false)}
      width={400}
      footer={null}
      className="LMF-formula-modal"
    >
      <span className="LMF-formula-modal__title">{TRANSLATION.TITLE}</span>
      <div className="LMF-formula-modal__body">
        <div className="LMF-formula-modal__body--adherent">
          <span className="LMF-formula-modal__body--adherent__title">
            <span>{TRANSLATION.ADHERENT.TITLE}</span>
            <span onClick={() => onEdit()}>{TRANSLATION.EDIT}</span>
          </span>
          <ul>
            <li>
              <span>{TRANSLATION.ADHERENT.NAME}</span>
              <span className="capitalize">
                {get(contact, "user.firstname")} {get(contact, "user.lastname")}
              </span>
            </li>
            <li>
              <span>{TRANSLATION.BIRTH_DATE}</span>
              <span>
                {moment(get(contact, "user.birthDate")).format(DATE_FORMAT)}
              </span>
            </li>
            <li>
              <span>{TRANSLATION.ADHERENT.EMAIL}</span>
              <span>{getTelecom(CONTACT_POINT_SYSTEM.EMAIL)}</span>
            </li>
            <li>
              <span>{TRANSLATION.ADHERENT.PHONE}</span>
              <span>{getTelecom(CONTACT_POINT_SYSTEM.PHONE)}</span>
            </li>
          </ul>
        </div>
      </div>
      {spouse && (
        <>
          <div className="LMF-formula-modal__body">
            <div className="LMF-formula-modal__body--adherent">
              <span className="LMF-formula-modal__body--adherent__title">
                <span>{TRANSLATION.SPOUSE}</span>
                <span onClick={() => onRelatedPersonEdit()}>
                  {TRANSLATION.EDIT}
                </span>
              </span>
              <ul>
                <li>
                  <span>{TRANSLATION.FULL_NAME}</span>
                  <span className="capitalize">
                    {get(spouse, "identity.user.firstname")}{" "}
                    {get(spouse, "identity.user.lastname")}
                  </span>
                </li>
                <li>
                  <span>{TRANSLATION.BIRTH_DATE}</span>
                  <span>
                    {moment(get(spouse, "identity.user.birthDate")).format(
                      DATE_FORMAT
                    )}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </>
      )}
      {children.length > 0 && (
        <div className="LMF-formula-modal__body--children">
          <div className="LMF-formula-modal__body--adherent">
            <span className="LMF-formula-modal__body--adherent__title">
              <span>{TRANSLATION.CHILDREN}</span>
              <span onClick={() => onRelatedPersonEdit()}>
                {TRANSLATION.EDIT}
              </span>
            </span>
          </div>
          {children.map((child, index) => (
            <div key={`cart-child-${index}`}>
              <span className="LMF-formula-modal__body--children__title">
                <span>{TRANSLATION[`COUNT_${index + 1}`]}</span>
              </span>
              <ul>
                <li>
                  <span>{TRANSLATION.FULL_NAME}</span>
                  <span className="capitalize">
                    {get(child, "identity.user.firstname")}{" "}
                    {get(child, "identity.user.lastname")}
                  </span>
                </li>
                <li>
                  <span>{TRANSLATION.BIRTH_DATE}</span>
                  <span>
                    {moment(get(child, "identity.user.birthDate")).format(
                      DATE_FORMAT
                    )}
                  </span>
                </li>
              </ul>
            </div>
          ))}
        </div>
      )}

      <div className="LMF-formula-modal__actions">
        <Button
          onClick={() => setOpen(false)}
          className="w-full"
          type="white"
          size="middle"
        >
          <span>{TRANSLATION.CLOSE}</span>
        </Button>
      </div>
    </Modal>
  );
};
export default Profile;

import { Drawer, Switch } from "antd";
import cuid from "cuid";
import { FieldArray, Formik } from "formik";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { MdAdd, MdCheck, MdDelete } from "react-icons/md";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import { INSURANCE_PLANS } from "utils/api/graphql/queries/insurance-plans";
import { PROCESSES } from "utils/api/graphql/queries/processes";
import { bindArrayInputProps, bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const View = ({ data, open, onClose, onSubmit }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.OFFER);
  const CREATE = t("CREATE", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  const isChecked = (values, index) =>
    get(values, `offerInsurancePlans.${index}.main`, false);

  return (
    <Formik
      initialValues={{
        ...data,
        offerInsurancePlans: get(data, "offerInsurancePlans", []).sort(
          (a, b) => b.main - a.main
        ),
        processes: get(data, "processes", []).map(({ id }) => id),
      }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, values, handleSubmit, setFieldValue, ...formProps }) => (
        <Drawer
          title={
            <div className="d-flex justify--between m-right-24">
              <div>{CREATE.HEADER.TITLE}</div>
              <Button
                type="primary"
                onClick={handleSubmit}
                disabled={
                  !get(values, "offerInsurancePlans", []).length || !isValid
                }
              >
                {CREATE.HEADER.SAVE}
                <MdCheck />
              </Button>
            </div>
          }
          placement="right"
          onClose={onClose}
          open={open}
          className="offer__drawer"
          contentWrapperStyle={{
            width: "600px",
          }}
        >
          <div className="grid--1">
            <Input
              placeholder={FORM.NAME}
              label={FORM.NAME}
              {...bindInputProps({ name: "name", values, ...formProps })}
            />
            <Input
              placeholder={FORM.DESCRIPTION}
              label={FORM.DESCRIPTION}
              {...bindInputProps({
                name: "description",
                values,
                ...formProps,
              })}
            />
            <Select
              type="async"
              mode="multiple"
              node={{
                query: PROCESSES,
                variables: { orderBy: [{ name: "ASC" }] },
                accessors: {
                  root: "processes.data",
                  label: "name",
                },
              }}
              label={FORM.PROCESS}
              placeholder={FORM.PROCESS}
              {...bindInputProps({
                name: "processes",
                values,
                ...formProps,
              })}
              allowClear
              onChange={(values) => setFieldValue("processes", values)}
            />
            {FORM.FORMULAS}
            <FieldArray
              name={"offerInsurancePlans"}
              render={(arrayHelpers) => [
                get(values, "offerInsurancePlans", []).map((_, index) => (
                  <div className="d-flex flex--wrap" key={_.id}>
                    <div className="d-flex flex--grow-1">
                      <div className="grid--2 flex--grow-1">
                        <Select
                          type="async"
                          node={{
                            query: INSURANCE_PLANS,
                            variables: { orderBy: [{ name: "ASC" }] },
                            accessors: {
                              root: "insurancePlans.data",
                              label: "name",
                            },
                          }}
                          placeholder={FORM.SELECT_FORMULA}
                          {...bindArrayInputProps({
                            parent: "offerInsurancePlans",
                            index: index,
                            name: "insurancePlan.id",
                            values,
                            ...formProps,
                          })}
                          filter={({ value }) => {
                            return !get(values, `offerInsurancePlans`, []).some(
                              ({ insurancePlan }, i) => {
                                return (
                                  i !== index &&
                                  insurancePlan?.id &&
                                  insurancePlan.id === value
                                );
                              }
                            );
                          }}
                        />
                        <Input
                          type="number"
                          placeholder={FORM.WEIGHT}
                          {...bindArrayInputProps({
                            parent: "offerInsurancePlans",
                            index: index,
                            name: "weight",
                            values,
                            ...formProps,
                          })}
                        />
                      </div>
                      <div className="d-flex">
                        <Switch
                          className="button__switch"
                          checkedChildren={FORM.MAIN}
                          checked={isChecked(values, index)}
                          onChange={(e, _) => {
                            get(values, "offerInsurancePlans", []).map((_, i) =>
                              setFieldValue(
                                `offerInsurancePlans.${i}.main`,
                                false
                              )
                            );
                            setFieldValue(
                              `offerInsurancePlans.${index}.main`,
                              e
                            );
                          }}
                        />
                      </div>
                      <div className="d-flex">
                        <MdDelete
                          size={24}
                          color={COLORS.C_DANGER}
                          onClick={() => arrayHelpers.remove(index)}
                        />
                      </div>
                    </div>
                  </div>
                )),
                <Button
                  type="primary"
                  key="add-row"
                  onClick={() => {
                    arrayHelpers.push({
                      id: cuid(),
                      insurancePlan: { id: null },
                      main: !get(values, "offerInsurancePlans", []).length,
                    });
                  }}
                >
                  <MdAdd /> {FORM.ADD_FORMULA}
                </Button>,
              ]}
            />
          </div>
        </Drawer>
      )}
    </Formik>
  );
};

export default View;

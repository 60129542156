import { Modal } from "antd";
import { get } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdForwardToInbox } from "react-icons/md";
import { useParams } from "react-router-dom";
import Button from "shared/components/Button";
import { SelectPlan } from "shared/components/SmartphoneWidgets";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { CREATE_LINK } from "utils/api/graphql/mutations/link";
import { TRACER } from "utils/api/graphql/queries/tracers";
import { CURRENCY_SYMBOL, MODAL_STATES } from "utils/constants";
import { costToFrench } from "utils/helpers/values";

const PlansModal = ({
  data,
  open,
  onClose,
  onSendQuote,
  onDownload,
  fields,
}) => {
  const { id } = useParams();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const [state, setState] = useState(MODAL_STATES.INITIAL);
  const [addLinks, setAddLinks] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [addLink] = useMutation(CREATE_LINK);
  const [getTracer] = useLazyQuery(TRACER);

  const onCheck = (id) => {
    setSelectedData((prev) =>
      prev.includes(id)
        ? [...prev.filter((item) => item !== id)]
        : [...prev, id]
    );
  };

  const sendQuote = () => {
    onSendQuote({
      selectedData,
      payload: {
        fields: {
          ...fields,
          insurancePlans: selectedData,
        },
      },
    });
    onClose();
  };

  const download = () => {
    onDownload({
      payload: {
        locked: true,
        fields: {
          ...fields,
          insurancePlans: selectedData,
        },
      },
    });
    onClose();
  };

  const plans = useMemo(
    () =>
      data?.map(({ cost, currency, insurancePlan }) => ({
        id: insurancePlan?.id,
        label: insurancePlan?.name,
        description: get(insurancePlan, "description", ""),
        price: `${costToFrench(cost / 12, 0)} ${
          CURRENCY_SYMBOL[currency] || currency
        }`,
        monthlyPrice: `${costToFrench(cost, 0)} ${
          CURRENCY_SYMBOL[currency] || currency
        }`,
      })),
    [open]
  );

  return (
    <Modal open={open} onCancel={onClose} width={"60%"} footer={null}>
      <div className="grid--1">
        <div className="title">
          {"Sélectionnez les offres à télécharger ou à envoyer par mail :"}
        </div>
        <SelectPlan items={plans} checked={selectedData} onCheck={onCheck} />
        <div className="d-flex justify--center">
          <Button
            onClick={download}
            disabled={!selectedData.length}
            type="gray--underline"
            size="full--width"
          >
            {"Télécharger"}
          </Button>
          <Button
            onClick={sendQuote}
            disabled={!selectedData.length}
            type="primary"
            size="full--width"
          >
            {"Envoyer par email"}
            <MdForwardToInbox size={16} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PlansModal;

import { Col, Row } from "antd";
import classNames from "classnames";
import { get, range } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import Slider from "shared/components/Slider";
import { NAME_SPACES } from "shared/locales/constants";

const Filters = ({ fields, setFields, onFilter, needs, setNeeds, data }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const [budgetError, setBudgetError] = useState(false);

  const levels = range(1, 5);
  const max = 4;

  return (
    <div className="card-skeleton--container highlighted">
      <div className="card-skeleton--wrapper">
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <h3 className="title--md">
              {DETAIL.TEMPLATES.FILTERS.LEVEL_OF_GUARANTEES}
            </h3>
          </Col>
          <Col lg={24} xs={24}>
            <Row gutter={[8, 8]}>
              {data?.map(({ icon, label, id, value }) => (
                <Col key={id} lg={24} xs={24}>
                  <div className="levels-coverage--wrapper">
                    <div className="d-flex align--center">
                      <img className="img" src={icon} alt={label} />
                      <span className="label-coverage">{label}</span>
                    </div>
                    <div className="custom-slider--wrapper">
                      <Slider
                        type="dot"
                        items={levels}
                        name={"grp" + label}
                        value={
                          (get(needs, value, 1) > max
                            ? 3
                            : get(needs, value, 1)) || 1
                        }
                        onChange={(item) =>
                          setNeeds((prev) => ({
                            ...prev,
                            [value]: item,
                          }))
                        }
                      />
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <div className="row-divider" />
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <h3 className="title--md">
              {DETAIL.TEMPLATES.FILTERS.BUDGET.TITLE}
            </h3>
          </Col>
          <Col lg={24} xs={24}>
            <Row gutter={[24, 24]}>
              <Col lg={24} xs={24}>
                <div className="custom-slider--wrapper">
                  <Slider
                    type="line"
                    tooltip={{
                      open: true,
                      formatter: (value) => `${value} €`,
                    }}
                    range
                    min={0}
                    max={1000}
                    value={[
                      get(fields, "budget.min"),
                      get(fields, "budget.max"),
                    ]}
                    onChange={([min, max]) =>
                      setFields((fields) => ({
                        ...fields,
                        budget: {
                          min,
                          max,
                        },
                      }))
                    }
                  />
                </div>
              </Col>
              <Col lg={24} xs={24}>
                <div className="custom-input__wrapper reverse">
                  <Row align="top" gutter={[7, 7]}>
                    <Col lg={11} xs={24}>
                      <Input
                        type="number"
                        suffix="€"
                        min={0}
                        max={get(fields, "budget.max")}
                        value={get(fields, "budget.min")}
                        label={DETAIL.TEMPLATES.FILTERS.BUDGET.MIN}
                        onChange={({ target: { value } }) =>
                          value <= 1000 &&
                          setFields((fields) => ({
                            ...fields,
                            budget: {
                              ...fields.budget,
                              min: Number(value),
                            },
                          }))
                        }
                        onBlur={({ target: { value } }) => {
                          if (value > get(fields, "budget.max"))
                            return setBudgetError(true);
                          setBudgetError(false);
                        }}
                        touched
                        errors={budgetError}
                      />
                    </Col>
                    <Col lg={2} xs={24}>
                      <div className="divider-input" />
                    </Col>
                    <Col lg={11} xs={24}>
                      <Input
                        type="number"
                        suffix="€"
                        min={get(fields, "budget.min")}
                        max="1000"
                        value={get(fields, "budget.max")}
                        label={DETAIL.TEMPLATES.FILTERS.BUDGET.MAX}
                        onChange={({ target: { value } }) =>
                          value <= 1000 &&
                          setFields((fields) => ({
                            ...fields,
                            budget: {
                              ...fields.budget,
                              max: Number(value),
                            },
                          }))
                        }
                        onBlur={({ target: { value } }) => {
                          if (value < get(fields, "budget.min"))
                            return setBudgetError(true);
                          setBudgetError(false);
                        }}
                        touched
                        errors={budgetError}
                      />
                    </Col>
                    <span
                      className={classNames("input--errors", {
                        hidden: !budgetError,
                      })}
                    >
                      {DETAIL.TEMPLATES.FILTERS.BUDGET.ERROR}
                    </span>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={24} xs={24}>
            <Button
              onClick={() =>
                onFilter({
                  min: get(fields, "budget.min"),
                  max: get(fields, "budget.max"),
                  needs,
                })
              }
              type="secondary"
              block
              disabled={get(fields, "budget.min") > get(fields, "budget.max")}
            >
              {DETAIL.TEMPLATES.FILTERS.BUTTON.TEXT.REFRESH_PRODUCT_LIST}
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Filters;

import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import icon from "../Components/svg/check-vert_eau.svg";

const View = () => {
  const { t } = useTranslation(NAME_SPACES.PUBLIC.PROCESSES);
  const TRANSLATION = t("B2C_LMF.SUBSCRIPTION_DONE", { returnObjects: true });
  return (
    <div className="subscription-done">
      <img src={icon} />
      <h1 className="header-title done">{TRANSLATION.TITLE}</h1>
      <span
        className="header-description"
        dangerouslySetInnerHTML={{ __html: TRANSLATION.DESCRIPTION }}
      ></span>
    </div>
  );
};

export default View;
